<div class="profile py-8" *ngIf="user">
    <div class="container">
        <div class="row justify-content-center mb-2">
            <div class="col-12 col-sm-9 col-lg-6">
                <div class="d-flex flex-wrap justify-content-center">
                    <div class="profile__avatar flex-grow-0 mr-5">
                        <app-user-avatar [user]="user" [size]="'big'"></app-user-avatar>
                    </div>
                    <div class="profile__content mt-4">
                        <h1 class="mb-3">{{ user.firstName }} {{ user.lastName }}</h1>
                        <ul class="list-unstyled mt-4">
                            <li class="list-item">
                                <a href="mailto:{{ user.email }}">
                                    <span class="material-icons material-icons-outlined d-inline-block align-middle mr-2">email</span>
                                    <span class="d-inline-block align-middle">{{ user.email }}</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="row justify-content-center mt-5" *ngIf="isMe">
            <div class="col-11 col-sm-7 col-lg-4 border px-1 px-sm-2">
                <h5 class="mt-1 mb-2 font-weight-bold">Abonnement</h5>
                <div *ngIf="subscription; else noSubscription">
                    <div class="mt-3">
                        <span>{{ subscription.frequency == 1 ? 'Mensuel' : subscription.frequency == 3 ? 'Trimestriel' : 'Annuel' }}, {{ subscription.storage / 1024 }} Go</span>
                        <a class="btn-link-primary pl-2" [routerLink]="['/profil/abonnement/augmenter-stockage']" title="Augmenter">Augmenter</a>
                    </div>
                    <div>
                        <progress class="align-middle" value="{{ subscription.storageUsed / subscription.storage * 100 }}" max="100"></progress>
                        <span class="ml-2 align-middle small">{{ subscription.storageUsed / 1024 | number:'1.0-2' }} Go utilisé ({{ subscription.storageUsed / subscription.storage * 100 | number:'1.0-2' }}%)</span>
                    </div>
                    <div class="mt-4 mb-1t">
                        <a class="btn-link-primary" [routerLink]="['/profil/abonnement/mettre-a-jour']" title="Mettre à jour mon moyen de paiement">
                            <span class="material-icons-outlined align-middle mr-2" style="font-size: 20px;">credit_card</span>
                            <span class="d-inline-block align-middle">Mettre à jour mon moyen de paiement</span>
                        </a>
                    </div>
                    <div class="my-1" *ngIf="subscription.invoices.length">
                        <a class="btn-link-primary" [routerLink]="['/profil/abonnement/factures']" title="Voir mes factures">
                            <span class="material-icons-outlined align-middle mr-2" style="font-size: 20px;">visibility</span>
                            <span class="d-inline-block align-middle">Voir mes factures</span>
                        </a>
                    </div>
                    <div class="my-1">
                        <button class="btn-link-danger" type="button" title="Arrêter mon abonnement" (click)="onStop()">
                            <span class="material-icons-outlined align-middle mr-2" style="font-size: 20px;">do_not_disturb_on</span>
                            <span class="d-inline-block align-middle">Arrêter mon abonnement</span>
                        </button>
                    </div>
                </div>
                <ng-template #noSubscription>
                    <div class="my-3">
                        <span class="pr-2 mb-1">Vous n'avez pas d'abonnement</span>
                        <a class="btn-link-primary mb-1" [routerLink]="['/profil/abonnement']" title="Souscrire">Souscrire</a>
                    </div>
                    <div class="my-1 d-flex justify-content-center justify-content-sm-start" *ngIf="user.invoices.length">
                        <a class="btn-link-primary" [routerLink]="['/profil/abonnement/factures']" title="Voir mes factures">
                            <span class="material-icons-outlined align-middle mr-2" style="font-size: 20px;">visibility</span>
                            <span class="d-inline-block align-middle">Voir mes factures</span>
                        </a>
                    </div>
                </ng-template>
            </div>
        </div>
        <div class="row justify-content-center mt-10" *ngIf="isMe">
            <div class="col-12 col-sm-5 d-flex justify-content-center justify-content-sm-end">
                <a class="btn btn-link-primary" [routerLink]="['/profil/modifier']">
                    <span class="material-icons-outlined mr-2 d-inline-block align-middle">edit</span>
                    <span class="d-inline-block align-middle">Modifier mon profil</span>
                </a>
            </div>
            <div class="col-12 col-sm-7 d-flex justify-content-sm-start mt-2 mt-sm-0">
                <button type="button" class="btn btn-link-danger ml-sm-4" (click)="onDeleteAccount()">
                    <span class="material-icons mr-2 d-inline-block align-middle">delete_outline</span>
                    <span class="d-inline-block align-middle">Quitter définitivement l'application</span>
                </button>
            </div>
        </div>
    </div>
</div>
