import { Directive, forwardRef, Attribute } from '@angular/core';
import { Validator, AbstractControl, FormGroup, ValidatorFn, NG_VALIDATORS } from '@angular/forms';

export function matchingValidator(matchingControlName: string, reverse: boolean = false): ValidatorFn {
  return (control: AbstractControl): {[key: string]: any} | null => {
    const formGroup = control.parent as FormGroup;
    if (formGroup) {
      const matchingControl = formGroup.get(matchingControlName);
      const matching = matchingControl.value == control.value;

      if (reverse) {
        if (!matching) {
          matchingControl.setErrors({
            matching : true
          });
        } else if (matchingControl.errors) {
          delete matchingControl.errors['matching'];
	        matchingControl.updateValueAndValidity({ onlySelf: true});
        }
      }

      return (matching || reverse) ? null : {'matching': {value: control.value}};
    }

    return null;
  };
}
