/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./user-avatar.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../pipes/initials.pipe";
import * as i4 from "./user-avatar.component";
var styles_UserAvatarComponent = [i0.styles];
var RenderType_UserAvatarComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_UserAvatarComponent, data: {} });
export { RenderType_UserAvatarComponent as RenderType_UserAvatarComponent };
function View_UserAvatarComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "avatar__content__initials"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", "", " "])), i1.ɵppd(2, 1), i1.ɵppd(3, 1)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i1.ɵnov(_v.parent.parent, 0), _co.user.firstName)); var currVal_1 = i1.ɵunv(_v, 1, 1, _ck(_v, 3, 0, i1.ɵnov(_v.parent.parent, 0), _co.user.lastName)); _ck(_v, 1, 0, currVal_0, currVal_1); }); }
function View_UserAvatarComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["aria-hidden", "true"], ["class", "avatar__content__image"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgStyleImpl, i2.ɵNgStyleR2Impl, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgStyle, [i2.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(3, { "background-image": 0 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 3, 0, (("url(" + (_co.user.avatar.data ? _co.user.avatar.data : _co.user.avatar.getAbsolutePath())) + ")")); _ck(_v, 2, 0, currVal_0); }, null); }
function View_UserAvatarComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UserAvatarComponent_2)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UserAvatarComponent_3)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.user.avatar; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.user.avatar; _ck(_v, 4, 0, currVal_1); }, null); }
function View_UserAvatarComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "avatar__content__default"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "i", [["class", "far fa-user"]], null, null, null, null, null))], null, null); }
export function View_UserAvatarComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i3.InitialsPipe, []), (_l()(), i1.ɵeld(1, 0, null, null, 8, "div", [["class", "avatar d-inline-block align-middle rounded-circle"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(3, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(4, { "avatar--tiny": 0, "avatar--small": 1, "avatar--big": 2, "under-surveillance": 3, "is-deleted": 4, "online": 5 }), (_l()(), i1.ɵeld(5, 0, null, null, 4, "div", [["class", "avatar__content rounded-circle bg-grey-85 border border-light-grey overflow-hidden w-100 h-100"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UserAvatarComponent_1)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UserAvatarComponent_4)), i1.ɵdid(9, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "avatar d-inline-block align-middle rounded-circle"; var currVal_1 = _ck(_v, 4, 0, (_co.size == "tiny"), (_co.size == "small"), (_co.size == "big"), _co.isUnderSurveillance, ((_co.user && _co.user.deletedAt) || _co.memberDeleted), _co.isOnline); _ck(_v, 3, 0, currVal_0, currVal_1); var currVal_2 = _co.user; _ck(_v, 7, 0, currVal_2); var currVal_3 = !_co.user; _ck(_v, 9, 0, currVal_3); }, null); }
export function View_UserAvatarComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-user-avatar", [], null, null, null, View_UserAvatarComponent_0, RenderType_UserAvatarComponent)), i1.ɵdid(1, 114688, null, 0, i4.UserAvatarComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var UserAvatarComponentNgFactory = i1.ɵccf("app-user-avatar", i4.UserAvatarComponent, View_UserAvatarComponent_Host_0, { user: "user", size: "size", isUnderSurveillance: "isUnderSurveillance", memberDeleted: "memberDeleted", isOnline: "isOnline" }, {}, []);
export { UserAvatarComponentNgFactory as UserAvatarComponentNgFactory };
