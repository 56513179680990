import { map } from 'rxjs/operators';
import { Invitation } from '../models/invitation.model';
import * as i0 from "@angular/core";
import * as i1 from "./api.service";
export class InvitationService {
    constructor(apiService) {
        this.apiService = apiService;
        this._fsServiceName = "invitations";
        this._validationPath = "/authentification/invitation";
    }
    getValidationPath() {
        return window.location.origin + this._validationPath;
    }
    createInvitation(data) {
        return this.apiService.post(this._fsServiceName, data).pipe(map(invitation => new Invitation().deserialize(invitation)));
    }
    acceptInvitation(token, data) {
        const params = {
            query: {
                operationPath: '/accept'
            }
        };
        return this.apiService.put(this._fsServiceName, token, data, params).pipe(map(invitation => new Invitation().deserialize(invitation)));
    }
    getInvitation(token) {
        return this.apiService.get(this._fsServiceName, token).pipe(map(invitation => new Invitation().deserialize(invitation)));
    }
}
InvitationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function InvitationService_Factory() { return new InvitationService(i0.ɵɵinject(i1.ApiService)); }, token: InvitationService, providedIn: "root" });
