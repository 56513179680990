import { Event } from '../models/event.model';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "./feathers.service";
import * as i2 from "./api.service";
export class EventService {
    constructor(feathers, apiService) {
        this.feathers = feathers;
        this.apiService = apiService;
        this._fsServiceName = "events";
        this._createdEvent = new BehaviorSubject(null);
        this._deletedEvent = new BehaviorSubject(null);
        this.setCreatedEventEvent();
        this.setDeletedEventEvent();
    }
    onCreatedEvent() {
        return this._createdEvent;
    }
    createEvent(data) {
        return this.apiService.post(this._fsServiceName, data).pipe(map(event => new Event().deserialize(event)));
    }
    setCreatedEventEvent() {
        this.feathers.client.service(this._fsServiceName).on('created', event => {
            this._createdEvent.next(new Event().deserialize(event));
            this._createdEvent.next(null);
        });
    }
    onDeletedEvent() {
        return this._deletedEvent;
    }
    deleteEvent(id) {
        return this.apiService.delete(this._fsServiceName, id).pipe(map(event => new Event().deserialize(event)));
    }
    patchEvent(data) {
        return this.apiService.patch(this._fsServiceName, data);
    }
    setDeletedEventEvent() {
        this.feathers.client.service(this._fsServiceName).on('patched', event => {
            this._deletedEvent.next(new Event().deserialize(event));
            this._deletedEvent.next(null);
        });
    }
}
EventService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function EventService_Factory() { return new EventService(i0.ɵɵinject(i1.FeathersService), i0.ɵɵinject(i2.ApiService)); }, token: EventService, providedIn: "root" });
