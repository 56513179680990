import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ProfileComponent } from './profile/profile.component';
import { EditProfileComponent } from './edit-profile/edit-profile.component';
import { SubscriptionComponent } from './subscription/subscription.component';
import { SubscriptionPaymentComponent } from './subscription-payment/subscription-payment.component';
import { SubscriptionResultComponent } from './subscription-result/subscription-result.component';
import { SubscriptionUpdateComponent } from './subscription-update/subscription-update.component';
import { SubscriptionUpdateResultComponent } from './subscription-update-result/subscription-update-result.component';
import { SubscriptionIncreaseStorageComponent } from './subscription-increase-storage/subscription-increase-storage.component';
import { SubscriptionInvoicesComponent } from './subscription-invoices/subscription-invoices.component';

import { AccountGuard } from '../core/guards/account.guard';

const routes: Routes = [
  {
    path: "modifier",
    component: EditProfileComponent,
  },
  {
    path: "abonnement",
    component: SubscriptionComponent
  },
  {
    path: "abonnement/mettre-a-jour",
    component: SubscriptionComponent,
  },
  {
    path: "abonnement/augmenter-stockage",
    component: SubscriptionIncreaseStorageComponent,
  },
  {
    path: "abonnement/factures",
    component: SubscriptionInvoicesComponent,
  },
  {
    path: "paiement",
    component: SubscriptionPaymentComponent,
    data: {
      hideHeader: true,
      hideFooter: true
    }
  },
  {
    path: "resultat",
    component: SubscriptionResultComponent,
    data: {
      hideHeader: true,
      hideFooter: true
    }
  },
  {
    path: "abonnement/mettre-a-jour/iframe",
    component: SubscriptionUpdateComponent,
    data: {
      hideHeader: true,
      hideFooter: true
    }
  },
  {
    path: "abonnement/mettre-a-jour/resultat",
    component: SubscriptionUpdateResultComponent,
    data: {
      hideHeader: true,
      hideFooter: true
    }
  },
  {
    path: ":token",
    component: ProfileComponent,
    canActivate: [AccountGuard],
    runGuardsAndResolvers: 'always'
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AccountRoutingModule { }
