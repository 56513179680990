import { Injectable } from '@angular/core';

import { FeathersService } from './feathers.service';
import { ApiService } from './api.service';
import { Comment } from '../models/comment.model';
import { Observable, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { Reaction } from '../models/reaction.model';

@Injectable({
  providedIn: 'root'
})
export class CommentService {

  private _fsServiceName: string = "comments";
  private _createdComment: BehaviorSubject<Comment> = new BehaviorSubject<Comment>(null);
  private _updatedComment: BehaviorSubject<Comment> = new BehaviorSubject<Comment>(null);

  constructor(private feathers: FeathersService,
    private apiService: ApiService) {
    this.setCreatedCommentEvent();
    this.setUpdatedCommentEvent();
  }

  onCreatedComment(): Observable<Comment> {
    return this._createdComment;
  }

  createComment(data: any): Observable<any> {
    return this.apiService.post(this._fsServiceName, data).pipe(
      map(comment => new Comment().deserialize(comment))
    );
  }

  setCreatedCommentEvent() {
    this.feathers.client.service(this._fsServiceName).on('created', comment => {
      this._createdComment.next(new Comment().deserialize(comment));
      this._createdComment.next(null);
    });
  }

  onUpdatedComment(): Observable<Comment> {
    return this._updatedComment;
  }

  updateComment(id: number, data: any): Observable<any> {
    return this.apiService.put(this._fsServiceName, id, data).pipe(
      map(comment => new Comment().deserialize(comment))
    );
  }

  setUpdatedCommentEvent() {
    this.feathers.client.service(this._fsServiceName).on('updated', comment => {
      this._updatedComment.next(new Comment().deserialize(comment));
      this._updatedComment.next(null);
    });
  }

  getCommentReactions(token: any, params: any = {}): Observable<any> {
    const defaultParams = {
      query: {
        operationPath: '/reactions'
      }
    };

    Object.assign(defaultParams.query, params);
    
    return this.apiService.get(this._fsServiceName, token, defaultParams).pipe(
      map(reactions => {
        reactions.forEach((value, index) => {
          reactions[index] = new Reaction().deserialize(value);
        });

        return reactions;
      })
    );
  }

}
