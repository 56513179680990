<div class="container py-7">
    <div class="row justify-content-center">
        <div class="col-12 text-center">
            <a routerLink="/">
                <img src="../../assets/img/logo.svg" class="logo img-fluid" />
            </a>
            <h1 class="mt-6 mt-lg-8">
                Mentions légales
            </h1>
            <div class="text-left mt-5 mt-lg-7">
                <h2>Editeur :</h2>
                <p>
                    CERCLE CONFIANCE<br/>
                    Société par Actions Simplifiées au capital de 70 000€ Immatriculée au RCS d’Orléans sous le numéro 817 644 305<br/>
                    <strong>Dont le siège social est situé :</strong>
                    1831 route de Mézières<br/>
                    45370 Cléry-Saint-André<br/>
                    <strong>Tel :</strong> 02 38 45 96 81<br/>
                    <strong>Adresse électronique : </strong> <a href="mailto:contact@cercle-confiance.fr">contact@cercle-confiance.fr</a><br/>
                    <strong>Directeur de la publication :</strong> Olivier JOUIN
                </p>
                <h2>Hébergement :</h2>
                <p>
                    OVH<br/>
                    Société par Actions Simplifiée au capital de 10 059 500€ Immatriculée au RCS de Lille sous le n° 424 761 419<br/>
                    <strong>Dont le siège social est situé :</strong><br/>
                    2 rue Kellermann BP 80157 59053 ROUBAIX Cedex 1<br/>
                    <strong>Tel :</strong> +33 8 203 203 63<br/>
                    <strong>Adresse électronique : </strong> <a href="mailto:support@ovh.com">support@ovh.com</a><br />
                    www.ovh.com
                </p>
                <h2>Propriété intellectuelle :</h2>
                <p>
                    Tous les éléments présents au sein de ce site, tels que sons, images, photographies, vidéos, écrits, animations, programmes, charte graphique, utilitaires, bases de données, logiciel, sont protégés par les dispositions du Code de la propriété intellectuelle et appartiennent à Cercle Confiance.
                </p>
            </div>
        </div>
    </div>
</div>
