import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';

import { AlertService } from '../../../core/services/alert.service';
import { Comment } from '../../../core/models/comment.model';
import { LoadingService } from '../../../core/services/loading.service';
import { CommentService } from '../../../core/services/comment.service';

@Component({
  selector: 'app-modal-edit-comment',
  templateUrl: './modal-edit-comment.component.html',
  styleUrls: ['./modal-edit-comment.component.scss']
})
export class ModalEditCommentComponent implements OnInit {

  public editCommentForm: FormGroup;
  @Input() public comment: Comment = new Comment();
  public editedComment: any = {};

  constructor(public bsModalRef: BsModalRef,
    private loadingService: LoadingService,
    private alertService: AlertService,
    private toastrService: ToastrService,
    private commentService: CommentService) { }

  ngOnInit() {
    this.prepareEditCommentForm();
  }

  prepareEditCommentForm() {
    this.editCommentForm = new FormGroup({
        message: new FormControl(this.comment.message),
    });
  }

  setEditedComment() {
    this.editedComment.message = this.editCommentForm.get('message').value;
    this.editedComment.post = this.comment.post.getIRI();
  }

  onEditCommentFormSubmit() {
    this.editCommentForm['submitted'] = true;

    if (this.editCommentForm.valid) {
        this.setEditedComment();
        this.loadingService.showLoading();
        this.commentService.updateComment(this.comment.getId(), this.editedComment).pipe(
            finalize(() => this.loadingService.dismissLoading())
        ).subscribe(comment => {
            this.editCommentForm['submitted'] = false;
            this.alertService.closeAlert();
            this.bsModalRef.hide();
            this.toastrService.success('Votre commentaire a bien été modifié', 'Confirmation');
        }, error => {
            this.alertService.showAlert(error.errors, 'danger');
        });
    }
}

}
