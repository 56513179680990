import { map } from 'rxjs/operators';
import { SubscriptionModel } from '../models/subscription.model';
import * as i0 from "@angular/core";
import * as i1 from "./api.service";
export class SubscriptionService {
    constructor(apiService) {
        this.apiService = apiService;
        this._fsServiceName = "subscriptions";
    }
    createSubscription(data, params = {}) {
        return this.apiService.post(this._fsServiceName, data).pipe(map(subscription => new SubscriptionModel().deserialize(subscription)));
    }
    updateSubscription(id, data, params = {}) {
        return this.apiService.put(this._fsServiceName, id, data, params).pipe(map(subscription => new SubscriptionModel().deserialize(subscription)));
    }
    updateStorageSubscription(id, data, params = {}) {
        const defaultParams = {
            query: {
                operationPath: '/increase-storage'
            }
        };
        Object.assign(defaultParams.query, params);
        return this.apiService.put(this._fsServiceName, id, data, defaultParams).pipe(map(subscription => new SubscriptionModel().deserialize(subscription)));
    }
    deleteSubscription(id) {
        return this.apiService.delete(this._fsServiceName, id);
    }
}
SubscriptionService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SubscriptionService_Factory() { return new SubscriptionService(i0.ɵɵinject(i1.ApiService)); }, token: SubscriptionService, providedIn: "root" });
