import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { ScrollEventModule } from 'ngx-scroll-event';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { FileUploadModule } from 'ng2-file-upload';
import { CirclesRoutingModule } from './circles-routing.module';

import { DashboardComponent } from './dashboard/dashboard.component';
import { CircleComponent } from './circle/circle.component';
import { PostsComponent } from './circle/posts/posts.component';
import { PostComponent } from './circle/post/post.component';
import { ModalAddMemberComponent } from './circle/modal-add-member/modal-add-member.component';
import { CommentComponent } from './circle/comment/comment.component';
import { MediasComponent } from './circle/medias/medias.component';
import { AlbumComponent } from './circle/album/album.component';
import { EventsComponent } from './circle/events/events.component';
import { EventComponent } from './circle/event/event.component';
import { VisioComponent } from './circle/visio/visio.component';
import { CircleThumbnailComponent } from './circle-thumbnail/circle-thumbnail.component';
import { ModalAddCircleComponent } from './modal-add-circle/modal-add-circle.component';
import { MembersComponent } from './circle/members/members.component';
import { ModalAddEventComponent } from './circle/modal-add-event/modal-add-event.component';
import { ModalMembersComponent } from './circle/modal-members/modal-members.component';
import { ModalMediasComponent } from './circle/modal-medias/modal-medias.component';
import { ModalEventsComponent } from './circle/modal-events/modal-events.component';
import { SettingsComponent } from './circle/settings/settings.component';
import { ModalReactionsComponent } from './circle/modal-reactions/modal-reactions.component';
import { ModalEditPostComponent } from './circle/modal-edit-post/modal-edit-post.component';
import { ModalEditCommentComponent } from './circle/modal-edit-comment/modal-edit-comment.component';
import { ModalConfirmationComponent } from './circle/modal-confirmation/modal-confirmation.component';
import { ModalInformationComponent } from './circle/modal-information/modal-information.component';
import { ModalArchiveExpiredComponent } from './circle/modal-archive-expired/modal-archive-expired.component';
import { PostDetailsComponent } from './circle/post-details/post-details.component';
import { AppointmentComponent } from './circle/appointment/appointment.component';
import { ModalAddAppointmentComponent } from './circle/modal-add-appointment/modal-add-appointment.component';
import { ModalAppointmentsComponent } from './circle/modal-appointments/modal-appointments.component';
import { ModalAddProposalComponent } from './circle/modal-add-proposal/modal-add-proposal.component';
import { AppointmentEventComponent } from './circle/appointment-event/appointment-event.component';
import { ModalOnlineUsersComponent } from './circle/modal-online-users/modal-online-users.component';
import { ModalUpdateCircleComponent } from './circle/modal-update-circle/modal-update-circle.component';

@NgModule({
  declarations: [DashboardComponent, CircleComponent, PostsComponent, PostComponent, ModalAddMemberComponent, CommentComponent, MediasComponent, AlbumComponent, EventsComponent, EventComponent, VisioComponent, CircleThumbnailComponent, ModalAddCircleComponent, MembersComponent, ModalAddEventComponent, ModalMembersComponent, ModalMediasComponent, ModalEventsComponent, SettingsComponent, ModalReactionsComponent, ModalEditPostComponent, ModalEditCommentComponent, ModalConfirmationComponent, ModalInformationComponent, ModalArchiveExpiredComponent, PostDetailsComponent, ModalOnlineUsersComponent, AppointmentComponent, ModalAddAppointmentComponent, ModalAppointmentsComponent, ModalAddProposalComponent, AppointmentEventComponent, ModalUpdateCircleComponent],
  imports: [
    CommonModule,
    CirclesRoutingModule,
    SharedModule,
    ScrollEventModule,
    RouterModule,
    BsDatepickerModule.forRoot(),
    TimepickerModule.forRoot(),
    FileUploadModule
  ],
  entryComponents: [
    ModalAddMemberComponent,
    ModalAddCircleComponent,
    ModalAddEventComponent,
    ModalMembersComponent,
    ModalMediasComponent,
    ModalEventsComponent,
    ModalReactionsComponent,
    ModalEditCommentComponent,
    ModalEditPostComponent,
    ModalConfirmationComponent,
    ModalInformationComponent,
    ModalArchiveExpiredComponent,
    ModalOnlineUsersComponent,
    ModalAddAppointmentComponent,
    ModalAppointmentsComponent,
    ModalAddProposalComponent,
    ModalUpdateCircleComponent
  ],
  providers: [DatePipe
  ]
})
export class CirclesModule { }
