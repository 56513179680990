import { Observable, from, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { CookieStorage } from 'cookie-storage';
import * as i0 from "@angular/core";
import * as i1 from "./feathers.service";
import * as i2 from "./auth.service";
export class ApiService {
    constructor(feathersService, authService) {
        this.feathersService = feathersService;
        this.authService = authService;
        this._storageKey = 'at';
        this._cookieStorage = new CookieStorage();
    }
    getAll(path, params = {}) {
        return from(this.feathersService.client.service(path).find(params)).pipe(catchError(error => {
            if (401 == error.status) {
                const accessToken = this._cookieStorage.getItem(this._storageKey);
                return from(this.authService.authenticate({
                    accessToken: accessToken
                })).pipe(switchMap(response => this.getAll(path, params)));
            }
            return throwError(error);
        }));
    }
    get(path, id, params = {}) {
        return from(this.feathersService.client.service(path).get(id, params)).pipe(catchError(error => {
            if (401 == error.status) {
                const accessToken = this._cookieStorage.getItem(this._storageKey);
                return from(this.authService.authenticate({
                    accessToken: accessToken
                })).pipe(switchMap(response => this.get(path, id, params)));
            }
            return throwError(error);
        }));
    }
    post(path, data, params = {}) {
        return from(this.feathersService.client.service(path).create(data, params)).pipe(catchError(error => {
            if (401 == error.status) {
                const accessToken = this._cookieStorage.getItem(this._storageKey);
                return from(this.authService.authenticate({
                    accessToken: accessToken
                })).pipe(switchMap(response => this.post(path, data, params)));
            }
            return throwError(error);
        }));
    }
    put(path, id, data, params = {}) {
        return from(this.feathersService.client.service(path).update(id, data, params)).pipe(catchError(error => {
            if (401 == error.status) {
                const accessToken = this._cookieStorage.getItem(this._storageKey);
                return from(this.authService.authenticate({
                    accessToken: accessToken
                })).pipe(switchMap(response => this.put(path, id, data, params)));
            }
            return throwError(error);
        }));
    }
    delete(path, id) {
        return from(this.feathersService.client.service(path).remove(id)).pipe(catchError(error => {
            if (401 == error.status) {
                const accessToken = this._cookieStorage.getItem(this._storageKey);
                return from(this.authService.authenticate({
                    accessToken: accessToken
                })).pipe(switchMap(response => this.delete(path, id)));
            }
            return throwError(error);
        }));
    }
    patch(path, data, params = {}) {
        return from(this.feathersService.client.service(path).patch("", data, params)).pipe(catchError(error => {
            if (401 == error.status) {
                const accessToken = this._cookieStorage.getItem(this._storageKey);
                return from(this.authService.authenticate({
                    accessToken: accessToken
                })).pipe(switchMap(response => this.patch(path, data)));
            }
            return throwError(error);
        }));
    }
}
ApiService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ApiService_Factory() { return new ApiService(i0.ɵɵinject(i1.FeathersService), i0.ɵɵinject(i2.AuthService)); }, token: ApiService, providedIn: "root" });
