import { Serializable } from '../interfaces/serializable';

import { Member } from './member.model';
import { Post } from './post.model';
import { Reaction } from './reaction.model';

export class Comment implements Serializable {

  private _id: number = null;
  public message: string = "";
  public member: Member = null;
  public post: Post = null;
  public createdAt: Date = null;
  public updatedAt: Date = null;
  public deletedAt: string = null;
  public reactions: string[] = [];

  constructor() {}

  public getIRI() {
    return '/api/comments/' + this._id;
  }

  public getId() {
    return this._id;
  }

  public deserialize(input: any) {
    if (!input) {
      return null;
    }

    Object.getOwnPropertyNames(this).forEach((property) => {
      switch (property) {
        case 'member':
          this[property] = new Member().deserialize(input[property]);
          break;
        case 'post':
          this[property] = new Post().deserialize(input[property]);
          break;
        default:
          if ('_id' == property && input.id) {
            this._id = input.id;
          } else if (property in input) {
            this[property] = input[property];
          }
          break;
      }
    });

    return this;
  }

  public serialize(): any {
    var output = {};

    Object.getOwnPropertyNames(this).forEach((property) => {
      switch (property) {
        case 'member':
          output[property] = this[property].getIRI();
          break;
        case 'post':
          output[property] = this[property].getIRI();
          break;
        default:
          if (property != '_id') {
            output[property] = this[property];
          }
          break;
      }
    });

    return output;
  }
}
