<div class="container py-7">
    <div class="row justify-content-center">
        <div class="col-12 col-sm-8 col-lg-5 text-center">
            <a routerLink="/">
                <img src="../../assets/img/logo.svg" class="logo img-fluid" />
            </a>
            <h1 class="mt-6 mt-lg-8">
                Se connecter
            </h1>
            <div class="bg-white border-light-grey border rounded text-left p-5 mt-5 mt-lg-7">
                <form [formGroup]="loginForm" (ngSubmit)="onLoginFormSubmit()">
                    <div class="form-group">
                        <label for="username">Adresse e-mail</label>
                        <input id="username" type="text" formControlName="username" class="form-control" placeholder="votreadresse@mail.com" />
                        <ng-container *ngIf="loginForm.get('username').invalid && (loginForm.get('username').dirty || loginForm.get('username').touched || loginForm['submitted'])">
                            <small class="text-danger" *ngIf="loginForm.get('username').errors.required">
                                L'adresse e-mail est obligatoire
                            </small>
                        </ng-container>
                    </div>
                    <div class="form-group">
                        <label for="password">Mot de passe</label>
                        <input id="password" type="password" class="form-control" formControlName="password" placeholder="********">
                        <div class="float-right mt-1">
                            <a routerLink="/authentification/mot-de-passe-oublie">Mot de passe oublié ?</a>
                        </div>
                        <ng-container *ngIf="loginForm.get('password').invalid && (loginForm.get('password').dirty || loginForm.get('password').touched || loginForm['submitted'])">
                            <small class="text-danger" *ngIf="loginForm.get('password').errors.required">
                                Le mot de passe est obligatoire
                            </small>
                        </ng-container>
                    </div>
                    <div class="form-group mt-12 mt-lg-14">
                        <button type="submit" class="btn btn-primary w-100">Valider</button>
                    </div>
                </form>
                <div class="text-center">
                    Vous n'avez pas encore de compte ?
                    <a [routerLink]="['/', 'authentification', 'inscription']" [queryParams]="{'redirectionUrl': redirectionUrl}" class="text-primary">Inscrivez-vous</a>
                </div>
            </div>
        </div>
    </div>
</div>
