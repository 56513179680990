import { Component, OnInit, OnDestroy } from '@angular/core';

import { FileUploader } from 'ng2-file-upload';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { FormControl, FormGroup } from '@angular/forms';
import { ScrollEvent } from 'ngx-scroll-event';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { FixOrientationPipe } from '../../../shared/pipes/fix-orientation.pipe';
import { CircleService } from '../../../core/services/circle.service';
import { PostService } from '../../../core/services/post.service';
import { LoadingService } from '../../../core/services/loading.service';
import { AlertService } from '../../../core/services/alert.service';
import { HeaderService } from '../../../core/services/header.service';
import { AppointmentService } from '../../../core/services/appointment.service';
import { Circle } from '../../../core/models/circle.model';
import { Member } from '../../../core/models/member.model';
import { Media } from '../../../core/models/media.model';
import { Event } from '../../../core/models/event.model';
import { Post } from '../../../core/models/post.model';
import { Appointment } from '../../../core/models/appointment.model';
import { ModalAddEventComponent } from '../modal-add-event/modal-add-event.component';
import { ModalAddAppointmentComponent } from '../modal-add-appointment/modal-add-appointment.component';

@Component({
    selector: 'app-posts',
    templateUrl: './posts.component.html',
    styleUrls: ['./posts.component.scss']
})

export class PostsComponent implements OnInit, OnDestroy {

    private _observers: Subscription[] = [];
    private _postsPage: number = 1;
    public postForm: FormGroup;
    public circle: Circle = new Circle();
    public memberMe: Member = new Member();
    public posts: Post[] = [];
    public medias: Media[] = [];
    public event: Event = null;
    public loadingPosts: boolean = true;
    public swipeLaunchVisio: boolean = false;
    public memberUnderSurveillance: Member;
    public uploader: FileUploader = new FileUploader({ itemAlias: 'file' });
    public modalAddEvent: BsModalRef;
    public modalAddAppointment: BsModalRef;
    public today = new Date();
    public firstPost: number = 0;
    public waitingAppointments: Appointment[] = [];
    public newAppointments: Appointment[] = [];

    constructor(private postService: PostService,
        private circleService: CircleService,
        private loadingService: LoadingService,
        private alertService: AlertService,
        private headerService: HeaderService,
        private modalService: BsModalService,
        private fixOrientationPipe: FixOrientationPipe,
        private appointmentService: AppointmentService) { }

    ngOnInit() {
        this.setObservers();
        this.preparePostForm();
        this.setUploader();
    }

    preparePostForm() {
        this.postForm = new FormGroup({
            message: new FormControl(''),
        });
    }

    setUploader() {
        this.uploader.onAfterAddingAll = (fileItems) => {
            
            fileItems.forEach(fileItem => {
                const fileObject = fileItem.file;

            var media = new Media().deserialize({
                filename: fileObject.name,
                mimeType: fileObject.type
            });

            if (media.isImage()) {
                this.transformImage(media, fileItem);
            }

            this.medias.push(media);
            });
            
        };
    }

    transformImage(media: Media, fileItem: any) {
        const reader = new FileReader();
        reader.onload = () => {
            this.fixOrientationPipe.transform(reader.result).subscribe(imageBase64 => {
                media.data = imageBase64;
            });
        };
        reader.readAsDataURL(fileItem._file);
    }

    setObservers() {
        this._observers.push(this.setCircleObserver());
        this._observers.push(this.setCreatedPostObserver());
        this._observers.push(this.setCreatedAppointmentObserver());
        this._observers.push(this.setUpdatedPostObserver());
    }

    setCircleObserver(): Subscription {
        return this.circleService.getCurrentCircle().subscribe(circle => {

            if (!circle) {
                return;
            }

            this.circle = circle;

            this.circleService.getCircleMembersMe(circle.token).subscribe(member => {
                this.memberMe = member;
                this.getAppointments();
                this.getPosts();
            });

            circle.members.forEach(member => {
                if (member.isUnderSurveillance) {
                    this.memberUnderSurveillance = member;
                }
            });
        });
    }

    setCreatedPostObserver(): Subscription {
        return this.postService.onCreatedPost()
            .subscribe(post => {
                if (post && this.circle.getIRI() == post.circle.getIRI()) {
                    this.posts.unshift(post);
                    this.getFirstPost();
                }
            });
    }

    setUpdatedPostObserver(): Subscription {
        return this.postService.onUpdatedPost()
            .subscribe(post => {
                if (post && this.circle.getIRI() == post.circle.getIRI()) {
                    this.posts = this.mapPosts(this.posts, post);
                    this.getFirstPost();
                }
            })
    }

    setCreatedAppointmentObserver(): Subscription {
        return this.appointmentService.onCreatedAppointment().subscribe(appointment => {
            if (!appointment) {
                return;
            }

            if (this.waitingAppointments.filter(a => a.getId() == appointment.getId()).length == 0 && this.newAppointments.filter(a => a.getId() == appointment.getId()).length == 0 && this.circle.getIRI() == appointment.circle.getIRI() && null == appointment.finalChoice) {
                this.newAppointments.push(appointment);
            }
        });
    }

    loadPosts(event: ScrollEvent) {
        if (!this.loadingPosts && event.isReachingBottom && this._postsPage) {
            this.getPosts();
        }

        this.headerService.swipeHeader(!event.isReachingTop);
        this.swipeLaunchVisio = !event.isReachingTop;
    }

    getPosts() {
        this.loadingPosts = true;
        this.circleService.getCirclePosts(this.circle.token, { page: this._postsPage }).subscribe(posts => {
            (posts.length > 0) ? this._postsPage++ : this._postsPage = 0;
            this.loadingPosts = false;

            this.posts = this.posts.concat(posts);
            this.getFirstPost();
        });
    }

    getAppointments() {
        this.circleService.getCircleAppointments(this.circle.token).subscribe(appointments => {
            appointments.forEach((appointment: Appointment) => {
                if (this.waitingAppointments.filter(a => a.getId() == appointment.getId()).length == 0 && this.newAppointments.filter(a => a.getId() == appointment.getId()).length == 0 && null == appointment.finalChoice) {
                    this.waitingAppointments.push(appointment);
                }
            });
        });
    }

    getFirstPost() {
        this.firstPost = this.posts.findIndex(post => post.deletedAt == null);
    }

    removeMedia(index: number) {
        this.uploader.queue[index].remove();
        this.medias.splice(index, 1);
    }

    removeEvent() {
        this.event = null;
    }

    setNewPost(): FormData {
        let input = new FormData();

        input.append('message', this.postForm.get('message').value || "");
        input.append('circle', this.circle.getIRI());

        // Add medias
        this.medias.forEach((fileItem, index) => {
            input.append(`medias[${index}]`, this.uploader.queue[index]._file);
        });

        // Add event
        if (this.event) {
            input.append('event', JSON.stringify(this.event.serialize()));
        }

        return input;
    }

    resetPostForm() {
        this.postForm.reset();
        this.uploader.queue = [];
        this.medias = [];
        this.event = null;
    }

    onPostFormSubmit() {
        this.postForm['submitted'] = true;

        if (this.postForm.valid) {
            const newPost = this.setNewPost();

            this.loadingService.showLoading();
            this.postService.createPost(newPost).pipe(
                finalize(() => this.loadingService.dismissLoading())
            ).subscribe(post => {
                this.postForm['submitted'] = false;
                this.resetPostForm();
                this.alertService.closeAlert();
            }, error => {
                const errorMessage: string[] = error.error.errors ? error.error.errors : ["Impossible de poster un message pour l'instant, veuillez réessayer ultérieurement, merci"];
                this.alertService.showAlert(errorMessage, 'danger');
                console.log(error);
            });
        }
    }

    openAddEventModal() {
        this.modalAddEvent = this.modalService.show(ModalAddEventComponent);
        this.modalService.onHide.subscribe((reason) => {
            if (this.modalAddEvent.content && this.modalAddEvent.content.newEvent.title) {
                this.event = this.modalAddEvent.content.newEvent;
                this.modalAddEvent.content = null;
            }
        });
    }

    openAddAppointmentModal() {
        this.modalAddAppointment = this.modalService.show(ModalAddAppointmentComponent);
        this.modalAddAppointment.content.circle = this.circle;
    }

    private mapPosts(postsTab: Post[], currentPost: Post) {
        for(var i = 0; i < postsTab.length; i++) {
            if (postsTab[i].getIRI() == currentPost.getIRI()) {
                postsTab[i] = currentPost;
            }
        }
        return postsTab;
    }

    ngOnDestroy() {
        this._observers.forEach((observer, index) => {
            observer.unsubscribe();
        });
    }
}
