import { Component, OnInit } from '@angular/core';
import * as FileSaver from 'file-saver';
import { MeService } from '../../core/services/me.service';
import { User } from '../../core/models/user.model';
import { UserService } from '../../core/services/user.service';
import { SubscriptionModel } from '../../core/models/subscription.model';
import { InvoiceService } from '../../core/services/invoice.service';
import { Invoice } from 'src/app/core/models/invoice.model';

@Component({
  selector: 'app-subscription-invoices',
  templateUrl: './subscription-invoices.component.html',
  styleUrls: ['./subscription-invoices.component.scss']
})
export class SubscriptionInvoicesComponent implements OnInit {

  public user: User;
  public invoices: Invoice[] = [];
  
  constructor(private meService: MeService,
    private userService: UserService,
    private invoiceService: InvoiceService) { }

  ngOnInit() {
    this.getUser();
    this.getSubscription();
  }

  getUser() {
    this.user = this.meService.getMe();
  }

  getSubscription() {
    this.userService.getUserInvoices(this.user.token).subscribe(invoices => {
        invoices.forEach(invoice => {
          this.invoices.push(invoice);
        });
    });
  }

  onDownload(index: number, filename: string) {
    this.invoiceService.downloadFile(index).subscribe(response => {
      var blob = new Blob([response], { type: 'application/pdf' });

      setTimeout(function() {
          FileSaver.saveAs(blob, filename);
      }, 250);
  });
  }

}
