<div class="container py-7">
    <div class="row justify-content-center">
        <div class="col-12 text-center">
            <a routerLink="/">
                <img src="../../assets/img/logo.svg" class="logo img-fluid" />
            </a>
            <h1 class="mt-6 mt-lg-8">
                Politique de confidentialité
            </h1>
            <div class="text-left mt-5 mt-lg-7">
                <p>
                    On sait que de nombreuses applications gratuites revendent les données collectées à des tiers pour financer leurs prestations.<br />
                    Cercle Confiance est une application payante, qui ne fait aucun usage des données collectées et en garantit la plus totale et la plus stricte confidentialité dans le cadre d’internet.
                </p>
                <h2 class="mt-4 mb-2">Données personnelles</h2>
                <p>
                    Ces données sont d’abord celles que vous nous transmettez directement, via un formulaire de contact pour vous identifier sur Cercle Confiance. Sont obligatoires dans le formulaire d’identification les champs « prénom », « nom », « email » et « mot de passe ». Vous avez la possibilité d’ajouter votre photo. Il n’est pas possible de changer l’adresse e-mail. Les autres données sont modifiables par l’utilisateur.
                </p>
                <p>
                    Ces données d’identification seront supprimées si vous quittez définitivement les services de Cercle Confiance (onglet « Mon compte », « Quitter définitivement l’application »), ou si l’administrateur du ou des cercles auxquels vous appartenez arrête ou ne paie plus son abonnement. Vous serez alors prévenu.
                </p>
                <p>
                    Bien évidemment, vous pouvez échanger des données personnelles a l’intérieur d’un Cercle, mais Cercle Confiance n’y a pas directement accès et elles sont sous la responsabilité collective des membres du Cercle. En cas d’arrêt du service, à l’initiative de l’administrateur ou de l’invité, l’utilisateur reçoit par mail un lien lui permettant de récupérer les données qu’il a déposées sur le cercle, dans un délai de 30 jours. Au-delà, toutes les données concernant l’utilisateur sorti des services de Cercle sont supprimées.
                </p>
                <h2 class="mt-4 mb-2">Utilisation des données</h2>
                <p>
                    Les données d’identification que vous nous transmettez directement sont utilisées dans le but de vous recontacter, de vous informer sur les évolutions du service ou de permettre votre accès et vos interactions avec les cercles auxquels vous appartenez.
                </p>
                <h2 class="mt-4 mb-2">Base légale</h2>
                <p>
                    Les données personnelles d’identification ne sont collectées qu’après consentement obligatoire de l’utilisateur. Ce consentement est valablement recueilli (boutons et cases à cocher), libre, clair et sans équivoque.
                </p>
                <h2 class="mt-4 mb-2">Durée de conservation</h2>
                <p>
                    Les données seront sauvegardées tant que l’utilisateur le souhaitera et n’aura pas quitté volontairement le service dans le cadre de l’abonnement de l’administrateur du cercle auquel il est inscrit. Il bénéficiera d’un délai de 30 jours pendant lesquels il aura la possibilité de récupérer les données échangées à l’intérieur du Cercle à travers un lien de téléchargement.
                    Ses données d’identification seront supprimées lorsqu’il décidera de quitter les services de Cercle Confiance. Les données échangées dans un cercle sont supprimées lorsque ce cercle est supprimé ou lorsque l’abonnement de l’administrateur du cercle est arrêté, avec un délai de 30 jours.
                </p>
                <h2 class="mt-4 mb-2">Cookies</h2>
                <p>
                    Cercle Confiance ne pose pas de cookies dans ses applications.
                </p>
                <h2 class="mt-4 mb-2">Vos droits concernant les données personnelles</h2>
                <p>
                    L’identification d’un utilisateur se fait à travers son adresse mail personnelle. Il n’est pas possible d’en changer. Les autres données personnelles que sont le prénom, le nom et la photo peuvent être modifiées à tout moment dans « Mon compte ».
                </p>
                <p>
                    Les données échangées dans les Cercles appartiennent collectivement aux membres du Cercle, à l’exception des données dont la propriété intellectuelle est identifiable, qui appartiennent à leur auteur.
                </p>
                <h2 class="mt-4 mb-2">Contact délégué à la protection des données</h2>
                <p>
                    Vous pouvez envoyer un mail à <a href="mailto:dpo@cercle-confiance.fr">dpo@cercle-confiance.fr</a>
                </p>
                <br/>
                <p class="small">Mis à jour le 27 septembre 2021</p>
            </div>
        </div>
    </div>
</div>
