import { Component, OnInit } from '@angular/core';

import { Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { PasswordRequestService } from '../../core/services/password-request.service';
import { LoadingService } from '../../core/services/loading.service';
import { AlertService } from '../../core/services/alert.service';

@Component({
  selector: 'app-password-request',
  templateUrl: './password-request.component.html',
  styleUrls: ['./password-request.component.scss']
})
export class PasswordRequestComponent implements OnInit {

  public newPasswordRequest: any = {
    email: "",
    redirectUrl: this.passwordRequestService.getResetPasswordPath()
  };
  public passwordRequestForm: FormGroup;

  constructor(private passwordRequestService: PasswordRequestService,
    private loadingService: LoadingService,
    private alertService: AlertService,
    private router: Router,
    private toastrService: ToastrService) { }

  ngOnInit() {
    this.setPasswordRequestForm();
  }

  setPasswordRequestForm() {
    this.passwordRequestForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')]),
    });
  }

  setNewPasswordRequest() {
    this.newPasswordRequest.email = this.passwordRequestForm.get('email').value;
  }

  onPasswordRequestFormSubmit() {
    this.passwordRequestForm['submitted'] = true;

    if (this.passwordRequestForm.valid) {
      this.setNewPasswordRequest();
      this.loadingService.showLoading();
      this.passwordRequestService.createPasswordRequest(this.newPasswordRequest).pipe(
        finalize(() => this.loadingService.dismissLoading())
      ).subscribe(passwordRequest => {
        this.toastrService.success('Votre demande a bien été envoyée', 'Félicitations !');
        this.router.navigateByUrl('/login');
      }, error => {
        this.alertService.showAlert(error.errors, 'danger');
      });
    }
  }
}
