/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./modal-archive-expired.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./modal-archive-expired.component";
import * as i3 from "ngx-bootstrap/modal";
var styles_ModalArchiveExpiredComponent = [i0.styles];
var RenderType_ModalArchiveExpiredComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ModalArchiveExpiredComponent, data: {} });
export { RenderType_ModalArchiveExpiredComponent as RenderType_ModalArchiveExpiredComponent };
export function View_ModalArchiveExpiredComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h3", [["class", "modal-title text-uppercase"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Archive expir\u00E9e"])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "button", [["aria-label", "Close"], ["class", "close pull-right"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.bsModalRef.hide() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "i", [["class", "fas fa-times"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 9, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "h6", [["class", "mb-4"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Votre archive a expir\u00E9. Voulez-vous en g\u00E9n\u00E9rer une nouvelle ?"])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "div", [["class", "mb-8 small"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" La g\u00E9n\u00E9ration de l'archive peut prendre quelques minutes. Vous recevrez un mail quand celle-ci sera pr\u00EAte. "])), (_l()(), i1.ɵeld(10, 0, null, null, 4, "div", [["class", "float-right mb-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 1, "button", [["class", "btn btn-outline-secondary btn--small py-2 px-3 mr-2"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onConfirmation(false) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Annuler"])), (_l()(), i1.ɵeld(13, 0, null, null, 1, "button", [["class", "btn btn-primary btn--small py-2 px-3"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onConfirmation(true) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["OK"]))], null, null); }
export function View_ModalArchiveExpiredComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-modal-archive-expired", [], null, null, null, View_ModalArchiveExpiredComponent_0, RenderType_ModalArchiveExpiredComponent)), i1.ɵdid(1, 114688, null, 0, i2.ModalArchiveExpiredComponent, [i3.BsModalRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ModalArchiveExpiredComponentNgFactory = i1.ɵccf("app-modal-archive-expired", i2.ModalArchiveExpiredComponent, View_ModalArchiveExpiredComponent_Host_0, {}, {}, []);
export { ModalArchiveExpiredComponentNgFactory as ModalArchiveExpiredComponentNgFactory };
