import { NgModule, ModuleWithProviders, APP_INITIALIZER } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { AuthenticationModule } from '../authentication/authentication.module';
import { CirclesModule } from '../circles/circles.module';
import { AccountModule } from '../account/account.module';
import { CoreRoutingModule } from './core-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';

import { AuthService } from './services/auth.service';
import { FeathersService } from './services/feathers.service';
import { MeService } from './services/me.service';
import { UserService } from './services/user.service';
import { CircleService } from './services/circle.service';
import { NotificationService } from './services/notification.service';
import { AppInitService } from './services/app-init.service';
import { HeaderComponent } from './header/header.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { HomepageComponent } from './homepage/homepage.component';
import { LoginComponent } from './login/login.component';
import { FooterComponent } from './footer/footer.component';
import { LegalNoticeComponent } from './legal-notice/legal-notice.component';
import { GeneralConditionsOfUseComponent } from './general-conditions-of-use/general-conditions-of-use.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { AboutComponent } from './about/about.component';

export function initializeAuthentication(appInitService: AppInitService) {
  return (): Promise<any> => {
    return appInitService.getAuthentication();
  }
}

@NgModule({
  declarations: [HeaderComponent, NotFoundComponent, HomepageComponent, LoginComponent, FooterComponent, LegalNoticeComponent, GeneralConditionsOfUseComponent, PrivacyPolicyComponent, AboutComponent],
  imports: [
    CommonModule,
    CoreRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AuthenticationModule,
    CirclesModule,
    AccountModule,
    SharedModule
  ],
  exports: [
    HeaderComponent,
    FooterComponent
  ],
  providers: [
  ]
})

export class CoreModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: CoreModule,
      providers: [
        AuthService,
        FeathersService,
        MeService,
        UserService,
        CircleService,
        AppInitService,
        { provide: APP_INITIALIZER, useFactory: initializeAuthentication, deps: [AppInitService], multi: true}
      ]
    }
  }
 }
