<div class="events container-fluid p-0">
    <ng-template #noEvents>
        <p class="mt-3" *ngIf="!loadingEvents">
            <i>Aucun évènement publié.</i>
        </p>
    </ng-template>
    <ng-template [ngIf]="events.length" [ngIfElse]="noEvents">
        <div class="row mt-5">
            <div class="col-12">
                <ng-container *ngFor="let event of events">
                    <div class="mb-5" *ngIf="!event.deletedAt">
                        <ng-container *ngIf="!isTypeAppointment(event)">
                            <app-event [event]="event" [showAuthor]="true"></app-event>
                        </ng-container>
                        <ng-container *ngIf="isTypeAppointment(event)">
                            <app-appointment-event [appointment]="event"></app-appointment-event>
                        </ng-container>
                    </div>
                </ng-container>
            </div>
        </div>
    </ng-template>
    <div class="row mt-4" *ngIf="loadingEvents">
        <div class="col-12 text-center">
            <div class="spinner-border text-primary" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
    </div>
</div>
