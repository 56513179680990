import { Component, OnInit } from '@angular/core';

import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { LoadingService } from '../../../core/services/loading.service';
import { Event } from '../../../core/models/event.model';

@Component({
    selector: 'app-modal-add-event',
    templateUrl: './modal-add-event.component.html',
    styleUrls: ['./modal-add-event.component.scss']
})
export class ModalAddEventComponent implements OnInit {

    public eventForm: FormGroup;
    public newEvent: Event = new Event();

    constructor(public bsModalRef: BsModalRef,
        private loadingService: LoadingService,
        private localeService: BsLocaleService) { }

    ngOnInit() {
        this.prepareEventForm();
    }

    prepareEventForm() {
        this.localeService.use('fr');
        this.eventForm = new FormGroup({
            title: new FormControl('', Validators.required),
            description: new FormControl(''),
            date: new FormControl('', Validators.required),
            time: new FormControl(''),
        });
    }

    setNewEvent() {
        const date = this.eventForm.get('date').value;
        const time = this.eventForm.get('time').value;

        this.newEvent.title = this.eventForm.get('title').value;
        this.newEvent.date = date;
        this.newEvent.description = this.eventForm.get('description').value;

        // Create datetime
        this.newEvent.date.setHours(time ? time.getHours() : 0);
        this.newEvent.date.setMinutes(time ? time.getMinutes() : 0);
        this.newEvent.date.setSeconds(0);
    }

    onEventFormSubmit() {
        this.eventForm['submitted'] = true;

        if (this.eventForm.valid) {
            this.eventForm['submitted'] = false;
            this.setNewEvent();
            this.eventForm.reset();
            this.bsModalRef.hide();
        }
    }

}
