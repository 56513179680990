import { Injectable } from '@angular/core';

import { Observable, from } from 'rxjs';
import { switchMap, map, tap, finalize } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { ApiService } from './api.service';
import { PasswordRequest } from '../models/password-request.model';

@Injectable({
  providedIn: 'root'
})
export class PasswordRequestService {

  private _fsServiceName: string = 'passwordRequests';
  private _resetPasswordPath: string = "/authentification/nouveau-mot-de-passe";

  constructor(private authService: AuthService,
    private apiService: ApiService) {
  }

  getResetPasswordPath(): string {
    return window.location.origin + this._resetPasswordPath;
  }

  createPasswordRequest(data: any): Observable<any> {
    return from(this.authService.authenticateAnonymous()).pipe(
      switchMap(response => {
        return this.apiService.post(this._fsServiceName, data);
      }),
      finalize(() => this.authService.logout())
    );
  }

  resetPassword(token: string, data: any): Observable<any> {
    const params = {
      query: {
        operationPath: '/reset-password'
      }
    };

    return from(this.authService.authenticateAnonymous()).pipe(
      switchMap(response => {
        return this.apiService.put(this._fsServiceName, token, data, params);
      }),
      map(passwordRequest => new PasswordRequest().deserialize(passwordRequest)),
      finalize(() => this.authService.logout())
    );
  }

  getPasswordRequest(token: string): Observable<any> {
    return from(this.authService.authenticateAnonymous()).pipe(
      switchMap(response => {
        return this.apiService.get(this._fsServiceName, token);
      }),
      map(passwordRequest => new PasswordRequest().deserialize(passwordRequest)),
      finalize(() => this.authService.logout())
    );
  }
}
