<div class="modal-header">
    <h3 class="modal-title text-uppercase">Ajouter un membre</h3>
    <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
        <i class="fas fa-times"></i>
    </button>
</div>
<div class="modal-body">
    <form [formGroup]="memberForm" (ngSubmit)="onMemberFormSubmit()">
        <div class="form-group">
            <label for="invitationEmail">Adresse mail de l'invité*</label>
            <input id="invitationEmail" type="email" name="invitationEmail" formControlName="invitationEmail" class="form-control" placeholder="uneadresse@email.com" />
            <ng-container *ngIf="memberForm.get('invitationEmail').invalid && (memberForm.get('invitationEmail').dirty || memberForm.get('invitationEmail').touched || memberForm['submitted'])">
                <small class="text-danger" *ngIf="memberForm.get('invitationEmail').errors.required">
                    L'email est obligatoire
                </small>
            </ng-container>
            <ng-container *ngIf="memberForm.get('invitationEmail').invalid && (memberForm.get('invitationEmail').dirty || memberForm.get('invitationEmail').touched || memberForm['submitted'])">
                <small class="text-danger" *ngIf="memberForm.get('invitationEmail').errors.pattern">
                    L'email n'est pas correct
                </small>
            </ng-container>
        </div>
        <div class="form-group mt-14">
            <button type="submit" class="btn btn-primary w-100">Envoyer</button>
        </div>
    </form>
</div>
