import { Injectable } from '@angular/core';

import { Observable, of, from, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { CookieStorage } from 'cookie-storage';
import { AuthService } from './auth.service';
import { FeathersService } from './feathers.service';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  private _cookieStorage;
  private _storageKey: string = 'at';

  constructor(private feathersService: FeathersService,
    private authService: AuthService) {
    this._cookieStorage = new CookieStorage();
  }

  getAll(path: string, params: any = {}): Observable<any> {
    return from(this.feathersService.client.service(path).find(params)).pipe(
      catchError(error => {
        if (401 == error.status) {
          const accessToken = this._cookieStorage.getItem(this._storageKey);

          return from(this.authService.authenticate({
            accessToken: accessToken
          })).pipe(
            switchMap(response => this.getAll(path, params))
          );
        }

        return throwError(error);
      })
    );
  }

  get(path: string, id: string|number, params: any = {}): Observable<any> {
    return from(this.feathersService.client.service(path).get(id, params)).pipe(
      catchError(error => {
        if (401 == error.status) {
          const accessToken = this._cookieStorage.getItem(this._storageKey);

          return from(this.authService.authenticate({
            accessToken: accessToken
          })).pipe(
            switchMap(response => this.get(path, id, params))
          );
        }

        return throwError(error);
      })
    );
  }

  post(path: string, data: any, params: any = {}): Observable<any> {
    return from(this.feathersService.client.service(path).create(data, params)).pipe(
      catchError(error => {
        if (401 == error.status) {
          const accessToken = this._cookieStorage.getItem(this._storageKey);

          return from(this.authService.authenticate({
            accessToken: accessToken
          })).pipe(
            switchMap(response => this.post(path, data, params))
          );
        }

        return throwError(error);
      })
    );
  }

  put(path: string, id: string|number, data: any, params: any = {}): Observable<any> {
    return from(this.feathersService.client.service(path).update(id, data, params)).pipe(
      catchError(error => {
        if (401 == error.status) {
          const accessToken = this._cookieStorage.getItem(this._storageKey);

          return from(this.authService.authenticate({
            accessToken: accessToken
          })).pipe(
            switchMap(response => this.put(path, id, data, params))
          );
        }

        return throwError(error);
      })
    );
  }

  delete(path: string, id: string|number): Observable<any> {
    return from(this.feathersService.client.service(path).remove(id)).pipe(
      catchError(error => {
        if (401 == error.status) {
          const accessToken = this._cookieStorage.getItem(this._storageKey);

          return from(this.authService.authenticate({
            accessToken: accessToken
          })).pipe(
            switchMap(response => this.delete(path, id))
          );
        }

        return throwError(error);
      })
    );
  }

  patch(path: string, data: any, params: any = {}): Observable<any> {
    return from(this.feathersService.client.service(path).patch("", data, params)).pipe(
      catchError(error => {
        if (401 == error.status) {
          const accessToken = this._cookieStorage.getItem(this._storageKey);

          return from(this.authService.authenticate({
            accessToken: accessToken
          })).pipe(
            switchMap(response => this.patch(path, data))
          );
        }

        return throwError(error);
      })
    );
  }
}
