<div class="modal-header">
    <h3 class="modal-title text-uppercase">Concertations en attente</h3>
    <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
        <i class="fas fa-times"></i>
    </button>
</div>
<div class="modal-body pt-4">
    <ul class="">
        <ng-container *ngFor="let appointment of appointments">
            <li>
                <a class="btn-link-primary" [routerLink]="['/cercles', appointment.circle.token, 'rendez-vous', appointment.getId()]" title="{{ appointment.subject }}" (click)="bsModalRef.hide()">
                    {{ appointment.subject }}
                </a>
            </li>
        </ng-container>
    </ul>
</div>
