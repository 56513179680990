import { Injectable } from '@angular/core';

import { Observable, of, throwError, BehaviorSubject } from 'rxjs';
import { User } from '../models/user.model';

@Injectable({
  providedIn: 'root'
})
export class MeService {

  private _storageKey: string = 'me';
  private _localStorage;
  private _updatedMe: BehaviorSubject<User> = new BehaviorSubject<User>(null);

  constructor() {
    this._localStorage = window.localStorage;
  }

  onUpdatedMe(): Observable<User> {
    return this._updatedMe.asObservable();
  }

  storeMe(user: User) {
    this._localStorage.setItem(this._storageKey, JSON.stringify(user));
    this._updatedMe.next(user);
  }

  getMe(): User|null {
    if (this._localStorage.getItem(this._storageKey)) {
      return new User().deserialize(JSON.parse(this._localStorage.getItem(this._storageKey)));
    }

    return null;
  }

  deleteMe() {
    this._localStorage.removeItem(this._storageKey);
    this._updatedMe.next(null);
  }
}
