import { Component, OnInit } from '@angular/core';

import { finalize, switchMap } from 'rxjs/operators';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { MemberService } from '../../../core/services/member.service';
import { LoadingService } from '../../../core/services/loading.service';
import { AlertService } from '../../../core/services/alert.service';
import { InvitationService } from '../../../core/services/invitation.service';
import { Circle } from '../../../core/models/circle.model';

@Component({
    selector: 'app-modal-add-member',
    templateUrl: './modal-add-member.component.html',
    styleUrls: ['./modal-add-member.component.scss']
})
export class ModalAddMemberComponent implements OnInit {

    public circle: Circle = new Circle();
    public newMember: any = {};
    public memberForm: FormGroup;

    constructor(public bsModalRef: BsModalRef,
        private memberService: MemberService,
        private loadingService: LoadingService,
        private alertService: AlertService,
        private toastrService: ToastrService,
        private invitationService: InvitationService) { }

    ngOnInit() {
        this.prepareMemberForm();
    }

    prepareMemberForm() {
        this.memberForm = new FormGroup({
            invitationEmail: new FormControl('', [Validators.required, Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')]),
        });
    }

    setNewMember() {
        this.newMember.invitationEmail = this.memberForm.get('invitationEmail').value;
        this.newMember.circle = this.circle.getIRI();
    }

    onMemberFormSubmit() {
        this.memberForm['submitted'] = true;

        if (this.memberForm.valid) {
            this.setNewMember();
            this.loadingService.showLoading();
            this.memberService.createMember(this.newMember).pipe(
                switchMap(member => {
                    const newInvitation = {
                        member: member.getIRI(),
                        redirectUrl: this.invitationService.getValidationPath()
                    }

                    return this.invitationService.createInvitation(newInvitation);
                }),
                finalize(() => this.loadingService.dismissLoading())
                ).subscribe(invitation => {
                    this.memberForm['submitted'] = false;
                    this.memberForm.reset();
                    this.bsModalRef.hide();
                    this.alertService.closeAlert();
                    this.toastrService.success('Votre invitation a bien été envoyée', 'Félicitations !');
                }, error => {
                    console.log(error)
                    this.alertService.showAlert(error.errors, 'danger');
                });
        }
    }
}
