import { Injectable } from '@angular/core';

import { Subscription, Observable, BehaviorSubject } from 'rxjs';
import { User } from '../models/user.model';
import { MeService } from './me.service';
import { PostService } from './post.service';
import { CommentService } from './comment.service';
import { CircleService } from './circle.service';

@Injectable({
    providedIn: 'root'
})
export class NotificationService {

    private _createdNotification: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    private _observers: Subscription[] = [];

    constructor(private meService: MeService,
        private postService: PostService,
        private commentService: CommentService,
        private circleService: CircleService) {
        this.setObservers();
    }

    setObservers() {
        this._observers.push(this.onCreatedPost());
        this._observers.push(this.onCreatedComment());
    }

    onCreatedNotification(): Observable<any> {
        return this._createdNotification.asObservable();
    }

    onCreatedPost(): Subscription {
        return this.postService.onCreatedPost().subscribe(post => {
            const me: User = this.meService.getMe();

            if (post) {
                this.circleService.getCurrentCircle().subscribe(circle => {
                    if (post.member.user.getId() != me.getId() && (!circle || post.circle.getIRI() != circle.getIRI())) {
                        this._createdNotification.next({
                            title: 'Nouveau post',
                            description: `${post.member.user.firstName} ${post.member.user.lastName} vient de publier un post sur ${post.circle.name}`
                        });
                    }
                })
            }
        });
    }

    onCreatedComment(): Subscription {
        return this.commentService.onCreatedComment().subscribe(comment => {
            const me: User = this.meService.getMe();

            if (comment) {
                this.circleService.getCurrentCircle().subscribe(circle => {
                    if (comment.member.user.getId() != me.getId() && (!circle || comment.post.circle.getIRI() != circle.getIRI())) {
                        this._createdNotification.next({
                            title: 'Nouveau commentaire',
                            description: `${comment.member.user.firstName} ${comment.member.user.lastName} vient d'ajouter un commmentaire sur ${comment.post.circle.name}`
                        });
                    }
                })
            }
        });
    }

}
