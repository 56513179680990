import { Injectable } from '@angular/core';

import { ApiService } from './api.service';
import { FeathersService } from './feathers.service';
import { Member } from '../models/member.model';
import { Observable, BehaviorSubject, from } from 'rxjs';
import { map, switchMap, finalize } from 'rxjs/operators';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class MemberService {

  private _fsServiceName: string = "members";
  private _createdMember: BehaviorSubject<Member> = new BehaviorSubject<Member>(null);
  private _updatedMember: BehaviorSubject<Member> = new BehaviorSubject<Member>(null);
  private _patchedMember: BehaviorSubject<Member> = new BehaviorSubject<Member>(null);
  
  constructor(private apiService: ApiService,
    private feathersService: FeathersService,
    private authService: AuthService) {
    this.setCreatedMemberEvent();
    this.setUpdatedMemberEvent();
    this.setPatchedMemberEvent();
  }

  onCreatedMember(): Observable<Member> {
    return this._createdMember.asObservable();
  }

  onUpdatedMember(): Observable<Member> {
    return this._updatedMember.asObservable();
  }

  onPatchedMember(): Observable<Member> {
    return this._patchedMember.asObservable();
  }

  createMember(data: any): Observable<any> {
    return this.apiService.post(this._fsServiceName, data).pipe(
      map(member => new Member().deserialize(member))
    );
  }

  leaveCircleMember(id: any, data: any): Observable<any> {
    const params = {
      query: {
        operationPath: '/leave'
      }
    };

    return this.apiService.put(this._fsServiceName, id, data, params).pipe(
      map(member => new Member().deserialize(member))
    );
  }

  patchMember(id: any, data: any, params: any = {}): Observable<any> {
    return this.apiService.patch(this._fsServiceName, data, params).pipe(
      map(member => new Member().deserialize(member))
    );
  }

  deleteMember(id: number): Observable<any> {
    return this.apiService.delete(this._fsServiceName, id);
  }

  regenerateArchive(id: any): Observable<any> {
    const params = {
      query: {
        operationPath: '/regenerate-archive'
      }
    };

    return from(this.authService.authenticateAnonymous()).pipe(
      switchMap(response => {
        return this.apiService.get(this._fsServiceName, id, params).pipe(
          map(member => new Member().deserialize(member))
        )
      }),
      finalize(() => this.authService.logout())
    );
  }

  setCreatedMemberEvent() {
    this.feathersService.client.service(this._fsServiceName).on('created', member => {
      this._createdMember.next(new Member().deserialize(member));
    });
  }

  setUpdatedMemberEvent() {
    this.feathersService.client.service(this._fsServiceName).on('updated', member => {
      this._updatedMember.next(new Member().deserialize(member));
    });
  }

  setPatchedMemberEvent() {
    this.feathersService.client.service(this._fsServiceName).on('patched', member => {
      this._patchedMember.next(new Member().deserialize(member));
    });
  }

}
