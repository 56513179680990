import { Component, OnInit } from '@angular/core';

import { finalize } from 'rxjs/operators';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { CircleService } from '../../core/services/circle.service';
import { InvitationService } from '../../core/services/invitation.service';
import { LoadingService } from '../../core/services/loading.service';
import { AlertService } from '../../core/services/alert.service';
import { Circle } from '../../core/models/circle.model';

@Component({
    selector: 'app-modal-add-circle',
    templateUrl: './modal-add-circle.component.html',
    styleUrls: ['./modal-add-circle.component.scss']
})
export class ModalAddCircleComponent implements OnInit {

    public circle: Circle;
    public newCircle: any = {};
    public circleForm: FormGroup;

    constructor(public bsModalRef: BsModalRef,
        private circleService: CircleService,
        private invitationService: InvitationService,
        private loadingService: LoadingService,
        private alertService: AlertService,
        private toastr: ToastrService) { }

    ngOnInit() {
        this.prepareCircleForm();
    }

    prepareCircleForm() {
        this.circleForm = new FormGroup({
            name: new FormControl('', Validators.required),
            description: new FormControl(''),
            isUnderSurveillance: new FormControl('me'),
            invitationEmail: new FormControl('')
        });

        this.circleForm.get('isUnderSurveillance').valueChanges.subscribe(isUnderSurveillance => {
            const validators = (isUnderSurveillance == 'other') ? [Validators.required, Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')] : null;

            this.circleForm.get('invitationEmail').setValidators(validators);
            this.circleForm.get('invitationEmail').updateValueAndValidity();
        });
    }

    setNewCircle() {
        this.newCircle.name = this.circleForm.get('name').value;
        this.newCircle.description = this.circleForm.get('description').value;
        if (this.circleForm.get('isUnderSurveillance').value == 'other') {
            Object.assign(this.newCircle, {
                members: [
                    {
                        isUnderSurveillance: true,
                        invitationEmail: this.circleForm.get('invitationEmail').value
                    }
                ]
            });
        } else {
            delete this.newCircle.members;
        }
    }

    onCircleFormSubmit() {
        this.circleForm['submitted'] = true;

        if (this.circleForm.valid) {
            this.setNewCircle();
            this.loadingService.showLoading();
            this.circleService.createCircle(this.newCircle).pipe(
                finalize(() => this.loadingService.dismissLoading())
            ).subscribe(circle => {
                
                // Send invitation
                circle.members.forEach(member => {
                    if (member.isWaiting()) {
                        const newInvitation = {
                            member: member.getIRI(),
                            redirectUrl: this.invitationService.getValidationPath()
                        }
                        this.invitationService.createInvitation(newInvitation);
                    }
                });

                this.circleForm['submitted'] = false;
                this.circleForm.reset();
                this.circle = circle;
                this.bsModalRef.hide();
                this.alertService.closeAlert();
                this.toastr.success("Votre cercle a bien été créé.", 'Félicitations !');
            }, error => {
                const errorMessage: string[] = error.errors ? error.errors : ["Impossible de créer un cercle pour l'instant, veuillez réessayer ultérieurement, merci"];
                this.alertService.showAlert(errorMessage, 'danger');
                console.log(error);
            });
        }
    }
}
