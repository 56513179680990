<div class="container py-7 py-lg-12 px-lg-9">
    <div class="text-center d-lg-none">
        <a routerLink="/">
            <img src="../../assets/img/logo.svg" class="logo img-fluid" />
        </a>
    </div>
    <div class="container-fluid bg-white border-light-grey border rounded overflow-hidden mt-9 mt-lg-0">
        <div class="row align-items-lg-stretch">
            <div class="col-12 col-lg-6 px-0">
                <div id="carousel-register" class="carousel slide h-100" data-ride="carousel">
                    <ol class="carousel-indicators">
                        <li data-target="#carousel-register" data-slide-to="0" class="active"></li>
                        <li data-target="#carousel-register" data-slide-to="1"></li>
                        <li data-target="#carousel-register" data-slide-to="2"></li>
                    </ol>
                    <div class="carousel-inner h-100">
                        <div class="carousel-item h-100 active">
                            <img class="cover-landscape cover-lg-portrait" src="assets/img/register/slide-3.jpg">
                            <div class="carousel-caption">
                                <p>Cercle-confiance, le réseau social qui garantit la sécurité, la confidentialité, la propriété de vos données.</p>
                            </div>
                        </div>
                        <div class="carousel-item h-100">
                            <img class="cover-landscape cover-lg-portrait" src="assets/img/register/slide-2.jpg">
                            <div class="carousel-caption">
                                <p>Dans chaque cercle, les personnes invitées peuvent communiquer par tchat ou par visio, partager et stocker des documents, créer des évènements.</p>
                            </div>
                        </div>
                        <div class="carousel-item h-100">
                            <img class="cover-landscape cover-lg-portrait" src="assets/img/register/slide-1.jpg">
                            <div class="carousel-caption">
                                <p>Cercles familiaux, cercles d'amis, cercles professionnels : la sécurité et la confidentialité garantis.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-lg-6 p-5">
                <div class="text-center d-none d-lg-block">
                    <a routerLink="/">
                        <img src="../../assets/img/logo.svg" class="logo img-fluid mw-25" />
                    </a>
                </div>
                <h1 class="mt-4">
                    Inscrivez-vous gratuitement
                </h1>
                <form [formGroup]="registerForm" (ngSubmit)="onRegisterFormSubmit()" class="mt-5 mt-lg-7">
                    <div class="form-group">
                        <label for="email">Adresse e-mail*</label>
                        <input id="email" type="email" required name="email" formControlName="email" class="form-control" placeholder="votreadresse@mail.com" />
                        <ng-container *ngIf="registerForm.get('email').invalid && (registerForm.get('email').dirty || registerForm.get('email').touched || registerForm['submitted'])">
                            <small class="text-danger" *ngIf="registerForm.get('email').errors.required">
                                L'email est obligatoire
                            </small>
                            <small class="text-danger" *ngIf="registerForm.get('email').errors.pattern">
                                L'email n'est pas correct
                            </small>
                        </ng-container>
                    </div>
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="form-group">
                                <label for="first-name">Prénom*</label>
                                <input id="first-name" type="text" required name="firstName" formControlName="firstName" class="form-control" placeholder="Jean" />
                                <ng-container *ngIf="registerForm.get('firstName').invalid && (registerForm.get('firstName').dirty || registerForm.get('firstName').touched || registerForm['submitted'])">
                                    <small class="text-danger" *ngIf="registerForm.get('firstName').errors.required">
                                        Le prénom est obligatoire
                                    </small>
                                </ng-container>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="form-group">
                                <label for="last-name">Nom*</label>
                                <input id="last-name" type="text" required name="lastName" formControlName="lastName" class="form-control" placeholder="Dupont" />
                                <ng-container *ngIf="registerForm.get('lastName').invalid && (registerForm.get('lastName').dirty || registerForm.get('lastName').touched || registerForm['submitted'])">
                                  <small class="text-danger" *ngIf="registerForm.get('lastName').errors.required">
                                    Le nom est obligatoire
                                  </small>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="password">Mot de passe*</label>
                        <input id="password" type="password" required formControlName="password" class="form-control" name="password" placeholder="********">
                        <ng-container *ngIf="registerForm.get('password').invalid && (registerForm.get('password').dirty || registerForm.get('password').touched || registerForm['submitted'])">
                            <small class="text-danger" *ngIf="registerForm.get('password').errors.required">
                                Le mot de passe est obligatoire
                            </small>
                            <small class="text-danger" *ngIf="registerForm.get('password').errors.minlength">
                                Le mot de passe doit contenir au minimum 6 caractères
                            </small>
                        </ng-container>
                    </div>
                    <div class="form-group">
                        <label for="confirm-password">Confirmation de mot de passe*</label>
                        <input id="confirm-password" type="password" class="form-control" name="confirmPassword" formControlName="confirmPassword" placeholder="********">
                        <ng-container *ngIf="registerForm.get('confirmPassword').invalid && (registerForm.get('confirmPassword').dirty || registerForm.get('confirmPassword').touched || registerForm['submitted'])">
                            <small class="text-danger" *ngIf="registerForm.get('confirmPassword').errors.required">
                                Le mot de passe est obligatoire
                            </small>
                            <small class="text-danger" *ngIf="registerForm.get('confirmPassword').errors.matching">
                                Les deux mots de passe doivent être identiques
                            </small>
                        </ng-container>
                    </div>
                    <div class="form-check">
                        <input type="checkbox" name="acceptedTermsOfService" formControlName="acceptedTermsOfService" class="form-check-input" id="accept-terms-of-service">
                        <label class="form-check-label" for="accept-terms-of-service">
                            <span>J'accepte les <a [routerLink]="['/', 'cgu']" class="text-primary">CGV/CGU</a></span>
                        </label>
                        <ng-container *ngIf="registerForm.get('acceptedTermsOfService').invalid && registerForm['submitted']">
                            <small class="text-danger" *ngIf="registerForm.get('acceptedTermsOfService').errors.required">
                                Veuillez accepter les conditions générales d'utilisation
                            </small>
                        </ng-container>
                    </div>
                    <div class="form-group mt-12 mt-lg-14">
                        <button type="submit" class="btn btn-primary w-100">Valider</button>
                    </div>
                </form>
                <div class="text-center text-dark-grey">
                    Vous avez déjà un compte ? <a routerLink="/login" class="text-primary">Connectez-vous</a>
                </div>
            </div>
        </div>
    </div>
</div>
