/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./events.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../event/event.component.ngfactory";
import * as i4 from "../event/event.component";
import * as i5 from "../appointment-event/appointment-event.component.ngfactory";
import * as i6 from "../appointment-event/appointment-event.component";
import * as i7 from "../../../core/services/circle.service";
import * as i8 from "../../../core/services/appointment.service";
import * as i9 from "ngx-bootstrap/modal";
import * as i10 from "../../../core/services/loading.service";
import * as i11 from "../../../core/services/alert.service";
import * as i12 from "ngx-toastr";
import * as i13 from "./events.component";
import * as i14 from "../../../core/services/post.service";
import * as i15 from "../../../core/services/event.service";
import * as i16 from "@angular/router";
var styles_EventsComponent = [i0.styles];
var RenderType_EventsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_EventsComponent, data: {} });
export { RenderType_EventsComponent as RenderType_EventsComponent };
function View_EventsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "p", [["class", "mt-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "i", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Aucun \u00E9v\u00E8nement publi\u00E9."]))], null, null); }
function View_EventsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_EventsComponent_2)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.loadingEvents; _ck(_v, 1, 0, currVal_0); }, null); }
function View_EventsComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-event", [], null, null, null, i3.View_EventComponent_0, i3.RenderType_EventComponent)), i1.ɵdid(2, 114688, null, 0, i4.EventComponent, [], { event: [0, "event"], showAuthor: [1, "showAuthor"] }, null)], function (_ck, _v) { var currVal_0 = _v.parent.parent.context.$implicit; var currVal_1 = true; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_EventsComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-appointment-event", [], null, null, null, i5.View_AppointmentEventComponent_0, i5.RenderType_AppointmentEventComponent)), i1.ɵdid(2, 114688, null, 0, i6.AppointmentEventComponent, [i7.CircleService, i8.AppointmentService, i9.BsModalService, i10.LoadingService, i11.AlertService, i12.ToastrService, i1.ChangeDetectorRef], { appointment: [0, "appointment"] }, null)], function (_ck, _v) { var currVal_0 = _v.parent.parent.context.$implicit; _ck(_v, 2, 0, currVal_0); }, null); }
function View_EventsComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "mb-5"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EventsComponent_6)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EventsComponent_7)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.isTypeAppointment(_v.parent.context.$implicit); _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.isTypeAppointment(_v.parent.context.$implicit); _ck(_v, 4, 0, currVal_1); }, null); }
function View_EventsComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EventsComponent_5)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = !_v.context.$implicit.deletedAt; _ck(_v, 2, 0, currVal_0); }, null); }
function View_EventsComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "row mt-5"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "col-12"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EventsComponent_4)), i1.ɵdid(3, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.events; _ck(_v, 3, 0, currVal_0); }, null); }
function View_EventsComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "row mt-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "col-12 text-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "spinner-border text-primary"], ["role", "status"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [["class", "sr-only"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Loading..."]))], null, null); }
export function View_EventsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "events container-fluid p-0"]], null, null, null, null, null)), (_l()(), i1.ɵand(0, [["noEvents", 2]], null, 0, null, View_EventsComponent_1)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EventsComponent_3)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EventsComponent_8)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.events.length; var currVal_1 = i1.ɵnov(_v, 1); _ck(_v, 3, 0, currVal_0, currVal_1); var currVal_2 = _co.loadingEvents; _ck(_v, 5, 0, currVal_2); }, null); }
export function View_EventsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-events", [], null, null, null, View_EventsComponent_0, RenderType_EventsComponent)), i1.ɵdid(1, 770048, null, 0, i13.EventsComponent, [i7.CircleService, i14.PostService, i15.EventService, i16.Router, i8.AppointmentService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var EventsComponentNgFactory = i1.ɵccf("app-events", i13.EventsComponent, View_EventsComponent_Host_0, { circle: "circle", scrollEvent: "scrollEvent" }, { onNavigationStart: "onNavigationStart" }, []);
export { EventsComponentNgFactory as EventsComponentNgFactory };
