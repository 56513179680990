<div class="container py-7">
    <div class="row justify-content-center">
        <div class="col-12 col-sm-8 col-lg-5 text-center">
            <a routerLink="/">
                <img src="../../assets/img/logo.svg" class="logo img-fluid" />
            </a>
            <h1 class="mt-6 mt-lg-8">
                Télécharger mes données
            </h1>
            <div class="bg-white border-light-grey border rounded text-left p-5 mt-5 mt-lg-7">
                <form [formGroup]="codeForm" (ngSubmit)="onCodeFormSubmit()">
                    <div class="form-group">
                        <label for="code">Code de validation</label>
                        <input id="code" type="text" formControlName="code" class="form-control" placeholder="123456" />
                        <ng-container *ngIf="codeForm.get('code').invalid && (codeForm.get('code').dirty || codeForm.get('code').touched || codeForm['submitted'])">
                            <small class="text-danger" *ngIf="codeForm.get('code').errors.required">
                                Le code est obligatoire
                            </small>
                        </ng-container>
                    </div>
                    <div class="form-group mt-12 mt-lg-14">
                        <button type="submit" class="btn btn-primary w-100">Valider</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
