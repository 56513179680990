import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomepageComponent } from './homepage/homepage.component';
import { LoginComponent } from './login/login.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { LegalNoticeComponent } from './legal-notice/legal-notice.component';
import { GeneralConditionsOfUseComponent } from './general-conditions-of-use/general-conditions-of-use.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';

import { AuthGuard } from '../core/guards/auth.guard';
import { AnonymousGuard } from '../core/guards/anonymous.guard';
import { AboutComponent } from './about/about.component';
import { LoginDownloadArchiveComponent } from '../account/login-download-archive/login-download-archive.component';

const routes: Routes = [
  {
    path: "",
    redirectTo: 'cercles',
    pathMatch: 'full'
  },
  {
    path: "login",
    component: LoginComponent,
    canActivate: [AnonymousGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: "authentification",
    loadChildren: '../authentication/authentication.module#AuthenticationModule'
  },
  {
    path: "cercles",
    loadChildren: '../circles/circles.module#CirclesModule',
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: "profil",
    loadChildren: '../account/account.module#AccountModule',
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: "mentions-legales",
    component: LegalNoticeComponent,
    data: {
      hideHeader: true
    }
  },
  {
    path: "cgu",
    component: GeneralConditionsOfUseComponent,
    data: {
      hideHeader: true
    }
  },
  {
    path: "politique-de-confidentialite",
    component: PrivacyPolicyComponent,
    data: {
      hideHeader: true
    }
  },
  {
    path: "a-propos",
    component: AboutComponent,
  },
  {
    path: "telecharger-donnees",
    component: LoginDownloadArchiveComponent
  },
  {
    path: "**",
    component: NotFoundComponent,
    data: {
      hideHeader: true
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CoreRoutingModule { }
