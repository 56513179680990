import { Injectable } from '@angular/core';

import io from 'socket.io-client';
import feathers from '@feathersjs/feathers';
import socketio from '@feathersjs/socketio-client';
import auth from '@feathersjs/authentication-client';
import { environment } from '../../../environments/environment';
import { CookieStorage } from 'cookie-storage';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FeathersService {

  public client;
  public _updatedOnlineUsers: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public _updatedOfflineUsers: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor() {
    const socket = io(environment.fsAPI.url, {
      transports: ['websocket'],
      forceNew: true,
      pingInterval: 2000,
      pingTimeout: 50000
    });

    this.client = feathers();

    this.client.configure(socketio(socket, {
      timeout: 30000
    }));

    this.client.configure(auth({
      cookie: 'at',
      storageKey: 'at',
      storage: new CookieStorage()
    }));

    socket.on('online', (user) => {
      this._updatedOnlineUsers.next(user);
    });
    socket.on('offline', (user) => {
      this._updatedOfflineUsers.next(user);
    });
  }

  onUpdatedAuthenticated(): Observable<any> {
    return this._updatedOnlineUsers.asObservable();
  }

  onUpdatedDisconnected(): Observable<any> {
    return this._updatedOfflineUsers.asObservable();
  }

}
