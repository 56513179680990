import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';

import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { FeathersService } from './feathers.service';

@Injectable({
  providedIn: 'root'
})
export class MediaService {

  private _fsServiceName: string = "media";

  constructor(private apiService: ApiService,
    private feathersService: FeathersService,
    private http: HttpClient) {
  }

  downloadFile(id: number, params: any = {}): Observable<any> {
      const defaultParams = {
        query: {
          operationPath: '/download-file'
        }
      };

      Object.assign(defaultParams.query, params);
      return this.apiService.get(this._fsServiceName, id, defaultParams);
  }

  deleteMedia(id: number): Observable<any> {
    return this.apiService.delete(this._fsServiceName, id);
  }

}
