<footer class="mt-12">
    <div class="container">
        <div class="border-top border-grey pt-3 pb-6 small">
            <div class="row text-center text-lg-left">
                <div class="col-12 col-lg-6 mb-1 mb-lg-0 text-lg-right order-lg-2">
                    <ul class="list-unstyled m-0 list-inline list-inline--dashed">
                        <li>
                            <a [routerLink]="['/', 'politique-de-confidentialite']">Politique de confidentialité</a>
                        </li>
                        <li>
                            <a [routerLink]="['/', 'mentions-legales']">Mentions légales</a>
                        </li>
                        <li>
                            <a [routerLink]="['/', 'cgu']">CGU</a>
                        </li>
                    </ul>
                </div>
                <div class="col-12 col-lg-6 order-lg-1 text-dark-grey">
                    &copy; {{ today| date:'yyyy' }} Cercle Confiance. Tous droits réservés.
                </div>
            </div>
        </div>
    </div>
</footer>
