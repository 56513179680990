import { Circle } from '../models/circle.model';
import { Post } from '../models/post.model';
import { Member } from '../models/member.model';
import { Event } from '../models/event.model';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { Appointment } from '../models/appointment.model';
import * as i0 from "@angular/core";
import * as i1 from "./api.service";
import * as i2 from "./feathers.service";
export class CircleService {
    constructor(apiService, feathers) {
        this.apiService = apiService;
        this.feathers = feathers;
        this._fsServiceName = "circles";
        this._currentCircle = new BehaviorSubject(null);
        this._updatedCircle = new BehaviorSubject(null);
        this.setUpdatedCircleEvent();
    }
    getCurrentCircle() {
        return this._currentCircle.asObservable();
    }
    updateCurrentCircle(circle) {
        this._currentCircle.next(circle);
    }
    createCircle(data, params = {}) {
        return this.apiService.post(this._fsServiceName, data).pipe(map(circle => new Circle().deserialize(circle)));
    }
    updateCircle(token, data) {
        return this.apiService.put(this._fsServiceName, token, data).pipe(map(circle => {
            return new Circle().deserialize(circle);
        }));
    }
    getCircles(params = {}) {
        const defaultParams = {
            query: params
        };
        return this.apiService.getAll(this._fsServiceName, defaultParams).pipe(map(circles => {
            circles.forEach((value, index) => {
                circles[index] = new Circle().deserialize(value);
            });
            return circles;
        }));
    }
    getCircle(token) {
        return this.apiService.get(this._fsServiceName, token).pipe(map(circle => new Circle().deserialize(circle)));
    }
    getCirclePosts(token, params = {}) {
        const defaultParams = {
            query: {
                operationPath: '/posts'
            }
        };
        Object.assign(defaultParams.query, params);
        return this.apiService.get(this._fsServiceName, token, defaultParams).pipe(map(posts => {
            posts.forEach((value, index) => {
                posts[index] = new Post().deserialize(value);
            });
            return posts;
        }));
    }
    getCircleMembers(token, params = {}) {
        const defaultParams = {
            query: {
                operationPath: '/members'
            }
        };
        Object.assign(defaultParams.query, params);
        return this.apiService.get(this._fsServiceName, token, defaultParams).pipe(map(members => {
            members.forEach((value, index) => {
                members[index] = new Member().deserialize(value);
            });
            return members;
        }));
    }
    getCircleEvents(token, params = {}) {
        const defaultParams = {
            query: {
                operationPath: '/events'
            }
        };
        Object.assign(defaultParams.query, params);
        return this.apiService.get(this._fsServiceName, token, defaultParams).pipe(map(events => {
            events.forEach((value, index) => {
                events[index] = new Event().deserialize(value);
            });
            return events;
        }));
    }
    getCircleMembersMe(token) {
        const params = {
            query: {
                operationPath: '/members/me'
            }
        };
        return this.apiService.get(this._fsServiceName, token, params).pipe(map(member => new Member().deserialize(member)));
    }
    onUpdatedCircle() {
        return this._updatedCircle;
    }
    deleteCircle(id, data) {
        const params = {
            query: {
                operationPath: '/delete'
            }
        };
        return this.apiService.put(this._fsServiceName, id, data, params).pipe(map(circle => new Circle().deserialize(circle)));
    }
    setUpdatedCircleEvent() {
        this.feathers.client.service(this._fsServiceName).on('updated', circle => {
            this._updatedCircle.next(new Circle().deserialize(circle));
            this._updatedCircle.next(null);
        });
    }
    getCircleAppointments(token, params = {}) {
        const defaultParams = {
            query: {
                operationPath: '/appointments'
            }
        };
        Object.assign(defaultParams.query, params);
        return this.apiService.get(this._fsServiceName, token, defaultParams).pipe(map(appointments => {
            appointments.forEach((value, index) => {
                appointments[index] = new Appointment().deserialize(value);
            });
            return appointments;
        }));
    }
}
CircleService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CircleService_Factory() { return new CircleService(i0.ɵɵinject(i1.ApiService), i0.ɵɵinject(i2.FeathersService)); }, token: CircleService, providedIn: "root" });
