<div class="circle-post-comment">
    <div class="circle-post-comment__header">
        <div class="container-fluid pt-3 pb-1">
            <div class="row align-items-center">
                <div class="col-10 d-flex">
                    <ng-template #memberDeleted>
                        <app-user-avatar [user]="comment.member.user" [isUnderSurveillance]="comment.member.isUnderSurveillance" [memberDeleted]="comment.member.deletedAt" [size]="'tiny'"></app-user-avatar>
                        <span class="name d-inline-block align-middle ml-2 font-weight-bold">
                            {{ comment.member.getName() }}
                        </span>
                    </ng-template>
                    <ng-template [ngIf]="!comment.member.deletedAt" [ngIfElse]="memberDeleted">
                        <a [routerLink]="['/profil', comment.member.user.token]" *ngIf="!comment.member.isWaiting()" title="{{ comment.member.getName() }}">
                            <app-user-avatar [user]="comment.member.user" [isUnderSurveillance]="comment.member.isUnderSurveillance" [size]="'tiny'"></app-user-avatar>
                            <span class="name d-inline-block align-middle ml-2 font-weight-bold">
                                {{ comment.member.getName() }}
                                <!-- <span class="small text-primary d-block font-weight-normal" *ngIf="comment.member.isUnderSurveillance">Centre du cercle</span> -->
                            </span>
                        </a>
                    </ng-template>
                    <span class="dropdown dropdown-actions pl-1" *ngIf="(comment.member.getId() == memberMe.getId()) || memberMe.isAdmin">
                        <button class="pr-0" id="dropdownAction" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <span class="text-grey-50"><i class="fas fa-ellipsis-h"></i></span>
                        </button>
                        <div class="dropdown-menu" aria-labelledby="dropdownAction">
                            <button class="dropdown-item d-flex pl-4" (click)="onEdit()">
                                <span class="material-icons-outlined mr-3">edit</span>
                                <span>Modifier</span>
                            </button>
                            <button class="dropdown-item d-flex pl-4" (click)="onDelete()">
                                <span class="material-icons-outlined mr-3">delete</span>
                                <span>Supprimer</span>
                            </button>
                        </div>
                    </span>
                </div>
                <div class="col-2 d-flex justify-content-end small">
                    <div class="dropdown dropdown-reactions">
                        <button class="text-dark-grey pr-0" [ngClass]="{'active' : reactionMemberMe}" id="dropdownReactionButton" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <span style="font-size: 1.25em;"><i [ngClass]="getClassReactionMemberMe()"></i></span>
                        </button>
                        <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownReactionButton">
                            <button class="dropdown-item text-dark-grey ml-2 px-2" (click)="onLike()">
                                <span style="font-size: 1.25em;"><i class="far fa-thumbs-up"></i></span>
                            </button>
                            <button class="dropdown-item text-dark-grey px-2" (click)="onLove()">
                                <span style="font-size: 1.25em;"><i class="far fa-heart"></i></span>
                            </button>
                            <button class="dropdown-item text-dark-grey mr-2 px-2" (click)="onQuestion()">
                                <span style="font-size: 1.25em;"><i class="fas fa-question"></i></span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="circle-post-comment__content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <p [innerHTML]="comment.message" class="mb-0">
                </div>
            </div>
        </div>
    </div>
    <div class="pt-2 circle-post-comment__footer">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12 small d-flex">
                    <span class="text-grey-50">{{ comment.createdAt | dateFrom }}</span>
                    <span class="text-grey-50 ml-2 comment-updated" *ngIf="comment.createdAt != comment.updatedAt">Modifié</span>
                    <button class="align-middle ml-2 pl-0 text-grey-50" [ngClass]="{'nb-reactions':isReacted}" (click)="openReactionModal()"> 
                        <ng-template ngFor let-r [ngForOf]="nbReactions | keyvalue: orderOriginal">
                            <ng-template [ngIf]="r.value > 0">
                                <span class="mr-1" style="font-size: 1em;"><i [ngClass]="reactionOutlined[r.key]"></i></span>
                                <span class="mr-2">{{ r.value }}</span>
                            </ng-template>
                        </ng-template>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
