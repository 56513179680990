import { Routes } from '@angular/router';
import { RegisterComponent } from './register/register.component';
import { InvitationComponent } from './invitation/invitation.component';
import { PasswordRequestComponent } from './password-request/password-request.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { AnonymousGuard } from '../core/guards/anonymous.guard';
import { AuthGuard } from '../core/guards/auth.guard';
import { InvitationGuard } from '../core/guards/invitation.guard';
import { PasswordRequestGuard } from '../core/guards/password-request.guard';
const ɵ0 = {
    hideHeader: true
}, ɵ1 = {
    hideHeader: true
}, ɵ2 = {
    hideHeader: true
}, ɵ3 = {
    hideHeader: true
};
const routes = [
    {
        path: "inscription",
        component: RegisterComponent,
        canActivate: [AnonymousGuard],
        runGuardsAndResolvers: 'always',
        data: ɵ0
    },
    {
        path: "invitation/:token",
        component: InvitationComponent,
        canActivate: [AuthGuard, InvitationGuard],
        runGuardsAndResolvers: 'always',
        data: ɵ1
    },
    {
        path: "mot-de-passe-oublie",
        component: PasswordRequestComponent,
        canActivate: [AnonymousGuard],
        runGuardsAndResolvers: 'always',
        data: ɵ2
    },
    {
        path: "nouveau-mot-de-passe/:token",
        component: ResetPasswordComponent,
        canActivate: [AnonymousGuard, PasswordRequestGuard],
        runGuardsAndResolvers: 'always',
        data: ɵ3
    },
];
export class AuthenticationRoutingModule {
}
export { ɵ0, ɵ1, ɵ2, ɵ3 };
