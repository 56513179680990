/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./album.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@angular/router";
import * as i4 from "../../../shared/components/user-avatar/user-avatar.component.ngfactory";
import * as i5 from "../../../shared/components/user-avatar/user-avatar.component";
import * as i6 from "../../../shared/pipes/shorten.pipe";
import * as i7 from "../../../shared/pipes/date-from.pipe";
import * as i8 from "./album.component";
import * as i9 from "../../../core/services/media.service";
import * as i10 from "../../../core/services/post.service";
import * as i11 from "ngx-bootstrap/modal";
import * as i12 from "../../../core/services/loading.service";
import * as i13 from "ngx-toastr";
import * as i14 from "../../../core/services/alert.service";
import * as i15 from "../../../core/services/circle.service";
var styles_AlbumComponent = [i0.styles];
var RenderType_AlbumComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AlbumComponent, data: {} });
export { RenderType_AlbumComponent as RenderType_AlbumComponent };
function View_AlbumComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "button", [["class", "close"], ["title", "Supprimer le m\u00E9dia"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.removeMedia(_v.parent.parent.context.index) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [["class", "material-icons"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["close"]))], null, null); }
function View_AlbumComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "div", [["class", "media"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [["class", "img-fluid"], ["src", "../assets/img/placeholders/placeholder-media.png"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AlbumComponent_3)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 10, "button", [["type", "button"]], [[8, "title", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.downloadImage(_v.parent.context.$implicit.getId(), _v.parent.context.$implicit.originalName, _v.parent.context.$implicit.mimeType) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 3, "i", [], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(7, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(8, { "media--file": 0, "media--spreadsheet": 1, "media--video": 2, "media-pdf": 3, "media-word": 4, "media-powerpoint": 5 }), (_l()(), i1.ɵeld(9, 0, null, null, 2, "span", [["class", "small text-dark-grey d-lg-none"]], null, null, null, null, null)), (_l()(), i1.ɵted(10, null, ["", ""])), i1.ɵppd(11, 2), (_l()(), i1.ɵeld(12, 0, null, null, 2, "span", [["class", "small text-dark-grey d-none d-lg-block"]], null, null, null, null, null)), (_l()(), i1.ɵted(13, null, ["", ""])), i1.ɵppd(14, 2)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.showAuthor && ((_co.post.member.getIRI() == _co.memberMe.getIRI()) || _co.memberMe.isAdmin)); _ck(_v, 3, 0, currVal_0); var currVal_2 = _ck(_v, 8, 0, _v.parent.context.$implicit.isFile(), _v.parent.context.$implicit.isSpreadsheet(), _v.parent.context.$implicit.isVideo(), _v.parent.context.$implicit.isPdf(), _v.parent.context.$implicit.isWord(), _v.parent.context.$implicit.isPresentation()); _ck(_v, 7, 0, currVal_2); }, function (_ck, _v) { var currVal_1 = i1.ɵinlineInterpolate(1, "", _v.parent.context.$implicit.originalName, ""); _ck(_v, 4, 0, currVal_1); var currVal_3 = i1.ɵunv(_v, 10, 0, _ck(_v, 11, 0, i1.ɵnov(_v.parent.parent, 0), _v.parent.context.$implicit.originalName, 14)); _ck(_v, 10, 0, currVal_3); var currVal_4 = i1.ɵunv(_v, 13, 0, _ck(_v, 14, 0, i1.ɵnov(_v.parent.parent, 0), _v.parent.context.$implicit.originalName, 8)); _ck(_v, 13, 0, currVal_4); }); }
function View_AlbumComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["class", "pl-0 text-black-50"], ["title", "Supprimer le m\u00E9dia"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.removeMedia(_v.parent.parent.context.index) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["class", "fas fa-trash-alt"], ["style", "padding-left: 3px;"]], null, null, null, null, null))], null, null); }
function View_AlbumComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "d-flex align-items-center mt-1"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "audio", [["controls", ""]], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Votre navigateur ne prend pas en charge l'audio HTML. "])), (_l()(), i1.ɵeld(3, 0, null, null, 4, "div", [["class", "d-flex flex-column pt-1"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "button", [["class", "pl-0 text-black-50"], ["title", "T\u00E9l\u00E9charger l'audio"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.downloadImage(_v.parent.context.$implicit.getId(), _v.parent.context.$implicit.originalName, _v.parent.context.$implicit.mimeType) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "i", [["class", "fas fa-arrow-circle-down ml-1"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AlbumComponent_5)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = (_co.showAuthor && ((_co.post.member.getIRI() == _co.memberMe.getIRI()) || _co.memberMe.isAdmin)); _ck(_v, 7, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.getAbsolutePath(); _ck(_v, 1, 0, currVal_0); }); }
function View_AlbumComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "a", [["class", "media media--image"]], [[8, "href", 4], [1, "data-caption", 0], [1, "data-mime-type", 0], [1, "data-media-id", 0], [8, "title", 0]], null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵprd(512, null, i2.ɵNgStyleImpl, i2.ɵNgStyleR2Impl, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2]), i1.ɵdid(4, 278528, null, 0, i2.NgStyle, [i2.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(5, { "background-image": 0 }), (_l()(), i1.ɵeld(6, 0, null, null, 0, "img", [["class", "img-fluid w-100"], ["src", "../assets/img/placeholders/placeholder-media.png"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_5 = "media media--image"; var currVal_6 = ("gallery-" + _co.post.getId()); _ck(_v, 2, 0, currVal_5, currVal_6); var currVal_7 = _ck(_v, 5, 0, (("url(" + _v.parent.context.$implicit.getAbsolutePath()) + ")")); _ck(_v, 4, 0, currVal_7); }, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.getAbsolutePath(); var currVal_1 = _v.parent.context.$implicit.originalName; var currVal_2 = _v.parent.context.$implicit.mimeType; var currVal_3 = _v.parent.context.$implicit.getId(); var currVal_4 = i1.ɵinlineInterpolate(1, "", _v.parent.context.$implicit.originalName, ""); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }); }
function View_AlbumComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "button", [["class", "delete text-black-50"], ["title", "Supprimer le m\u00E9dia"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.removeMedia(_v.parent.context.index) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [["class", "material-icons"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["close"]))], null, null); }
function View_AlbumComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "col-6 col-sm-4 px-0 px-sm-1 align-middle"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AlbumComponent_2)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AlbumComponent_4)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AlbumComponent_6)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AlbumComponent_7)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_v.context.$implicit.isFile() || _v.context.$implicit.isSpreadsheet()) || _v.context.$implicit.isVideo()); _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.context.$implicit.isAudio(); _ck(_v, 4, 0, currVal_1); var currVal_2 = _v.context.$implicit.isImage(); _ck(_v, 6, 0, currVal_2); var currVal_3 = ((_v.context.$implicit.isImage() && _co.showAuthor) && ((_co.post.member.getIRI() == _co.memberMe.getIRI()) || _co.memberMe.isAdmin)); _ck(_v, 8, 0, currVal_3); }, null); }
function View_AlbumComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "a", [], [[8, "title", 0], [1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 671744, null, 0, i3.RouterLinkWithHref, [i3.Router, i3.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(2, 2), (_l()(), i1.ɵeld(3, 0, null, null, 1, "app-user-avatar", [], null, null, null, i4.View_UserAvatarComponent_0, i4.RenderType_UserAvatarComponent)), i1.ɵdid(4, 114688, null, 0, i5.UserAvatarComponent, [], { user: [0, "user"], size: [1, "size"], isUnderSurveillance: [2, "isUnderSurveillance"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_3 = _ck(_v, 2, 0, "/profil", _co.post.member.user.token); _ck(_v, 1, 0, currVal_3); var currVal_4 = _co.post.member.user; var currVal_5 = "tiny"; var currVal_6 = _co.post.member.isUnderSurveillance; _ck(_v, 4, 0, currVal_4, currVal_5, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", _co.post.member.getName(), ""); var currVal_1 = i1.ɵnov(_v, 1).target; var currVal_2 = i1.ɵnov(_v, 1).href; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
function View_AlbumComponent_10(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-user-avatar", [], null, null, null, i4.View_UserAvatarComponent_0, i4.RenderType_UserAvatarComponent)), i1.ɵdid(1, 114688, null, 0, i5.UserAvatarComponent, [], { user: [0, "user"], size: [1, "size"], isUnderSurveillance: [2, "isUnderSurveillance"], memberDeleted: [3, "memberDeleted"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.post.member.user; var currVal_1 = "tiny"; var currVal_2 = _co.post.member.isUnderSurveillance; var currVal_3 = _co.post.member.deletedAt; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
function View_AlbumComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "row mt-1"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "col-9 pr-0"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "span", [["class", "small"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), i1.ɵppd(4, 1), (_l()(), i1.ɵeld(5, 0, null, null, 3, "div", [["class", "col-3 text-right pl-0"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AlbumComponent_9)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["memberDeleted", 2]], null, 0, null, View_AlbumComponent_10))], function (_ck, _v) { var _co = _v.component; var currVal_1 = !_co.post.member.deletedAt; var currVal_2 = i1.ɵnov(_v, 8); _ck(_v, 7, 0, currVal_1, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 3, 0, _ck(_v, 4, 0, i1.ɵnov(_v.parent, 1), _co.post.createdAt)); _ck(_v, 3, 0, currVal_0); }); }
export function View_AlbumComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i6.ShortenPipe, []), i1.ɵpid(0, i7.DateFromPipe, []), (_l()(), i1.ɵeld(2, 0, null, null, 8, "div", [["class", "album"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(4, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 3, "div", [["class", "album__medias"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 2, "div", [["class", "row align-items-stretch mx-0 mx-sm-n1"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AlbumComponent_1)), i1.ɵdid(8, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AlbumComponent_8)), i1.ɵdid(10, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "album"; var currVal_1 = ("album--" + _co.container); _ck(_v, 4, 0, currVal_0, currVal_1); var currVal_2 = _co.post.medias; _ck(_v, 8, 0, currVal_2); var currVal_3 = (_co.showAuthor && _co.post.medias.length); _ck(_v, 10, 0, currVal_3); }, null); }
export function View_AlbumComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-album", [], null, null, null, View_AlbumComponent_0, RenderType_AlbumComponent)), i1.ɵdid(1, 114688, null, 0, i8.AlbumComponent, [i9.MediaService, i10.PostService, i11.BsModalService, i1.ChangeDetectorRef, i12.LoadingService, i13.ToastrService, i14.AlertService, i2.DatePipe, i15.CircleService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AlbumComponentNgFactory = i1.ɵccf("app-album", i8.AlbumComponent, View_AlbumComponent_Host_0, { post: "post", showAuthor: "showAuthor", container: "container" }, {}, []);
export { AlbumComponentNgFactory as AlbumComponentNgFactory };
