/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./modal-confirmation.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./modal-confirmation.component";
import * as i3 from "ngx-bootstrap/modal";
var styles_ModalConfirmationComponent = [i0.styles];
var RenderType_ModalConfirmationComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ModalConfirmationComponent, data: {} });
export { RenderType_ModalConfirmationComponent as RenderType_ModalConfirmationComponent };
export function View_ModalConfirmationComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h3", [["class", "modal-title text-uppercase"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Confirmation"])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "button", [["aria-label", "Close"], ["class", "close pull-right"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.bsModalRef.hide() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "i", [["class", "fas fa-times"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 7, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "h6", [["class", "mb-8"]], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["Etes-vous s\u00FBr(e) de vouloir ", " ?"])), (_l()(), i1.ɵeld(8, 0, null, null, 4, "div", [["class", "float-right mb-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "button", [["class", "btn btn-outline-secondary btn--small py-2 px-3 mr-2"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onConfirmation(false) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Annuler"])), (_l()(), i1.ɵeld(11, 0, null, null, 1, "button", [["class", "btn btn-primary btn--small py-2 px-3"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onConfirmation(true) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["OK"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.objectToRemove; _ck(_v, 7, 0, currVal_0); }); }
export function View_ModalConfirmationComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-modal-confirmation", [], null, null, null, View_ModalConfirmationComponent_0, RenderType_ModalConfirmationComponent)), i1.ɵdid(1, 114688, null, 0, i2.ModalConfirmationComponent, [i3.BsModalRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ModalConfirmationComponentNgFactory = i1.ɵccf("app-modal-confirmation", i2.ModalConfirmationComponent, View_ModalConfirmationComponent_Host_0, { objectToRemove: "objectToRemove" }, {}, []);
export { ModalConfirmationComponentNgFactory as ModalConfirmationComponentNgFactory };
