<div class="container py-7">
    <div class="row justify-content-center">
        <div class="col-12 col-sm-8 col-lg-5 text-center">
            <a routerLink="/">
                <img src="../../assets/img/logo.svg" class="logo img-fluid" />
            </a>
            <h1 class="mt-6 mt-lg-8">
                Vous êtes invité au cercle <span class="text-primary">{{ invitation.circleName }}</span>
            </h1>
            <ng-container *ngIf="invitationExpired && invitationExpired != null">
                <div class="mt-5 mt-lg-7 text-center">
                    <p class="font-weight-bold mb-9">Désolé, l'invitation a expirée.</p>
                    <a routerLink="/">Retour à l'application</a>
                </div>
            </ng-container>
            <ng-container *ngIf="!invitationExpired && invitationExpired != null">
                <div class="mt-12 mt-lg-14">
                    <button type="button" class="btn btn-primary w-100" (click)="acceptInvitation()">
                        J'accepte l'invitation
                    </button>
                    <a routerLink="/" class="d-inline-block mt-5">Je refuse l'invitation</a>
                </div>
            </ng-container>
        </div>
    </div>
</div>
