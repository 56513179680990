<div class="modal-header">
    <h3 class="modal-title text-uppercase">Modifier un post</h3>
    <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
        <i class="fas fa-times"></i>
    </button>
</div>
<div class="modal-body">
    <form [formGroup]="editPostForm" (ngSubmit)="onEditPostFormSubmit()">
        <div class="form-group">
            <textarea name="message" formControlName="message" rows="2" class="form-control" placeholder="Tapez votre message ici..."></textarea>
        </div>
        <div class="form-group mt-14">
            <button type="submit" class="btn btn-primary w-100">Envoyer</button>
        </div>
        <div class="row mt-3" *ngIf="editEvent">
            <div class="col-12 col-lg-6">
                <div class="circle-posts__edit-post__event">
                    <button type="button" class="close" (click)="removeExistingEvent()" title="Supprimer l'évènement">
                        <span class="material-icons">close</span>
                    </button>
                    <app-event [event]="editEvent"></app-event>
                </div>
            </div>
        </div>
        <div class="row mt-3" *ngIf="newEvent">
            <div class="col-12 col-lg-6">
                <div class="circle-posts__edit-post__event">
                    <button type="button" class="close" (click)="removeEvent()" title="Supprimer l'évènement">
                        <span class="material-icons">close</span>
                    </button>
                    <app-event [event]="newEvent"></app-event>
                </div>
            </div>
        </div>
        <div class="row mt-3" *ngIf="medias.length || newMedias.length">
            <div class="col-12">
                <div class="circle-posts__edit-post__medias">
                    <div class="row mx-n1">
                        <ng-container *ngFor="let media of medias; index as i;">
                            <div class="col-3 col-sm-2 px-1">
                                <div class="circle-posts__edit-post__medias__media-container">
                                    <button type="button" class="close" (click)="removeExistingMedia(i)" title="Supprimer le média">
                                        <span class="material-icons">close</span>
                                    </button>
                                    <div class="media" title="{{ media.originalName }}" *ngIf="media.isFile() || media.isSpreadsheet() || media.isVideo() || media.isAudio()" [ngClass]="{'media--file': media.isFile(), 'media--spreadsheet': media.isSpreadsheet(), 'media--audio': media.isAudio(), 'media--video': media.isVideo()}">
                                        <img src="../assets/img/placeholders/placeholder-media.png" class="img-fluid">
                                        <span class="small text-dark-grey d-md-none">{{ media.originalName|shorten:4 }}</span>
                                        <span class="small text-dark-grey d-none d-md-block">{{ media.originalName|shorten:7 }}</span>
                                    </div>
                                    <div class="media media--image" [ngStyle]="{'background-image': 'url(' + media.getAbsolutePath() + ')'}" title="{{ media.originalName }}" *ngIf="media.isImage()">
                                        <img src="../assets/img/placeholders/placeholder-media.png" class="img-fluid">
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                        <ng-container *ngFor="let media of newMedias; index as i;">
                            <div class="col-3 col-sm-2 px-1">
                                <div class="circle-posts__edit-post__medias__media-container">
                                    <button type="button" class="close" (click)="removeMedia(i)" title="Supprimer le média">
                                        <span class="material-icons">close</span>
                                    </button>
                                    <div class="media" title="{{ media.filename }}" *ngIf="media.isFile() || media.isSpreadsheet() || media.isVideo() || media.isAudio()" [ngClass]="{'media--file': media.isFile(), 'media--spreadsheet': media.isSpreadsheet(), 'media--audio': media.isAudio(), 'media--video': media.isVideo()}">
                                        <img src="../assets/img/placeholders/placeholder-media.png" class="img-fluid">
                                        <span class="small text-dark-grey d-md-none">{{ media.filename|shorten:4 }}</span>
                                        <span class="small text-dark-grey d-none d-md-block">{{ media.filename|shorten:7 }}</span>
                                    </div>
                                    <div class="media media--image" [ngStyle]="{'background-image': 'url(' + media.data + ')'}" title="{{ media.filename }}" *ngIf="media.isImage()">
                                        <img src="../assets/img/placeholders/placeholder-media.png" class="img-fluid">
                                        <div class="align-center position-absolute" *ngIf="!media.data">
                                            <div class="spinner-border text-primary" role="status">
                                                <span class="sr-only">Loading...</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-12">
                <div class="form-group mb-0 d-inline-block align-middle">
                    <input type="file" id="mediasEdit" name="mediasEdit" ng2FileSelect [uploader]="uploaderEdit" multiple />
                    <label for="mediasEdit" class="btn btn-secondary btn--small btn-add-content" title="Ajouter un document">
                        <span class="material-icons d-inline-block align-middle mr-md-2">
                            attach_file
                        </span>
                        <span class="d-none d-sm-inline-block align-middle">Ajouter un document</span>
                    </label>
                </div>
                <div class="form-group mb-0 d-inline-block align-middle ml-3" *ngIf="!editEvent">
                    <button type="button" class="btn btn-secondary btn--small btn-add-content" title="Ajouter un évènement" (click)="openEditAddEventModal()">
                        <span class="material-icons d-inline-block align-middle mr-md-2">
                            event
                        </span>
                        <span class="d-none d-sm-inline-block align-middle">Ajouter un évènement</span>
                    </button>
                </div>
            </div>
        </div>
    </form>
</div>
