<div class="circle-settings h-lg-100">
    <div class="container-fluid py-8">
        <div class="row justify-content-center">
            <div class="col-12 col-md-10">
                <div class="row">
                    <div class="col-12">
                        <h2>Paramètres</h2>
                    </div>
                </div>
                <div class="row my-6" *ngIf="!loadingSettings">
                    <div class="col-12">
                        <h4>Gérer les membres</h4>
                        <div class="row mt-4 align-items-center">
                            <ng-container *ngFor="let member of circle.members; index as index">
                                <ng-container *ngIf="!member.isWaiting() && !member.deletedAt">
                                    <div class="col-12 col-md-6 col-lg-4 mb-3 d-flex flex-row-reverse justify-content-end flex-sm-row justify-content-sm-start">
                                        <div>
                                            <button type="button" class="pt-2 align-middle" title="Sortir du cercle" *ngIf="!member.isAdmin && !member.isUnderSurveillance && (member.getId() == memberMe.getId() || memberMe.isAdmin)" (mouseover)="hover[index]=true" (mouseout)="hover[index]=false" (click)="onLeaveCircle(index)">
                                                <span class="material-icons text-danger">{{ hover[index] == true ? 'remove_circle' : 'remove_circle_outline' }}</span>
                                            </button>
                                        </div>
                                        <div>
                                            <app-user-avatar [user]="member.user" [isUnderSurveillance]="member.isUnderSurveillance"></app-user-avatar>
                                            <span class="name d-inline-block align-middle ml-2 font-weight-bold">
                                                <span class="d-none d-lg-block">{{ member.getName() |shorten: 15 }}</span>
                                                <span class="d-lg-none">{{ member.getName() }}</span>
                                                <span class="small text-primary d-block font-weight-normal" *ngIf="member.isUnderSurveillance">Centre du cercle</span>
                                            </span>
                                        </div>
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="member.isWaiting()">
                                    <div class="col-12 col-md-6 col-lg-4 mb-3">
                                        <app-user-avatar [user]="member.user" [isUnderSurveillance]="member.isUnderSurveillance"></app-user-avatar>
                                        <span class="name d-inline-block align-middle ml-2 font-weight-bold">
                                            En attente
                                            <span class="d-none d-lg-block small text-dark-grey font-weight-normal">{{ member.email|shorten:15 }}</span>
                                            <span class="d-block d-lg-none small text-dark-grey font-weight-normal">{{ member.email }}</span>
                                        </span>
                                        <button type="button" class="d-block ml-10 btn-link-danger small"(click)="deleteMember(index)">Supprimer</button>
                                    </div>
                                </ng-container>
                            </ng-container>
                        </div>
                    </div>
                </div>
                <div class="row mb-2">
                    <div class="col-12">
                        <button type="button" class="btn btn-link-primary" (click)="openUpdateCircleModal()">
                            <h4 class="font-weight-normal">Modifier le nom du cercle</h4>
                        </button>
                    </div>
                </div>
                <div class="row" *ngIf="!circle.deletedAt">
                    <div class="col-12">
                        <button type="button" class="btn btn-link-danger" (click)="onDeleteCircle()">
                            <h4 class="font-weight-normal">Supprimer le cercle</h4>
                        </button>
                    </div>
                </div>
                <div class="row" *ngIf="loadingSettings">
                    <div class="col-12 text-center">
                        <div class="spinner-border text-primary" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
