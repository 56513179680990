import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService } from './api.service';
import { Invitation } from '../models/invitation.model';

@Injectable({
  providedIn: 'root'
})
export class InvitationService {

  private _fsServiceName: string = "invitations";
  private _validationPath: string = "/authentification/invitation";

  constructor(private apiService: ApiService) { }

  getValidationPath(): string {
    return window.location.origin + this._validationPath;
  }

  createInvitation(data: any): Observable<any> {
    return this.apiService.post(this._fsServiceName, data).pipe(
      map(invitation => new Invitation().deserialize(invitation))
    );
  }

  acceptInvitation(token: string, data: any): Observable<any> {
    const params = {
      query: {
        operationPath: '/accept'
      }
    };

    return this.apiService.put(this._fsServiceName, token, data, params).pipe(
      map(invitation => new Invitation().deserialize(invitation))
    );
  }

  getInvitation(token: string): Observable<any> {
    return this.apiService.get(this._fsServiceName, token).pipe(
      map(invitation => new Invitation().deserialize(invitation))
    );
  }
}
