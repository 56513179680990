import { Serializable } from "../interfaces/serializable";
import { User } from "./user.model";

export class SubscriptionModel implements Serializable {
    static readonly STATUS_OK = ['ACCEPTED', 'AUTHORISED'];  
  
    private _id: number = null;
    public alias: string = "";
    public user: string = "";
    public frequency: number = null;
    public price: number = null;
    public storage: number = 2048;
    public createdAt: Date = null;
    public status: string = "";
    public transId: string = "";
    public storageUsed: number = null;
    public effectDate: Date = null;
    public circles: string[] = [];
    public invoices: string[] = [];

    constructor() {}

    public getIRI() {
        return '/api/subscriptions/' + this.alias;
    }

    public getId() {
        return this._id;
    }

    public deserialize(input: any) {
        if (!input) {
          return null;
        }
    
        Object.getOwnPropertyNames(this).forEach((property) => {
          switch (property) {
            default:
              if ('_id' == property && input.id) {
                this._id = input.id;
              } else if (property in input) {
                this[property] = input[property];
              }
              break;
          }
        });
        return this;
      }
    
      public serialize(): any {
        var output = {};
    
        Object.getOwnPropertyNames(this).forEach((property) => {
          switch (property) {
            default:
              if (property != '_id') {
                output[property] = this[property];
              }
              break;
          }
        });
    
        return output;
      }
}
