import { Component, OnInit } from '@angular/core';

import { FileUploader } from 'ng2-file-upload';
import { Router } from '@angular/router';
import { of } from 'rxjs';
import { finalize, switchMap } from 'rxjs/operators';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { FixOrientationPipe } from '../../shared/pipes/fix-orientation.pipe';
import { matchingValidator } from '../../shared/directives/matching-validator.directive';
import { UserService } from '../../core/services/user.service';
import { MeService } from '../../core/services/me.service';
import { LoadingService } from '../../core/services/loading.service';
import { AlertService } from '../../core/services/alert.service';
import { AuthService } from '../../core/services/auth.service';
import { User } from '../../core/models/user.model';
import { Avatar } from '../../core/models/avatar.model';

@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.scss']
})
export class EditProfileComponent implements OnInit {

    private _userAvatar: Avatar = null;
    public user: User = new User();
    public newAvatar: Avatar = null;
    public userForm: FormGroup;
    public previewInProgress: boolean = false;
    public uploader: FileUploader = new FileUploader({ itemAlias: 'file' });

    constructor(private meService: MeService,
        private userService: UserService,
        private loadingService: LoadingService,
        private alertService: AlertService,
        private toastrService: ToastrService,
        private router: Router,
        private fixOrientationPipe: FixOrientationPipe) {}

    ngOnInit() {
        this.getMe();
        this.prepareUserForm();
        this.setUploader();
    }

    getMe() {
        this.user = this.meService.getMe();
        this._userAvatar = this.user.avatar;
    }

    setUploader() {
        const reader = new FileReader();

        this.uploader.onAfterAddingFile = (fileItem) => {
            const fileObject = fileItem.file;

            this.previewInProgress = true;
            reader.onload = () => {
                this.fixOrientationPipe.transform(reader.result).subscribe(imageBase64 => {
                    this.previewInProgress = false;
                    this.newAvatar = new Avatar().deserialize({
                        filename: fileObject.name,
                        data: imageBase64
                    });
                    this.user.avatar = this.newAvatar;
                });
            };

            reader.readAsDataURL(fileItem._file);
        };
    }

    prepareUserForm() {
        this.userForm = new FormGroup({
            firstName: new FormControl(this.user.firstName, Validators.required),
            lastName: new FormControl(this.user.lastName, Validators.required),
            password: new FormControl('', [Validators.minLength(6), matchingValidator('confirmPassword', true)]),
            confirmPassword: new FormControl('', matchingValidator('password'))
        });
    }

    setUser() {
        this.user.firstName = this.userForm.get('firstName').value;
        this.user.lastName = this.userForm.get('lastName').value;
        this.user.plainPassword = this.userForm.get('password').value;
    }

    removeAvatar() {
        this.newAvatar = null;
        this.uploader.queue = [];
        this.user.avatar = this._userAvatar;
    }

    getNewAvatarData(): FormData {
        let input = new FormData();

        input.append('image', this.uploader.queue[0]._file);

        return input;
    }

    onEditUserFormSubmit() {
        this.userForm['submitted'] = true;

        if (this.userForm.valid) {
            this.loadingService.showLoading();

            // Update Avatar
            const updateAvatar = (this.newAvatar) ? this.userService.updateAvatar(this.user.token, this.getNewAvatarData()) : of(false);

            this.setUser();
            updateAvatar.pipe(
                switchMap(avatar => {
                    if (avatar) {
                        this.user.avatar = avatar;
                    }

                    return this.userService.updateUser(this.user.token, this.user);
                }),
                finalize(() => this.loadingService.dismissLoading())
            ).subscribe(user => {
                this.meService.storeMe(user);
                this.toastrService.success('Votre compte a bien été modifié', 'Félicitations !');
                this.router.navigateByUrl('/profil/' + user.token);
            }, error => {
                this.alertService.showAlert(error.errors, 'danger');
            });
        }
    }

}
