<div class="modal-header">
    <h3 class="modal-title text-uppercase">Réactions</h3>
    <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
        <i class="fas fa-times"></i>
    </button>
</div>
<div class="modal-body pt-4">
    <div class="d-flex align-items-center mb-5">
        <ng-template ngFor let-r [ngForOf]="nbReactions | keyvalue: orderOriginal">
            <span class="mr-1" style="font-size: 1.25em;"><i [ngClass]="reactionFilled[r.key]"></i></span>
            <span class="align-middle mr-3">{{ r.value }}</span>
        </ng-template>
    </div>
    
    <ng-container *ngFor="let reaction of reactions">
        <div class="row mt-4">
            <div class="col-10">
                <app-user-avatar [user]="reaction.member.user" [isUnderSurveillance]="reaction.member.isUnderSurveillance" [memberDeleted]="reaction.member.deletedAt"></app-user-avatar>
                <span class="name d-inline-block align-middle ml-2 font-weight-bold">
                    {{ reaction.member.getName() }}
                    <span class="small text-primary d-block font-weight-normal" *ngIf="reaction.member.isUnderSurveillance">Centre du cercle</span>
                </span>
            </div>
            <div class="col-2 d-flex align-items-center">
                <span style="font-size: 1.25em;"><i [ngClass]="reactionFilled[reaction.type]"></i></span>
            </div>
        </div>
    </ng-container>
   
</div>
