import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'shorten'
})
export class ShortenPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    const length: string = args[0] || 15;
    const splittedName = value.split(".");
    const extension = splittedName.length > 1 ? splittedName[splittedName.length - 1] : ' ';
    const name = value.substr(0, value.length - extension.length - 1);

    if (name.length > length) {
        return extension === ' ' ? value.substr(0, length) + '...' : value.substr(0, length) + '...' + extension;
    }

    return value;
  }

}
