import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';

import { finalize } from 'rxjs/operators';
import { combineLatest, Subscription } from 'rxjs';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { DatePipe } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../../core/services/user.service';
import { LoadingService } from '../../core/services/loading.service';
import { MeService } from '../../core/services/me.service';
import { User } from '../../core/models/user.model';
import { ModalConfirmationComponent } from '../../circles/circle/modal-confirmation/modal-confirmation.component';
import { AlertService } from '../../core/services/alert.service';
import { AuthService } from '../../core/services/auth.service';
import { ToastrService } from 'ngx-toastr';
import { SubscriptionModel } from '../../core/models/subscription.model';
import { SubscriptionService } from '../../core/services/subscription.service';

@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

    @Input() public user: User;
    public isMe: boolean = false;
    private subscriptionsModal: Subscription[] = [];
    public modalConfirmation: BsModalRef;
    public modalDeleteAccount: BsModalRef;
    public subscription: SubscriptionModel = null;

    constructor(private meService: MeService,
        private userService: UserService,
        private loadingService: LoadingService,
        private activatedRoute: ActivatedRoute,
        private alertService: AlertService,
        private modalService: BsModalService,
        private datePipe: DatePipe,
        private changeDetection: ChangeDetectorRef,
        private router: Router,
        private authService: AuthService,
        private toastrService: ToastrService,
        private subscriptionService: SubscriptionService) {
        this.loadingService.showLoading();
    }

    ngOnInit() {
        this.getUser();
    }

    getUser() {
        return this.userService.getUser(this.activatedRoute.snapshot.params.token).pipe(
            finalize(() => this.loadingService.dismissLoading())
        ).subscribe(user => {
            this.user = user;
            this.checkMe();
            this.getSubscriptions();
        }, error => {
            console.log(error)
        });
    }

    checkMe() {
        const me = this.meService.getMe();

        if (me.getId() == this.user.getId()) {
            this.isMe = true;
        }
    }

    onDeleteAccount() {
        this.modalConfirmation = this.modalService.show(ModalConfirmationComponent);
        this.modalConfirmation.content.objectToRemove = "supprimer définitivement votre compte";

        const _combine = combineLatest(
            this.modalService.onHide
          ).subscribe(() => this.changeDetection.markForCheck());
      
        this.subscriptionsModal.push(
            this.modalService.onHide.subscribe((reason) => {
                if (this.modalConfirmation.content && this.modalConfirmation.content.confirmation) {
                    this.loadingService.showLoading();
                    this.user.deletedAt = this.datePipe.transform(new Date(), 'yyyy-MM-dd HH:mm:ss');
                    this.user.enabled = false;

                    this.userService.quitApplication(this.user.token, this.user).pipe(
                        finalize(() => this.loadingService.dismissLoading())
                    ).subscribe(user => {
                        if (this.user.getIRI() == user.getIRI()) {
                            this.alertService.closeAlert();
                            this.authService.logout().then(res => {
                                this.router.navigate(['/login']);
                            });
                            this.toastrService.success('Vous êtes bien sorti(e) de l\'application', 'Confirmation');
                        }
                    }, error => {
                        const errorMessage: string[] = error.error.errors ? error.error.errors : ["Impossible de supprimer votre compte pour l'instant, veuillez réessayer ultérieurement, merci"];
                        this.alertService.showAlert(errorMessage, 'danger');
                    });
                    
                }
                this.unsubscribe();
            })
        );
        this.subscriptionsModal.push(_combine);
    }

    private unsubscribe() {
        this.subscriptionsModal.forEach((subscription: Subscription) => {
          subscription.unsubscribe();
        });
        this.subscriptionsModal = [];
    }
    
    getSubscriptions() {
        this.userService.getUserSubscriptions(this.user.token).subscribe(subscriptions => {
            subscriptions.forEach(subscription => {
                if (SubscriptionModel.STATUS_OK.indexOf(subscription.status) > -1) {
                    this.subscription = subscription;
                }
            });
        });
    }

    onStop() {
        this.loadingService.showLoading();
        this.subscriptionService.deleteSubscription(this.subscription.getId()).pipe(
            finalize(() => this.loadingService.dismissLoading())
        ).subscribe(subscription => {
            this.subscription = null;
            this.alertService.closeAlert();
            this.toastrService.success('Votre abonnement a bien été arrêté', 'Confirmation');
        }, error => {
            console.log(error);
            this.alertService.showAlert(error.errors, 'danger');
        });
    }

}
