import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "./me.service";
import * as i2 from "./post.service";
import * as i3 from "./comment.service";
import * as i4 from "./circle.service";
export class NotificationService {
    constructor(meService, postService, commentService, circleService) {
        this.meService = meService;
        this.postService = postService;
        this.commentService = commentService;
        this.circleService = circleService;
        this._createdNotification = new BehaviorSubject(null);
        this._observers = [];
        this.setObservers();
    }
    setObservers() {
        this._observers.push(this.onCreatedPost());
        this._observers.push(this.onCreatedComment());
    }
    onCreatedNotification() {
        return this._createdNotification.asObservable();
    }
    onCreatedPost() {
        return this.postService.onCreatedPost().subscribe(post => {
            const me = this.meService.getMe();
            if (post) {
                this.circleService.getCurrentCircle().subscribe(circle => {
                    if (post.member.user.getId() != me.getId() && (!circle || post.circle.getIRI() != circle.getIRI())) {
                        this._createdNotification.next({
                            title: 'Nouveau post',
                            description: `${post.member.user.firstName} ${post.member.user.lastName} vient de publier un post sur ${post.circle.name}`
                        });
                    }
                });
            }
        });
    }
    onCreatedComment() {
        return this.commentService.onCreatedComment().subscribe(comment => {
            const me = this.meService.getMe();
            if (comment) {
                this.circleService.getCurrentCircle().subscribe(circle => {
                    if (comment.member.user.getId() != me.getId() && (!circle || comment.post.circle.getIRI() != circle.getIRI())) {
                        this._createdNotification.next({
                            title: 'Nouveau commentaire',
                            description: `${comment.member.user.firstName} ${comment.member.user.lastName} vient d'ajouter un commmentaire sur ${comment.post.circle.name}`
                        });
                    }
                });
            }
        });
    }
}
NotificationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function NotificationService_Factory() { return new NotificationService(i0.ɵɵinject(i1.MeService), i0.ɵɵinject(i2.PostService), i0.ɵɵinject(i3.CommentService), i0.ɵɵinject(i4.CircleService)); }, token: NotificationService, providedIn: "root" });
