import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { FileUploadModule } from 'ng2-file-upload';

import { AccountRoutingModule } from './account-routing.module';
import { EditProfileComponent } from './edit-profile/edit-profile.component';
import { ProfileComponent } from './profile/profile.component';
import { LoginDownloadArchiveComponent } from './login-download-archive/login-download-archive.component';
import { SubscriptionComponent } from './subscription/subscription.component';
import { SubscriptionPaymentComponent } from './subscription-payment/subscription-payment.component';
import { SubscriptionResultComponent } from './subscription-result/subscription-result.component';
import { SubscriptionUpdateComponent } from './subscription-update/subscription-update.component';
import { SubscriptionUpdateResultComponent } from './subscription-update-result/subscription-update-result.component';
import { SubscriptionIncreaseStorageComponent } from './subscription-increase-storage/subscription-increase-storage.component';
import { SubscriptionInvoicesComponent } from './subscription-invoices/subscription-invoices.component';

@NgModule({
  declarations: [EditProfileComponent, ProfileComponent, LoginDownloadArchiveComponent, SubscriptionComponent, SubscriptionPaymentComponent, SubscriptionResultComponent, SubscriptionUpdateComponent, SubscriptionUpdateResultComponent, SubscriptionIncreaseStorageComponent, SubscriptionInvoicesComponent],
  imports: [
    CommonModule,
    AccountRoutingModule,
    SharedModule,
    FileUploadModule
  ]
})
export class AccountModule { }
