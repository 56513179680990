import { Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { LegalNoticeComponent } from './legal-notice/legal-notice.component';
import { GeneralConditionsOfUseComponent } from './general-conditions-of-use/general-conditions-of-use.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { AuthGuard } from '../core/guards/auth.guard';
import { AnonymousGuard } from '../core/guards/anonymous.guard';
import { AboutComponent } from './about/about.component';
import { LoginDownloadArchiveComponent } from '../account/login-download-archive/login-download-archive.component';
const ɵ0 = {
    hideHeader: true
}, ɵ1 = {
    hideHeader: true
}, ɵ2 = {
    hideHeader: true
}, ɵ3 = {
    hideHeader: true
};
const routes = [
    {
        path: "",
        redirectTo: 'cercles',
        pathMatch: 'full'
    },
    {
        path: "login",
        component: LoginComponent,
        canActivate: [AnonymousGuard],
        runGuardsAndResolvers: 'always',
    },
    {
        path: "authentification",
        loadChildren: '../authentication/authentication.module#AuthenticationModule'
    },
    {
        path: "cercles",
        loadChildren: '../circles/circles.module#CirclesModule',
        canActivate: [AuthGuard],
        runGuardsAndResolvers: 'always',
    },
    {
        path: "profil",
        loadChildren: '../account/account.module#AccountModule',
        canActivate: [AuthGuard],
        runGuardsAndResolvers: 'always',
    },
    {
        path: "mentions-legales",
        component: LegalNoticeComponent,
        data: ɵ0
    },
    {
        path: "cgu",
        component: GeneralConditionsOfUseComponent,
        data: ɵ1
    },
    {
        path: "politique-de-confidentialite",
        component: PrivacyPolicyComponent,
        data: ɵ2
    },
    {
        path: "a-propos",
        component: AboutComponent,
    },
    {
        path: "telecharger-donnees",
        component: LoginDownloadArchiveComponent
    },
    {
        path: "**",
        component: NotFoundComponent,
        data: ɵ3
    }
];
export class CoreRoutingModule {
}
export { ɵ0, ɵ1, ɵ2, ɵ3 };
