<div class="container py-7">
    <div class="row justify-content-center">
        <div class="col-12 col-sm-8 col-lg-5 text-center">
            <a routerLink="/">
                <img src="../../assets/img/logo.svg" class="logo img-fluid" />
            </a>
            <h1 class="mt-6 mt-lg-8">
                Nouveau mot de passe
            </h1>
            <ng-container *ngIf="passwordRequestExpired && passwordRequestExpired != null">
                <div class="mt-5 mt-lg-7 text-center">
                    <p class="font-weight-bold mb-9">Désolé, la demande a expirée.</p>
                    <a routerLink="/">Retour à la connexion</a>
                </div>
            </ng-container>
            <ng-container *ngIf="!passwordRequestExpired && passwordRequestExpired != null">
                <div class="bg-white border-light-grey border rounded text-left p-5 mt-5 mt-lg-7">
                    <form [formGroup]="resetPasswordForm" (ngSubmit)="onResetPasswordFormSubmit()">
                        <div class="form-group">
                            <label for="password">Mot de passe*</label>
                            <input id="password" type="password" required class="form-control" name="password" formControlName="password" placeholder="********">
                            <ng-container *ngIf="resetPasswordForm.get('password').invalid && (resetPasswordForm.get('password').dirty || resetPasswordForm.get('password').touched || resetPasswordForm['submitted'])">
                                <small class="text-danger" *ngIf="resetPasswordForm.get('password').errors.required">
                                    Le mot de passe est obligatoire
                                </small>
                                <small class="text-danger" *ngIf="resetPasswordForm.get('password').errors.minlength">
                                    Le mot de passe doit contenir au minimum 6 caractères
                                </small>
                            </ng-container>
                        </div>
                        <div class="form-group">
                            <label for="confirm-password">Confirmation de mot de passe*</label>
                            <input id="confirm-password" type="password" class="form-control" name="confirmPassword" formControlName="confirmPassword" placeholder="********">
                            <ng-container *ngIf="resetPasswordForm.get('confirmPassword').invalid && (resetPasswordForm.get('confirmPassword').dirty || resetPasswordForm.get('confirmPassword').touched || resetPasswordForm['submitted'])">
                                <small class="text-danger" *ngIf="resetPasswordForm.get('confirmPassword').errors.required">
                                    Le mot de passe est obligatoire
                                </small>
                                <small class="text-danger" *ngIf="resetPasswordForm.get('confirmPassword').errors.matching">
                                    Les deux mots de passe doivent être identiques
                                </small>
                            </ng-container>
                        </div>
                        <div class="form-group mt-12 mt-lg-14">
                            <button type="submit" class="btn btn-primary w-100">Valider</button>
                        </div>
                    </form>
                    <div class="text-center">
                        <a routerLink="/login">J'ai retrouvé mon mot de passe</a>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>
