import { Component, OnInit, Input } from '@angular/core';

import { Event } from '../../../core/models/event.model';
@Component({
  selector: 'app-event',
  templateUrl: './event.component.html',
  styleUrls: ['./event.component.scss']
})
export class EventComponent implements OnInit {

  @Input() public event: Event = new Event();
  @Input() public showAuthor: boolean = false;

  constructor() { }

  ngOnInit() {
  }

}
