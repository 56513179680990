import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, CanLoad, Route, Router, UrlSegment, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { switchMap, catchError } from 'rxjs/operators';

import { PasswordRequestService } from '../services/password-request.service';

@Injectable({
  providedIn: 'root'
})
export class PasswordRequestGuard implements CanActivate, CanActivateChild, CanLoad {

  constructor(private passwordRequestService: PasswordRequestService, private router: Router) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      if (!next.params.token) {
        return of(false);
      }

      return this.passwordRequestService.getPasswordRequest(next.params.token).pipe(
        switchMap(response => of(true)),
        catchError(error => {
          this.router.navigate(['']);
          return of(false);
        })
      );
  }
  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;
  }
  canLoad(
    route: Route,
    segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    return true;
  }
}
