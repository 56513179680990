/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./subscription-increase-storage.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./subscription-increase-storage.component";
import * as i4 from "../../core/services/me.service";
import * as i5 from "@angular/router";
import * as i6 from "../../core/services/user.service";
import * as i7 from "../../core/services/subscription.service";
import * as i8 from "ngx-toastr";
import * as i9 from "../../core/services/alert.service";
import * as i10 from "../../core/services/loading.service";
var styles_SubscriptionIncreaseStorageComponent = [i0.styles];
var RenderType_SubscriptionIncreaseStorageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SubscriptionIncreaseStorageComponent, data: {} });
export { RenderType_SubscriptionIncreaseStorageComponent as RenderType_SubscriptionIncreaseStorageComponent };
function View_SubscriptionIncreaseStorageComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "mx-lg-10 text-justify"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Votre espace de stockage sera augment\u00E9 de 2 gigaoctets. Votre abonnement sera maintenant au tarif de "])), (_l()(), i1.ɵeld(2, 0, null, null, 1, "b", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", "\u20AC"])), (_l()(), i1.ɵted(4, null, [" par ", ". "])), (_l()(), i1.ɵeld(5, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Etes-vous s\u00FBr(e) de vouloir augmenter votre espace de stockage ? "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.subscription.price + (_co.subscription.price / 2)); _ck(_v, 3, 0, currVal_0); var currVal_1 = ((_co.subscription.frequency == 1) ? "mois" : ((_co.subscription.frequency == 3) ? "trimestre" : "an")); _ck(_v, 4, 0, currVal_1); }); }
export function View_SubscriptionIncreaseStorageComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "container py-8"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h1", [["class", "text-center mb-8"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Abonnement"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SubscriptionIncreaseStorageComponent_1)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 1, "button", [["class", "btn btn-primary ml-lg-10 mt-6"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onUpdateStorage() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["OK"])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "button", [["class", "btn btn-primary mt-6 ml-2"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onCancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Annuler"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.subscription; _ck(_v, 4, 0, currVal_0); }, null); }
export function View_SubscriptionIncreaseStorageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-subscription-increase-storage", [], null, null, null, View_SubscriptionIncreaseStorageComponent_0, RenderType_SubscriptionIncreaseStorageComponent)), i1.ɵdid(1, 114688, null, 0, i3.SubscriptionIncreaseStorageComponent, [i4.MeService, i5.Router, i6.UserService, i7.SubscriptionService, i8.ToastrService, i9.AlertService, i10.LoadingService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SubscriptionIncreaseStorageComponentNgFactory = i1.ɵccf("app-subscription-increase-storage", i3.SubscriptionIncreaseStorageComponent, View_SubscriptionIncreaseStorageComponent_Host_0, {}, {}, []);
export { SubscriptionIncreaseStorageComponentNgFactory as SubscriptionIncreaseStorageComponentNgFactory };
