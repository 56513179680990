import { Component, OnInit, OnDestroy } from '@angular/core';

import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { ScrollEvent } from 'ngx-scroll-event';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

import { CircleService } from '../../core/services/circle.service';
import { LoadingService } from '../../core/services/loading.service';
import { Circle } from '../../core/models/circle.model';
import { SubscriptionModel } from '../../core/models/subscription.model';
import { ModalAddCircleComponent } from '../modal-add-circle/modal-add-circle.component';
import { Member } from '../../core/models/member.model';
import { User } from '../../core/models/user.model';
import { MeService } from '../../core/services/me.service';
import { UserService } from '../../core/services/user.service';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy {

    private _observers: Subscription[] = [];
    private _circlesPage: number = 1;
    public circles: Circle[] = [];
    public modalAddCircle: BsModalRef;
    public loadingCircles: boolean = false;
    public circlesLeft: string[] = [];
    public subscription: SubscriptionModel = null;
    private today: Date = new Date();

    constructor(private circleService: CircleService,
        private loadingService: LoadingService,
        private modalService: BsModalService,
        private meService: MeService,
        private userService: UserService,
        private router: Router,
        private datePipe: DatePipe) {
        this.loadingService.showLoading();
    }

    ngOnInit() {
        this.circleService.updateCurrentCircle(null);

        this.getCircles();
        this.getSubscriptions();
        this.setObservers();
    }

    setObservers() {
        this._observers.push(this.setUpdatedCircleObserver());
    }

    loadCircles(event: ScrollEvent) {
      if (!this.loadingCircles && event.isReachingBottom && this._circlesPage) {
        this.getCircles();
      }
    }

    getCircles() {
        const me: User = this.meService.getMe();
        let dateToDelete = this.datePipe.transform(this.today.setDate(this.today.getDate() - 30), 'yyyy-MM-dd');
        this.loadingCircles = true;

        this.circleService.getCircles({ page: this._circlesPage }).pipe(
            finalize(() => this.loadingService.dismissLoading())
        ).subscribe(circles => {
            (circles.length > 0) ? this._circlesPage++ : this._circlesPage = 0;
            this.loadingCircles = false;

            circles.forEach((circle, index) => {
                if (null == circle.deletedAt || (circle.deletedAt && dateToDelete <= circle.deletedAt)) {
                    circle.members.forEach(member => {
                        if (member.user && member.user.getIRI() == me.getIRI() && (null == member.deletedAt || (member.deletedAt && dateToDelete <= member.deletedAt))) {
                            this.circles.push(circle);
                        }
                    });
                }
		});
            this.getMembersMe();
        });
    }

    getMembersMe() {
        this.circles.forEach((circle, index) => {
            this.circleService.getCircleMembersMe(circle.token).subscribe(member => {
                if (member.deletedAt) {
                    this.circlesLeft.push(circle.token);
                }
            });
        });
    }

    getSubscriptions() {
        const me = this.meService.getMe();
        this.userService.getUserSubscriptions(me.token).subscribe(subscriptions => {
            subscriptions.forEach(subscription => {
                if (SubscriptionModel.STATUS_OK.indexOf(subscription.status) > -1) {
                    this.subscription = subscription;
                }
            });
        });
    }

    setUpdatedCircleObserver() : Subscription {
        return this.circleService.onUpdatedCircle().subscribe(circle => {
            if (circle) {
                this.circles = this.mapCircles(this.circles, circle);
            }
        });
    }

    openAddCircleModal() {
        if (this.subscription) {
            this.modalAddCircle = this.modalService.show(ModalAddCircleComponent);
            this.modalService.onHide.subscribe(() => {
                if (this.modalAddCircle && this.modalAddCircle.content.circle) {
                    this.circles.unshift(this.modalAddCircle.content.circle);
                }
            });
        } else {
            this.router.navigateByUrl('/profil/abonnement');
        }
    }

    private mapCircles(circlesTab: Circle[], currentCircle: Circle) {
        for(var i = 0; i < circlesTab.length; i++) {
            if (circlesTab[i].getIRI() == currentCircle.getIRI()) {
                circlesTab[i] = currentCircle;
            }
        }
        return circlesTab;
    }

    ngOnDestroy() {
        this._observers.forEach(observer => {
            observer.unsubscribe();
        });
    }
}
