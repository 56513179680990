/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./modal-appointments.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "./modal-appointments.component";
import * as i5 from "ngx-bootstrap/modal";
var styles_ModalAppointmentsComponent = [i0.styles];
var RenderType_ModalAppointmentsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ModalAppointmentsComponent, data: {} });
export { RenderType_ModalAppointmentsComponent as RenderType_ModalAppointmentsComponent };
function View_ModalAppointmentsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "a", [["class", "btn-link-primary"]], [[8, "title", 0], [1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.bsModalRef.hide() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(3, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(4, 4), (_l()(), i1.ɵted(5, null, [" ", " "]))], function (_ck, _v) { var currVal_3 = _ck(_v, 4, 0, "/cercles", _v.context.$implicit.circle.token, "rendez-vous", _v.context.$implicit.getId()); _ck(_v, 3, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "", _v.context.$implicit.subject, ""); var currVal_1 = i1.ɵnov(_v, 3).target; var currVal_2 = i1.ɵnov(_v, 3).href; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); var currVal_4 = _v.context.$implicit.subject; _ck(_v, 5, 0, currVal_4); }); }
export function View_ModalAppointmentsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h3", [["class", "modal-title text-uppercase"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Concertations en attente"])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "button", [["aria-label", "Close"], ["class", "close pull-right"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.bsModalRef.hide() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "i", [["class", "fas fa-times"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 3, "div", [["class", "modal-body pt-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 2, "ul", [["class", ""]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModalAppointmentsComponent_1)), i1.ɵdid(8, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.appointments; _ck(_v, 8, 0, currVal_0); }, null); }
export function View_ModalAppointmentsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-modal-appointments", [], null, null, null, View_ModalAppointmentsComponent_0, RenderType_ModalAppointmentsComponent)), i1.ɵdid(1, 114688, null, 0, i4.ModalAppointmentsComponent, [i5.BsModalRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ModalAppointmentsComponentNgFactory = i1.ɵccf("app-modal-appointments", i4.ModalAppointmentsComponent, View_ModalAppointmentsComponent_Host_0, {}, {}, []);
export { ModalAppointmentsComponentNgFactory as ModalAppointmentsComponentNgFactory };
