import { Serializable } from '../interfaces/serializable';

import { Member } from './member.model';

export class Invitation implements Serializable {

  private _id: number = null;
  public token: string = "";
  public expiredAt: Date = null;
  public circleName: string = "";
  public circleToken: string = "";
  public redirectUrl: string = "";
  public member: Member = null;
  public sender: Member = null;

  constructor() {}

  public getIRI() {
    return '/api/invitations/' + this._id;
  }

  public deserialize(input: any): this {
    if (!input) {
      return null;
    }

    Object.getOwnPropertyNames(this).forEach((property) => {
        switch (property) {
          case 'member':
          case 'sender':
            this[property] = new Member().deserialize(input[property]);
            break;
          default:
            if ('_id' == property && input.id) {
              this._id = input.id;
            } else if (property in input) {
              this[property] = input[property];
            }
            break;
        }
    });

    return this;
  }

  public serialize(): any {
    var output = {};

    Object.getOwnPropertyNames(this).forEach((property) => {
        switch (property) {
          case 'member':
          case 'sender':
            output[property] = this[property].getIRI();
            break;
          default:
            if (property != '_id') {
              output[property] = this[property];
            }
            break;
        }
    });

    return output;
  }
}
