import { HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { switchMap, map, finalize } from 'rxjs/operators';
import { Observable, from, BehaviorSubject } from 'rxjs';
import { User } from '../models/user.model';
import { Avatar } from '../models/avatar.model';
import { CookieStorage } from 'cookie-storage';
import { SubscriptionModel } from '../models/subscription.model';
import { Invoice } from '../models/invoice.model';
import * as i0 from "@angular/core";
import * as i1 from "./auth.service";
import * as i2 from "./feathers.service";
import * as i3 from "./api.service";
import * as i4 from "@angular/common/http";
export class UserService {
    constructor(authService, feathersService, apiService, http) {
        this.authService = authService;
        this.feathersService = feathersService;
        this.apiService = apiService;
        this.http = http;
        this._fsServiceName = 'users';
        this._updatedUser = new BehaviorSubject(null);
        this._storageKey = 'at';
        this._cookieStorage = new CookieStorage();
    }
    createUser(data) {
        return from(this.authService.authenticateAnonymous()).pipe(switchMap(response => {
            return this.apiService.post(this._fsServiceName, data);
        }), map(user => new User().deserialize(user)), finalize(() => this.authService.logout()));
    }
    updateUser(token, user) {
        return this.apiService.put(this._fsServiceName, token, user.serialize()).pipe(map(user => {
            return new User().deserialize(user);
        }));
    }
    onUpdatedUser() {
        return this._updatedUser;
    }
    setUpdatedUserEvent() {
        this.feathersService.client.service(this._fsServiceName).on('updated', comment => {
            this._updatedUser.next(new User().deserialize(comment));
            this._updatedUser.next(null);
        });
    }
    getUser(token) {
        return this.apiService.get(this._fsServiceName, token).pipe(map(user => new User().deserialize(user)));
    }
    updateAvatar(token, data) {
        const accessToken = this.feathersService.client.settings.accessToken;
        const httpOptions = {
            headers: new HttpHeaders({
                authorization: accessToken
            }),
            params: new HttpParams().set('operationPath', `/${token}/avatar`)
        };
        return this.http.post(environment.fsAPI.url + '/' + this._fsServiceName, data, httpOptions).pipe(map(avatar => {
            return new Avatar().deserialize(avatar);
        }));
    }
    quitApplication(id, data) {
        const params = {
            query: {
                operationPath: '/quit-app'
            }
        };
        return this.apiService.put(this._fsServiceName, id, data, params).pipe(map(user => new User().deserialize(user)));
    }
    downloadArchive(token, hash, archiveId, expires, memberId, code) {
        this._cookieStorage.removeItem(this._storageKey);
        return from(this.authService.authenticateAnonymous()).pipe(switchMap(response => {
            const defaultParams = {
                query: {
                    operationPath: `/archive`,
                    _hash: hash,
                    code: code,
                    archive: archiveId,
                    expire: expires,
                    membre: memberId
                }
            };
            return this.apiService.get(this._fsServiceName, token, defaultParams);
        }), finalize(() => this.authService.logout()));
    }
    regenerateArchive(id) {
        const params = {
            query: {
                operationPath: '/regenerate-archive'
            }
        };
        return from(this.authService.authenticateAnonymous()).pipe(switchMap(response => {
            return this.apiService.get(this._fsServiceName, id, params);
        }), finalize(() => this.authService.logout()));
    }
    getUserSubscriptions(token, params = {}) {
        const defaultParams = {
            query: {
                operationPath: '/subscriptions'
            }
        };
        Object.assign(defaultParams.query, params);
        return this.apiService.get(this._fsServiceName, token, defaultParams).pipe(map(subscriptions => {
            subscriptions.forEach((value, index) => {
                subscriptions[index] = new SubscriptionModel().deserialize(value);
            });
            return subscriptions;
        }));
    }
    getUserInvoices(token, params = {}) {
        const defaultParams = {
            query: {
                operationPath: '/invoices'
            }
        };
        Object.assign(defaultParams.query, params);
        return this.apiService.get(this._fsServiceName, token, defaultParams).pipe(map(invoices => {
            invoices.forEach((value, index) => {
                invoices[index] = new Invoice().deserialize(value);
            });
            return invoices;
        }));
    }
}
UserService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UserService_Factory() { return new UserService(i0.ɵɵinject(i1.AuthService), i0.ɵɵinject(i2.FeathersService), i0.ɵɵinject(i3.ApiService), i0.ɵɵinject(i4.HttpClient)); }, token: UserService, providedIn: "root" });
