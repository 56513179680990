import { Observable, BehaviorSubject } from 'rxjs';
import { User } from '../models/user.model';
import * as i0 from "@angular/core";
export class MeService {
    constructor() {
        this._storageKey = 'me';
        this._updatedMe = new BehaviorSubject(null);
        this._localStorage = window.localStorage;
    }
    onUpdatedMe() {
        return this._updatedMe.asObservable();
    }
    storeMe(user) {
        this._localStorage.setItem(this._storageKey, JSON.stringify(user));
        this._updatedMe.next(user);
    }
    getMe() {
        if (this._localStorage.getItem(this._storageKey)) {
            return new User().deserialize(JSON.parse(this._localStorage.getItem(this._storageKey)));
        }
        return null;
    }
    deleteMe() {
        this._localStorage.removeItem(this._storageKey);
        this._updatedMe.next(null);
    }
}
MeService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MeService_Factory() { return new MeService(); }, token: MeService, providedIn: "root" });
