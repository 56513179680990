<div class="container py-8">
    <div class="row justify-content-around align-items-center">
        <div class="col-9 col-md-6">
            <h1>Mes cercles</h1>
        </div>
        <div class="col-3 col-md-6 text-right">
            <button type="button" class="btn btn-link-primary d-none d-sm-inline-block" (click)="openAddCircleModal()">
                <span class="d-none d-md-inline-block">Créer un cercle</span>
                <span class="material-icons d-md-none">add_circle_outline</span>
            </button>
        </div>
    </div>
    <div class="row justify-content-center mt-4">
        <div class="col-12 col-md-10 col-lg-8 px-0 px-md-3">
            <ng-template #noCircles>
                <p class="font-weight-bold" *ngIf="!loadingCircles">
                    Vous n'êtes pas encore membre d'un cercle.
                </p>
            </ng-template>
            <ng-template [ngIf]="circles.length" [ngIfElse]="noCircles">
                <div class="circles-container" detect-scroll (onScroll)="loadCircles($event)" [bottomOffset]="100" [topOffset]="100">
                    <ng-container *ngFor="let circle of circles; last as isLast;">
                        <div [ngClass]="{'border-bottom border-light-grey': !isLast}">
                            <app-circle-thumbnail [circle]="circle" [circleLeft]="(circlesLeft.indexOf(circle.token) > -1)"></app-circle-thumbnail>
                        </div>
                    </ng-container>
                </div>
            </ng-template>
        </div>
    </div>
    <div class="row mt-4" *ngIf="loadingCircles">
        <div class="col-12 text-center">
            <div class="spinner-border text-primary" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
    </div>
</div>
<button type="button" class="btn btn-primary d-sm-none btn-highlight" (click)="openAddCircleModal()">
    Créer un cercle
</button>
