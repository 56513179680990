import { Serializable } from '../interfaces/serializable';
import { environment } from '../../../environments/environment';

const enum Images {
  JPG = "image/jpg",
  JPEG = "image/jpeg",
  PNG = "image/png",
  GIF = "image/gif"
}

const enum Videos {
  WEBM = "video/webm",
  OGV = "video/ogg",
  MPEG = "video/mpeg",
  MP4 = "video/mp4",
  MSVIDEO = "video/x-msvideo"
}

const enum Audios {
  WEBM = "audio/webm",
  OGA = "audio/ogg",
  ACC = "audio/aac",
  MP3 = "audio/mpeg"
}

const enum Spreadsheets {
  XLSX = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  XLS = "application/vnd.ms-excel"
}

const enum Presentations {
  PPT = "application/vnd.ms-powerpoint",
  PPTX = "application/vnd.openxmlformats-officedocument.presentationml.presentation"
}

const enum Files {
  PDF = "application/pdf",
  DOC = "application/msword",
  DOCX = "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
}

export class Media implements Serializable {

  static readonly FILTERS_TYPE = [
    {
      name: 'Images',
      value: 'images'
    },
    {
      name: 'Vidéos',
      value: 'videos'
    },
    {
      name: 'Audios',
      value: 'audios'
    },
    {
      name: 'Tableurs',
      value: 'spreadsheets'
    },
    {
      name: 'Documents',
      value: 'files'
    }
  ];

  private _id: number = null;
  public filename: string = "";
  public mimeType: string = "";
  public pathName: string = "";
  public originalName: string = "";
  public data: string = "";
  public albumId: number;

  constructor() {}

  public getId() {
    return this._id;
  }

  public getIRI() {
    return '/api/medias/' + this._id;
  }

  public getAbsolutePath() {
    return environment.publicServer.url + environment.publicServer.directories.circles + '/' + this.pathName;
  }

  public isImage(): boolean {
    return (this.mimeType == Images.JPEG || this.mimeType == Images.JPG
      || this.mimeType == Images.PNG || this.mimeType == Images.GIF);
  }

  public isVideo(): boolean {
    return (this.mimeType == Videos.WEBM || this.mimeType == Videos.OGV
      || this.mimeType == Videos.MPEG || this.mimeType == Videos.MSVIDEO
      || this.mimeType == Videos.MP4);
  }

  public isAudio(): boolean {
    return (this.mimeType == Audios.WEBM || this.mimeType == Audios.OGA
      || this.mimeType == Audios.ACC || this.mimeType == Audios.MP3);
  }

  public isSpreadsheet(): boolean {
    return (this.mimeType == Spreadsheets.XLS || this.mimeType == Spreadsheets.XLSX);
  }

  public isPresentation(): boolean {
    return (this.mimeType == Presentations.PPT || this.mimeType == Presentations.PPTX);
  }

  public isFile(): boolean {
    return !this.isImage() && !this.isVideo() && !this.isAudio() && !this.isSpreadsheet();
  }

  public isPdf(): boolean {
    return this.mimeType == Files.PDF;
  }

  public isWord(): boolean {
    return (this.mimeType == Files.DOC || this.mimeType == Files.DOCX);
  }

  public deserialize(input: any): this {
    if (!input) {
      return null;
    }

    Object.getOwnPropertyNames(this).forEach((property) => {
      if ('_id' == property && input.id) {
        this._id = input.id;
      } else if (property in input) {
        this[property] = input[property];
      }
    });

    return this;
  }

  public serialize(): any {
    var output = {};

    Object.getOwnPropertyNames(this).forEach((property) => {
      if (property != '_id') {
        output[property] = this[property];
      }
    });

    return output;
  }

}
