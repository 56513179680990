import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';

import { finalize } from 'rxjs/operators';
import { combineLatest, Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Member } from '../../../core/models/member.model';
import { Circle } from '../../../core/models/circle.model';
import { LoadingService } from '../../../core/services/loading.service';
import { AlertService } from '../../../core/services/alert.service';
import { InvitationService } from '../../../core/services/invitation.service';
import { ModalAddMemberComponent } from '../modal-add-member/modal-add-member.component';
import { MemberService } from '../../../core/services/member.service';
import { InvitationVisioService } from '../../../core/services/invitation-visio.service';
import { DatePipe } from '@angular/common';
import { ModalConfirmationComponent } from '../modal-confirmation/modal-confirmation.component';
import { FeathersService } from '../../../core/services/feathers.service';
import { MeService } from '../../../core/services/me.service';

@Component({
    selector: 'app-members',
    templateUrl: './members.component.html',
    styleUrls: ['./members.component.scss']
})
export class MembersComponent implements OnInit, OnDestroy {

    @Input() public circle: Circle;
    @Input() public members: Member[] = [];
    @Input() public memberMe: Member = new Member();
    @Output() public onNavigationStart: EventEmitter<any> = new EventEmitter();
    private _observers: Subscription[] = [];
    private subscriptionsModal: Subscription[] = [];
    public modalAddMember: BsModalRef;
    public isVisio: boolean = false;
    public isAllInvited: boolean = false;
    public hover = {};
    public modalConfirmation: BsModalRef;
    public onlineUsers: string[] = [];

    constructor(private modalService: BsModalService,
        private router: Router,
        private loadingService: LoadingService,
        private alertService: AlertService,
        private toastrService: ToastrService,
        private invitationService: InvitationService,
        private memberService: MemberService,
        private invitationVisioService: InvitationVisioService,
        private datePipe: DatePipe,
        private changeDetection: ChangeDetectorRef,
        private feathersService: FeathersService,
        private meService: MeService) {
         }

    ngOnInit() {
        this.setObservers();
        this.getVisioInvitation();
    }

    setObservers() {
        this._observers.push(this.onlineUsersObserver());
        this._observers.push(this.offlineUsersObserver());
        this._observers.push(this.setRouterObserver());
        this._observers.push(this.setOnVisioObserver());
        this._observers.push(this.setOnInvitationObserver());
        this._observers.push(this.setUpdatedMemberObserver());
    }

    setRouterObserver(): Subscription {
        return this.router.events.subscribe(event => {
            if (event instanceof NavigationStart) {
                this.onNavigationStart.emit(event);
            }
        });
    }

    setOnVisioObserver(): Subscription {
        return this.memberService.onPatchedMember().subscribe(member => {
            if(!member) {
                return;
            }

            if (member.getId() == this.memberMe.getId()) {
                this.memberMe.isOnVisio = member.isOnVisio;
            }
            this.members = this.members.map((m) => m.getId() == member.getId() ? member : m);
            this.getVisioInvitation();
        });
    }

    setOnInvitationObserver(): Subscription {
        return this.invitationVisioService.onCreatedInvitation().subscribe(invitation => {

            if(!invitation) {
                return;
            }

            for (var i = 0; i < invitation.members.length; i++) {
                for (var j = 0; j < this.members.length; j++) {
                    if (invitation.members[i]._id == this.members[j].getId()) {
                        this.members[j].isInvited = true;
                        if (this.memberMe.getId() == this.members[j].getId()) {
                            this.toastrService.success(`${invitation.sender.user.firstName} ${invitation.sender.user.lastName} vous invite à rejoindre la visio sur le cercle ${invitation.sender.circle.name}`, 'Notification');
                        }
                    }
                }
            }

        });
    }

    onlineUsersObserver(): Subscription {
        return this.feathersService.onUpdatedAuthenticated().subscribe(usersToken => {
            if(!usersToken) {
                return;
            }

            for (const token in usersToken) {
                for (var i = 0; i < this.circle.members.length; i++) {
                    if (this.circle.members[i].user && this.circle.members[i].user.token == token && !this.onlineUsers.includes(token)) {
                        this.onlineUsers.push(token);
                        break;
                    }
                }
            }
        });
    }
    
    offlineUsersObserver(): Subscription {
        return this.feathersService.onUpdatedDisconnected().subscribe(usersToken => {
            if(JSON.stringify(usersToken) === 'null') {
                return;
            }

            for (var i = 0; i < this.onlineUsers.length; i++) {
                if (this.onlineUsers[i] != this.meService.getMe().token && !(this.onlineUsers[i] in usersToken)) {
                    this.onlineUsers.splice(i, 1);
                    i--;
                }
            }
        });
    }

    setUpdatedMemberObserver(): Subscription {
        return this.memberService.onUpdatedMember().subscribe(member => {

            if(!member) {
                return;
            }

            this.members = this.mapMembers(this.members, member);
        });
    }

    openAddMemberModal() {
        this.modalAddMember = this.modalService.show(ModalAddMemberComponent);
        this.modalAddMember.content.circle = this.circle;
    }

    reInvite(member: Member) {
        const newInvitation = {
            member: member.getIRI(),
            redirectUrl: this.invitationService.getValidationPath()
        }

        this.loadingService.showLoading();

        this.invitationService.createInvitation(newInvitation).pipe(
            finalize(() => this.loadingService.dismissLoading())
        ).subscribe(invitation => {
            this.alertService.closeAlert();
            this.toastrService.success('Votre relance a bien été envoyée', 'Félicitations !');
        }, error => {
            console.log(error)
            this.alertService.showAlert(error.errors, 'danger');
        });
    }

    getVisioInvitation() {
        let memberOnVisio: Member[] = [];
        for (var i = 0; i < this.members.length; i++) {
            if (this.members[i].isOnVisio) {
                memberOnVisio.push(this.members[i]);
            }
        }
        if (memberOnVisio.length == 0) {
            this.isVisio = false;
            this.isAllInvited = false;
            this.members.forEach(member => {
                member.isInvited = false;
            });
        } else {
            this.isVisio = true;
            if (this.members.length == memberOnVisio.length) {
                this.isAllInvited = true;
            }
        }
    }

    inviteOnVisio(members: Member[]) {
        const invitationVisio = {
            members: members,
            sender: this.memberMe
        };

        this.invitationVisioService.createInvitation(invitationVisio).subscribe(
            invitation => {},
            error => {
                const errorMessage: string[] = error.errors ? error.errors : ["Impossible d'inviter un membre sur la visio pour l'instant, veuillez réessayer ultérieurement, merci"];
                this.alertService.showAlert(errorMessage, 'danger');
                console.log(error);
            }
        );
    }

    inviteAllOnVisio() {
        let membersToInvite: Member[] = [];
        this.members.forEach(member => {
            if (!member.isOnVisio && !member.isInvited) {
                membersToInvite.push(member);
            }
        });
        this.inviteOnVisio(membersToInvite);
        this.isAllInvited = true;
    }

    setUpdatedMember(index: number) {
        let member: any = {};
        member.circle = this.members[index].circle;
        member.deletedAt = this.datePipe.transform(new Date(), 'yyyy-MM-dd HH:mm:ss');
        member.selfLeft = this.memberMe.getId() == this.members[index].getId();
        member.status = member.STATUS_DELETED;
        return member;
    }

    onLeaveCircle(index: number) {
        this.modalConfirmation = this.modalService.show(ModalConfirmationComponent);
        this.modalConfirmation.content.objectToRemove = this.memberMe.getId() == this.members[index].getId() ? "sortir du cercle" : "sortir ce membre du cercle";

        const _combine = combineLatest(
            this.modalService.onHide
          ).subscribe(() => this.changeDetection.markForCheck());
      
        this.subscriptionsModal.push(
            this.modalService.onHide.subscribe((reason) => {
      
                if (this.modalConfirmation.content && this.modalConfirmation.content.confirmation) {
                    this.loadingService.showLoading();
                    
                    const memberToUpdate = this.setUpdatedMember(index);
                    this.memberService.leaveCircleMember(this.members[index].getId(), memberToUpdate).pipe(
                        finalize(() => this.loadingService.dismissLoading())
                    ).subscribe(member => {
            
                        if (this.members[index].getId() == member.getId()) {
                            this.alertService.closeAlert();
                            if (this.memberMe.getId() == this.members[index].getId()) {
                                this.router.navigate(['/cercles']);
                                this.toastrService.success('Vous êtes sorti(e) du cercle', 'Confirmation');
                            } else {
                                this.toastrService.success('Le membre a bien été sorti du cercle', 'Confirmation');
                            }
                            this.modalConfirmation = null;
                        }
                    }, error => {
                        const errorMessage: string[] = error.error.errors ? error.error.errors : ["Impossible de supprimer ce membre pour l'instant, veuillez réessayer ultérieurement, merci"];

                        this.alertService.showAlert(errorMessage, 'danger');
                    });
                }
                this.unsubscribe();
            })
        );
        this.subscriptionsModal.push(_combine);
    }
    
    private unsubscribe() {
        this.subscriptionsModal.forEach((subscription: Subscription) => {
          subscription.unsubscribe();
        });
        this.subscriptionsModal = [];
    }

    private mapMembers(membersTab: Member[], currentMember: Member) {
        for(var i = 0; i < membersTab.length; i++) {
            if (membersTab[i].getIRI() == currentMember.getIRI()) {
                membersTab[i] = currentMember;
            }
        }
        return membersTab;
    }

    ngOnDestroy() {
        this._observers.forEach((observer, index) => {
            observer.unsubscribe();
        });
    }

}
