import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Appointment } from '../models/appointment.model';
import { ApiService } from './api.service';
import { FeathersService } from './feathers.service';

@Injectable({
  providedIn: 'root'
})
export class AppointmentService {

  private _fsServiceName: string = "appointments";
  private _createdAppointment: BehaviorSubject<Appointment> = new BehaviorSubject<Appointment>(null);
  private _updatedAppointment: BehaviorSubject<Appointment> = new BehaviorSubject<Appointment>(null);
  private _deletedAppointment: BehaviorSubject<Appointment> = new BehaviorSubject<Appointment>(null);

  constructor(private feathers: FeathersService,
    private apiService: ApiService) {
    this.setCreatedAppointmentEvent();
    this.setUpdatedAppointmentEvent();
    this.setDeletedAppointmentEvent();
  }

  getAppointment(id: number): Observable<any> {
    return this.apiService.get(this._fsServiceName, id).pipe(
      map(circle => new Appointment().deserialize(circle))
    );
  }

  onCreatedAppointment(): Observable<Appointment> {
    return this._createdAppointment;
  }

  createAppointment(data: any): Observable<any> {
    return this.apiService.post(this._fsServiceName, data).pipe(
      map(event => new Appointment().deserialize(event))
    );
  }

  setCreatedAppointmentEvent() {
    this.feathers.client.service(this._fsServiceName).on('created', event => {
      this._createdAppointment.next(new Appointment().deserialize(event));
    });
  }

  onUpdatedAppointment(): Observable<Appointment> {
    return this._updatedAppointment;
  }

  updateAppointment(id: number, data: any): Observable<any> {
    return this.apiService.put(this._fsServiceName, id, data).pipe(
      map(event => new Appointment().deserialize(event))
    );
  }

  setUpdatedAppointmentEvent() {
    this.feathers.client.service(this._fsServiceName).on('updated', appointment => {
      this._updatedAppointment.next(new Appointment().deserialize(appointment));
      this._updatedAppointment.next(null);
    });
  }

  onDeletedAppointment(): Observable<Appointment> {
    return this._deletedAppointment;
  }

  deleteAppointment(id: number): Observable<any> {
    return this.apiService.delete(this._fsServiceName, id);
  }

  patchAppointment(data: any): Observable<any> {
    return this.apiService.patch(this._fsServiceName, data);
  }

  setDeletedAppointmentEvent() {
    this.feathers.client.service(this._fsServiceName).on('patched', event => {
      this._deletedAppointment.next(new Appointment().deserialize(event));
      this._deletedAppointment.next(null);
    });
  }
}
