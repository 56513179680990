import { Component, OnInit } from '@angular/core';

import { BsModalRef } from 'ngx-bootstrap/modal';
import { Circle } from '../../../core/models/circle.model';
import { Member } from '../../../core/models/member.model';

@Component({
    selector: 'app-modal-members',
    templateUrl: './modal-members.component.html',
    styleUrls: ['./modal-members.component.scss']
})
export class ModalMembersComponent implements OnInit {

    public circle: Circle = null;
    public memberMe: Member = new Member();

    constructor(public bsModalRef: BsModalRef) { }

    ngOnInit() {
    }

}
