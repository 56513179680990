import { Serializable } from '../interfaces/serializable';

import { Media } from './media.model';
import { Event } from './event.model';
import { Member } from './member.model';
import { Circle } from './circle.model';
import { Reaction } from './reaction.model';

export class Post implements Serializable {

  private _id: number = null;
  public message: string = "";
  public member: Member = null;
  public circle: Circle = null;
  public comments: string[] = [];
  public medias: Media[] = [];
  public event: Event = null;
  public createdAt: Date = null;
  public updatedAt: Date = null;
  public deletedAt: Date = null;
  public reactions: string[] = [];

  constructor() {}

  public getIRI() {
    return '/api/posts/' + this._id;
  }

  public getId() {
    return this._id;
  }

  public deserialize(input: any) {
    if (!input) {
      return null;
    }

    Object.getOwnPropertyNames(this).forEach((property) => {
      switch (property) {
        case 'medias':
            if (Array.isArray(input[property])) {
                input[property].forEach(media => {
                    this[property].push(new Media().deserialize(media));
                });
            } else {
                this[property] = input[property];
            }
          break;
        case 'member':
          this[property] = new Member().deserialize(input[property]);
          break;
        case 'circle':
            this[property] = new Circle().deserialize(input[property]);
            break;
        case 'event':
          this[property] = new Event().deserialize(input[property]);
          break;
        default:
          if ('_id' == property && input.id) {
            this._id = input.id;
          } else if (property in input) {
            this[property] = input[property];
          }
          break;
      }
    });

    return this;
  }

  public serialize(): any {
    var output = {};

    Object.getOwnPropertyNames(this).forEach(property => {
      switch (property) {
        case 'medias':
          output[property] = [];
          this[property].forEach(item => {
            output[property].push(
              item.getIRI()
            );
          });
          break;
        case 'event':
        case 'member':
        case 'circle':
          output[property] = this[property] != null ? this[property].getIRI() : null;
          break;
        default:
          if (property != '_id') {
            output[property] = this[property];
          }
          break;
      }
    });

    return output;
  }
}
