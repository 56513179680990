import { Injectable } from '@angular/core';

import { Observable, BehaviorSubject } from 'rxjs';
import { Alert } from '../interfaces/alert';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  private _alert: BehaviorSubject<Alert> = new BehaviorSubject<Alert>(null);

  constructor() { }

  alert(): Observable<Alert> {
    return this._alert.asObservable();
  }

  showAlert(messages: string[], type: string = "success") {
    this._alert.next({
      messages: messages,
      type: type
    });
  }

  closeAlert() {
    this._alert.next(null);
  }
}
