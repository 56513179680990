import { Component, Input, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-modal-confirmation',
  templateUrl: './modal-confirmation.component.html',
  styleUrls: ['./modal-confirmation.component.scss']
})
export class ModalConfirmationComponent implements OnInit {

  @Input() public objectToRemove: string;
  public confirmation: boolean = false;
  
  constructor(public bsModalRef: BsModalRef) { }

  ngOnInit() {
  }

  onConfirmation(confirmation: boolean) {
    this.confirmation = confirmation;
    this.bsModalRef.hide();
  }

}
