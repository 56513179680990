import { Component, Input, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-modal-information',
  templateUrl: './modal-information.component.html',
  styleUrls: ['./modal-information.component.scss']
})
export class ModalInformationComponent implements OnInit {
  
  public deletedAt: string;
  public nbDays: number = 0;
  
  constructor(public bsModalRef: BsModalRef) { }

  ngOnInit() {
    this.countNumberDays();
  }

  countNumberDays() {
    const today = new Date();
    const deletedDate = new Date(this.deletedAt);
    let difference = today.getTime() - deletedDate.getTime();
    this.nbDays = Math.round(30 - (difference / (1000 * 3600 * 24)));
  }

}
