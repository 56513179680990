<div class="album" [ngClass]="'album--' + container">
    <div class="album__medias">
        <div class="row align-items-stretch mx-0 mx-sm-n1">
            <div class="col-6 col-sm-4 px-0 px-sm-1 align-middle" *ngFor="let media of post.medias; index as i;">
                    <div class="media" *ngIf="media.isFile() || media.isSpreadsheet() || media.isVideo()">
                        <img src="../assets/img/placeholders/placeholder-media.png" class="img-fluid">
                        <button type="button" class="close" (click)="removeMedia(i)" title="Supprimer le média" *ngIf="showAuthor && (post.member.getIRI() == memberMe.getIRI() || memberMe.isAdmin)">
                            <span class="material-icons">close</span>
                        </button>
                        <button type="button" (click)="downloadImage(media.getId(), media.originalName, media.mimeType)" title="{{ media.originalName }}">
                            <i [ngClass]="{'media--file': media.isFile(), 'media--spreadsheet': media.isSpreadsheet(), 'media--video': media.isVideo(), 'media-pdf': media.isPdf(), 'media-word': media.isWord(), 'media-powerpoint': media.isPresentation()}"></i>
                            <span class="small text-dark-grey d-lg-none">{{ media.originalName|shorten:14 }}</span>
                            <span class="small text-dark-grey d-none d-lg-block">{{ media.originalName|shorten:8 }}</span>
                        </button>
                    </div>
                <div class="d-flex align-items-center mt-1" *ngIf="media.isAudio()">
                    <audio controls [src]="media.getAbsolutePath()">
                        Votre navigateur ne prend pas en charge l'audio HTML.
                    </audio>
                    <div class="d-flex flex-column pt-1">
                    <button class="pl-0 text-black-50" type="button" (click)="downloadImage(media.getId(), media.originalName, media.mimeType)" title="Télécharger l'audio">
                        <i class="fas fa-arrow-circle-down ml-1"></i>
                    </button>
                    <button class="pl-0 text-black-50" type="button" (click)="removeMedia(i)" title="Supprimer le média" *ngIf="showAuthor && (post.member.getIRI() == memberMe.getIRI() || memberMe.isAdmin)">
                        <i class="fas fa-trash-alt" style="padding-left: 3px;"></i>
                    </button>
                    </div>
                </div>
                <a [href]="media.getAbsolutePath()" class="media media--image" [ngClass]="'gallery-' + post.getId()" [attr.data-caption]="media.originalName" [attr.data-mime-type]="media.mimeType" [attr.data-media-id]="media.getId()" [ngStyle]="{'background-image': 'url(' + media.getAbsolutePath() + ')'}" *ngIf="media.isImage()" title="{{ media.originalName }}">
                    <img src="../assets/img/placeholders/placeholder-media.png" class="img-fluid w-100">
                </a>
                <button type="button" class="delete text-black-50" (click)="removeMedia(i)" title="Supprimer le média" *ngIf="media.isImage() && showAuthor && (post.member.getIRI() == memberMe.getIRI() || memberMe.isAdmin)">
                    <span class="material-icons">close</span>
                </button>
            </div>
        </div>
    </div>
    <div class="row mt-1" *ngIf="showAuthor && post.medias.length">
        <div class="col-9 pr-0">
            <span class="small">{{ post.createdAt | dateFrom }}</span>
        </div>
        <div class="col-3 text-right pl-0">
            <ng-template [ngIf]="!post.member.deletedAt" [ngIfElse]="memberDeleted">
                <a [routerLink]="['/profil', post.member.user.token]" title="{{ post.member.getName() }}">
                    <app-user-avatar [user]="post.member.user" [isUnderSurveillance]="post.member.isUnderSurveillance" [size]="'tiny'"></app-user-avatar>
                </a>
            </ng-template>
            <ng-template #memberDeleted>
                <app-user-avatar [user]="post.member.user" [isUnderSurveillance]="post.member.isUnderSurveillance" [memberDeleted]="post.member.deletedAt" [size]="'tiny'"></app-user-avatar>
            </ng-template>
        </div>
    </div>
</div>
