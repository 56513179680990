import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { Appointment } from '../models/appointment.model';
import * as i0 from "@angular/core";
import * as i1 from "./feathers.service";
import * as i2 from "./api.service";
export class AppointmentService {
    constructor(feathers, apiService) {
        this.feathers = feathers;
        this.apiService = apiService;
        this._fsServiceName = "appointments";
        this._createdAppointment = new BehaviorSubject(null);
        this._updatedAppointment = new BehaviorSubject(null);
        this._deletedAppointment = new BehaviorSubject(null);
        this.setCreatedAppointmentEvent();
        this.setUpdatedAppointmentEvent();
        this.setDeletedAppointmentEvent();
    }
    getAppointment(id) {
        return this.apiService.get(this._fsServiceName, id).pipe(map(circle => new Appointment().deserialize(circle)));
    }
    onCreatedAppointment() {
        return this._createdAppointment;
    }
    createAppointment(data) {
        return this.apiService.post(this._fsServiceName, data).pipe(map(event => new Appointment().deserialize(event)));
    }
    setCreatedAppointmentEvent() {
        this.feathers.client.service(this._fsServiceName).on('created', event => {
            this._createdAppointment.next(new Appointment().deserialize(event));
        });
    }
    onUpdatedAppointment() {
        return this._updatedAppointment;
    }
    updateAppointment(id, data) {
        return this.apiService.put(this._fsServiceName, id, data).pipe(map(event => new Appointment().deserialize(event)));
    }
    setUpdatedAppointmentEvent() {
        this.feathers.client.service(this._fsServiceName).on('updated', appointment => {
            this._updatedAppointment.next(new Appointment().deserialize(appointment));
            this._updatedAppointment.next(null);
        });
    }
    onDeletedAppointment() {
        return this._deletedAppointment;
    }
    deleteAppointment(id) {
        return this.apiService.delete(this._fsServiceName, id);
    }
    patchAppointment(data) {
        return this.apiService.patch(this._fsServiceName, data);
    }
    setDeletedAppointmentEvent() {
        this.feathers.client.service(this._fsServiceName).on('patched', event => {
            this._deletedAppointment.next(new Appointment().deserialize(event));
            this._deletedAppointment.next(null);
        });
    }
}
AppointmentService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AppointmentService_Factory() { return new AppointmentService(i0.ɵɵinject(i1.FeathersService), i0.ɵɵinject(i2.ApiService)); }, token: AppointmentService, providedIn: "root" });
