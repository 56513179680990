<div class="modal-header">
    <h3 class="modal-title text-uppercase">Ajouter un cercle</h3>
    <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
        <i class="fas fa-times"></i>
    </button>
</div>
<div class="modal-body">
    <form [formGroup]="circleForm" (ngSubmit)="onCircleFormSubmit()">
        <div class="form-group">
            <label for="name">Nom*</label>
            <input id="name" type="text" required name="name" formControlName="name" class="form-control" placeholder="Mon nouveau cercle" />
            <ng-container *ngIf="circleForm.get('name').invalid && (circleForm.get('name').dirty || circleForm.get('name').touched || circleForm['submitted'])">
                <small class="text-danger" *ngIf="circleForm.get('name').errors.required">
                    Le nom est obligatoire
                </small>
            </ng-container>
        </div>
        <div class="form-group">
            <label for="description">Description</label>
            <textarea id="description" name="description" rows="3" name="description" formControlName="description" class="form-control" placeholder="Mon cercle familial..."></textarea>
        </div>
        <h4>Qui sera la personne au centre du cercle ?</h4>
        <div class="form-check form-check-radio form-check-inline">
            <input class="form-check-input" type="radio" formControlName="isUnderSurveillance" name="isUnderSurveillance" id="under-surveillance-me" value="me" checked>
            <label class="form-check-label" for="under-surveillance-me">
                <span>Moi-même</span>
            </label>
        </div>
        <div class="form-check form-check-radio form-check-inline">
            <input class="form-check-input" type="radio" formControlName="isUnderSurveillance" name="isUnderSurveillance" id="under-surveillance-other" value="other">
            <label class="form-check-label" for="under-surveillance-other">
                <span>Un invité</span>
            </label>
        </div>
        <ng-container *ngIf="circleForm.get('isUnderSurveillance').value == 'other'">
            <div class="form-group mt-4">
                <label for="invitation-email">Adresse mail de l'invité*</label>
                <input id="invitation-email" type="email" required name="invitationEmail" formControlName="invitationEmail" class="form-control" placeholder="uneadresse@mail.com" />
                <ng-container *ngIf="circleForm.get('invitationEmail').invalid && (circleForm.get('invitationEmail').dirty || circleForm.get('invitationEmail').touched || circleForm['submitted'])">
                    <small class="text-danger" *ngIf="circleForm.get('invitationEmail').errors.required">
                        L'email est obligatoire
                    </small>
                    <small class="text-danger" *ngIf="circleForm.get('invitationEmail').errors.pattern">
                        L'email n'est pas correct
                    </small>
                </ng-container>
            </div>
        </ng-container>
        <div class="form-group mt-14">
            <button type="submit" class="btn btn-primary w-100">Valider</button>
        </div>
    </form>
    <p class="text-justify">
        Attention, la création du cercle n’est effective que lorsque la personne au centre du cercle a donné son accord,
        un email lui sera envoyé. Si c’est vous-même, la création du cercle est immédiate.
        Si c’est une autre personne son accord est nécessaire pour que les invitations puissent partir.
    </p>

</div>
