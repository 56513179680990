import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
export class LoadingService {
    constructor() {
        this._loading = new BehaviorSubject(false);
    }
    isLoading() {
        return this._loading.asObservable();
    }
    showLoading() {
        this._loading.next(true);
    }
    dismissLoading() {
        this._loading.next(false);
    }
}
LoadingService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LoadingService_Factory() { return new LoadingService(); }, token: LoadingService, providedIn: "root" });
