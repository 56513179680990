import { Component, OnInit, OnDestroy } from '@angular/core';

import { Subscription } from 'rxjs';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import { MeService } from '../services/me.service';
import { HeaderService } from '../services/header.service';
import { User } from '../models/user.model';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {

    private _observers: Subscription[] = [];
    public connectedUser: User;
    public sidebarIsActive: boolean = false;
    public swipedHeader: boolean = false;
    public today = new Date();
    public circlesPage: boolean = false;

    constructor(private authService: AuthService,
        private meService: MeService,
        private headerService: HeaderService,
        private router: Router,
        private activatedRoute: ActivatedRoute) { }

    ngOnInit() {
        this.setObservers();
    }

    setObservers() {
        this._observers.push(this.authObserver());
        this._observers.push(this.getMenuClosure());
        this._observers.push(this.getHeaderSwipe());
        this._observers.push(this.getPageWithAbout());
    }

    authObserver() {
        return this.authService.isAuthenticated().pipe(
            switchMap(authenticated => {
                return this.meService.onUpdatedMe();
            })
        ).subscribe(user => {
            this.connectedUser = this.meService.getMe();
        });
    }

    getMenuClosure(): Subscription {
        return this.headerService.closedMenu().subscribe(response => {
            this.sidebarIsActive = false;
        });
    }

    getHeaderSwipe(): Subscription {
        return this.headerService.swipedHeader().subscribe(response => {
            this.swipedHeader = response;
        });
    }

    getPageWithAbout(): Subscription {
        return this.router.events.subscribe(e => {
            if (e instanceof NavigationEnd) {
                const url = this.activatedRoute.snapshot['_routerState'].url;
                this.circlesPage = url == '/cercles' || url == '/a-propos';
            }
        });
    }

    logout() {
        this.authService.logout().then(res => {
            this.router.navigate(['/']);
        });
    }

    ngOnDestroy() {
        this._observers.forEach((observer, index) => {
            observer.unsubscribe();
        });
    }
}
