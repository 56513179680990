<div class="modal-header">
    <h3 class="modal-title text-uppercase">Créer un rendez-vous</h3>
    <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
        <i class="fas fa-times"></i>
    </button>
</div>
<div class="modal-body">
    <form [formGroup]="appointmentForm" (ngSubmit)="onAppointmentFormSubmit()">
        <div class="form-group">
            <label for="subject">Sujet*</label>
            <input type="text" class="form-control" name="subject" id="subject" aria-describedby="subject" formControlName="subject" placeholder="Sujet du rendez-vous" required>
            <ng-container *ngIf="appointmentForm.get('subject').invalid && (appointmentForm.get('subject').dirty || appointmentForm.get('subject').touched || appointmentForm['submitted'])">
                <small class="text-danger" *ngIf="appointmentForm.get('subject').errors.required">
                    Le sujet est obligatoire
                </small>
            </ng-container>
        </div>
        <div class="form-check form-check-radio form-check-inline">
            <input type="radio" class="form-check-input" name="place" id="visio" value="visio" formControlName="place" required checked>
            <label class="form-check-label" for="visio">Visio</label>
        </div>
        <div class="form-check form-check-radio form-check-inline">
            <input type="radio" class="form-check-input" name="place" id="faceToFace" value="faceToFace" formControlName="place" required>
            <label class="form-check-label" for="faceToFace">Présentiel</label>
        </div>
        <ng-container *ngIf="appointmentForm.get('place').invalid && (appointmentForm.get('place').dirty || appointmentForm.get('place').touched || appointmentForm['submitted'])">
            <small class="text-danger" *ngIf="appointmentForm.get('place').errors.required">
                Le lieu est obligatoire
            </small>
        </ng-container>
        <div class="row mt-4">
            <div class="col-12 col-lg-6">
                <div class="form-group">
                    <label for="proposalOne">Proposition 1*</label>
                    <input type="text"
                        id="proposalOne"
                        placeholder="Choisissez une date"
                        class="form-control"
                        formControlName="proposalOne"
                        name="proposalOne"
                        [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY' }"
                        required
                        bsDatepicker
                        [minDate]="today"
                        autocomplete="off">
                    <ng-container *ngIf="appointmentForm.get('proposalOne').invalid && (appointmentForm.get('proposalOne').dirty || appointmentForm.get('proposalOne').touched || appointmentForm['submitted'])">
                        <small class="text-danger" *ngIf="appointmentForm.get('proposalOne').errors.required">
                            Au moins une proposition est requise
                        </small>
                    </ng-container>
                </div>
            </div>
            <div class="col-12 col-lg-6">
                <div class="form-group">
                    <table>
                        <tbody>
                        <tr class="text-center">
                          <td>
                            <a class="btn btn-link" (click)="incrementTime($event, 'hourOne')"><span class="bs-chevron bs-chevron-up"></span></a>
                          </td>
                          <td>&nbsp;&nbsp;&nbsp;</td>
                          <td>
                            <a class="btn btn-link" (click)="incrementTime($event, 'timeOne')"><span class="bs-chevron bs-chevron-up"></span></a>
                          </td>
                        </tr>
                        <tr>
                          <td class="form-group mb-3">
                            <input type="number" formControlName="hourOne" class="form-control text-center bs-timepicker-field" id="hourOne" name="hourOne" min="00" max="23" placeholder="HH">
                          </td>
                          <td>&nbsp;:&nbsp;</td>
                          <td class="form-group mb-3">
                            <input type="number" formControlName="timeOne" class="form-control text-center bs-timepicker-field" id="timeOne" name="timeOne" min="00" max="59" step="5" placeholder="MM">
                          </td>
                        </tr>
                        
                        <tr class="text-center">
                          <td>
                            <a class="btn btn-link" (click)="decrementTime($event, 'hourOne')"><span class="bs-chevron bs-chevron-down"></span></a>
                          </td>
                          <td>&nbsp;&nbsp;&nbsp;</td>
                          <td>
                            <a class="btn btn-link" (click)="decrementTime($event, 'timeOne')"><span class="bs-chevron bs-chevron-down"></span></a>
                          </td>
                        </tr>
                        </tbody>
                    </table>
                    <ng-container *ngIf="appointmentForm.get('hourOne').errors || appointmentForm.get('timeOne').errors">
                        <small class="text-danger">
                            L'heure sélectionnée est incorrecte
                        </small>
                    </ng-container>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-lg-6">
                <div class="form-group">
                    <label for="proposalTwo">Proposition 2</label>
                    <input type="text"
                        id="proposalTwo"
                        placeholder="Choisissez une date"
                        class="form-control"
                        formControlName="proposalTwo"
                        name="proposalTwo"
                        [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY' }"
                        bsDatepicker
                        [minDate]="today"
                        autocomplete="off">
                </div>
            </div>
            <div class="col-12 col-lg-6">
                <div class="form-group">
                    <table>
                        <tbody>
                        <tr class="text-center">
                          <td>
                            <a class="btn btn-link" (click)="incrementTime($event, 'hourTwo')"><span class="bs-chevron bs-chevron-up"></span></a>
                          </td>
                          <td>&nbsp;&nbsp;&nbsp;</td>
                          <td>
                            <a class="btn btn-link" (click)="incrementTime($event, 'timeTwo')"><span class="bs-chevron bs-chevron-up"></span></a>
                          </td>
                        </tr>
                        <tr>
                          <td class="form-group mb-3">
                            <input type="number" formControlName="hourTwo" class="form-control text-center bs-timepicker-field" id="hourTwo" name="hourTwo" min="00" max="23" placeholder="HH">
                          </td>
                          <td>&nbsp;:&nbsp;</td>
                          <td class="form-group mb-3">
                            <input type="number" formControlName="timeTwo" class="form-control text-center bs-timepicker-field" id="timeTwo" name="timeTwo" min="00" max="59" step="5" placeholder="MM">
                          </td>
                        </tr>
                        <tr class="text-center">
                          <td>
                            <a class="btn btn-link" (click)="decrementTime($event, 'hourTwo')"><span class="bs-chevron bs-chevron-down"></span></a>
                          </td>
                          <td>&nbsp;&nbsp;&nbsp;</td>
                          <td>
                            <a class="btn btn-link" (click)="decrementTime($event, 'timeTwo')"><span class="bs-chevron bs-chevron-down"></span></a>
                          </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-lg-6">
                <div class="form-group">
                    <label for="proposalThree">Proposition 3</label>
                    <input type="text"
                        id="proposalThree"
                        placeholder="Choisissez une date"
                        class="form-control"
                        formControlName="proposalThree"
                        name="proposalThree"
                        [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY' }"
                        bsDatepicker
                        [minDate]="today"
                        autocomplete="off">
                </div>
            </div>
            <div class="col-12 col-lg-6">
                <div class="form-group">
                    <table>
                        <tbody>
                        <tr class="text-center">
                          <td>
                            <a class="btn btn-link" (click)="incrementTime($event, 'hourThree')"><span class="bs-chevron bs-chevron-up"></span></a>
                          </td>
                          <td>&nbsp;&nbsp;&nbsp;</td>
                          <td>
                            <a class="btn btn-link" (click)="incrementTime($event, 'timeThree')"><span class="bs-chevron bs-chevron-up"></span></a>
                          </td>
                        </tr>
                        <tr>
                          <td class="form-group mb-3">
                            <input type="number" formControlName="hourThree" class="form-control text-center bs-timepicker-field" id="hourThree" name="hourThree" min="00" max="23" placeholder="HH">
                          </td>
                          <td>&nbsp;:&nbsp;</td>
                          <td class="form-group mb-3">
                            <input type="number" formControlName="timeThree" class="form-control text-center bs-timepicker-field" id="timeThree" name="timeThree" min="00" max="59" step="5" placeholder="MM">
                          </td>
                        </tr>
                        <tr class="text-center">
                          <td>
                            <a class="btn btn-link" (click)="decrementTime($event, 'hourThree')"><span class="bs-chevron bs-chevron-down"></span></a>
                          </td>
                          <td>&nbsp;&nbsp;&nbsp;</td>
                          <td>
                            <a class="btn btn-link" (click)="decrementTime($event, 'timeThree')"><span class="bs-chevron bs-chevron-down"></span></a>
                          </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="form-group mt-14">
            <button type="submit" class="btn btn-primary w-100">Créer</button>
        </div>
    </form>
</div>
