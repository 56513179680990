import { Component, OnInit } from '@angular/core';

import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { InvitationService } from '../../core/services/invitation.service';
import { LoadingService } from '../../core/services/loading.service';
import { AlertService } from '../../core/services/alert.service';
import { Invitation } from '../../core/models/invitation.model';

@Component({
    selector: 'app-invitation',
    templateUrl: './invitation.component.html',
    styleUrls: ['./invitation.component.scss']
})
export class InvitationComponent implements OnInit {

    public invitation: Invitation = new Invitation();
    public invitationExpired: boolean;

    constructor(private invitationService: InvitationService,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private loadingService: LoadingService,
        private alertService: AlertService,
        private toastr: ToastrService) {
        this.loadingService.showLoading();
    }

    ngOnInit() {
        this.getInvitation();
    }

    getInvitation() {
        this.invitationService.getInvitation(this.activatedRoute.snapshot.params.token).pipe(
            finalize(() => this.loadingService.dismissLoading())
        ).subscribe(invitation => {
            this.invitation = invitation;
            const expiredAt = new Date(this.invitation.expiredAt);
            const now = new Date();

            this.invitationExpired = now > expiredAt;
        }, error => {
            console.log(error)
            this.alertService.showAlert(error.errors, 'danger');
        });
    }

    acceptInvitation() {
        this.loadingService.showLoading();
        this.invitationService.acceptInvitation(this.invitation.token, {}).pipe(
            finalize(() => this.loadingService.dismissLoading())
        ).subscribe(invitation => {
              this.toastr.success('Vous êtes désormais membre du cercle ' + this.invitation.circleName, 'Félicitations !');
              this.router.navigateByUrl('/cercles/' + this.invitation.circleToken);
        }, error => {
            console.log(error)
            this.alertService.showAlert(error.errors, 'danger');
        });
    }

}
