import { Component, OnInit } from '@angular/core';

import { BsModalRef } from 'ngx-bootstrap/modal';
import { ScrollEvent } from 'ngx-scroll-event';
import { Circle } from '../../../core/models/circle.model';

@Component({
    selector: 'app-modal-medias',
    templateUrl: './modal-medias.component.html',
    styleUrls: ['./modal-medias.component.scss']
})
export class ModalMediasComponent implements OnInit {

    public circle: Circle = null;
    public scrollEvent: ScrollEvent;

    constructor(public bsModalRef: BsModalRef) { }

    ngOnInit() {
    }

    loadData(event: ScrollEvent) {
        this.scrollEvent = event;
    }

}
