import { Component, OnInit } from '@angular/core';

import { Router, ActivatedRoute } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { matchingValidator } from '../../shared/directives/matching-validator.directive';
import { finalize } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { UserService } from '../../core/services/user.service';
import { AuthService } from '../../core/services/auth.service';
import { LoadingService } from '../../core/services/loading.service';
import { AlertService } from '../../core/services/alert.service';

@Component({
    selector: 'app-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

    public registerForm: FormGroup;
    public newUser: any = {
        email: "",
        firstName: "",
        lastName: "",
        password: ""
    };

  constructor(private userService: UserService,
    private auth: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private loadingService: LoadingService,
    private alertService: AlertService,
    private toastr: ToastrService) { }

    ngOnInit() {
        this.prepareRegisterForm();
    }

    prepareRegisterForm() {
        this.registerForm = new FormGroup({
            email: new FormControl(this.newUser.email, [Validators.required, Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')]),
            firstName: new FormControl(this.newUser.firstName, Validators.required),
            lastName: new FormControl(this.newUser.lastName, Validators.required),
            password: new FormControl(this.newUser.password, [Validators.required, Validators.minLength(6), , matchingValidator('confirmPassword', true)]),
            confirmPassword: new FormControl('', [Validators.required, matchingValidator('password')]),
            acceptedTermsOfService: new FormControl(false, Validators.requiredTrue)
        });
    }

    prepareNewUser() {
        this.newUser.email = this.registerForm.get('email').value;
        this.newUser.firstName = this.registerForm.get('firstName').value;
        this.newUser.lastName = this.registerForm.get('lastName').value;
        this.newUser.password = this.registerForm.get('password').value;
        this.newUser.acceptedTermsOfService = this.registerForm.get('acceptedTermsOfService').value;
        this.newUser.plainPassword = this.newUser.password;
    }

    onRegisterFormSubmit() {
        this.registerForm['submitted'] = true;

        if (this.registerForm.valid) {
            this.loadingService.showLoading();
            this.prepareNewUser();
            this.userService.createUser(this.newUser).pipe(
                finalize(() => this.loadingService.dismissLoading())
            ).subscribe(user => {
                const data = {
                    username: this.newUser.email,
                    password: this.newUser.password
                };

                this.loadingService.showLoading();
                this.auth.authenticate(data).then((data) => {
                    const redirectionUrl = this.route.snapshot.queryParams['redirectionUrl'] || '/';

                    this.toastr.success('Votre compte a bien été créé', 'Félicitations !');                    
                    this.router.navigateByUrl(redirectionUrl);
                }).catch(e => {
                }).finally(() => this.loadingService.dismissLoading())
                }, error => {
                    console.log(error)
                    this.alertService.showAlert(error.errors, 'danger');
                });
        }
    }
}
