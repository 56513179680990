<div class="members container-fluid p-0">
    <div class="row mb-3">
        <div class="col-12 d-flex justify-content-end" *ngIf="isVisio && memberMe.isOnVisio">
            <button type="button" class="btn-link-primary" (click)="inviteAllOnVisio()" [ngClass]="{'invited':isAllInvited}">
                <span class="material-icons material-icons-outlined mr-1 align-middle">video_call</span>
                <span class="align-middle">Tous</span>
            </button>
        </div>
    </div>
    <ng-container *ngFor="let member of members; let i = index">
        <div class="row mb-3" *ngIf="!member.deletedAt">
            <div class="col-1 d-flex justify-content-center" *ngIf="!memberMe.isUnderSurveillance || memberMe.isAdmin">
                <button type="button" class="px-0 ml-3 pt-2" title="Sortir du cercle" *ngIf="!member.isAdmin && !member.isUnderSurveillance && !member.isWaiting() && (member.getId() == memberMe.getId() || memberMe.isAdmin)" (mouseover)="hover[i]=true" (mouseout)="hover[i]=false" (click)="onLeaveCircle(i)">
                    <span class="material-icons text-danger">{{ hover[i] == true ? 'remove_circle' : 'remove_circle_outline' }}</span>
                </button>
            </div>
            <ng-container *ngIf="!member.isWaiting()">
                <div class="col-9 pr-0">
                    <a [routerLink]="['/profil', member.user.token]" title="{{ member.getName() }}">
                        <app-user-avatar [user]="member.user" [isUnderSurveillance]="member.isUnderSurveillance" [isOnline]="(onlineUsers.includes(member.user.token))"></app-user-avatar>
                        <span class="name d-inline-block align-middle ml-2 font-weight-bold">
                            {{ member.getName() }}
                            <span class="small text-primary d-block font-weight-normal" *ngIf="member.isUnderSurveillance">Centre du cercle</span>
                        </span>
                    </a>
                </div>
                <div class="col-1 d-flex justify-content-center align-items-center pl-0" *ngIf="isVisio && (member.getId() != memberMe.getId()) && !member.isOnVisio && memberMe.isOnVisio">
                    <button type="button" class="btn-link-primary" (click)="inviteOnVisio([member])" title="Inviter sur la visio">
                        <span class="material-icons material-icons-filled video-call" [ngClass]="{'invited':member.isInvited}">video_call</span>
                    </button>
                </div>
            </ng-container>
            <ng-container *ngIf="member.isWaiting()">
                <div class="col-10">
                    <app-user-avatar [user]="member.user" [isUnderSurveillance]="member.isUnderSurveillance"></app-user-avatar>
                    <span class="name d-inline-block align-middle ml-2 font-weight-bold">
                        En attente
                        <span class="d-block small text-dark-grey font-weight-normal">{{ member.email }}</span>
                    </span>
                    <button type="button" class="d-block ml-10 btn-link-primary small"(click)="reInvite(member)" *ngIf="memberMe.isAdmin">Ré-inviter</button>
                </div>
            </ng-container>
        </div>
    </ng-container>
    <div class="row mt-4" *ngIf="memberMe.isAdmin">
        <div class="col-12">
            <button type="button" class="btn-link-primary" (click)="openAddMemberModal()" *ngIf="circle">
                <span class="material-icons material-icons-outlined mr-2 d-inline-block align-middle">person_add</span>
                <span class="d-inline-block align-middle">Ajouter un membre</span>
            </button>
        </div>
    </div>
</div>
