import { Injectable } from '@angular/core';

import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { switchMap, map, finalize } from 'rxjs/operators';
import { Observable, from, pipe, BehaviorSubject } from 'rxjs';
import { AuthService } from './auth.service';
import { ApiService } from './api.service';
import { FeathersService } from './feathers.service';
import { User } from '../models/user.model';
import { Avatar } from '../models/avatar.model';
import { CookieStorage } from 'cookie-storage';
import { SubscriptionModel } from '../models/subscription.model';
import { Invoice } from '../models/invoice.model';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private _fsServiceName: string = 'users';
  private _updatedUser: BehaviorSubject<User> = new BehaviorSubject<User>(null);
  private _cookieStorage;
  private _storageKey: string = 'at';

  constructor(private authService: AuthService,
    private feathersService: FeathersService,
    private apiService: ApiService,
    private http: HttpClient) {
      this._cookieStorage = new CookieStorage();
  }

  createUser(data: any): Observable<any> {
    return from(this.authService.authenticateAnonymous()).pipe(
      switchMap(response => {
        return this.apiService.post(this._fsServiceName, data);
      }),
      map(user => new User().deserialize(user)),
      finalize(() => this.authService.logout())
    );
  }

  updateUser(token: string, user: User): Observable<any> {
    return this.apiService.put(this._fsServiceName, token, user.serialize()).pipe(
      map(user => {
        return new User().deserialize(user)
      })
    );
  }

  onUpdatedUser(): Observable<User> {
    return this._updatedUser;
  }

  setUpdatedUserEvent() {
    this.feathersService.client.service(this._fsServiceName).on('updated', comment => {
      this._updatedUser.next(new User().deserialize(comment));
      this._updatedUser.next(null);
    });
  }

  getUser(token: string): Observable<any> {
    return this.apiService.get(this._fsServiceName, token).pipe(
      map(user => new User().deserialize(user))
    );
  }

  updateAvatar(token: string, data: any): Observable<any> {
    const accessToken = this.feathersService.client.settings.accessToken;
    const httpOptions = {
      headers: new HttpHeaders({
        authorization: accessToken
      }),
      params: new HttpParams().set('operationPath', `/${token}/avatar`)
    };

    return this.http.post(environment.fsAPI.url + '/' + this._fsServiceName, data, httpOptions).pipe(
      map(avatar => {
          return new Avatar().deserialize(avatar);
      })
    );
  }

  quitApplication(id: any, data: any): Observable<any> {
    const params = {
      query: {
        operationPath: '/quit-app'
      }
    };

    return this.apiService.put(this._fsServiceName, id, data, params).pipe(
      map(user => new User().deserialize(user))
    );
  }

  downloadArchive(token: string, hash: string, archiveId: string, expires: string, memberId: string, code: string): Observable<any> {
    this._cookieStorage.removeItem(this._storageKey);
    return from(this.authService.authenticateAnonymous()).pipe(
      switchMap(response => {
        const defaultParams = {
          query: {
            operationPath: `/archive`,
            _hash: hash,
            code: code,
            archive: archiveId,
            expire: expires,
            membre: memberId
          }
        };
  
        return this.apiService.get(this._fsServiceName, token, defaultParams);
      }),
      finalize(() => this.authService.logout())
    );
  }

  regenerateArchive(id: any): Observable<any> {
    const params = {
      query: {
        operationPath: '/regenerate-archive'
      }
    };

    return from(this.authService.authenticateAnonymous()).pipe(
      switchMap(response => {
        return this.apiService.get(this._fsServiceName, id, params)
      }),
      finalize(() => this.authService.logout())
    );
  }

  getUserSubscriptions(token: any, params: any = {}): Observable<any> {
    const defaultParams = {
      query: {
        operationPath: '/subscriptions'
      }
    };

    Object.assign(defaultParams.query, params);

    return this.apiService.get(this._fsServiceName, token, defaultParams).pipe(
      map(subscriptions => {
        subscriptions.forEach((value, index) => {
          subscriptions[index] = new SubscriptionModel().deserialize(value);
        });

        return subscriptions;
      })
    );
  }

  getUserInvoices(token: any, params: any = {}): Observable<any> {
    const defaultParams = {
      query: {
        operationPath: '/invoices'
      }
    };

    Object.assign(defaultParams.query, params);

    return this.apiService.get(this._fsServiceName, token, defaultParams).pipe(
      map(invoices => {
        invoices.forEach((value, index) => {
          invoices[index] = new Invoice().deserialize(value);
        });

        return invoices;
      })
    );
  }
}
