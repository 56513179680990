import { Serializable } from '../interfaces/serializable';

import { Member } from './member.model';
import { Proposal } from './proposal.model';

export class Vote implements Serializable {
    static readonly CHOICE_YES = 'Oui';
    static readonly CHOICE_MAYBE = 'Peut-être';
    static readonly CHOICE_NO = 'Non';

    private _id: number = null;
    public choice: string = "";
    public member: Member = null;
    public proposal: Proposal = null;
    public createdAt: Date = null;
    public updatedAt: Date = null;

    constructor() {}

    public getIRI() {
        return '/api/votes/' + this._id;
    }

    public getId() {
        return this._id;
    } 

    public deserialize(input: any): this {
        if (!input) {
        return null;
        }

        Object.getOwnPropertyNames(this).forEach((property) => {
        switch (property) {
            case 'member':
                this[property] = new Member().deserialize(input[property]);
            break;
            case 'proposal':
                this[property] = new Proposal().deserialize(input[property]);
            break;
            default:
                if ('_id' == property && input.id) {
                    this._id = input.id;
                } else if (property in input) {
                    this[property] = input[property];
                }
            break;
        }
        });

        return this;
    }

    public serialize(): any {
        var output = {};

        Object.getOwnPropertyNames(this).forEach((property) => {
        switch (property) {
            case 'member':
            case 'proposal':
                if (this[property]) {
                    output[property] = this[property].getIRI();
                }
            break;
            default:
                if (property != '_id') {
                    output[property] = this[property];
                }
            break;
        }
        });

        return output;
    }
}
