<div class="container py-8">
    <h1 class="text-center mb-8">Abonnement</h1>
    <div class="mx-lg-10 text-justify" *ngIf="isSubscribe">
        Pour avoir la possibilité de créer des cercles, il faut souscrire une offre d’abonnement.<br/><br/>
        Seul l’administrateur des cercles finance le service. Les invités au cercle ne paient pas.
        On peut créer autant de cercle que l’on veut et avoir autant d’invités que l’on veut.<br/>
        L’espace de stockage est de 2 gigaoctets, et peut être augmenté.
    </div>
    <iframe class="ml-lg-10 mt-4" id="subscriptionFrame" name="subscriptionFrame" [src]="iframeUrl" width="100%" height="450" frameBorder="0"></iframe>
    <div class="ml-lg-10 mt-3">
        <a [routerLink]="['/cercles']" class="" title="Revenir aux cercle">
            <i class="fas fa-arrow-left ml-1 mr-2 align-middle"></i>
            <span>Revenir aux cercles</span>
        </a>
    </div>
</div>
