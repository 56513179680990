import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Appointment } from '../../../core/models/appointment.model';

@Component({
  selector: 'app-modal-appointments',
  templateUrl: './modal-appointments.component.html',
  styleUrls: ['./modal-appointments.component.scss']
})
export class ModalAppointmentsComponent implements OnInit {

  public appointments: Appointment[] = [];

  constructor(public bsModalRef: BsModalRef) { }

  ngOnInit() {
  }

}
