import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import HmacSHA256 from 'crypto-js/hmac-sha256';
import Base64 from 'crypto-js/enc-base64';

@Component({
  selector: 'app-subscription-update-result',
  templateUrl: './subscription-update-result.component.html',
  styleUrls: ['./subscription-update-result.component.scss']
})
export class SubscriptionUpdateResultComponent implements OnInit {

  public isAccepted: boolean = false;
  
  constructor(private route: ActivatedRoute,
    private router: Router) { }

  ngOnInit() {
    this.getResult();
  }

  getResult() {
    let parameters = this.route.snapshot.queryParams;
    if (parameters['signature']) {
      if (this.verifySignature()) {
        if ("ACCEPTED".indexOf(parameters['vads_trans_status']) > -1) {
          this.isAccepted = true;
        }
      }
    }
  }

  verifySignature() {
    let parameters = this.route.snapshot.queryParams;
    let signatureContent = "";
    const keySogecommerce = environment.sogecommerce.key;
    let ordered = Object.entries(parameters).sort();
    for (const [key, value] of ordered) {
      if (key.substring(0,5) == 'vads_') {
        signatureContent += value + "+";
      }
    }
    signatureContent += keySogecommerce;
    let hash = HmacSHA256(signatureContent, keySogecommerce);
    return Base64.stringify(hash) == parameters['signature'];
  }

  refreshIframe() {
    this.router.navigateByUrl('/profil/abonnement/mettre-a-jour/iframe');
  }

}
