<div class="modal-header">
    <h3 class="modal-title text-uppercase">Modifier un commentaire</h3>
    <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
        <i class="fas fa-times"></i>
    </button>
</div>
<div class="modal-body">
    <form [formGroup]="editCommentForm" (ngSubmit)="onEditCommentFormSubmit()">
        <div class="form-group">
            <textarea name="message" formControlName="message" rows="2" class="form-control" placeholder="Tapez votre message ici..."></textarea>
        </div>
        <div class="form-group mt-14">
            <button type="submit" class="btn btn-primary w-100">Envoyer</button>
        </div>
    </form>
</div>
