import { Component, OnInit, OnDestroy, Input, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';

import { Subscription } from 'rxjs';
import { ScrollEvent } from 'ngx-scroll-event';
import { CircleService } from '../../../core/services/circle.service';
import { PostService } from '../../../core/services/post.service';
import { Circle } from '../../../core/models/circle.model';
import { Post } from '../../../core/models/post.model';
import { Media } from '../../../core/models/media.model';

@Component({
  selector: 'app-medias',
  templateUrl: './medias.component.html',
  styleUrls: ['./medias.component.scss']
})
export class MediasComponent implements OnInit, OnDestroy {

    @Input() public circle: Circle = new Circle();
    @Input() public scrollEvent: ScrollEvent;
    @Input() public container: string = 'medias';
    @Output() public onNavigationStart: EventEmitter<any> = new EventEmitter();
    private _observers: Subscription[] = [];
    private _postsPage: number = 1;
    private _typeFilter: string = "";
    private _memberFilter: string = "";
    public posts: any[] = [];
    public albums: any[] = [];
    public loadingAlbums: boolean = true;
    public memberFilters: any[] = [];
    public typeFilters: any[] = Media.FILTERS_TYPE;

    constructor(private postService: PostService,
        private circleService: CircleService,
        private router: Router) { }

    ngOnInit() {
        this.getPosts();
        this.setObservers();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.scrollEvent) {
            this.loadPosts(changes.scrollEvent.currentValue);
        }
    }

    setObservers() {
        this._observers.push(this.setCreatedPostObserver());
        this._observers.push(this.setRouterObserver());
    }

    setCreatedPostObserver(): Subscription {
        return this.postService.onCreatedPost()
            .subscribe(post => {
                if (!post) {
                    return;
                }

                if (this.circle.getIRI() == post.circle.getIRI()) {
                    if (post.medias.length) {
                        if (!this.memberFilters.find(member => member.getId() == post.member.getId())) {
                            this.memberFilters.push(post.member);
                        }
                        post.medias = this.filterMedias(post);

                        this.posts.unshift(post);
                        this.albums.unshift(post);
                    }
                }
        });
    }

    setRouterObserver(): Subscription {
        return this.router.events.subscribe(event => {
            if (event instanceof NavigationStart) {
                this.onNavigationStart.emit(event);
            }
        });
    }

    loadPosts(event: ScrollEvent, force: boolean = false) {
        if (!this.loadingAlbums && ((event && event.isReachingBottom) || force) && this._postsPage) {
            this.getPosts();
        }
    }

    getPosts() {
        this.loadingAlbums = true;
        this.circleService.getCirclePosts(this.circle.token, { page: this._postsPage }).subscribe(posts => {
            (posts.length > 0) ? this._postsPage++ : this._postsPage = 0;
            this.loadingAlbums = false;

            const newPosts = posts.filter(post => post.medias.length > 0);

            if (newPosts.length > 0) {
                this.posts = this.posts.concat(newPosts);
                newPosts.forEach(post => {
                    if (!this.memberFilters.find(member => member.getId() == post.member.getId())) {
                        this.memberFilters.push(post.member);
                    }

                    let newPost = Object.assign({}, post);

                    newPost.medias = this.filterMedias(newPost);
                    if (newPost.medias.length > 0) {
                        this.albums.push(new Post().deserialize(newPost));
                    }
                });
            } else {
                this.loadPosts(null, true);
            }
        });
    }

    filterByType(post: Post): Media[] {
        let medias: Media[] = [];

        post.medias.forEach(media => {
            switch(this._typeFilter) {
                case 'images':
                    if (media.isImage()) {
                        medias.push(media);
                    }
                break;
                case 'videos':
                    if (media.isVideo()) {
                        medias.push(media);
                    }
                    break;
                case 'audios':
                    if (media.isAudio()) {
                        medias.push(media);
                    }
                break;
                case 'spreadsheets':
                    if (media.isSpreadsheet()) {
                        medias.push(media);
                    }
                break;
                case 'files':
                    if (media.isFile()) {
                        medias.push(media);
                    }
                break;
                default: break;
            }
        });

        if (medias.length == 0) {
            this.loadPosts(null, true);
        }

        return medias;
    }

    filterByMember(post: Post): Media[] {
        return (post.member.getId() == parseInt(this._memberFilter)) ? post.medias : [];
    }

    filterMedias(post: Post): Media[] {
        let medias = Object.assign([], post.medias);

        medias = (this._memberFilter) ? this.filterByMember(post) : medias;
        medias = (this._typeFilter && medias.length) ? this.filterByType(post) : medias;

        return medias;
    }

    onTypeFilterChanged(type) {
        this._typeFilter = type;

        this.albums = [];
        this.posts.forEach(post => {
            let newPost = Object.assign({}, post);

            newPost.medias = this.filterMedias(newPost);
            if (newPost.medias.length > 0) {
                this.albums.push(new Post().deserialize(newPost));
            }
        });
    }

    onMemberFilterChanged(memberId) {
        this._memberFilter = memberId;

        this.albums = [];
        this.posts.forEach(post => {
            let newPost = Object.assign({}, post);

            newPost.medias = this.filterMedias(newPost);
            if (newPost.medias.length > 0) {
                this.albums.push(new Post().deserialize(newPost));
            }
        });
    }

    ngOnDestroy() {
        this._observers.forEach((observer, index) => {
            observer.unsubscribe();
        });
    }

}
