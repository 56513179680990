/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./subscription.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@angular/router";
import * as i4 from "./subscription.component";
import * as i5 from "@angular/platform-browser";
var styles_SubscriptionComponent = [i0.styles];
var RenderType_SubscriptionComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SubscriptionComponent, data: {} });
export { RenderType_SubscriptionComponent as RenderType_SubscriptionComponent };
function View_SubscriptionComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "mx-lg-10 text-justify"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Pour avoir la possibilit\u00E9 de cr\u00E9er des cercles, il faut souscrire une offre d\u2019abonnement."])), (_l()(), i1.ɵeld(2, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Seul l\u2019administrateur des cercles finance le service. Les invit\u00E9s au cercle ne paient pas. On peut cr\u00E9er autant de cercle que l\u2019on veut et avoir autant d\u2019invit\u00E9s que l\u2019on veut."])), (_l()(), i1.ɵeld(5, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" L\u2019espace de stockage est de 2 gigaoctets, et peut \u00EAtre augment\u00E9. "]))], null, null); }
export function View_SubscriptionComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [["class", "container py-8"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h1", [["class", "text-center mb-8"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Abonnement"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SubscriptionComponent_1)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 0, "iframe", [["class", "ml-lg-10 mt-4"], ["frameBorder", "0"], ["height", "450"], ["id", "subscriptionFrame"], ["name", "subscriptionFrame"], ["width", "100%"]], [[8, "src", 5]], null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 6, "div", [["class", "ml-lg-10 mt-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 5, "a", [["class", ""], ["title", "Revenir aux cercle"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 8).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(8, 671744, null, 0, i3.RouterLinkWithHref, [i3.Router, i3.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(9, 1), (_l()(), i1.ɵeld(10, 0, null, null, 0, "i", [["class", "fas fa-arrow-left ml-1 mr-2 align-middle"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Revenir aux cercles"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isSubscribe; _ck(_v, 4, 0, currVal_0); var currVal_4 = _ck(_v, 9, 0, "/cercles"); _ck(_v, 8, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.iframeUrl; _ck(_v, 5, 0, currVal_1); var currVal_2 = i1.ɵnov(_v, 8).target; var currVal_3 = i1.ɵnov(_v, 8).href; _ck(_v, 7, 0, currVal_2, currVal_3); }); }
export function View_SubscriptionComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-subscription", [], null, null, null, View_SubscriptionComponent_0, RenderType_SubscriptionComponent)), i1.ɵdid(1, 114688, null, 0, i4.SubscriptionComponent, [i5.DomSanitizer, i3.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SubscriptionComponentNgFactory = i1.ɵccf("app-subscription", i4.SubscriptionComponent, View_SubscriptionComponent_Host_0, {}, {}, []);
export { SubscriptionComponentNgFactory as SubscriptionComponentNgFactory };
