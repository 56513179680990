import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';
import { AlertService } from '../../core/services/alert.service';
import { SubscriptionModel } from '../../core/models/subscription.model';
import { User } from '../../core/models/user.model';
import { MeService } from '../../core/services/me.service';
import { SubscriptionService } from '../../core/services/subscription.service';
import { UserService } from '../../core/services/user.service';
import { LoadingService } from '../../core/services/loading.service';

@Component({
  selector: 'app-subscription-increase-storage',
  templateUrl: './subscription-increase-storage.component.html',
  styleUrls: ['./subscription-increase-storage.component.scss']
})
export class SubscriptionIncreaseStorageComponent implements OnInit {

  public me: User = new User();
  public subscription: SubscriptionModel = null;
  
  constructor(private meService: MeService,
    private router: Router,
    private userService: UserService,
    private subscriptionService: SubscriptionService,
    private toastrService: ToastrService,
    private alertService: AlertService,
    private loadingService: LoadingService) { }

  ngOnInit() {
    this.me = this.meService.getMe();
    this.getSubscriptions();
  }

  onCancel() {
    this.router.navigateByUrl('/cercles');
  }

  getSubscriptions() {
    this.userService.getUserSubscriptions(this.me.token).subscribe(subscriptions => {
        subscriptions.forEach(subscription => {
            if (SubscriptionModel.STATUS_OK.indexOf(subscription.status) > -1) {
                this.subscription = subscription;
            }
        });
    });
  }

  onUpdateStorage() {
    this.loadingService.showLoading();
    this.subscriptionService.updateStorageSubscription(this.subscription.getId(), this.subscription).pipe(
      finalize(() => this.loadingService.dismissLoading())
    ).subscribe(subscription => {
      this.alertService.closeAlert();
      this.router.navigateByUrl('/profil/' + this.me.token);
      this.toastrService.success('Votre espace de stockage a bien été augmenté', 'Confirmation');
    }, error => {
      console.log(error);
      this.alertService.showAlert(error.errors, 'danger');
    });
  }

}
