/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./dashboard.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../circle-thumbnail/circle-thumbnail.component.ngfactory";
import * as i4 from "../circle-thumbnail/circle-thumbnail.component";
import * as i5 from "../../core/services/me.service";
import * as i6 from "../../core/services/circle.service";
import * as i7 from "ngx-toastr";
import * as i8 from "ngx-bootstrap/modal";
import * as i9 from "ngx-scroll-event/src/scroll.directive";
import * as i10 from "./dashboard.component";
import * as i11 from "../../core/services/loading.service";
import * as i12 from "../../core/services/user.service";
import * as i13 from "@angular/router";
var styles_DashboardComponent = [i0.styles];
var RenderType_DashboardComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DashboardComponent, data: {} });
export { RenderType_DashboardComponent as RenderType_DashboardComponent };
function View_DashboardComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [["class", "font-weight-bold"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Vous n'\u00EAtes pas encore membre d'un cercle. "]))], null, null); }
function View_DashboardComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_DashboardComponent_2)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.loadingCircles; _ck(_v, 1, 0, currVal_0); }, null); }
function View_DashboardComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(3, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(4, { "border-bottom border-light-grey": 0 }), (_l()(), i1.ɵeld(5, 0, null, null, 1, "app-circle-thumbnail", [], null, null, null, i3.View_CircleThumbnailComponent_0, i3.RenderType_CircleThumbnailComponent)), i1.ɵdid(6, 114688, null, 0, i4.CircleThumbnailComponent, [i5.MeService, i6.CircleService, i7.ToastrService, i8.BsModalService], { circle: [0, "circle"], circleLeft: [1, "circleLeft"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 4, 0, !_v.context.last); _ck(_v, 3, 0, currVal_0); var currVal_1 = _v.context.$implicit; var currVal_2 = (_co.circlesLeft.indexOf(_v.context.$implicit.token) > (0 - 1)); _ck(_v, 6, 0, currVal_1, currVal_2); }, null); }
function View_DashboardComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "circles-container"], ["detect-scroll", ""]], null, [[null, "onScroll"], [null, "scroll"], ["window", "scroll"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("scroll" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).scrolled($event) !== false);
        ad = (pd_0 && ad);
    } if (("window:scroll" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).windowScrolled($event) !== false);
        ad = (pd_1 && ad);
    } if (("onScroll" === en)) {
        var pd_2 = (_co.loadCircles($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 16384, null, 0, i9.ScrollDirective, [], { bottomOffset: [0, "bottomOffset"], topOffset: [1, "topOffset"] }, { onScroll: "onScroll" }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DashboardComponent_4)), i1.ɵdid(3, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = 100; var currVal_1 = 100; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = _co.circles; _ck(_v, 3, 0, currVal_2); }, null); }
function View_DashboardComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "row mt-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "col-12 text-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "spinner-border text-primary"], ["role", "status"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [["class", "sr-only"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Loading..."]))], null, null); }
export function View_DashboardComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 17, "div", [["class", "container py-8"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 9, "div", [["class", "row justify-content-around align-items-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "col-9 col-md-6"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Mes cercles"])), (_l()(), i1.ɵeld(5, 0, null, null, 5, "div", [["class", "col-3 col-md-6 text-right"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 4, "button", [["class", "btn btn-link-primary d-none d-sm-inline-block"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openAddCircleModal() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "span", [["class", "d-none d-md-inline-block"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Cr\u00E9er un cercle"])), (_l()(), i1.ɵeld(9, 0, null, null, 1, "span", [["class", "material-icons d-md-none"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["add_circle_outline"])), (_l()(), i1.ɵeld(11, 0, null, null, 4, "div", [["class", "row justify-content-center mt-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 3, "div", [["class", "col-12 col-md-10 col-lg-8 px-0 px-md-3"]], null, null, null, null, null)), (_l()(), i1.ɵand(0, [["noCircles", 2]], null, 0, null, View_DashboardComponent_1)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DashboardComponent_3)), i1.ɵdid(15, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DashboardComponent_5)), i1.ɵdid(17, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(18, 0, null, null, 1, "button", [["class", "btn btn-primary d-sm-none btn-highlight"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openAddCircleModal() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" Cr\u00E9er un cercle\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.circles.length; var currVal_1 = i1.ɵnov(_v, 13); _ck(_v, 15, 0, currVal_0, currVal_1); var currVal_2 = _co.loadingCircles; _ck(_v, 17, 0, currVal_2); }, null); }
export function View_DashboardComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-dashboard", [], null, null, null, View_DashboardComponent_0, RenderType_DashboardComponent)), i1.ɵdid(1, 245760, null, 0, i10.DashboardComponent, [i6.CircleService, i11.LoadingService, i8.BsModalService, i5.MeService, i12.UserService, i13.Router, i2.DatePipe], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DashboardComponentNgFactory = i1.ɵccf("app-dashboard", i10.DashboardComponent, View_DashboardComponent_Host_0, {}, {}, []);
export { DashboardComponentNgFactory as DashboardComponentNgFactory };
