import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { finalize } from 'rxjs/operators';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { LoadingService } from '../../../core/services/loading.service';
import { AppointmentService } from '../../../core/services/appointment.service';
import { AlertService } from '../../../core/services/alert.service';
import { ProposalService } from '../../../core/services/proposal.service';
import { Appointment } from '../../../core/models/appointment.model';
import { Circle } from '../../../core/models/circle.model';
import { Proposal } from '../../../core/models/proposal.model';

@Component({
  selector: 'app-modal-add-appointment',
  templateUrl: './modal-add-appointment.component.html',
  styleUrls: ['./modal-add-appointment.component.scss']
})
export class ModalAddAppointmentComponent implements OnInit {

  public appointmentForm: FormGroup;
  public newAppointment: any = {};
  public circle: Circle = new Circle();
  public today: Date = new Date();
  public valid = true;

  constructor(public bsModalRef: BsModalRef,
    private localeService: BsLocaleService,
    private loadingService: LoadingService,
    private appointmentService: AppointmentService,
    private proposalService: ProposalService,
    private alertService: AlertService,
    private router: Router,
    private datePipe: DatePipe) { }

  ngOnInit() {
    this.prepareAppointmentForm();
  }

  prepareAppointmentForm() {
    this.localeService.use('fr');
    
    this.appointmentForm = new FormGroup({
        subject: new FormControl('', Validators.required),
        place: new FormControl('visio', Validators.required),
        proposalOne: new FormControl('', Validators.required),
        hourOne: new FormControl('00', [Validators.min(0), Validators.max(23)]),
        timeOne: new FormControl('00', [Validators.min(0), Validators.max(59)]),
        proposalTwo: new FormControl(''),
        hourTwo: new FormControl('00', [Validators.min(0), Validators.max(23)]),
        timeTwo: new FormControl('00'),
        proposalThree: new FormControl(''),
        hourThree: new FormControl('00', [Validators.min(0), Validators.max(23)]),
        timeThree: new FormControl('00')
    });
  }

  incrementTime(e, id) {
    let input = e.target.ownerDocument.getElementById(id);
    input.stepUp();
    if(!isNaN(input.value) && input.value.length === 1) {
      input.value = '0' + input.value;
    }
    this.appointmentForm.get(id).setValue(input.value);
  }

  decrementTime(e, id) {
    let input = e.target.ownerDocument.getElementById(id);
    input.stepDown();
    if(!isNaN(input.value) && input.value.length === 1) {
      input.value = '0' + input.value;
    }
    this.appointmentForm.get(id).setValue(input.value);
  }

  setNewAppointment() {
    this.newAppointment.subject = this.appointmentForm.get('subject').value;
    this.newAppointment.meetingPlace = this.appointmentForm.get('place').value == 'visio' ? Appointment.VISIO : Appointment.FACE_TO_FACE;
    this.newAppointment.circle = this.circle.getIRI();
  }

  setProposal(appointment: string, fieldDate: string, fieldHour: string, fieldTime: string) {
    let newProposal = new Proposal();
    newProposal.appointment = appointment;

    const dateForm = this.appointmentForm.get(fieldDate).value;
    const date = new Date();
    date.setDate(dateForm.getDate());
    date.setMonth(dateForm.getMonth());
    date.setFullYear(dateForm.getFullYear());
    const hours = this.appointmentForm.get(fieldHour).value;
    const time = this.appointmentForm.get(fieldTime).value;
    date.setHours(hours);
    date.setMinutes(time);
    date.setSeconds(0);
    newProposal.date = this.datePipe.transform(date, 'yyyy-MM-dd HH:mm:ss');
    return newProposal;
  }
  
  onAppointmentFormSubmit() {
    this.appointmentForm['submitted'] = true;

    if (this.appointmentForm.valid) {
      this.setNewAppointment();
      this.loadingService.showLoading();
      this.appointmentService.createAppointment(this.newAppointment).pipe(
        finalize(() => this.loadingService.dismissLoading())
      ).subscribe(appointment => {
        let newProposal = {};
        newProposal = this.setProposal(appointment.getIRI(), 'proposalOne', 'hourOne', 'timeOne');
        this.proposalService.createProposal(newProposal).subscribe(proposal => {
        }, error => {
          console.log(error.errors)
          this.alertService.showAlert(error.errors, 'danger');
        });
        if (this.appointmentForm.get('proposalTwo').value != '') {
          newProposal = this.setProposal(appointment.getIRI(), 'proposalTwo', 'hourTwo', 'timeTwo');
          this.proposalService.createProposal(newProposal).subscribe(proposal => {
          }, error => {
            console.log(error.errors)
            this.alertService.showAlert(error.errors, 'danger');
          });
        }
        if (this.appointmentForm.get('proposalThree').value != '') {
          newProposal = this.setProposal(appointment.getIRI(), 'proposalThree', 'hourThree', 'timeThree');
          this.proposalService.createProposal(newProposal).subscribe(proposal => {
          }, error => {
            console.log(error.errors)
            this.alertService.showAlert(error.errors, 'danger');
          });
        }

        this.appointmentForm['submitted'] = false;
        this.appointmentForm.reset();
        this.bsModalRef.hide();
        this.alertService.closeAlert();
        this.router.navigateByUrl('/cercles/' + appointment.circle.token + '/rendez-vous/' + appointment.getId());
      }, error => {
        console.log(error.errors)
        this.alertService.showAlert(error.errors, 'danger');
      });
    }
  }

}
