import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { finalize } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { FileUploader } from 'ng2-file-upload';

import { AlertService } from '../../../core/services/alert.service';
import { LoadingService } from '../../../core/services/loading.service';
import { PostService } from '../../../core/services/post.service';
import { Post } from '../../../core/models/post.model';
import { Media } from '../../../core/models/media.model';
import { Event } from '../../../core/models/event.model';
import { FixOrientationPipe } from '../../../shared/pipes/fix-orientation.pipe';
import { ModalAddEventComponent } from '../modal-add-event/modal-add-event.component';
import { MediaService } from '../../../core/services/media.service';
import { EventService } from '../../../core/services/event.service';
import { ModalConfirmationComponent } from '../modal-confirmation/modal-confirmation.component';

@Component({
  selector: 'app-modal-edit-post',
  templateUrl: './modal-edit-post.component.html',
  styleUrls: ['./modal-edit-post.component.scss']
})
export class ModalEditPostComponent implements OnInit {

  public editPostForm: FormGroup;
  @Input() public post: Post = new Post();
  public uploaderEdit: FileUploader = new FileUploader({ itemAlias: 'file' });
  public medias: Media[] = [];
  public newMedias: Media[] = [];
  public editEvent: Event = null;
  public newEvent: Event = null;
  public editModalAddEvent: BsModalRef;
  public modalConfirmation: BsModalRef;
  
  constructor(public bsModalRef: BsModalRef,
    private bsModalService: BsModalService,
    private loadingService: LoadingService,
    private postService: PostService,
    private mediaService: MediaService,
    private eventService: EventService,
    private alertService: AlertService,
    private toastrService: ToastrService,
    private fixOrientationPipe: FixOrientationPipe) { }

  ngOnInit() {
    this.prepareEditPostForm();
    this.medias = this.post.medias;
    this.editEvent = this.post.event;
    this.setUploader();
  }

  prepareEditPostForm() {
    this.editPostForm = new FormGroup({
        message: new FormControl(this.post.message),
    });
  }

  setUploader() {
    const reader = new FileReader();

    this.uploaderEdit.onAfterAddingAll = (fileItems) => {
        
        fileItems.forEach(fileItem => {
          const fileObject = fileItem.file;

          var media = new Media().deserialize({
              filename: fileObject.name,
              mimeType: fileObject.type
          });

          if (media.isImage()) {
            this.transformImage(media, fileItem);
          }

          this.newMedias.push(media);
        });
    };
  }

  transformImage(media: Media, fileItem: any) {
    const reader = new FileReader();
    reader.onload = () => {
        this.fixOrientationPipe.transform(reader.result).subscribe(imageBase64 => {
            media.data = imageBase64;
        });
    };
    reader.readAsDataURL(fileItem._file);
  }


  setEditedPost(): FormData {
    let input = new FormData();

    input.append('_id', this.post.getId().toString());
    input.append('message', this.editPostForm.get('message').value || "");
    input.append('circle', this.post.circle.getIRI());

    // Add medias
    this.newMedias.forEach((fileItem, index) => {
      input.append(`medias[${index}]`, this.uploaderEdit.queue[index]._file);
    });

    // Add event
    if (this.newEvent) {
        input.append('event', JSON.stringify(this.newEvent.serialize()));
    }

    return input;
  }

  onEditPostFormSubmit() {
    this.editPostForm['submitted'] = true;

    if (this.editPostForm.valid) {
        const editedPost = this.setEditedPost();

        this.loadingService.showLoading();
        this.postService.updatePost(editedPost).pipe(
            finalize(() => this.loadingService.dismissLoading())
        ).subscribe(post => {
            this.editPostForm['submitted'] = false;
            this.alertService.closeAlert();
            this.bsModalRef.hide();
            this.toastrService.success('Votre post a bien été modifié', 'Confirmation');
        }, error => {
            const errorMessage: string[] = error.error.errors ? error.error.errors : ["Impossible de modifier votre post pour l'instant, veuillez réessayer ultérieurement, merci"];
            this.alertService.showAlert(errorMessage, 'danger');
            console.log(error);

        });
    }
  }

  openEditAddEventModal() {
    this.editModalAddEvent = this.bsModalService.show(ModalAddEventComponent);
    this.bsModalService.onHide.subscribe((reason) => {
        if (this.editModalAddEvent.content && this.editModalAddEvent.content.newEvent.title) {
            this.newEvent = this.editModalAddEvent.content.newEvent;
            this.editModalAddEvent.content = null;
        }
    });
  }
  
  removeExistingMedia(index: number) {
    this.modalConfirmation = this.bsModalService.show(ModalConfirmationComponent);
    this.modalConfirmation.setClass("shadow");
    this.modalConfirmation.content.objectToRemove = "supprimer ce document";
    this.bsModalService.onHide.subscribe((reason) => {
      if (this.medias[index] && this.modalConfirmation.content && this.modalConfirmation.content.confirmation) {
        this.mediaService.deleteMedia(this.medias[index].getId()).subscribe(error => {
          console.log(error);
        });
        this.medias.splice(index, 1);
      }
    });
  }

  removeMedia(index: number) {
    this.uploaderEdit.queue[index].remove();
    this.newMedias.splice(index, 1);
  }

  removeExistingEvent() {
    this.modalConfirmation = this.bsModalService.show(ModalConfirmationComponent);
    this.modalConfirmation.setClass("shadow");
    this.modalConfirmation.content.objectToRemove = "supprimer cet évènement";
    this.bsModalService.onHide.subscribe((reason) => {
      if (this.modalConfirmation.content && this.modalConfirmation.content.confirmation) {
        if (this.editEvent) {
          this.eventService.deleteEvent(this.editEvent.getId()).subscribe(error => {
            console.log(error);
          });
          this.eventService.patchEvent(this.editEvent).subscribe(event => {
            this.editEvent = null;
          });
        }
      }
    });
  }

  removeEvent() {
    this.newEvent = null;
  }

}
