/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./modal-medias.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "ngx-scroll-event/src/scroll.directive";
import * as i3 from "../medias/medias.component.ngfactory";
import * as i4 from "../medias/medias.component";
import * as i5 from "../../../core/services/post.service";
import * as i6 from "../../../core/services/circle.service";
import * as i7 from "@angular/router";
import * as i8 from "@angular/common";
import * as i9 from "./modal-medias.component";
import * as i10 from "ngx-bootstrap/modal";
var styles_ModalMediasComponent = [i0.styles];
var RenderType_ModalMediasComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ModalMediasComponent, data: {} });
export { RenderType_ModalMediasComponent as RenderType_ModalMediasComponent };
function View_ModalMediasComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "modal-body"], ["detect-scroll", ""]], null, [[null, "onScroll"], [null, "scroll"], ["window", "scroll"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("scroll" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).scrolled($event) !== false);
        ad = (pd_0 && ad);
    } if (("window:scroll" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).windowScrolled($event) !== false);
        ad = (pd_1 && ad);
    } if (("onScroll" === en)) {
        var pd_2 = (_co.loadData($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 16384, null, 0, i2.ScrollDirective, [], { bottomOffset: [0, "bottomOffset"], topOffset: [1, "topOffset"] }, { onScroll: "onScroll" }), (_l()(), i1.ɵeld(2, 0, null, null, 1, "app-medias", [], null, [[null, "onNavigationStart"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onNavigationStart" === en)) {
        var pd_0 = (_co.bsModalRef.hide() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_MediasComponent_0, i3.RenderType_MediasComponent)), i1.ɵdid(3, 770048, null, 0, i4.MediasComponent, [i5.PostService, i6.CircleService, i7.Router], { circle: [0, "circle"], scrollEvent: [1, "scrollEvent"], container: [2, "container"] }, { onNavigationStart: "onNavigationStart" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = 200; var currVal_1 = 200; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = _co.circle; var currVal_3 = _co.scrollEvent; var currVal_4 = (_co.modal - _co.medias); _ck(_v, 3, 0, currVal_2, currVal_3, currVal_4); }, null); }
export function View_ModalMediasComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h3", [["class", "modal-title text-uppercase"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Documents"])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "button", [["aria-label", "Close"], ["class", "close pull-right"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.bsModalRef.hide() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "i", [["class", "fas fa-times"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModalMediasComponent_1)), i1.ɵdid(6, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.circle; _ck(_v, 6, 0, currVal_0); }, null); }
export function View_ModalMediasComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-modal-medias", [], null, null, null, View_ModalMediasComponent_0, RenderType_ModalMediasComponent)), i1.ɵdid(1, 114688, null, 0, i9.ModalMediasComponent, [i10.BsModalRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ModalMediasComponentNgFactory = i1.ɵccf("app-modal-medias", i9.ModalMediasComponent, View_ModalMediasComponent_Host_0, {}, {}, []);
export { ModalMediasComponentNgFactory as ModalMediasComponentNgFactory };
