import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'initials'
})
export class InitialsPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    const words = value.split(' ');
    const initials = words.map(word => word.charAt(0));

    return initials.join();
  }

}
