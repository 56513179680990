import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxLoadingModule } from 'ngx-loading';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { ModalModule } from 'ngx-bootstrap/modal';
import { InitialsPipe } from './pipes/initials.pipe';
import { UserAvatarComponent } from './components/user-avatar/user-avatar.component';
import { ShortenPipe } from './pipes/shorten.pipe';
import { VisioDirective } from './directives/visio.directive';
import { DateFromPipe } from './pipes/date-from.pipe';
import { FixOrientationPipe } from './pipes/fix-orientation.pipe';

@NgModule({
  declarations: [InitialsPipe, UserAvatarComponent, ShortenPipe, VisioDirective, DateFromPipe, FixOrientationPipe],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgxLoadingModule.forRoot({}),
    ModalModule,
    RouterModule
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    NgxLoadingModule,
    ModalModule,
    InitialsPipe,
    ShortenPipe,
    DateFromPipe,
    FixOrientationPipe,
    UserAvatarComponent,
    VisioDirective
  ],
  providers: [
    FixOrientationPipe
  ]
})
export class SharedModule { }
