import { Pipe, PipeTransform } from '@angular/core';
import { formatDate } from '@angular/common';

@Pipe({
    name: 'dateFrom'
})
export class DateFromPipe implements PipeTransform {

    constructor() {}

    transform(value: any, ...args: any[]): any {
        const today: Date = new Date();
        const date: Date = new Date(value);
        var diffTime: number = today.getTime() - date.getTime();
        diffTime = diffTime/1000;
        var seconds: number = Math.floor(diffTime%60);
        diffTime = diffTime/60;
        var minutes: number = Math.floor(diffTime%60);
        diffTime = diffTime/60;
        var hours: number = Math.floor(diffTime%24);
        var days: number = Math.floor(diffTime/24);
        var dateFrom: string[] = ['Il y a'];

        if (days < 7) {
            if (days > 0) {
                dateFrom.push(days + "j");
                dateFrom.push(hours + "h");
                dateFrom.push(minutes + "min");
            } else if (hours > 0) {
                dateFrom.push(hours + "h");
                dateFrom.push(minutes + "min");
            } else if (minutes > 0) {
                dateFrom.push(minutes + "min");
            } else if (seconds > 0) {
                dateFrom.push(seconds + "s");
            } else {
                dateFrom.push("1s");
            }

            if (dateFrom.length) {
                return dateFrom.join(' ');
            }
        }

        return formatDate(date, "dd/MM/yy HH:mm", 'fr-FR');
    }

}
