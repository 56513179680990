export const environment = {
  fsAPI: {
    url: 'https://api-js.cercle-confiance.fr',
    clientId: "1_334h7jjv0hgkck0wgcgo88os0ksocg8k8404c00k4sk4s04skk",
    clientSecret: "44vwg3gn9lic04sg4og4c4sc0ccwkss0sg44owwososwooc484"
  },
  publicServer: {
    url: 'https://api-back.cercle-confiance.fr/uploads',
    directories: {
      circles: '/circles',
      users: '/users'
    }
  },
  production: true,
  jitsi: {
    domain: 'jitsi.cercle-confiance.fr'
  },
  sogecommerce: {
    key: '47vxUt7svheg2eep',
    mode: 'PRODUCTION'
  }
};
