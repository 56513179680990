<div class="medias container-fluid p-0">
    <div class="row">
        <div class="col-12 col-sm-6">
            <select name="type" class="w-100" (change)="onTypeFilterChanged($event.target.value)">
                <option value="">Tous les types</option>
                <option *ngFor="let type of typeFilters" [value]="type.value">{{ type.name }}</option>
            </select>
        </div>
        <div class="col-12 col-sm-6 mt-3 mt-sm-0">
            <select name="member" class="w-100" (change)="onMemberFilterChanged($event.target.value)">
                <option value="">Tous les membres</option>
                <option *ngFor="let member of memberFilters" [value]="member.getId()">{{ member.getName() }}</option>
            </select>
        </div>
    </div>
    <ng-template #noMedias>
        <p class="mt-3" *ngIf="!loadingAlbums">
            <i>Aucun média publié.</i>
        </p>
    </ng-template>
    <ng-template [ngIf]="albums.length" [ngIfElse]="noMedias">
        <div class="row mt-5">
            <div class="col-12">
                <ng-container *ngFor="let album of albums">
                    <div class="mb-5">
                        <app-album [post]="album" [container]="container"></app-album>
                    </div>
                </ng-container>
            </div>
        </div>
    </ng-template>
    <div class="row mt-4" *ngIf="loadingAlbums">
        <div class="col-12 text-center">
            <div class="spinner-border text-primary" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
    </div>
</div>
