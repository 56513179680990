import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { NgxLoadingModule, ngxLoadingAnimationTypes } from 'ngx-loading';
import { LightboxModule } from 'ngx-lightbox';
import { AlertModule } from 'ngx-bootstrap/alert';
import { ModalModule } from 'ngx-bootstrap/modal';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { frLocale } from 'ngx-bootstrap/locale';
defineLocale('fr', frLocale);

import { AppRoutingModule } from './app-routing.module';
import { CoreModule } from './core/core.module';
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CoreModule.forRoot(),
    AlertModule.forRoot(),
    NgxLoadingModule.forRoot({
        animationType: ngxLoadingAnimationTypes.doubleBounce,
        backdropBackgroundColour: 'rgba(0,0,0,0.5)',
        primaryColour: '#1c75bc',
        fullScreenBackdrop: true
    }),
    BrowserAnimationsModule, // required animations module
    ToastrModule.forRoot(),
    ModalModule.forRoot(),
    LightboxModule,
    DeviceDetectorModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  providers: [
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
