import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { Reaction } from '../models/reaction.model';
import * as i0 from "@angular/core";
import * as i1 from "./api.service";
import * as i2 from "./feathers.service";
export class ReactionService {
    constructor(apiService, feathers) {
        this.apiService = apiService;
        this.feathers = feathers;
        this._fsServiceName = "reactions";
        this._createdReaction = new BehaviorSubject(null);
        this._updatedReaction = new BehaviorSubject(null);
        this._removedReaction = new BehaviorSubject(null);
        this.setCreatedReactionEvent();
        this.setUpdatedReactionEvent();
        this.setRemovedReactionEvent();
    }
    onCreatedReaction() {
        return this._createdReaction;
    }
    onUpdatedReaction() {
        return this._updatedReaction;
    }
    onRemovedReaction() {
        return this._removedReaction;
    }
    createReaction(data, params = {}) {
        return this.apiService.post(this._fsServiceName, data).pipe(map(reaction => new Reaction().deserialize(reaction)));
    }
    updateReaction(id, data, params = {}) {
        return this.apiService.put(this._fsServiceName, id, data, params).pipe(map(reaction => new Reaction().deserialize(reaction)));
    }
    deleteReaction(data, id) {
        return this.apiService.delete(this._fsServiceName, id).pipe(map(reaction => new Reaction().deserialize(reaction)));
    }
    patchReaction(data) {
        return this.apiService.patch(this._fsServiceName, data);
    }
    setCreatedReactionEvent() {
        this.feathers.client.service(this._fsServiceName).on('created', reaction => {
            this._createdReaction.next(new Reaction().deserialize(reaction));
            this._createdReaction.next(null);
        });
    }
    setUpdatedReactionEvent() {
        this.feathers.client.service(this._fsServiceName).on('updated', reaction => {
            this._updatedReaction.next(new Reaction().deserialize(reaction));
            this._updatedReaction.next(null);
        });
    }
    setRemovedReactionEvent() {
        this.feathers.client.service(this._fsServiceName).on('patched', reaction => {
            this._removedReaction.next(new Reaction().deserialize(reaction));
            this._removedReaction.next(null);
        });
    }
}
ReactionService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ReactionService_Factory() { return new ReactionService(i0.ɵɵinject(i1.ApiService), i0.ɵɵinject(i2.FeathersService)); }, token: ReactionService, providedIn: "root" });
