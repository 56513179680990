import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HeaderService {

    private _menuClosure: BehaviorSubject<any> = new BehaviorSubject(false);
    private _headerSwipe: BehaviorSubject<any> = new BehaviorSubject(false);

  constructor() { }

  closeMenu() {
    this._menuClosure.next(false);
  }

  closedMenu(): Observable<string> {
    return this._menuClosure.asObservable();
  }

  swipeHeader(swiped) {
    this._headerSwipe.next(swiped);
  }

  swipedHeader(): Observable<boolean> {
    return this._headerSwipe.asObservable();
  }
}
