import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import HmacSHA256 from 'crypto-js/hmac-sha256';
import Base64 from 'crypto-js/enc-base64';
import { environment } from '../../../environments/environment';

import { MeService } from '../../core/services/me.service';
import { User } from '../../core/models/user.model';
import { SubscriptionModel } from '../../core/models/subscription.model';
import { UserService } from '../../core/services/user.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-subscription-update',
  templateUrl: './subscription-update.component.html',
  styleUrls: ['./subscription-update.component.scss']
})
export class SubscriptionUpdateComponent implements OnInit {

  public me: User = new User();
  public vads_ctx_mode: FormControl = new FormControl(environment.sogecommerce.mode);
  public vads_identifier: FormControl = new FormControl('');
  public vads_trans_date: FormControl = new FormControl(this.datePipe.transform(new Date(), 'yyyyMMddHHmmss'));
  public signature: FormControl = new FormControl('');
  public resultUrl: string = "";
  
  constructor(private meService: MeService,
    private router: Router,
    private userService: UserService,
    private datePipe: DatePipe) { }

  ngOnInit() {
    this.me = this.meService.getMe();
    this.resultUrl = window.location.origin + '/profil/abonnement/mettre-a-jour/resultat';
    this.getSubscriptions();
  }

  onCancel() {
    const url = this.router.createUrlTree(['/cercles']);
    window.open(url.toString(), '_parent');
  }

  getSubscriptions() {
    this.userService.getUserSubscriptions(this.me.token).subscribe(subscriptions => {
        subscriptions.forEach(subscription => {
            if (SubscriptionModel.STATUS_OK.indexOf(subscription.status) > -1) {
                this.vads_identifier.setValue(subscription.alias);
                this.calculateSignature();
            }
        });
    });
  }

  calculateSignature() {
    let form = document.getElementById("updateForm");
    let arrayForm = Array.prototype.slice.call(form.getElementsByTagName("input"));
    let arraySorted = arrayForm.sort((a,b) => (a.name > b.name ? 1 : -1));
    let signatureContent = "";
    const key = environment.sogecommerce.key;
    arraySorted.forEach(element => {
      if (element.name.substring(0,5) == 'vads_') {
        signatureContent += unescape(encodeURIComponent(element.value)) + "+";
      }
    });
    signatureContent += key;
    let hash = HmacSHA256(signatureContent, key);
    this.signature.setValue(Base64.stringify(hash));
  }

}
