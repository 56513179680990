import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { Subscription } from 'rxjs';

import { LoadingService } from '../../../core/services/loading.service';
import { PostService } from '../../../core/services/post.service';
import { Post } from '../../../core/models/post.model';
import { CircleService } from '../../../core/services/circle.service';
import { Member } from '../../../core/models/member.model';
import { Circle } from '../../../core/models/circle.model';

@Component({
  selector: 'app-post-details',
  templateUrl: './post-details.component.html',
  styleUrls: ['./post-details.component.scss']
})
export class PostDetailsComponent implements OnInit, OnDestroy {

  public post: Post = new Post();
  private _observers: Subscription[] = [];
  public memberMe: Member = new Member();
  public circle: Circle = new Circle();
  public loadingPost: boolean = true;

  constructor(private postService: PostService,
    private loadingService: LoadingService,
    private activatedRoute: ActivatedRoute,
    private circleService: CircleService) { }

  ngOnInit() {
    this.setObservers();
  }

  getPost() {
    if (this.activatedRoute.snapshot.params.id) {
      this.loadingService.showLoading();
      this.loadingPost = false;
      return this.postService.getPost(this.activatedRoute.snapshot.params.id).pipe(
          finalize(() => this.loadingService.dismissLoading())
      ).subscribe(post => {
        this.loadingPost = true;
          this.post = post;
      }, error => {
          console.log(error);
      });
    }
  }

  setObservers() {
    this._observers.push(this.setCircleObserver());
  }

  setCircleObserver(): Subscription {
    return this.circleService.getCurrentCircle().subscribe(circle => {

        if (!circle) {
            return;
        }

        this.circle = circle;

        this.circleService.getCircleMembersMe(circle.token).subscribe(member => {
            this.memberMe = member;
            this.getPost();
        });
    });
  }

  ngOnDestroy() {
    this._observers.forEach((observer, index) => {
        observer.unsubscribe();
    });
  }

}
