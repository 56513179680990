<div class="modal-header">
    <h3 class="modal-title text-uppercase">Membres en ligne</h3>
    <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
        <i class="fas fa-times"></i>
    </button>
</div>
<div class="modal-body" *ngIf="onlineMembers">
    <div class="container-fluid p-0">
        <ng-container *ngFor="let member of onlineMembers; let i = index">
            <div class="row mb-3" *ngIf="!member.deletedAt">
                <ng-container *ngIf="!member.isWaiting()">
                    <div class="col-9 pr-0">
                        <a [routerLink]="['/profil', member.user.token]" title="{{ member.getName() }}">
                            <app-user-avatar [user]="member.user" [isUnderSurveillance]="member.isUnderSurveillance" [isOnline]="true"></app-user-avatar>
                            <span class="name d-inline-block align-middle ml-2 font-weight-bold">
                                {{ member.getName() }}
                                <span class="small text-primary d-block font-weight-normal" *ngIf="member.isUnderSurveillance">Centre du cercle</span>
                            </span>
                        </a>
                    </div>
                </ng-container>
            </div>
        </ng-container>
    </div>
</div>
