<div class="modal-header">
    <h3 class="modal-title text-uppercase">Ajouter un évènement</h3>
    <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
        <i class="fas fa-times"></i>
    </button>
</div>
<div class="modal-body">
    <form [formGroup]="eventForm" (ngSubmit)="onEventFormSubmit()">
        <div class="form-group">
            <label for="title">Titre*</label>
            <input type="text" class="form-control" name="title" id="title" aria-describedby="title" formControlName="title" placeholder="Titre de l'évènement" required>
            <ng-container *ngIf="eventForm.get('title').invalid && (eventForm.get('title').dirty || eventForm.get('title').touched || eventForm['submitted'])">
                <small class="text-danger" *ngIf="eventForm.get('title').errors.required">
                    Le titre est obligatoire
                </small>
            </ng-container>
        </div>
        <div class="row">
            <div class="col-12 col-lg-6">
                <div class="form-group">
                    <label for="date">Date*</label>
                    <input type="text"
                        id="date"
                        placeholder="Choisissez une date"
                        class="form-control"
                        formControlName="date"
                        name="date"
                        [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY' }"
                        required
                        bsDatepicker>
                    <ng-container *ngIf="eventForm.get('date').invalid && (eventForm.get('date').dirty || eventForm.get('date').touched || eventForm['submitted'])">
                        <small class="text-danger" *ngIf="eventForm.get('date').errors.required">
                            La date est obligatoire
                        </small>
                    </ng-container>
                </div>
            </div>
            <div class="col-12 col-lg-6">
                <div class="form-group">
                    <timepicker formControlName="time" name="time" [showMeridian]="false" [mousewheel]=""></timepicker>
                </div>
            </div>
        </div>
        <div class="form-group">
            <label for="description">Description</label>
            <textarea class="form-control" id="description" name="description" rows="3" placeholder="Votre texte ici..." formControlName="description"></textarea>
        </div>
        <div class="form-group mt-14">
            <button type="submit" class="btn btn-primary w-100">Ajouter</button>
        </div>
    </form>
</div>
