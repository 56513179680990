<div class="circle container-fluid h-lg-100" *ngIf="circle">
    <div class="row h-lg-100">
        <div class="col-12 col-lg-3 border-right border-light-grey h-lg-100">
            <div class="row flex-column h-lg-100">
                <div class="col bg-secondary py-3 py-lg-2 flex-grow-0 d-flex d-lg-block justify-content-between align-items-center circle__presentation">
                    <div class="d-flex align-items-center align-items-lg-start">
                        <a [routerLink]="['/cercles', circle.token]" class="text-white small font-weight-bold d-inline-block py-lg-2" title="Revenir au cercle" *ngIf="!isMainPage">
                            <i class="fas fa-arrow-left mr-3 text-white fa-1_5x d-inline-block align-middle"></i>
                        </a>
                        <h1 class="text-white d-flex flex-row align-middle mb-0 mb-lg-2 flex-grow-1">
                            <div>
                                <span class="d-none d-sm-block">{{ circle.name }}</span>
                                <span class="d-sm-none mr-3">{{ circle.name|shorten:11 }}</span>
                            </div>
                            <button class="d-lg-none align-items-center small d-flex flex-row" type="button" (click)="openOnlineUsersModal()" *ngIf="onlineUsers.length">
                                <div class="mr-2" id="online-circle"></div>
                                <span class="text-white" style="font-size: 70%;">{{ onlineUsers.length }}/{{ activeMembers }}</span>
                            </button>
                        </h1>
                    </div>
                    <div class="dropdown d-lg-none mr-2">
                        <a class="text-white" href="#" role="button" id="dropdownCircleMenu" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <span class="badge badge-pill badge-primary circle-notification" *ngIf="waitingAppointments.length + newAppointments.length > 0">1</span>
                            <i class="fas fa-ellipsis-v fa-1_5x"></i>
                        </a>
                        <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownCircleMenu">
                            <button class="dropdown-item" type="button" (click)="openWaitingAppointmentsModal()">
                                <span class="badge badge-primary align-middle mr-2">{{ waitingAppointments.length + newAppointments.length }}</span>
                                <span class="align-middle">Concertations en attente</span> 
                            </button>
                            <div class="dropdown-divider"></div>
                            <button class="dropdown-item" type="button" (click)="openMembersModal()">Membres</button>
                            <button class="dropdown-item" type="button" (click)="openMediasModal()">Documents</button>
                            <button class="dropdown-item" type="button" (click)="openEventsModal()">Agenda</button>
                            <div class="dropdown-divider"></div>
                            <a [routerLink]="['visio']" class="dropdown-item">
                                <span class="material-icons material-icons-outlined mr-2 d-inline-block align-middle">videocam</span>
                                <span>Visio</span>
                            </a>
                            <a [routerLink]="['parametres']" class="dropdown-item" *ngIf="memberMe.isAdmin">
                                <span class="material-icons material-icons-outlined mr-2 d-inline-block align-middle">settings</span>
                                <span class="d-inline-block align-middle">Paramètres</span>
                            </a>
                            <div class="dropdown-divider"></div>
                            <a [routerLink]="['/cercles']" class="dropdown-item" title="Revenir au cercle">
                                Revenir aux cercles
                            </a>
                        </div>
                    </div>
                    <ul class="d-lg-block list-unstyled list-inline mb-0 d-none">
                        <ng-container *ngFor="let member of circle.members; index as i;">
                            <li class="ml-n3" [ngClass]="{ 'ml-n3': i > 0 }" *ngIf="!member.deletedAt">
                                <app-user-avatar [user]="member.user" [size]="'tiny'" [isOnline]="(member.user && onlineUsers.includes(member.user.token))"></app-user-avatar>
                            </li>
                        </ng-container>
                    </ul>
                    <div class="text-right d-none d-lg-block">
                        <a [routerLink]="['/cercles']" class="text-white small font-weight-bold">Revenir aux cercles</a>
                    </div>
                </div>
                <div class="col flex-grow-1 overflow-auto py-3 d-none d-lg-block" detect-scroll (onScroll)="loadData($event)" [bottomOffset]="200" [topOffset]="200">
                    <ng-template #noDescription>
                        <p>
                            <i>Aucune description donnée.</i>
                        </p>
                    </ng-template>
                    <ng-template [ngIf]="circle.description" [ngIfElse]="noDescription">
                        <p class="dark-grey" [innerHTML]="circle.description"></p>
                    </ng-template>
                    <div id="accordionFeatures" class="accordion mt-3">
                        <div class="card">
                            <div class="card-header" id="headingMembers">
                                <h5 class="mb-0">
                                    <button class="collapsed" data-toggle="collapse" data-target="#collapseMembers" aria-expanded="true" aria-controls="collapseOne">
                                        Membres
                                    </button>
                                </h5>
                            </div>
                            <div id="collapseMembers" class="collapse" aria-labelledby="headingMembers" data-parent="#accordionFeatures">
                                <div class="card-body">
                                    <app-members [members]="circle.members" [circle]="circle" [memberMe]="memberMe"></app-members>
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header" id="headingMedias">
                                <h5 class="mb-0">
                                    <button class="collapsed" data-toggle="collapse" data-target="#collapseMedias" aria-expanded="true" aria-controls="collapseMedias">
                                        Documents
                                    </button>
                                </h5>
                            </div>
                            <div id="collapseMedias" class="collapse" aria-labelledby="headingMedias" data-parent="#accordionFeatures">
                                <div class="card-body">
                                    <div class="card-body">
                                        <app-medias [circle]="circle" [scrollEvent]="scrollEvent"></app-medias>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header" id="headingEvents">
                                <h5 class="mb-0">
                                    <button class="collapsed" data-toggle="collapse" data-target="#collapseEvents" aria-expanded="true" aria-controls="collapseEvents">
                                        Agenda
                                    </button>
                                </h5>
                            </div>
                            <div id="collapseEvents" class="collapse" aria-labelledby="headingEvents" data-parent="#accordionFeatures">
                                <div class="card-body">
                                    <div class="card-body">
                                        <app-events [circle]="circle" [scrollEvent]="scrollEvent"></app-events>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ng-container>
                    <div class="col px-0 flex-grow-0 align-self-end border-top d-none d-lg-block">
                        <ul class="list-unstyled nav-circle">
                            <li class="nav-item">
                                <a [routerLink]="['visio']" [routerLinkActive]="['bg-grey-90']" class="px-3">
                                    <span class="material-icons material-icons-outlined mr-3">videocam</span>
                                    <span>Visio</span>
                                </a>
                            </li>
                            <li class="nav-item" *ngIf="memberMe.isAdmin">
                                <a [routerLink]="['parametres']" [routerLinkActive]="['bg-grey-90']" class="px-3">
                                    <span class="material-icons material-icons-outlined mr-3">settings</span>
                                    <span>Paramètres</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </ng-container>
            </div>
        </div>
        <div class="col-12 col-lg-9 h-lg-100">
            <div class="row h-lg-100">
                <div class="w-100 h-lg-100">
                    <router-outlet></router-outlet>
                </div>
            </div>
        </div>
    </div>
</div>
