/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./post-details.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "../post/post.component.ngfactory";
import * as i5 from "../post/post.component";
import * as i6 from "../../../core/services/comment.service";
import * as i7 from "../../../core/services/loading.service";
import * as i8 from "ngx-toastr";
import * as i9 from "../../../core/services/post.service";
import * as i10 from "../../../core/services/alert.service";
import * as i11 from "../../../core/services/reaction.service";
import * as i12 from "ngx-bootstrap/modal";
import * as i13 from "./post-details.component";
import * as i14 from "../../../core/services/circle.service";
var styles_PostDetailsComponent = [i0.styles];
var RenderType_PostDetailsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PostDetailsComponent, data: {} });
export { RenderType_PostDetailsComponent as RenderType_PostDetailsComponent };
function View_PostDetailsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [["class", "container-fluid py-10 pt-sm-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, "div", [["class", "row pt-7 pt-sm-0 mb-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 6, "div", [["class", "col-12 post-details"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 5, "a", [["class", ""], ["title", "Revenir au cercle"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 4).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(4, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(5, 2), (_l()(), i1.ɵeld(6, 0, null, null, 0, "i", [["class", "fas fa-arrow-left ml-1 mr-2 align-middle"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Revenir au cercle"])), (_l()(), i1.ɵeld(9, 0, null, null, 3, "div", [["class", "row h-lg-100"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 2, "div", [["class", "col-12 col-lg-9 post-details"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 1, "app-post", [], null, null, null, i4.View_PostComponent_0, i4.RenderType_PostComponent)), i1.ɵdid(12, 245760, null, 0, i5.PostComponent, [i6.CommentService, i7.LoadingService, i8.ToastrService, i9.PostService, i10.AlertService, i11.ReactionService, i12.BsModalService, i3.DatePipe, i1.ChangeDetectorRef], { post: [0, "post"], memberMe: [1, "memberMe"], openedComments: [2, "openedComments"], index: [3, "index"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _ck(_v, 5, 0, "/cercles", _co.circle.token); _ck(_v, 4, 0, currVal_2); var currVal_3 = _co.post; var currVal_4 = _co.memberMe; var currVal_5 = true; var currVal_6 = 0; _ck(_v, 12, 0, currVal_3, currVal_4, currVal_5, currVal_6); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 4).target; var currVal_1 = i1.ɵnov(_v, 4).href; _ck(_v, 3, 0, currVal_0, currVal_1); }); }
function View_PostDetailsComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "container-fluid pt-6 pb-1"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Le post n'existe plus."])), (_l()(), i1.ɵeld(3, 0, null, null, 3, "a", [["class", "btn-link-primary"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 4).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(4, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(5, 2), (_l()(), i1.ɵted(-1, null, ["Revenir au cercle"]))], function (_ck, _v) { var _co = _v.component; var currVal_2 = _ck(_v, 5, 0, "/cercles", _co.circle.token); _ck(_v, 4, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 4).target; var currVal_1 = i1.ɵnov(_v, 4).href; _ck(_v, 3, 0, currVal_0, currVal_1); }); }
function View_PostDetailsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_PostDetailsComponent_2)), i1.ɵdid(1, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["postDeleted", 2]], null, 0, null, View_PostDetailsComponent_3))], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.post.deletedAt; var currVal_1 = i1.ɵnov(_v, 2); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_PostDetailsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "h-lg-100 overflow-auto bg-grey-92"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PostDetailsComponent_1)), i1.ɵdid(2, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.loadingPost && _co.post.member); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_PostDetailsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-post-details", [], null, null, null, View_PostDetailsComponent_0, RenderType_PostDetailsComponent)), i1.ɵdid(1, 245760, null, 0, i13.PostDetailsComponent, [i9.PostService, i7.LoadingService, i2.ActivatedRoute, i14.CircleService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PostDetailsComponentNgFactory = i1.ɵccf("app-post-details", i13.PostDetailsComponent, View_PostDetailsComponent_Host_0, {}, {}, []);
export { PostDetailsComponentNgFactory as PostDetailsComponentNgFactory };
