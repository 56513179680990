<div class="container py-7">
    <div class="row justify-content-center">
        <div class="col-12 col-sm-8 col-lg-5 text-center">
            <a routerLink="/">
                <img src="../../assets/img/logo.svg" class="logo img-fluid" />
            </a>
            <h1 class="mt-6 mt-lg-8">
                Demande de nouveau mot de passe
            </h1>
            <div class="bg-white border-light-grey border rounded text-left p-5 mt-5 mt-lg-7">
                <form [formGroup]="passwordRequestForm" (ngSubmit)="onPasswordRequestFormSubmit()">
                    <div class="form-group">
                        <label for="email">Adresse e-mail*</label>
                        <input id="email" type="email" formControlName="email" class="form-control" placeholder="votreadresse@mail.com" />
                        <ng-container *ngIf="passwordRequestForm.get('email').invalid && (passwordRequestForm.get('email').dirty || passwordRequestForm.get('email').touched || passwordRequestForm['submitted'])">
                            <small class="text-danger" *ngIf="passwordRequestForm.get('email').errors.required">
                                L'email est obligatoire
                            </small>
                            <small class="text-danger" *ngIf="passwordRequestForm.get('email').errors.pattern">
                                L'email n'est pas correct
                            </small>
                        </ng-container>
                    </div>
                    <div class="form-group mt-12 mt-lg-14">
                        <button type="submit" class="btn btn-primary w-100">Envoyer</button>
                    </div>
                </form>
                <div class="text-center">
                    <a routerLink="/login">J'ai retrouvé mon mot de passe</a>
                </div>
            </div>
        </div>
    </div>
</div>
