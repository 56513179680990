import { Component, OnInit } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { PasswordRequestService } from '../../core/services/password-request.service';
import { PasswordRequest } from '../../core/models/password-request.model';
import { LoadingService } from '../../core/services/loading.service';
import { AlertService } from '../../core/services/alert.service';
import { matchingValidator } from '../../shared/directives/matching-validator.directive';

@Component({
    selector: 'app-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

    public resetPasswordForm: FormGroup;
    public newResetPassword: any = {
        password: "",
        confirmationPassword: ""
    };
    public passwordRequest: PasswordRequest = new PasswordRequest();
    public passwordRequestExpired: boolean;

    constructor(private passwordRequestService: PasswordRequestService,
        private loadingService: LoadingService,
        private alertService: AlertService,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private toastr: ToastrService) {
            this.loadingService.showLoading();
    }

    ngOnInit() {
        this.getPasswordRequest();
        this.prepareResetPasswordForm();
    }

    getPasswordRequest() {
        this.passwordRequestService.getPasswordRequest(this.activatedRoute.snapshot.params.token).pipe(
            finalize(() => this.loadingService.dismissLoading())
        ).subscribe(passwordRequest => {
            this.passwordRequest = passwordRequest;
            const expiredAt = new Date(this.passwordRequest.expiredAt);
            const now = new Date();

            this.passwordRequestExpired = now > expiredAt;
        });
    }

    prepareResetPasswordForm() {
        this.resetPasswordForm = new FormGroup({
            password: new FormControl('', [Validators.required, Validators.minLength(6), matchingValidator('confirmPassword', true)]),
            confirmPassword: new FormControl('', [Validators.required, matchingValidator('password')] ),
        });
    }

    setNewResetPassword() {
        this.newResetPassword.password = this.resetPasswordForm.get('password').value;
        this.newResetPassword.confirmationPassword = this.resetPasswordForm.get('confirmPassword').value;
    }

    onResetPasswordFormSubmit() {
        this.resetPasswordForm['submitted'] = true;

        if (this.resetPasswordForm.valid) {
            this.loadingService.showLoading();
            this.setNewResetPassword();

            this.passwordRequestService.resetPassword(this.passwordRequest.token, this.newResetPassword).pipe(
                finalize(() => this.loadingService.dismissLoading())
            ).subscribe(passwordRequest => {
                this.toastr.success('Votre mot de passe a bien été modifié', 'Félicitations !');
                this.router.navigateByUrl('/');
            });
        }
    }

}
