import { Serializable } from '../interfaces/serializable';

import { Member } from './member.model';

export class Event implements Serializable {

  private _id: number = null;
  public title: string = "";
  public description: string = "";
  public date: Date = null;
  public member: Member = null;
  public circle: string = "";
  public post: string = "";
  public createdAt: Date = null;
  public updatedAt: Date = null;
  public deletedAt: Date = null;

  constructor() {}

  public getIRI() {
    return '/api/events/' + this._id;
  }

  public getId() {
    return this._id;
  } 

  public deserialize(input: any): this {
    if (!input) {
      return null;
    }

    Object.getOwnPropertyNames(this).forEach((property) => {
      switch (property) {
        case 'member':
          this[property] = new Member().deserialize(input[property]);
          break;
        default:
          if ('_id' == property && input.id) {
            this._id = input.id;
          } else if (property in input) {
            this[property] = input[property];
          }
          break;
      }
    });

    return this;
  }

  public serialize(): any {
    var output = {};

    Object.getOwnPropertyNames(this).forEach((property) => {
      switch (property) {
        case 'member':
          if (this[property]) {
            output[property] = this[property].getIRI();
          }
          break;
        default:
          if (property != '_id') {
            output[property] = this[property];
          }
          break;
      }
    });

    return output;
  }

}
