<div class="avatar d-inline-block align-middle rounded-circle" [ngClass]="{ 'avatar--tiny': size == 'tiny', 'avatar--small': size == 'small', 'avatar--big': size == 'big', 'under-surveillance': isUnderSurveillance, 'is-deleted': ((user && user.deletedAt) || memberDeleted), 'online': isOnline }">
    <div class="avatar__content rounded-circle bg-grey-85 border border-light-grey overflow-hidden w-100 h-100">
        <ng-container *ngIf="user">
            <div class="avatar__content__initials" *ngIf="!user.avatar">
                {{ user.firstName | initials }}{{ user.lastName | initials }}
            </div>
            <div class="avatar__content__image" [ngStyle]="{'background-image': 'url(' + (user.avatar.data ? user.avatar.data : user.avatar.getAbsolutePath()) + ')'}" *ngIf="user.avatar" aria-hidden="true"></div>
        </ng-container>
        <ng-container *ngIf="!user">
            <div class="avatar__content__default">
                <i class="far fa-user"></i>
            </div>
        </ng-container>
    </div>
</div>
