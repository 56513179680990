<div class="container py-8">
    <h1 class="text-center mb-8">Abonnement</h1>
    <div class="mx-lg-10 text-justify" *ngIf="subscription">
        Votre espace de stockage sera augmenté de 2 gigaoctets. 
        Votre abonnement sera maintenant au tarif de <b>{{ subscription.price + subscription.price / 2 }}€</b> par {{ subscription.frequency == 1 ? 'mois' : subscription.frequency == 3 ? 'trimestre' : 'an' }}. <br/>
        Etes-vous sûr(e) de vouloir augmenter votre espace de stockage ?
    </div>
    <button type="button" class="btn btn-primary ml-lg-10 mt-6" (click)="onUpdateStorage()">OK</button>
    <button type="button" class="btn btn-primary mt-6 ml-2" (click)="onCancel()">Annuler</button>
</div>
