import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Vote } from '../models/vote.model';
import { ApiService } from './api.service';
import { FeathersService } from './feathers.service';

@Injectable({
  providedIn: 'root'
})
export class VoteService {

  private _fsServiceName: string = "votes";
  private _createdVote: BehaviorSubject<Vote> = new BehaviorSubject<Vote>(null);

  constructor(private feathers: FeathersService,
    private apiService: ApiService) {
    this.setCreatedVoteEvent(); }

  onCreatedVote(): Observable<Vote> {
    return this._createdVote;
  }

  createVote(data: any): Observable<any> {
    return this.apiService.post(this._fsServiceName, data).pipe(
      map(vote => new Vote().deserialize(vote))
    );
  }

  setCreatedVoteEvent() {
    this.feathers.client.service(this._fsServiceName).on('created', vote => {
      this._createdVote.next(new Vote().deserialize(vote));
      //this._createdVote.next(null);
    });
  }
}
