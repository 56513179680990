import { Serializable } from '../interfaces/serializable';

import { Avatar } from './avatar.model';
import { User } from './user.model';

export class Member implements Serializable {

  static readonly STATUS_WAITING = 'STATUS_WAITING';
  static readonly STATUS_ENABLED = 'STATUS_ENABLED';
  static readonly STATUS_DELETED = 'STATUS_DELETED';

  public _id: number = null;
  public email: string = "";
  public isAdmin: boolean = false;
  public isUnderSurveillance: boolean = false;
  public status: string = Member.STATUS_WAITING;
  public user: User = null;
  public circle: string = "";
  public isOnVisio: boolean = false;
  public isInvited: boolean = false;
  public deletedAt: string = null;
  public selfLeft: boolean = null;

  constructor() {}

  public getIRI() {
    return '/api/members/' + this._id;
  }

  public getId() {
    return this._id;
  }

  public getName(): string {
    if (this.status == Member.STATUS_WAITING) {
        return this.email;
    }

    return this.user.firstName + ' ' + this.user.lastName;
  }

  public isWaiting(): boolean {
    return this.status == Member.STATUS_WAITING;
  }

  public deserialize(input: any) {
    if (!input) {
      return null;
    }

    Object.getOwnPropertyNames(this).forEach((property) => {
      switch (property) {
        case 'user':
          this[property] = new User().deserialize(input[property]);
          break;
        default:
          if ('_id' == property && input.id) {
            this._id = input.id;
          } else if (property in input) {
            this[property] = input[property];
          }
          break;
      }
    });

    return this;
  }

  public serialize(): any {
    var output = {};

    Object.getOwnPropertyNames(this).forEach((property) => {
      switch (property) {
        case 'user':
          output[property] = this[property] != null ? this[property].getIRI() : null;
          break;
        default:
          if (property != '_id') {
            output[property] = this[property];
          }
          break;
      }
    });

    return output;
  }
}
