import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-modal-archive-expired',
  templateUrl: './modal-archive-expired.component.html',
  styleUrls: ['./modal-archive-expired.component.scss']
})
export class ModalArchiveExpiredComponent implements OnInit {

  public confirmation: boolean = false;
  
  constructor(public bsModalRef: BsModalRef) { }

  ngOnInit() {
  }

  onConfirmation(confirmation: boolean) {
    this.confirmation = confirmation;
    this.bsModalRef.hide();
  }

}
