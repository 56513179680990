/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./modal-events.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "ngx-scroll-event/src/scroll.directive";
import * as i3 from "../events/events.component.ngfactory";
import * as i4 from "../events/events.component";
import * as i5 from "../../../core/services/circle.service";
import * as i6 from "../../../core/services/post.service";
import * as i7 from "../../../core/services/event.service";
import * as i8 from "@angular/router";
import * as i9 from "../../../core/services/appointment.service";
import * as i10 from "@angular/common";
import * as i11 from "./modal-events.component";
import * as i12 from "ngx-bootstrap/modal";
var styles_ModalEventsComponent = [i0.styles];
var RenderType_ModalEventsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ModalEventsComponent, data: {} });
export { RenderType_ModalEventsComponent as RenderType_ModalEventsComponent };
function View_ModalEventsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "modal-body"], ["detect-scroll", ""]], null, [[null, "onScroll"], [null, "scroll"], ["window", "scroll"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("scroll" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).scrolled($event) !== false);
        ad = (pd_0 && ad);
    } if (("window:scroll" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).windowScrolled($event) !== false);
        ad = (pd_1 && ad);
    } if (("onScroll" === en)) {
        var pd_2 = (_co.loadData($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 16384, null, 0, i2.ScrollDirective, [], { bottomOffset: [0, "bottomOffset"], topOffset: [1, "topOffset"] }, { onScroll: "onScroll" }), (_l()(), i1.ɵeld(2, 0, null, null, 1, "app-events", [], null, [[null, "onNavigationStart"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onNavigationStart" === en)) {
        var pd_0 = (_co.bsModalRef.hide() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_EventsComponent_0, i3.RenderType_EventsComponent)), i1.ɵdid(3, 770048, null, 0, i4.EventsComponent, [i5.CircleService, i6.PostService, i7.EventService, i8.Router, i9.AppointmentService], { circle: [0, "circle"], scrollEvent: [1, "scrollEvent"] }, { onNavigationStart: "onNavigationStart" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = 200; var currVal_1 = 200; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = _co.circle; var currVal_3 = _co.scrollEvent; _ck(_v, 3, 0, currVal_2, currVal_3); }, null); }
export function View_ModalEventsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h3", [["class", "modal-title text-uppercase"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Agenda"])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "button", [["aria-label", "Close"], ["class", "close pull-right"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.bsModalRef.hide() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "i", [["class", "fas fa-times"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModalEventsComponent_1)), i1.ɵdid(6, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.circle; _ck(_v, 6, 0, currVal_0); }, null); }
export function View_ModalEventsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-modal-events", [], null, null, null, View_ModalEventsComponent_0, RenderType_ModalEventsComponent)), i1.ɵdid(1, 114688, null, 0, i11.ModalEventsComponent, [i12.BsModalRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ModalEventsComponentNgFactory = i1.ɵccf("app-modal-events", i11.ModalEventsComponent, View_ModalEventsComponent_Host_0, {}, {}, []);
export { ModalEventsComponentNgFactory as ModalEventsComponentNgFactory };
