import { HttpHeaders } from '@angular/common/http';
import { Post } from '../models/post.model';
import { Comment } from '../models/comment.model';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { Reaction } from '../models/reaction.model';
import * as i0 from "@angular/core";
import * as i1 from "./feathers.service";
import * as i2 from "./circle.service";
import * as i3 from "./api.service";
import * as i4 from "@angular/common/http";
export class PostService {
    constructor(feathers, circleService, apiService, http) {
        this.feathers = feathers;
        this.circleService = circleService;
        this.apiService = apiService;
        this.http = http;
        this._fsServiceName = "posts";
        this._createdPost = new BehaviorSubject(null);
        this._updatedPost = new BehaviorSubject(null);
        this.setCreatedPostEvent();
        this.setUpdatedPostEvent();
    }
    onCreatedPost() {
        return this._createdPost.asObservable();
    }
    onUpdatedPost() {
        return this._updatedPost.asObservable();
    }
    createPost(data) {
        const accessToken = this.feathers.client.settings.accessToken;
        const httpOptions = {
            headers: new HttpHeaders({
                'authorization': accessToken
            })
        };
        return this.http.post(environment.fsAPI.url + '/' + this._fsServiceName, data, httpOptions).pipe(map(post => {
            return new Post().deserialize(post);
        }));
    }
    updatePost(data) {
        const accessToken = this.feathers.client.settings.accessToken;
        const httpOptions = {
            headers: new HttpHeaders({
                'authorization': accessToken
            })
        };
        return this.http.put(environment.fsAPI.url + '/' + this._fsServiceName + '/' + data.get('_id'), data, httpOptions).pipe(map(post => {
            return new Post().deserialize(post);
        }));
    }
    getPost(token) {
        const accessToken = this.feathers.client.settings.accessToken;
        const httpOptions = {
            headers: new HttpHeaders({
                'authorization': accessToken
            })
        };
        return this.http.get(environment.fsAPI.url + '/' + this._fsServiceName + '/' + token, httpOptions).pipe(map(post => {
            return new Post().deserialize(post);
        }));
    }
    deletePost(token) {
        return this.apiService.delete(this._fsServiceName, token).pipe(map(post => {
            return new Post().deserialize(post);
        }));
    }
    setCreatedPostEvent() {
        this.feathers.client.service(this._fsServiceName).on('created', post => {
            this._createdPost.next(new Post().deserialize(post));
        });
    }
    setUpdatedPostEvent() {
        this.feathers.client.service(this._fsServiceName).on('updated', post => {
            this._updatedPost.next(new Post().deserialize(post));
        });
    }
    getPostComments(token, params = {}) {
        const defaultParams = {
            query: {
                operationPath: '/comments'
            }
        };
        Object.assign(defaultParams.query, params);
        return this.apiService.get(this._fsServiceName, token, defaultParams).pipe(map(comments => {
            comments.forEach((value, index) => {
                comments[index] = new Comment().deserialize(value);
            });
            return comments;
        }));
    }
    getPostDeletedComments(token, params = {}) {
        const defaultParams = {
            query: {
                operationPath: '/deleted-comments'
            }
        };
        Object.assign(defaultParams.query, params);
        return this.apiService.get(this._fsServiceName, token, defaultParams);
    }
    getPostReactions(token, params = {}) {
        const defaultParams = {
            query: {
                operationPath: '/reactions'
            }
        };
        Object.assign(defaultParams.query, params);
        return this.apiService.get(this._fsServiceName, token, defaultParams).pipe(map(reactions => {
            reactions.forEach((value, index) => {
                reactions[index] = new Reaction().deserialize(value);
            });
            return reactions;
        }));
    }
}
PostService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PostService_Factory() { return new PostService(i0.ɵɵinject(i1.FeathersService), i0.ɵɵinject(i2.CircleService), i0.ɵɵinject(i3.ApiService), i0.ɵɵinject(i4.HttpClient)); }, token: PostService, providedIn: "root" });
