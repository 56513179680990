/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./medias.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/forms";
import * as i3 from "@angular/common";
import * as i4 from "../album/album.component.ngfactory";
import * as i5 from "../album/album.component";
import * as i6 from "../../../core/services/media.service";
import * as i7 from "../../../core/services/post.service";
import * as i8 from "ngx-bootstrap/modal";
import * as i9 from "../../../core/services/loading.service";
import * as i10 from "ngx-toastr";
import * as i11 from "../../../core/services/alert.service";
import * as i12 from "../../../core/services/circle.service";
import * as i13 from "./medias.component";
import * as i14 from "@angular/router";
var styles_MediasComponent = [i0.styles];
var RenderType_MediasComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MediasComponent, data: {} });
export { RenderType_MediasComponent as RenderType_MediasComponent };
function View_MediasComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "option", [], null, null, null, null, null)), i1.ɵdid(1, 147456, null, 0, i2.NgSelectOption, [i1.ElementRef, i1.Renderer2, [8, null]], { value: [0, "value"] }, null), i1.ɵdid(2, 147456, null, 0, i2.ɵangular_packages_forms_forms_y, [i1.ElementRef, i1.Renderer2, [8, null]], { value: [0, "value"] }, null), (_l()(), i1.ɵted(3, null, ["", ""]))], function (_ck, _v) { var currVal_0 = _v.context.$implicit.value; _ck(_v, 1, 0, currVal_0); var currVal_1 = _v.context.$implicit.value; _ck(_v, 2, 0, currVal_1); }, function (_ck, _v) { var currVal_2 = _v.context.$implicit.name; _ck(_v, 3, 0, currVal_2); }); }
function View_MediasComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "option", [], null, null, null, null, null)), i1.ɵdid(1, 147456, null, 0, i2.NgSelectOption, [i1.ElementRef, i1.Renderer2, [8, null]], { value: [0, "value"] }, null), i1.ɵdid(2, 147456, null, 0, i2.ɵangular_packages_forms_forms_y, [i1.ElementRef, i1.Renderer2, [8, null]], { value: [0, "value"] }, null), (_l()(), i1.ɵted(3, null, ["", ""]))], function (_ck, _v) { var currVal_0 = _v.context.$implicit.getId(); _ck(_v, 1, 0, currVal_0); var currVal_1 = _v.context.$implicit.getId(); _ck(_v, 2, 0, currVal_1); }, function (_ck, _v) { var currVal_2 = _v.context.$implicit.getName(); _ck(_v, 3, 0, currVal_2); }); }
function View_MediasComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "p", [["class", "mt-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "i", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Aucun m\u00E9dia publi\u00E9."]))], null, null); }
function View_MediasComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_MediasComponent_4)), i1.ɵdid(1, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.loadingAlbums; _ck(_v, 1, 0, currVal_0); }, null); }
function View_MediasComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "mb-5"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "app-album", [], null, null, null, i4.View_AlbumComponent_0, i4.RenderType_AlbumComponent)), i1.ɵdid(3, 114688, null, 0, i5.AlbumComponent, [i6.MediaService, i7.PostService, i8.BsModalService, i1.ChangeDetectorRef, i9.LoadingService, i10.ToastrService, i11.AlertService, i3.DatePipe, i12.CircleService], { post: [0, "post"], container: [1, "container"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit; var currVal_1 = _co.container; _ck(_v, 3, 0, currVal_0, currVal_1); }, null); }
function View_MediasComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "row mt-5"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "col-12"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MediasComponent_6)), i1.ɵdid(3, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.albums; _ck(_v, 3, 0, currVal_0); }, null); }
function View_MediasComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "row mt-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "col-12 text-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "spinner-border text-primary"], ["role", "status"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [["class", "sr-only"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Loading..."]))], null, null); }
export function View_MediasComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 22, "div", [["class", "medias container-fluid p-0"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 16, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 7, "div", [["class", "col-12 col-sm-6"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 6, "select", [["class", "w-100"], ["name", "type"]], null, [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.onTypeFilterChanged($event.target.value) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 3, "option", [["value", ""]], null, null, null, null, null)), i1.ɵdid(5, 147456, null, 0, i2.NgSelectOption, [i1.ElementRef, i1.Renderer2, [8, null]], { value: [0, "value"] }, null), i1.ɵdid(6, 147456, null, 0, i2.ɵangular_packages_forms_forms_y, [i1.ElementRef, i1.Renderer2, [8, null]], { value: [0, "value"] }, null), (_l()(), i1.ɵted(-1, null, ["Tous les types"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MediasComponent_1)), i1.ɵdid(9, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(10, 0, null, null, 7, "div", [["class", "col-12 col-sm-6 mt-3 mt-sm-0"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 6, "select", [["class", "w-100"], ["name", "member"]], null, [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.onMemberFilterChanged($event.target.value) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 3, "option", [["value", ""]], null, null, null, null, null)), i1.ɵdid(13, 147456, null, 0, i2.NgSelectOption, [i1.ElementRef, i1.Renderer2, [8, null]], { value: [0, "value"] }, null), i1.ɵdid(14, 147456, null, 0, i2.ɵangular_packages_forms_forms_y, [i1.ElementRef, i1.Renderer2, [8, null]], { value: [0, "value"] }, null), (_l()(), i1.ɵted(-1, null, ["Tous les membres"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MediasComponent_2)), i1.ɵdid(17, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(0, [["noMedias", 2]], null, 0, null, View_MediasComponent_3)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MediasComponent_5)), i1.ɵdid(20, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MediasComponent_7)), i1.ɵdid(22, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ""; _ck(_v, 5, 0, currVal_0); var currVal_1 = ""; _ck(_v, 6, 0, currVal_1); var currVal_2 = _co.typeFilters; _ck(_v, 9, 0, currVal_2); var currVal_3 = ""; _ck(_v, 13, 0, currVal_3); var currVal_4 = ""; _ck(_v, 14, 0, currVal_4); var currVal_5 = _co.memberFilters; _ck(_v, 17, 0, currVal_5); var currVal_6 = _co.albums.length; var currVal_7 = i1.ɵnov(_v, 18); _ck(_v, 20, 0, currVal_6, currVal_7); var currVal_8 = _co.loadingAlbums; _ck(_v, 22, 0, currVal_8); }, null); }
export function View_MediasComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-medias", [], null, null, null, View_MediasComponent_0, RenderType_MediasComponent)), i1.ɵdid(1, 770048, null, 0, i13.MediasComponent, [i7.PostService, i12.CircleService, i14.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MediasComponentNgFactory = i1.ɵccf("app-medias", i13.MediasComponent, View_MediasComponent_Host_0, { circle: "circle", scrollEvent: "scrollEvent", container: "container" }, { onNavigationStart: "onNavigationStart" }, []);
export { MediasComponentNgFactory as MediasComponentNgFactory };
