import { Serializable } from '../interfaces/serializable';

import { Member } from './member.model';
import { SubscriptionModel } from './subscription.model';

export class Circle implements Serializable {
  private _id: number = null;
  public name: string = "";
  public description: string = "";
  public nbMembers: number = 0;
  public token: string = "";
  public members: Member[] = [];
  public createdAt: Date = null;
  public updatedAt: Date = null;
  public deletedAt: Date = null;
  public subscription: SubscriptionModel = null;

  constructor() {}

  public getIRI() {
    return '/api/circles/' + this.token;
  }

  public isFull() {
    return this.nbMembers <= this.members.length;
  }

  public deserialize(input: any) {
    if (!input) {
      return null;
    }

    Object.getOwnPropertyNames(this).forEach((property) => {
      switch (property) {
        case 'members':
          input[property].forEach(member => {
            this[property].push(new Member().deserialize(member));
          });
          break;
        case 'subscription':
          this[property] = new SubscriptionModel().deserialize(input[property]);
          break;
        default:
          if ('_id' == property && input.id) {
            this._id = input.id;
          } else if (property in input) {
            this[property] = input[property];
          }
          break;
      }
    });

    return this;
  }

  public serialize(): any {
    var output = {};

    Object.getOwnPropertyNames(this).forEach((property) => {
      switch (property) {
        case 'members':
          output[property] = [];
          this[property].forEach(item => {
            output[property].push(
              item.getIRI()
            );
          });
          break;
        default:
          if (property != '_id') {
            output[property] = this[property];
          }
          break;
      }
    });

    return output;
  }
}
