<div class="container py-7">
    <div class="row justify-content-center">
        <div class="col-12 text-center">
            <a routerLink="/">
                <img src="../../assets/img/logo.svg" class="logo img-fluid" />
            </a>
            <h1 class="mt-6 mt-lg-8">
                Conditions Générales d’Utilisation des services de Cercle Confiance
            </h1>
            <div class="text-left mt-5 mt-lg-7">
                <p>
                    Les présentes conditions générales d’Utilisation (ci-après dénommées « les CGU ») ont pour objet de définir les modalités et les conditions dans lesquelles la société Cercle Confiance SAS met à disposition des utilisateurs le service accessible en ligne sur le site cercle-confiance.fr (ci-après dénommé « le site »).
                </p>
                <p>
                    La société Cercle Confiance est une Société par Actions Simplifiées au capital de 70 000€, immatriculée au RCS d’Orléans sous le numéro 817 644 305 dont le siège social est situé 1831 route de Mézières à Cléry-Saint-André dans le Loiret.
                </p>

                <h2 class="mt-4 mb-2">Article 1 : Description des services Cercle Confiance</h2>
                <p>
                    Le service principal permet aux utilisateurs de créer des modalités d’échange et de stockage de données dans un cercle restreint de personnes. Chaque cercle est défini autour d’une personne et associe d’autres personnes qui ont accepté une invitation et validé les présentes CGU.
                </p>
                <p>On distingue l’administrateur du cercle, qui a souscrit le service en payant un abonnement, et les invités. L’administrateur est obligatoirement un membre du cercle qu’il administre. Cette administration consiste notamment à créer le cercle, à supprimer des documents, à modifier ou supprimer les posts ou les commentaires ou à supprimer le cercle lui-même.</p>
                <p>L’administrateur a une responsabilité sur la légalité des données échangées et doit en modérer les contenus.</p>
                <p>L’administrateur peut créer autant de cercle qu’il le souhaite. Il souscrit un abonnement qui définit une capacité de stockage de données pour les cercles administrés. </p>
                <p>Les invités dans les cercles ne paient pas le service Cercle Confiance dont ils bénéficient. </p>
                
                <h2 class="mt-4 mb-2">Article 2 : Conditions d’éligibilité</h2>
                <p>
                    L’accès et l’utilisation des services de Cercle Confiance sont réservés aux personnes majeures. Dans l’hypothèse où l’utilisateur est mineur, il s’engage à recueillir l’autorisation préalable du (ou des) titulaires de l’autorité parentale pour utiliser les services. En cas de manquement au respect des CGU, les parents sont tenus responsables de l’activité de leurs enfants mineurs.
                </p>

                <h2 class="mt-4 mb-2">Article 3 : Conditions d’utilisation des services Cercle Confiance</h2>
                <p>
                    Pour créer un compte Cercle Confiance, l’administrateur doit ouvrir un compte et souscrire un abonnement via le site.
                </p>
                <p>La création d’un compte exige une adresse mail et un mot de passe.</p>
                <p>Lorsqu’une personne est invitée à rejoindre un cercle, elle reçoit un mail d’invitation, et crée un compte avec le mail d’invitation et un mot de passe qu’elle définit. Elle doit obligatoirement valider les présentes CGU.</p>
                <p>L’utilisation des services de Cercle Confiance nécessite un ordinateur, ou une tablette ou un téléphone de type smartphone dotés d’un navigateur et une connexion internet supérieure à 512 kbits.</p>
                <p>Tout utilisateur reconnaît comprendre internet et ses limites techniques, en particulier les variations de débit et les interruptions, les risques inhérents aux transferts de données sur le réseau ouvert.</p>
                
                <h2 class="mt-4 mb-2">Article 4 : Tarif, durée et facturation du service</h2>
                <p>Les coûts de connexion internet ne sont pas compris dans le service, et doivent être souscrits auprès d’un opérateur.</p>
                <p>L’administrateur souscrit sur le site un pack comprenant un volume de données stockables. La facturation et le paiement sont mensuels. Le pack est souscrit pour une durée indéterminée assortie d’une période minimale d’un mois.</p>
                
                <h2 class="mt-4 mb-2">Article 5 : Résiliation de l’abonnement à Cercle Confiance</h2>
                <p>La résiliation de l’abonnement est possible à tout moment à travers l’onglet « Mon compte » « Arrêter mon abonnement ». La résiliation prend effet à la prochaine date de facturation de l’administrateur. Tout mois entamé est donc dû.</p>
                <p>Tous les membres des cercles sont avertis de la résiliation et peuvent demander la récupération de leurs données stockées en tant que documents ou conversations dans un délai de 30 jours après la suppression du cercle ou l’arrêt de l’abonnement de l’administrateur. </p>
                <p>Dans le mois suivant la résiliation effective, toutes les données (documents, agenda, conversations du mur) sont détruites définitivement.</p>
                
                <h2 class="mt-4 mb-2">Article 6 : Obligations et responsabilité de l’administrateur</h2>
                <p>L’administrateur a une forte responsabilité vis-à-vis des personnes membres d’un cercle confiance, en particulier celles qui sont au centre des cercles. En effet, il lui revient d’inviter les membres du cercle, qu’il peut aussi exclure. L’objectif d’un cercle est de créer une confiance entre les membres, permettant un degré de confidentialité fort sur les contenus. Il serait contraire à l’objectif du service d’inviter des personnes pouvant mettre en cause cette confiance, créer un sentiment de surveillance inadapté, troubler la sérénité et la nature des échanges d’informations.</p>
                <p>L’administrateur a la possibilité de supprimer les documents ou les commentaires de l’ensemble des membres du cercle qu’il administre. Il partage donc une responsabilité avec tous les membres d’un cercle, pour la modération des contenus et des échanges qui doivent respecter la loi.
                <p>L’administrateur s’engage à payer son abonnement et ne saurait tenir Cercle Confiance responsable des conséquences d’arrêt des services pour défaut de paiement. Il lui revient en particulier d’anticiper l’obsolescence de ses moyens de paiement.</p>

                <h2 class="mt-4 mb-2">Article 7 : Obligations et responsabilités des utilisateurs de Cercle Confiance</h2>
                <p>Toute utilisation commerciale du service doit faire l’objet d’un accord explicite de Cercle Confiance, à travers un échange de mail sur <a href="mailto:contact@cercle-confiance.fr">contact@cercle-confiance.fr</a></p>
                <p>Les utilisateurs individuels sont responsables de la qualité et de la confidentialité de leur mot de passe.</p>
                <p>Les utilisateurs s’engagent à vérifier de manière raisonnable que les contenus qu’ils utilisent ne contiennent pas de virus ou de programmes malveillants susceptibles de perturber le fonctionnement du service.</p>
                <p>L’esprit du service Cercle Confiance est de préserver la confidentialité des données entre les membres d’un cercle. Il n’est donc pas souhaitable de diffuser les données échangées en dehors des membres du cercle sans l’accord de ces derniers.</p>
                <p>
                    L’utilisateur s’engage à ne mettre en ligne que des contenus strictement conformes à la loi. L’utilisateur s’engage notamment, sans que cette liste puisse être considérée comme limitative :
                </p>
                <ul>
                    <li>A n’utiliser aucun contenu et à ne diffuser aucun message ou information à caractère injurieux, diffamatoire, raciste, xénophobe, révisionniste, portant atteinte à l’honneur ou à la réputation d’autrui, incitant à la discrimination, à la haine d’une personne ou d’un groupe de personnes en raison de leur origine ou de leur appartenance à une ethnie, une nation ou une religion, à caractère pornographique ou pédophile, incitant à commettre un délit, un crime ou un acte de terrorisme, portant atteinte aux droits d’autrui ou à la sécurité des personnes et des biens</li>
                    <li>A respecter les droits d’autrui, et notamment : les droits à la personnalité (tels que droit à l’image, droit au respect de la vie privée), les droits des marques, les droits d’auteur, d’une manière générale, les droits des personnes et des biens</li>
                    <li>Plus généralement, l’utilisateur garantit que ses contenus sont conformes à la réglementation en vigueur et ne portent pas atteinte aux droits d’autrui</li>
                </ul>
                <p>L’utilisateur reconnait que les contenus considérés comme contrevenant aux lois, règles ou réglementations en vigueur pourront être remis aux autorités chargées de faire respecter la loi qui le requerraient. </p>
                <p>Le cas échéant, il revient à l’utilisateur de demander le rapatriement des données qu’il a stockées comme documents dans un cercle dont l’administrateur l’informe de la suppression.</p>
                <p>Lorsqu’un utilisateur invité souhaite quitter Cercle Confiance, il lui est recommandé de récupérer ses données, à travers la procédure adéquate.</p>
                <p>Les utilisateurs peuvent supprimer ou modifier les conversations qu’ils ont créées, supprimer les documents qu’ils ont déposés.</p>

                <h2 class="mt-4 mb-2">Article 8 : Obligations et responsabilité de Cercle Confiance SAS</h2>
                <p>La responsabilité de Cercle Confiance ne peut être engagée que pour des fautes établies qui lui seraient imputables.</p>
                <p>Cercle Confiance met en place les moyens nécessaires au bon fonctionnement des services. Elle met en œuvre les moyens pour assurer un hébergement sécurisé des espaces de stockage et des contenus contre toute intrusion malveillante.</p>
                <p>Cercle Confiance met en œuvre les moyens nécessaires pour assurer la maintenance et la continuité des services.</p>
                <p>Cercle Confiance se réserve, pour des raisons de maintenance, le droit de suspendre momentanément et en cas d’urgence sans préavis, l’accès aux services ou à certaines fonctions.</p>
                <p>Cercle Confiance fournit une assistance par courrier électronique, en contactant l’adresse suivante : <a href="mailto:support@cercle-confiance.fr">support@cercle-confiance.fr</a></p>
                <p>Cercle Confiance met à disposition une procédure permettant à l’utilisateur de supprimer les données qu’il a postées. Cette possibilité cesse si un invité est exclu par l’administrateur du cercle.</p>
                <p>
                    Sauf pour le cas de manquement à ses obligations ci-dessous énoncées, Cercle Confiance ne saurait voir sa responsabilité engagée notamment pour :
                </p>
                <ul>
                    <li>Les difficultés d’accès au service du fait des difficultés de connexion liées au réseau de télécommunication et au réseau internet </li>
                    <li>La contamination par virus des données et/ou des logiciels de l’utilisateur dont la protection lui incombe </li>
                    <li>Les intrusions malveillantes de tiers malgré les mesures de sécurité mises en place par Cercle Confiance</li>
                    <li>La mauvaise utilisation du service et de son espace de stockage par l'utilisateur</li>
                    <li>Les dommages que pourraient subir les équipements de l’utilisateur, ceux-ci étant sous l’entière responsabilité de l'utilisateur</li>
                    <li>Le détournement éventuel de mots de passe, et plus généralement de toute information à caractère sensible pour l’utilisateur, ceux-ci étant sous l’entière responsabilité de l’utilisateur.</li>
                </ul>
                <p>Les éventuelles réparations des dommages à l’utilisateur dues par Cercle Confiance qui résulteraient d’une faute dûment établie à son encontre correspondront au préjudice direct, personnel et certain lié à la défaillance en cause, à l’exclusion expresse des dommages indirects.</p>
                <p>L’utilisateur décharge et garantit Cercle Confiance de toute responsabilité en ce qui concerne l’utilisation du service et les contenus utilisés de quelque manière que ce soit.</p>
                
                <h2 class="mt-4 mb-2">Article 9 : Non-respect des CGU</h2>
                <p>En cas de non-respect avéré des CGU, Cercle Confiance se réserve le droit de suspendre de manière temporaire ou définitive l’accès au service, le temps de recueillir la résiliation du service, le réabonnement, le paiement du service ou la mise en conformité des contenus.</p>
                <p>En cohérence, et même sans résiliation explicite, Cercle Confiance se réserve le droit de supprimer un compte administrateur et par conséquent l’ensemble des cercles qui y sont liés, en cas de non-paiement de l’abonnement ou de manquement aux CGU. Cette interruption se fera dans un délai de quinze jours après l’envoi d’un message électronique signalant ce manquement au CGU et ne donnera lieu à aucune indemnité. Tous les membres des cercles concernés recevront un message leur donnant la procédure pour récupérer leurs données.</p>
                
                <h2 class="mt-4 mb-2">Article 10 : Evolutions</h2>
                <p>Cercle Confiance se réserve le droit de faire évoluer les services, notamment en mettant à disposition de nouvelles fonctionnalités, ou en modifiant ou supprimant certaines fonctionnalités.</p>
                
                <h2 class="mt-4 mb-2">Article 11 : Données personnelles</h2>
                <p>Par le biais du service, Cercle Confiance traite les données de l’utilisateur en exécution des CGU acceptées lors de la création du compte.</p>
                <p>Lorsqu’il s’agit de la création d’un compte personnel, ces données sont les suivantes :</p>
                <ul>
                    <li>Données obligatoires : adresse mail, prénom, nom</li>
                    <li>Données facultatives que l’utilisateur choisit de renseigner : photo</li>
                </ul>
                <p>Cercle Confiance collecte des données techniques (logs, adresses IP…), afin de répondre à ses obligations légales. Les utilisateurs sont informés que conformément aux lois et réglementations en vigueur, les données peuvent être transmises aux autorités compétentes, à leur demande, en leur qualité de tiers autorisés.</p>
                <p>Cercle Confiance peut utiliser le mail de l’utilisateur pour communiquer sur les services proposés, en particulier l’avertir sur les procédures de résiliation, de récupération des données stockées en cas de suppression d’un cercle, ou l’informer en cas de non paiement des services ou de non respect des CGU.</p>
                <p>De plus, lorsque Cercle Confiance met en œuvre un traitement de données personnelles reposant sur le consentement, l’utilisateur peut retirer son consentement à tout moment en sortant des services de « Cercle Confiance ».</p>
                <p>Les données personnelles sont traitées tant que le compte de l’administrateur est actif. En cas de désactivation, notamment lorsque l’administrateur résilie son compte, les données de tous les membres des cercles concernés sont supprimées, après les avoir prévenus, dans un délai d’un mois.</p>
                <p>Les utilisateurs peuvent exercer leur droit d’accès, de rectification et d’effacement de leurs données personnelles à tout moment. Pour exercer ce droit, ils peuvent modifier leur profil directement sur le site, ou écrire à Cercle Confiance sur <a href="mailto:support@cercle-confiance.fr">support@cercle-confiance.fr</a></p>
                <p>L’utilisateur est informé qu’il a le droit d’introduire une demande auprès de la Commission Nationale de l’Informatique et des Libertés (CNIL), autorité de contrôle en charge du respect des obligations en matière de données à caractère personnel en France. Cercle Confiance encourage l’utilisateur à prendre contact avec elle avant toute demande, pour essayer de la résoudre.</p>
                <p>L’utilisateur peut contacter le délégué à la protection des données de Cercle Confiance pour des questions relatives à la protection des données personnelles :</p>
                <p>Par e-mail : <a href="mailto:dpo@cercle-confiance.fr">dpo@cercle-confiance.fr</a></p>
                <p>Par voie postale : Cercle Confiance, 1831 route de Mézières, 45370 Cléry-Saint-André</p>
                <br/>
                <p class="small">Mis à jour le 27 septembre 2021</p>
            </div>
        </div>
    </div>
</div>
