<div class="modal-header">
    <h3 class="modal-title text-uppercase">Archive expirée</h3>
    <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
        <i class="fas fa-times"></i>
    </button>
</div>
<div class="modal-body">
    <h6 class="mb-4">Votre archive a expiré. Voulez-vous en générer une nouvelle ?</h6>
    <div class="mb-8 small">
        La génération de l'archive peut prendre quelques minutes. Vous recevrez un mail quand celle-ci sera prête.
    </div>
    <div class="float-right mb-4">
        <button type="button" class="btn btn-outline-secondary btn--small py-2 px-3 mr-2" (click)="onConfirmation(false)">Annuler</button>
        <button type="button" class="btn btn-primary btn--small py-2 px-3" (click)="onConfirmation(true)">OK</button>
    </div>
</div>
