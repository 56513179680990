<ng-template [ngIf]="!hasSubscription" [ngIfElse]="alreadySubscribed">
    <form id="paymentForm" class="ml-4 ml-lg-6 mt-8" ngNoForm method="POST" action="https://sogecommerce.societegenerale.eu/vads-payment/" target="subscriptionFrame">
        <h3>Choisir votre abonnement</h3>
            <div class="form-group ml-10">
                <div class="form-check form-check-radio">
                    <input class="form-check-input" type="radio" [formControl]="frequency" name="frequency" id="frequency-1" value="1">
                    <label class="form-check-label" for="frequency-1">
                        <span>Mensuel à 12€</span>
                    </label>
                </div>
                <div class="form-check form-check-radio">
                    <input class="form-check-input" type="radio" [formControl]="frequency" name="frequency" id="frequency-3" value="3">
                    <label class="form-check-label" for="frequency-3">
                        <span>Trimestriel à 30€</span>
                    </label>
                </div>
                <div class="form-check form-check-radio">
                    <input class="form-check-input" type="radio" [formControl]="frequency" name="frequency" id="frequency-12" value="12">
                    <label class="form-check-label" for="frequency-12">
                        <span>Annuel à 120€</span>
                    </label>
                </div>
            </div>
        <input type="hidden" name="vads_action_mode" value="IFRAME" />
        <input type="hidden" name="vads_amount" [formControl]="vads_sub_amount" />
        <input type="hidden" name="vads_ctx_mode" [formControl]="vads_ctx_mode" />
        <input type="hidden" name="vads_currency" value="978" />
        <input type="hidden" name="vads_cust_email" value="{{ me.email }}" />
        <input type="hidden" name="vads_ext_info_subscription_id" [formControl]="subscription_id" />
        <input type="hidden" name="vads_page_action" value="REGISTER_SUBSCRIBE" />
        <input type="hidden" name="vads_payment_config" value="SINGLE" />
        <input type="hidden" name="vads_redirect_error_timeout" value="0" />
        <input type="hidden" name="vads_redirect_success_timeout" value="0" />
        <input type="hidden" name="vads_return_mode" value="GET" />
        <input type="hidden" name="vads_site_id" value="64936260" />
        <input type="hidden" name="vads_sub_amount" [formControl]="vads_sub_amount" />
        <input type="hidden" name="vads_sub_currency" value="978" />
        <input type="hidden" name="vads_sub_desc" [formControl]="vads_sub_desc" />
        <input type="hidden" name="vads_sub_effect_date" [formControl]="vads_sub_effect_date" />
        <input type="hidden" name="vads_theme_config" value="3DS_LOGOS=false;FORM_TARGET=_parent" />
        <input type="hidden" name="vads_trans_date" [formControl]="vads_trans_date" />
        <input type="hidden" name="vads_trans_id" [formControl]="vads_trans_id" />
        <input type="hidden" name="vads_url_return" value="{{ resultUrl }}" />
        <input type="hidden" name="vads_url_success" value="{{ resultUrl }}" />
        <input type="hidden" name="vads_version" value="V2" />
        <input type="hidden" name="signature" [formControl]="signature" />
        <input id="pay-button" class="btn btn-primary" type="submit" name="souscrire" value="Souscrire" />
    </form>
</ng-template>
<ng-template #alreadySubscribed>
    <div class="ml-2 ml-lg-10 mt-8">Vous avez déjà un abonnement en cours.</div>
</ng-template>
