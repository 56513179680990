import { Serializable } from '../interfaces/serializable';

export class PasswordRequest implements Serializable {

  private _id: number = null;
  public token: string = "";
  public expiredAt: Date = null;

  constructor() {}

  public getIRI() {
    return '/api/password-requests/' + this._id;
  }

  public deserialize(input: any): this {
    if (!input) {
      return null;
    }

    Object.getOwnPropertyNames(this).forEach((property) => {
      if ('_id' == property && input.id) {
        this._id = input.id;
      } else if (property in input) {
        this[property] = input[property];
      }
    });

    return this;
  }

  public serialize(): any {
    var output = {};

    Object.getOwnPropertyNames(this).forEach((property) => {
      if (property != '_id') {
        output[property] = this[property];
      }
    });

    return output;
  }
}
