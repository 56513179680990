<form id="updateForm" class="ml-4 ml-lg-6 mt-8" ngNoForm method="POST" action="https://sogecommerce.societegenerale.eu/vads-payment/" target="subscriptionFrame">
    <input type="hidden" name="vads_action_mode" value="IFRAME" />
    <input type="hidden" name="vads_ctx_mode" [formControl]="vads_ctx_mode" />
    <input type="hidden" name="vads_currency" value="978" />
    <input type="hidden" name="vads_cust_email" value="{{ me.email }}" />
    <input type="hidden" name="vads_identifier" [formControl]="vads_identifier" />
    <input type="hidden" name="vads_page_action" value="REGISTER_UPDATE" />
    <input type="hidden" name="vads_redirect_error_timeout" value="0" />
    <input type="hidden" name="vads_redirect_success_timeout" value="0" />
    <input type="hidden" name="vads_return_mode" value="GET" />
    <input type="hidden" name="vads_site_id" value="64936260" />
    <input type="hidden" name="vads_theme_config" value="3DS_LOGOS=false;FORM_TARGET=_parent" />
    <input type="hidden" name="vads_trans_date" [formControl]="vads_trans_date" />
    <input type="hidden" name="vads_url_return" value="{{ resultUrl }}" />
    <input type="hidden" name="vads_url_success" value="{{ resultUrl }}" />
    <input type="hidden" name="vads_version" value="V2" />
    <input type="hidden" name="signature" [formControl]="signature" />
    <div>
        Etes-vous sûr(e) de vouloir mettre à jour votre moyen de paiement ?
        <input id="pay-button" class="btn-primary ml-4 py-2 px-2" type="submit" name="payer" value="OK" />
        <button type="button" class="btn-primary ml-2 py-2 px-2" (click)="onCancel()">Annuler</button>
    </div>
</form>
