<div class="app-content" [ngClass]="{ 'fullscreen': isFullscreen, 'bg-white mb-12 mb-lg-0 app-content-sidebar': !hideHeader, 'swiped-header': swipedHeader }">
    <app-header *ngIf="!hideHeader"></app-header>
    <main>
        <alert [type]="alert.type" *ngIf="alert" [dismissible]="true" (onClosed)="onAlertClosed()">
          <ul class="mb-0 list-unstyled">
            <li *ngFor="let message of alert.messages">{{ message }}</li>
          </ul>
        </alert>
        <router-outlet></router-outlet>
    </main>
    <app-footer *ngIf="!hideFooter"></app-footer>
</div>
<ngx-loading [show]="loading"></ngx-loading>
