import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, CanLoad, Route, Router, UrlSegment, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of, throwError } from 'rxjs';
import { switchMap, catchError } from 'rxjs/operators';

import { CircleService } from '../services/circle.service';
import { Member } from '../models/member.model';

@Injectable({
  providedIn: 'root'
})
export class CircleGuard implements CanActivate, CanActivateChild, CanLoad {

  constructor(private circle: CircleService, private router: Router) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      if (!next.params.token) {
        return of(false);
      }

      return this.circle.getCircle(next.params.token).pipe(
        switchMap(response => of(true)),
        catchError(error => {
          this.router.navigate(['cercles']);
          return of(false);
        })
      );
  }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        if (!next.data.adminAccess) {
          return of(true);
        }

        return this.circle.getCircleMembersMe(next.parent.params.token).pipe(
            switchMap(member => member.isAdmin ? of(true) : throwError('Not admin')),
            catchError(error => {
                this.router.navigate(['cercles', next.parent.params.token]);
                return of(false);
            })
        );
  }

  canLoad(
    route: Route,
    segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    return true;
  }
}
