/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./modal-online-users.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "../../../shared/components/user-avatar/user-avatar.component.ngfactory";
import * as i5 from "../../../shared/components/user-avatar/user-avatar.component";
import * as i6 from "./modal-online-users.component";
import * as i7 from "ngx-bootstrap/modal";
var styles_ModalOnlineUsersComponent = [i0.styles];
var RenderType_ModalOnlineUsersComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ModalOnlineUsersComponent, data: {} });
export { RenderType_ModalOnlineUsersComponent as RenderType_ModalOnlineUsersComponent };
function View_ModalOnlineUsersComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "small text-primary d-block font-weight-normal"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Centre du cercle"]))], null, null); }
function View_ModalOnlineUsersComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 9, "div", [["class", "col-9 pr-0"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 8, "a", [], [[8, "title", 0], [1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(3, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(4, 2), (_l()(), i1.ɵeld(5, 0, null, null, 1, "app-user-avatar", [], null, null, null, i4.View_UserAvatarComponent_0, i4.RenderType_UserAvatarComponent)), i1.ɵdid(6, 114688, null, 0, i5.UserAvatarComponent, [], { user: [0, "user"], isUnderSurveillance: [1, "isUnderSurveillance"], isOnline: [2, "isOnline"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 3, "span", [["class", "name d-inline-block align-middle ml-2 font-weight-bold"]], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, [" ", " "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModalOnlineUsersComponent_5)), i1.ɵdid(10, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_3 = _ck(_v, 4, 0, "/profil", _v.parent.parent.context.$implicit.user.token); _ck(_v, 3, 0, currVal_3); var currVal_4 = _v.parent.parent.context.$implicit.user; var currVal_5 = _v.parent.parent.context.$implicit.isUnderSurveillance; var currVal_6 = true; _ck(_v, 6, 0, currVal_4, currVal_5, currVal_6); var currVal_8 = _v.parent.parent.context.$implicit.isUnderSurveillance; _ck(_v, 10, 0, currVal_8); }, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "", _v.parent.parent.context.$implicit.getName(), ""); var currVal_1 = i1.ɵnov(_v, 3).target; var currVal_2 = i1.ɵnov(_v, 3).href; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); var currVal_7 = _v.parent.parent.context.$implicit.getName(); _ck(_v, 8, 0, currVal_7); }); }
function View_ModalOnlineUsersComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "row mb-3"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModalOnlineUsersComponent_4)), i1.ɵdid(2, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_0 = !_v.parent.context.$implicit.isWaiting(); _ck(_v, 2, 0, currVal_0); }, null); }
function View_ModalOnlineUsersComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModalOnlineUsersComponent_3)), i1.ɵdid(2, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = !_v.context.$implicit.deletedAt; _ck(_v, 2, 0, currVal_0); }, null); }
function View_ModalOnlineUsersComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "container-fluid p-0"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModalOnlineUsersComponent_2)), i1.ɵdid(3, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.onlineMembers; _ck(_v, 3, 0, currVal_0); }, null); }
export function View_ModalOnlineUsersComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h3", [["class", "modal-title text-uppercase"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Membres en ligne"])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "button", [["aria-label", "Close"], ["class", "close pull-right"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.bsModalRef.hide() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "i", [["class", "fas fa-times"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModalOnlineUsersComponent_1)), i1.ɵdid(6, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.onlineMembers; _ck(_v, 6, 0, currVal_0); }, null); }
export function View_ModalOnlineUsersComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-modal-online-users", [], null, null, null, View_ModalOnlineUsersComponent_0, RenderType_ModalOnlineUsersComponent)), i1.ɵdid(1, 114688, null, 0, i6.ModalOnlineUsersComponent, [i7.BsModalRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ModalOnlineUsersComponentNgFactory = i1.ɵccf("app-modal-online-users", i6.ModalOnlineUsersComponent, View_ModalOnlineUsersComponent_Host_0, {}, {}, []);
export { ModalOnlineUsersComponentNgFactory as ModalOnlineUsersComponentNgFactory };
