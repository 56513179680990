import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { finalize } from 'rxjs/operators';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Proposal } from '../../../core/models/proposal.model';
import { Appointment } from '../../../core/models/appointment.model';
import { ProposalService } from '../../../core/services/proposal.service';
import { LoadingService } from '../../../core/services/loading.service';
import { AlertService } from '../../../core/services/alert.service';

@Component({
  selector: 'app-modal-add-proposal',
  templateUrl: './modal-add-proposal.component.html',
  styleUrls: ['./modal-add-proposal.component.scss']
})
export class ModalAddProposalComponent implements OnInit {

  public appointment: Appointment = new Appointment();
  public proposalForm: FormGroup;
  public newProposal: Proposal = new Proposal();
  public today: Date = new Date();

  constructor(public bsModalRef: BsModalRef,
    private localeService: BsLocaleService,
    private datePipe: DatePipe,
    private proposalService: ProposalService,
    private loadingService: LoadingService,
    private alertService: AlertService) { }

  ngOnInit() {
    this.prepareProposalForm();
  }

  prepareProposalForm() {
    this.localeService.use('fr');
    this.proposalForm = new FormGroup({
        date: new FormControl('', Validators.required),
        time: new FormControl(''),
    });
  }

  setNewProposal() {
    this.newProposal.appointment = this.appointment.getIRI();
    this.newProposal.isCounterProposal = true;

    const dateForm = this.proposalForm.get('date').value;
    const date = new Date();
    date.setDate(dateForm.getDate());
    date.setMonth(dateForm.getMonth());
    date.setFullYear(dateForm.getFullYear());
    const time = this.proposalForm.get('time').value;
    const hours = time ? time.getHours() : 0;
    const minutes = time ? time.getMinutes() : 0;
    date.setHours(hours);
    date.setMinutes(minutes);
    date.setSeconds(0);
    this.newProposal.date = this.datePipe.transform(date, 'yyyy-MM-dd HH:mm:ss');
  }

  onProposalFormSubmit() {
    this.proposalForm['submitted'] = true;
    if (this.proposalForm.valid) {
      this.setNewProposal();
      this.loadingService.showLoading();
      this.proposalService.createProposal(this.newProposal).pipe(
        finalize(() => this.loadingService.dismissLoading())
      ).subscribe(proposal => {
        this.proposalForm['submitted'] = false;
        this.proposalForm.reset();
        this.bsModalRef.hide();
        this.alertService.closeAlert();
      }, error => {
        console.log(error)
        this.alertService.showAlert(error.errors, 'danger');
      });
    }
  }

}
