/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./modal-information.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./modal-information.component";
import * as i3 from "ngx-bootstrap/modal";
var styles_ModalInformationComponent = [i0.styles];
var RenderType_ModalInformationComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ModalInformationComponent, data: {} });
export { RenderType_ModalInformationComponent as RenderType_ModalInformationComponent };
export function View_ModalInformationComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h3", [["class", "modal-title text-uppercase"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Information"])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "button", [["aria-label", "Close"], ["class", "close pull-right"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.bsModalRef.hide() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "i", [["class", "fas fa-times"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 9, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "div", [["class", "mb-2"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Vous ne faites plus partie de ce cercle. "])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "div", [["class", "mb-8"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Un mail vous a \u00E9t\u00E9 envoy\u00E9 afin de vous permettre de t\u00E9l\u00E9charger les donn\u00E9es que vous y avez enregistr\u00E9es. "])), (_l()(), i1.ɵeld(10, 0, null, null, 1, "div", [["class", "small"]], null, null, null, null, null)), (_l()(), i1.ɵted(11, null, ["Attention : il vous reste ", " ", " pour les t\u00E9l\u00E9charger"])), (_l()(), i1.ɵeld(12, 0, null, null, 2, "div", [["class", "float-right mb-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 1, "button", [["class", "btn btn-primary btn--small py-2 px-3"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.bsModalRef.hide() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["OK"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.nbDays; var currVal_1 = ((_co.nbDays > 1) ? "jours" : "jour"); _ck(_v, 11, 0, currVal_0, currVal_1); }); }
export function View_ModalInformationComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-modal-information", [], null, null, null, View_ModalInformationComponent_0, RenderType_ModalInformationComponent)), i1.ɵdid(1, 114688, null, 0, i2.ModalInformationComponent, [i3.BsModalRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ModalInformationComponentNgFactory = i1.ɵccf("app-modal-information", i2.ModalInformationComponent, View_ModalInformationComponent_Host_0, {}, {}, []);
export { ModalInformationComponentNgFactory as ModalInformationComponentNgFactory };
