import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap';
import { Member } from 'src/app/core/models/member.model';

@Component({
  selector: 'app-modal-online-users',
  templateUrl: './modal-online-users.component.html',
  styleUrls: ['./modal-online-users.component.scss']
})
export class ModalOnlineUsersComponent implements OnInit {

  public onlineMembers: Member[] = [];

  constructor(public bsModalRef: BsModalRef) { }

  ngOnInit() {
  }

}
