import { Serializable } from '../interfaces/serializable';

import { Circle } from './circle.model';
import { Member } from './member.model';
import { Proposal } from './proposal.model';

export class Appointment implements Serializable {
    static readonly VISIO = 'VISIO';
    static readonly FACE_TO_FACE = 'FACE_TO_FACE';
    
    private _id: number = null;
    public subject: string = "";
    public meetingPlace: string = "";
    public finalChoice: string = null;
    public member: Member = null;
    public circle: Circle = null;
    public proposals: Proposal[] = [];
    public createdAt: Date = null;
    public updatedAt: Date = null;
    public deletedAt: Date = null;

    constructor() {}

    public getIRI() {
        return '/api/appointments/' + this._id;
    }

    public getId() {
        return this._id;
    } 

    public deserialize(input: any): this {
        if (!input) {
        return null;
        }

        Object.getOwnPropertyNames(this).forEach((property) => {
        switch (property) {
            case 'member':
                this[property] = new Member().deserialize(input[property]);
            break;
            case 'circle':
                this[property] = new Circle().deserialize(input[property]);
            break;
            case 'proposals':
                input[property].forEach(proposal => {
                    this[property].push(new Proposal().deserialize(proposal));
                });
            break;
            default:
            if ('_id' == property && input.id) {
                this._id = input.id;
            } else if (property in input) {
                this[property] = input[property];
            }
            break;
        }
        });

        return this;
    }

    public serialize(): any {
        var output = {};

        Object.getOwnPropertyNames(this).forEach((property) => {
        switch (property) {
            case 'member':
            case 'circle':
                if (this[property]) {
                    output[property] = this[property].getIRI();
                }
            break;
            case 'proposals':
                output[property] = [];
                this[property].forEach(item => {
                    output[property].push(
                    item.getIRI()
                    );
                });
            break;
            default:
            if (property != '_id') {
                output[property] = this[property];
            }
            break;
        }
        });

        return output;
    }
}
