<header class="header px-lg-3" [ngClass]="{'swiped': swipedHeader}">
    <nav class="navbar navbar-expand-lg">
        <button type="button" class="d-lg-none" (click)="sidebarIsActive = !sidebarIsActive">
            <i class="fas fa-bars"></i>
        </button>
        <a class="navbar-brand" routerLink="/">
            <img src="../../assets/img/logo.svg" class="img-fluid" />
        </a>
        <div class="collapse justify-content-center navbar-collapse" id="navbarNav" [ngClass]="{ 'navbar-centered': !connectedUser}">
            <ul class="navbar-nav" *ngIf="!connectedUser || circlesPage">
                <li class="nav-item">
                    <a class="nav-link" [routerLink]="['/a-propos']" routerLinkActive="active">A propos</a>
                </li>
                <li class="nav-item">
                    <span class="nav-link text-grey-85">Objects connectés</span>
                </li>
                <li class="nav-item">
                    <span class="nav-link text-grey-85">Services</span>
                </li>
            </ul>
            <ul class="navbar-nav" *ngIf="connectedUser && !circlesPage">
                <li class="nav-item">
                    <a class="nav-link" [routerLink]="['/cercles']" routerLinkActive="active">Mes cercles</a>
                </li>
                <li class="nav-item">
                    <span class="nav-link text-grey-85">Mes objects connectés</span>
                </li>
            </ul>
        </div>
        <div class="dropdown" *ngIf="connectedUser">
            <button class="dropdown-toggle" type="button" id="dropdownMenuConnectedUserButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <app-user-avatar [user]="connectedUser"></app-user-avatar>
                <i class="ml-3 fas fa-chevron-down d-inline-block align-middle"></i>
            </button>
            <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuConnectedUserButton">
                <a class="dropdown-item" [routerLink]="['/profil', connectedUser.token]">Mon compte</a>
                <div class="dropdown-divider"></div>
                <button type="button" class="dropdown-item text-danger" (click)="logout()">Se déconnecter</button>
            </div>
        </div>
    </nav>
</header>

<aside class="sidebar px-3 py-6" [ngClass]="{'active': sidebarIsActive}">
    <button type="button" class="btn-close fa-2x" (click)="sidebarIsActive = !sidebarIsActive">
        <i class="far fa-times-circle"></i>
    </button>
    <div class="row h-100">
        <div class="col-12 pt-6 pb-3" *ngIf="!connectedUser || circlesPage">
            <ul class="sidebar-nav list-unstyled mt-4">
                <li class="nav-item">
                    <a class="nav-link" [routerLink]="['/a-propos']" routerLinkActive="active">A propos</a>
                </li>
                <li class="nav-item">
                    <span class="nav-link text-grey-85">Objects connectés</span>
                </li>
                <li class="nav-item">
                    <span class="nav-link text-grey-85">Services</span>
                </li>
            </ul>
        </div>
        <div class="col-12 pt-6 pb-3" *ngIf="connectedUser && !circlesPage">
            <h3>Mes services</h3>
            <ul class="sidebar-nav list-unstyled mt-4">
                <li class="nav-item">
                    <a class="nav-link" routerLink="/cercles" routerLinkActive="active">Mes cercles</a>
                </li>
                <li class="nav-item">
                    <span class="nav-link text-grey-85">Mes objects connectés</span>
                </li>
            </ul>
        </div>
        <div class="col-12 align-self-end">
            <ul class="sidebar-nav small list-unstyled mb-3 border-top border-light-grey pt-4">
                <li class="nav-item">
                    <a class="nav-link" [routerLink]="['/', 'politique-de-confidentialite']">Politique de confidentialité</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" [routerLink]="['/', 'mentions-legales']">Mentions légales</a>
                </li>
                <li class="nav-item">
                    <a  class="nav-link" [routerLink]="['/', 'cgu']">CGU</a>
                </li>
            </ul>
            <div class="small text-dark-grey">
                &copy; {{ today| date:'yyyy' }} Cercle Confiance. Tous droits réservés.
            </div>
        </div>
    </div>
</aside>
<div class="sidebar-backdrop"></div>
