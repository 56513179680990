import { Member } from '../models/member.model';
import { BehaviorSubject, from } from 'rxjs';
import { map, switchMap, finalize } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "./api.service";
import * as i2 from "./feathers.service";
import * as i3 from "./auth.service";
export class MemberService {
    constructor(apiService, feathersService, authService) {
        this.apiService = apiService;
        this.feathersService = feathersService;
        this.authService = authService;
        this._fsServiceName = "members";
        this._createdMember = new BehaviorSubject(null);
        this._updatedMember = new BehaviorSubject(null);
        this._patchedMember = new BehaviorSubject(null);
        this.setCreatedMemberEvent();
        this.setUpdatedMemberEvent();
        this.setPatchedMemberEvent();
    }
    onCreatedMember() {
        return this._createdMember.asObservable();
    }
    onUpdatedMember() {
        return this._updatedMember.asObservable();
    }
    onPatchedMember() {
        return this._patchedMember.asObservable();
    }
    createMember(data) {
        return this.apiService.post(this._fsServiceName, data).pipe(map(member => new Member().deserialize(member)));
    }
    leaveCircleMember(id, data) {
        const params = {
            query: {
                operationPath: '/leave'
            }
        };
        return this.apiService.put(this._fsServiceName, id, data, params).pipe(map(member => new Member().deserialize(member)));
    }
    patchMember(id, data, params = {}) {
        return this.apiService.patch(this._fsServiceName, data, params).pipe(map(member => new Member().deserialize(member)));
    }
    deleteMember(id) {
        return this.apiService.delete(this._fsServiceName, id);
    }
    regenerateArchive(id) {
        const params = {
            query: {
                operationPath: '/regenerate-archive'
            }
        };
        return from(this.authService.authenticateAnonymous()).pipe(switchMap(response => {
            return this.apiService.get(this._fsServiceName, id, params).pipe(map(member => new Member().deserialize(member)));
        }), finalize(() => this.authService.logout()));
    }
    setCreatedMemberEvent() {
        this.feathersService.client.service(this._fsServiceName).on('created', member => {
            this._createdMember.next(new Member().deserialize(member));
        });
    }
    setUpdatedMemberEvent() {
        this.feathersService.client.service(this._fsServiceName).on('updated', member => {
            this._updatedMember.next(new Member().deserialize(member));
        });
    }
    setPatchedMemberEvent() {
        this.feathersService.client.service(this._fsServiceName).on('patched', member => {
            this._patchedMember.next(new Member().deserialize(member));
        });
    }
}
MemberService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MemberService_Factory() { return new MemberService(i0.ɵɵinject(i1.ApiService), i0.ɵɵinject(i2.FeathersService), i0.ɵɵinject(i3.AuthService)); }, token: MemberService, providedIn: "root" });
