import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import HmacSHA256 from 'crypto-js/hmac-sha256';
import Base64 from 'crypto-js/enc-base64';
import { environment } from '../../../environments/environment';

import { MeService } from '../../core/services/me.service';
import { User } from '../../core/models/user.model';
import { SubscriptionService } from '../../core/services/subscription.service';
import { UserService } from '../../core/services/user.service';
import { SubscriptionModel } from '../../core/models/subscription.model';

@Component({
  selector: 'app-subscription-payment',
  templateUrl: './subscription-payment.component.html',
  styleUrls: ['./subscription-payment.component.scss']
})
export class SubscriptionPaymentComponent implements OnInit {

  public subscriptionForm: FormGroup;
  public me: User = new User();
  public hasSubscription: boolean = false;
  public resultUrl: string = "";
  public frequency : FormControl = new FormControl('', Validators.required);
  public vads_sub_effect_date: FormControl = new FormControl(this.datePipe.transform(new Date(), 'yyyyMMdd'));
  public vads_trans_date: FormControl = new FormControl(this.datePipe.transform(new Date(), 'yyyyMMddHHmmss'));
  public vads_trans_id: FormControl = new FormControl('');
  public subscription_id: FormControl = new FormControl('');
  public vads_sub_amount: FormControl = new FormControl('');
  public vads_sub_desc: FormControl = new FormControl('');
  public vads_ctx_mode: FormControl = new FormControl(environment.sogecommerce.mode);
  public signature: FormControl = new FormControl('');
  
  constructor(private meService: MeService,
    private userService: UserService,
    private datePipe: DatePipe,
    private subscriptionService: SubscriptionService) { }

  ngOnInit() {
    this.me = this.meService.getMe();
    this.getSubscriptions();
    this.resultUrl = window.location.origin + '/profil/resultat';
  }

  getSubscriptions() {
    this.userService.getUserSubscriptions(this.me.token).subscribe(subscriptions => {
        subscriptions.forEach(subscription => {
            if (SubscriptionModel.STATUS_OK.indexOf(subscription.status) > -1) {
                this.hasSubscription = true;
            }
        });
        if (!this.hasSubscription) {
          this.prepareSubscriptionForm();
        }
    });
  }

  prepareSubscriptionForm() {
    this.frequency.valueChanges.subscribe(frequency => {
      let amount = '';
      let rule = '';
      switch(frequency) {
        case "1": {
          amount = '1200';
          if (this.datePipe.transform(new Date(), 'dd') >= '28') {
            rule = 'RRULE:FREQ=MONTHLY;BYMONTHDAY=28,29,30,31;BYSETPOS=-1;';
          } else {
            rule = 'RRULE:FREQ=MONTHLY';
          }
          break;
        }
        case "3": {
          amount = '3000';
          if (this.datePipe.transform(new Date(), 'dd') >= '28') {
            rule = 'RRULE:FREQ=MONTHLY;INTERVAL=3;BYMONTHDAY=28,29,30,31;BYSETPOS=-1;';
          } else {
            rule = 'RRULE:FREQ=MONTHLY;INTERVAL=3;';
          }
          break;
        }
        case "12": {
          amount = '12000';
          if (this.datePipe.transform(new Date(), 'MMdd') == '0229') {
            rule = 'RRULE:FREQ=YEARLY;BYMONTHDAY=28,29;BYMONTH=' + this.datePipe.transform(new Date(), 'M');
          } else {
            rule = 'RRULE:FREQ=YEARLY;BYMONTH=' + this.datePipe.transform(new Date(), 'M');
          }
          break;
        }
      }
      this.subscriptionService.createSubscription({}).subscribe(subscription => {
        this.vads_trans_id.setValue(subscription.transId);
        this.subscription_id.setValue(subscription.getId());
      })
      this.vads_sub_amount.setValue(amount);
      this.vads_sub_desc.setValue(rule);
    });
    this.subscription_id.valueChanges.subscribe(value => {
      this.calculateSignature();
    });
  }

  calculateSignature() {
    let form = document.getElementById("paymentForm");
    let arrayForm = Array.prototype.slice.call(form.getElementsByTagName("input"));
    let arraySorted = arrayForm.sort((a,b) => (a.name > b.name ? 1 : -1));
    let signatureContent = "";
    const key = environment.sogecommerce.key;
    arraySorted.forEach(element => {
      if (element.name.substring(0,5) == 'vads_') {
        signatureContent += unescape(encodeURIComponent(element.value)) + "+";
      }
    });
    signatureContent += key;
    let hash = HmacSHA256(signatureContent, key);
    this.signature.setValue(Base64.stringify(hash));
  }

}
