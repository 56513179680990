<div class="container-fluid circle-posts h-lg-100">
    <div class="row h-lg-100">
        <div class="col-12 col-lg-3 border-left border-light-grey order-lg-2">
            <div class="row flex-column h-lg-100">
                <div class="col under-surveillance-member flex-grow-1 py-2 py-lg-4">
                    <ng-container *ngIf="memberUnderSurveillance">
                        <a [routerLink]="['/profil', memberUnderSurveillance.user.token]" *ngIf="!memberUnderSurveillance.isWaiting()" title="{{ memberUnderSurveillance.getName() }}">
                            <app-user-avatar [user]="memberUnderSurveillance.user"></app-user-avatar>
                            <span class="circle__under-surveillance-member__name d-inline-block align-middle ml-2 font-weight-bold">
                                {{ memberUnderSurveillance.getName() }}
                                <span class="small text-primary d-block d-lg-none font-weight-normal" *ngIf="memberUnderSurveillance.isUnderSurveillance">Centre du cercle</span>
                            </span>
                        </a>
                        <ng-container *ngIf="memberUnderSurveillance.isWaiting()">
                            <app-user-avatar [user]="memberUnderSurveillance.user"></app-user-avatar>
                            <span class="circle__under-surveillance-member__name d-inline-block align-middle ml-2 font-weight-bold">
                                En attente
                                <span class="text-dark-grey d-block font-weight-normal">{{ memberUnderSurveillance.email }}</span>
                            </span>
                        </ng-container>
                    </ng-container>
                    <ul class="list-unstyled mt-3 d-none d-lg-block">
                        <li class="list-item">
                            <a href="mailto:{{ memberUnderSurveillance.email }}">
                                <span class="material-icons material-icons-outlined d-inline-block align-middle mr-2">email</span>
                                <span class="d-inline-block align-middle">{{ memberUnderSurveillance.email }}</span>
                            </a>
                        </li>
                    </ul>
                    <ng-container *ngIf="waitingAppointments.length + newAppointments.length > 0">
                        <div class="waiting-appointments mt-6 d-none d-lg-block">
                            <a class="collapsed" data-toggle="collapse" href="#collapseAppointment" role="button" aria-expanded="false" aria-controls="collapseAppointment">
                                <span class="badge badge-primary align-middle mr-2">{{ waitingAppointments.length + newAppointments.length }}</span>
                                <span class="align-middle">Concertations en attente</span> 
                            </a>
                            <div class="collapse mt-1" id="collapseAppointment">
                                <div class="card card-body">
                                    <ul class="d-lg-block mb-0 d-none">
                                        <ng-container *ngFor="let appointment of waitingAppointments">
                                            <li>
                                                <a class="btn-link-primary" [routerLink]="['rendez-vous', appointment.getId()]" title="{{ appointment.subject }}">
                                                    {{ appointment.subject }}
                                                </a>
                                            </li>
                                        </ng-container>
                                        <ng-container *ngFor="let newAppointment of newAppointments">
                                            <li>
                                                <a class="btn-link-primary" [routerLink]="['rendez-vous', newAppointment.getId()]" title="{{ newAppointment.subject }}">
                                                    {{ newAppointment.subject }}
                                                </a>
                                            </li>
                                        </ng-container>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div>
                <div class="col align-self-end flex-grow-0 py-4 border-top border-light-grey d-none d-lg-block">
                    <ul class="small list-unstyled mb-3 nav-legal-notice">
                        <li class="nav-item">
                            <a class="nav-link" [routerLink]="['/', 'politique-de-confidentialite']">Politique de confidentialité</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" [routerLink]="['/', 'mentions-legales']">Mentions légales</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" [routerLink]="['/', 'cgu']">CGU</a>
                        </li>
                    </ul>
                    <div class="small text-dark-grey">
                        &copy; {{ today| date:'yyyy' }} Cercle Confiance. Tous droits réservés.
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 col-lg-9 bg-grey-92 order-lg-1">
            <div class="row flex-column h-lg-100">
                <div class="col flex-grow-0 bg-white py-3 py-lg-4 circle-posts__new-post">
                    <form [formGroup]="postForm" (ngSubmit)="onPostFormSubmit()" enctype="multipart/form-data">
                        <div class="row">
                            <div class="col flex-grow-1 pr-0">
                                <div class="form-group mb-0">
                                    <textarea name="name" rows="2" formControlName="message" class="form-control" placeholder="Tapez votre message ici..."></textarea>
                                </div>
                            </div>
                            <div class="col flex-grow-0 text-right">
                                <div class="form-group mb-0">
                                    <button type="submit" class="btn btn-primary btn-rounded rounded-circle text-center" title="Envoyer">
                                        <span class="material-icons">
                                            send
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-3" *ngIf="event">
                            <div class="col-12 col-lg-6">
                                <div class="circle-posts__new-post__event">
                                    <button type="button" class="close" (click)="removeEvent()" title="supprimer l'évènement">
                                        <span class="material-icons">close</span>
                                    </button>
                                    <app-event [event]="event"></app-event>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-3" *ngIf="medias.length">
                            <div class="col-12">
                                <div class="circle-posts__new-post__medias">
                                    <div class="row mx-n1">
                                        <ng-container *ngFor="let media of medias; index as i;">
                                            <div class="col-3 col-sm-2 px-1">
                                                <div class="circle-posts__new-post__medias__media-container">
                                                    <button type="button" class="close" (click)="removeMedia(i)" title="supprimer le média">
                                                        <span class="material-icons">close</span>
                                                    </button>
                                                    <div class="media" title="{{ media.filename }}" *ngIf="media.isFile() || media.isSpreadsheet() || media.isVideo() || media.isAudio()" [ngClass]="{'media--file': media.isFile(), 'media--spreadsheet': media.isSpreadsheet(), 'media--audio': media.isAudio(), 'media--video': media.isVideo()}">
                                                        <img src="../assets/img/placeholders/placeholder-media.png" class="img-fluid">
                                                        <span class="small text-dark-grey d-md-none">{{ media.filename|shorten:5 }}</span>
                                                        <span class="small text-dark-grey d-none d-md-block">{{ media.filename|shorten:9 }}</span>
                                                    </div>
                                                    <div class="media media--image" [ngStyle]="{'background-image': 'url(' + media.data + ')'}" title="{{ media.filename }}" *ngIf="media.isImage()">
                                                        <img src="../assets/img/placeholders/placeholder-media.png" class="img-fluid">
                                                        <div class="align-center position-absolute" *ngIf="!media.data">
                                                            <div class="spinner-border text-primary" role="status">
                                                                <span class="sr-only">Loading...</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-3">
                            <div class="col-12">
                                <div class="form-group mb-0 d-inline-block align-middle">
                                    <input type="file" id="medias" name="medias" ng2FileSelect [uploader]="uploader" multiple />
                                    <label for="medias" class="btn btn-secondary btn--small btn-add-content" title="Ajouter un document">
                                        <span class="material-icons d-inline-block align-middle mr-md-2">
                                            attach_file
                                        </span>
                                        <span class="d-none d-sm-inline-block align-middle">Ajouter un document</span>
                                    </label>
                                </div>
                                <div class="form-group mb-0 d-inline-block align-middle ml-3">
                                    <button type="button" class="btn btn-secondary btn--small btn-add-content" title="Ajouter un évènement" (click)="openAddEventModal()">
                                        <span class="material-icons d-inline-block align-middle mr-md-2">
                                            event
                                        </span>
                                        <span class="d-none d-sm-inline-block align-middle">Ajouter un évènement</span>
                                    </button>
                                </div>
                                <div class="form-group mb-0 d-inline-block align-middle ml-3">
                                    <button type="button" class="btn btn-secondary btn--small btn-add-content" title="Créer un rendez-vous" (click)="openAddAppointmentModal()">
                                        <span class="material-icons d-inline-block align-middle mr-md-2">
                                            groups
                                        </span>
                                        <span class="d-none d-sm-inline-block align-middle">Créer un rendez-vous</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="col flex-grow-1 py-4 circle-posts__wall" detect-scroll (onScroll)="loadPosts($event)" [bottomOffset]="200" [topOffset]="10">
                    <ng-template #noPosts>
                        <p class="mt-2 mx-3 mx-lg-0" *ngIf="!loadingPosts">
                            <i>Aucun post publié.</i>
                        </p>
                    </ng-template>
                    <ng-template [ngIf]="posts.length" [ngIfElse]="noPosts">
                        <ng-container *ngFor="let post of posts; index as index">
                            <div class="mb-5" *ngIf="!post.deletedAt">
                                <app-post [post]="post" [memberMe]="memberMe" [openedComments]="firstPost == index" [index]="index"></app-post>
                            </div>
                        </ng-container>
                    </ng-template>
                    <div class="mt-4" *ngIf="loadingPosts">
                        <div class="col-12 text-center">
                            <div class="spinner-border text-primary" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                        </div>
                    </div>
                </div>
                <a id="launch-visio" class="btn btn-primary w-100 w-lg-75 d-lg-none" [routerLink]="['visio']" [ngClass]="{'swiped': swipeLaunchVisio}">Démarrer la visio</a>
            </div>
        </div>
    </div>
</div>
