import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { combineLatest, Subscription } from 'rxjs';

import { Circle } from '../../core/models/circle.model';
import { Member } from '../../core/models/member.model';
import { User } from '../../core/models/user.model';
import { MeService } from '../../core/services/me.service';
import { CircleService } from '../../core/services/circle.service';
import { ModalInformationComponent } from '../circle/modal-information/modal-information.component';

@Component({
    selector: 'app-circle-thumbnail',
    templateUrl: './circle-thumbnail.component.html',
    styleUrls: ['./circle-thumbnail.component.scss']
})
export class CircleThumbnailComponent implements OnInit {

    @Input() public circle: Circle;
    @Input() public circleLeft: boolean;
    public memberUnderSurveillance: Member;
    public otherMembers: Member[] = [];
    public meIsAdmin: boolean = false;
    public deletedAt: string = "";
    public modalConfirmation: BsModalRef;
    public modalInformation: BsModalRef;
    private subscriptionsModal: Subscription[] = [];

    constructor(private meService: MeService,
        private circleService: CircleService,
        private toastrService: ToastrService,
        private modalService: BsModalService) {
    }

    ngOnInit() {
        this.rangeMembers();
    }

    rangeMembers() {
        const me: User = this.meService.getMe();

        this.memberUnderSurveillance = this.circle.members[0];
        this.circle.members.forEach(member => {
            if (member.isUnderSurveillance) {
                this.memberUnderSurveillance = member;
            } else if (!member.deletedAt && ((member.user && !member.user.deletedAt) || !member.user)) {
                this.otherMembers.push(member);
            }

            if (member.user && member.user.getId() == me.getId()) {
                if (member.isAdmin) {
                    this.meIsAdmin = true;
                }
                if (member.deletedAt) {
                    this.deletedAt = member.deletedAt;
                }
            }
        });
    }

    openInfoModal() {
        if (this.circleLeft || this.circle.deletedAt) {
            let deletedDate = this.circle.deletedAt ? this.circle.deletedAt : this.deletedAt;
            const initialState = {deletedAt : deletedDate};
            this.modalInformation = this.modalService.show(ModalInformationComponent, {initialState});
        }
    }

}
