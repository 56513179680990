import { of, throwError } from 'rxjs';
import { switchMap, catchError } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "../services/circle.service";
import * as i2 from "@angular/router";
export class CircleGuard {
    constructor(circle, router) {
        this.circle = circle;
        this.router = router;
    }
    canActivate(next, state) {
        if (!next.params.token) {
            return of(false);
        }
        return this.circle.getCircle(next.params.token).pipe(switchMap(response => of(true)), catchError(error => {
            this.router.navigate(['cercles']);
            return of(false);
        }));
    }
    canActivateChild(next, state) {
        if (!next.data.adminAccess) {
            return of(true);
        }
        return this.circle.getCircleMembersMe(next.parent.params.token).pipe(switchMap(member => member.isAdmin ? of(true) : throwError('Not admin')), catchError(error => {
            this.router.navigate(['cercles', next.parent.params.token]);
            return of(false);
        }));
    }
    canLoad(route, segments) {
        return true;
    }
}
CircleGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CircleGuard_Factory() { return new CircleGuard(i0.ɵɵinject(i1.CircleService), i0.ɵɵinject(i2.Router)); }, token: CircleGuard, providedIn: "root" });
