<div class="circle-visio h-lg-100 w-100">
    <div class="position-absolute align-center" *ngIf="loadingVisio">
        <div class="text-center">
            <div class="spinner-border text-primary" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
    </div>
    <div appVisio [roomName]="circle.token" [subject]="circle.name" [participant]="participant" (onLoad)="loadedVisio()" (onVideoConferenceLeft)="leaveVisio()" (onVideoConferenceJoined)="updateParticipant($event)" class="h-100" *ngIf="participant"></div>
</div>
