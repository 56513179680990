/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./footer.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@angular/router";
import * as i4 from "./footer.component";
var styles_FooterComponent = [i0.styles];
var RenderType_FooterComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FooterComponent, data: {} });
export { RenderType_FooterComponent as RenderType_FooterComponent };
export function View_FooterComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.DatePipe, [i1.LOCALE_ID]), (_l()(), i1.ɵeld(1, 0, null, null, 23, "footer", [["class", "mt-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 22, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 21, "div", [["class", "border-top border-grey pt-3 pb-6 small"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 20, "div", [["class", "row text-center text-lg-left"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 16, "div", [["class", "col-12 col-lg-6 mb-1 mb-lg-0 text-lg-right order-lg-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 15, "ul", [["class", "list-unstyled m-0 list-inline list-inline--dashed"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 4, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 3, "a", [], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 9).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(9, 671744, null, 0, i3.RouterLinkWithHref, [i3.Router, i3.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(10, 2), (_l()(), i1.ɵted(-1, null, ["Politique de confidentialit\u00E9"])), (_l()(), i1.ɵeld(12, 0, null, null, 4, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 3, "a", [], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 14).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(14, 671744, null, 0, i3.RouterLinkWithHref, [i3.Router, i3.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(15, 2), (_l()(), i1.ɵted(-1, null, ["Mentions l\u00E9gales"])), (_l()(), i1.ɵeld(17, 0, null, null, 4, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(18, 0, null, null, 3, "a", [], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 19).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(19, 671744, null, 0, i3.RouterLinkWithHref, [i3.Router, i3.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(20, 2), (_l()(), i1.ɵted(-1, null, ["CGU"])), (_l()(), i1.ɵeld(22, 0, null, null, 2, "div", [["class", "col-12 col-lg-6 order-lg-1 text-dark-grey"]], null, null, null, null, null)), (_l()(), i1.ɵted(23, null, [" \u00A9 ", " Cercle Confiance. Tous droits r\u00E9serv\u00E9s. "])), i1.ɵppd(24, 2)], function (_ck, _v) { var currVal_2 = _ck(_v, 10, 0, "/", "politique-de-confidentialite"); _ck(_v, 9, 0, currVal_2); var currVal_5 = _ck(_v, 15, 0, "/", "mentions-legales"); _ck(_v, 14, 0, currVal_5); var currVal_8 = _ck(_v, 20, 0, "/", "cgu"); _ck(_v, 19, 0, currVal_8); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 9).target; var currVal_1 = i1.ɵnov(_v, 9).href; _ck(_v, 8, 0, currVal_0, currVal_1); var currVal_3 = i1.ɵnov(_v, 14).target; var currVal_4 = i1.ɵnov(_v, 14).href; _ck(_v, 13, 0, currVal_3, currVal_4); var currVal_6 = i1.ɵnov(_v, 19).target; var currVal_7 = i1.ɵnov(_v, 19).href; _ck(_v, 18, 0, currVal_6, currVal_7); var currVal_9 = i1.ɵunv(_v, 23, 0, _ck(_v, 24, 0, i1.ɵnov(_v, 0), _co.today, "yyyy")); _ck(_v, 23, 0, currVal_9); }); }
export function View_FooterComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-footer", [], null, null, null, View_FooterComponent_0, RenderType_FooterComponent)), i1.ɵdid(1, 114688, null, 0, i4.FooterComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FooterComponentNgFactory = i1.ɵccf("app-footer", i4.FooterComponent, View_FooterComponent_Host_0, {}, {}, []);
export { FooterComponentNgFactory as FooterComponentNgFactory };
