import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { DashboardComponent } from './dashboard/dashboard.component';
import { CircleComponent } from './circle/circle.component';
import { PostsComponent } from './circle/posts/posts.component';
import { VisioComponent } from './circle/visio/visio.component';
import { SettingsComponent } from './circle/settings/settings.component';
import { PostDetailsComponent } from './circle/post-details/post-details.component';
import { AppointmentComponent } from './circle/appointment/appointment.component';

import { CircleGuard } from '../core/guards/circle.guard';

const routes: Routes = [
  {
    path: ":token",
    component: CircleComponent,
    canActivate: [CircleGuard],
    canActivateChild: [CircleGuard],
    runGuardsAndResolvers: 'always',
    children: [
        {
          path: '',
          component: PostsComponent,
          pathMatch: 'full',
          data: {
              isFullscreen: true
          }
        },
        {
          path: 'posts/:id',
          component: PostDetailsComponent,
          data: {
              isFullscreen: true
          }
        },
        {
          path: 'rendez-vous/:id',
          component: AppointmentComponent,
          data: {
              isFullscreen: true
          }
        },
      // {
      //   path: 'medias',
      //   component: MediasComponent
      // },
      {
        path: 'visio',
        component: VisioComponent,
        data: {
            isFullscreen: true
        }
      },
      {
        path: 'parametres',
        component: SettingsComponent,
        runGuardsAndResolvers: 'always',
        data: {
            isFullscreen: true,
            adminAccess: true
        }
      },
      // {
      //   path: 'agenda',
      //   component: EventsComponent
      // },
      {
        path: '**',
        redirectTo: '',
      }
    ],
  },
  {
    path: "**",
    component: DashboardComponent,
    runGuardsAndResolvers: 'always',
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CirclesRoutingModule { }
