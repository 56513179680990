import { Component, OnInit, Input, OnDestroy, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';

import { Subscription } from 'rxjs';
import { ScrollEvent } from 'ngx-scroll-event';
import { CircleService } from '../../../core/services/circle.service';
import { EventService } from '../../../core/services/event.service';
import { PostService } from '../../../core/services/post.service';
import { AppointmentService } from '../../../core/services/appointment.service';
import { Circle } from '../../../core/models/circle.model';
import { Event } from '../../../core/models/event.model';
import { Appointment } from '../../../core/models/appointment.model';

@Component({
    selector: 'app-events',
    templateUrl: './events.component.html',
    styleUrls: ['./events.component.scss']
})
export class EventsComponent implements OnInit, OnDestroy {

    @Input() public circle: Circle = new Circle();
    @Input() public scrollEvent: ScrollEvent;
    @Output() public onNavigationStart: EventEmitter<any> = new EventEmitter();
    private _observers: Subscription[] = [];
    private _eventsPage: number = 1;
    private _appointmentsPage: number = 1;
    public events: any = [];
    public loadingEvents: boolean = true;

    constructor(private circleService: CircleService,
        private postService: PostService,
        private eventService: EventService,
        private router: Router,
        private appointmentService: AppointmentService) {
    }

    ngOnInit() {
        this.getEvents();
        this.setObservers();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.scrollEvent) {
            this.loadEvents(changes.scrollEvent.currentValue);
        }
    }

    setObservers() {
        this._observers.push(this.setCreatedPostObserver());
        this._observers.push(this.setUpdatedPostObserver());
        this._observers.push(this.setUpdatedAppointmentObserver());
        this._observers.push(this.setDeletedEventObserver());
        this._observers.push(this.setDeletedAppointmentObserver());
        this._observers.push(this.setRouterObserver());
    }

    setCreatedPostObserver(): Subscription {
        return this.postService.onCreatedPost()
            .subscribe(post => {
                if (!post) {
                    return;
                }

                if (this.circle.getIRI() == post.circle.getIRI()) {
                    if (post.event) {
                      this.events.unshift(post.event);
                  }
              }
        });
    }

    setUpdatedPostObserver(): Subscription {
        return this.postService.onUpdatedPost()
            .subscribe(post => {
                if (!post) {
                    return;
                }

                if (this.circle.getIRI() == post.circle.getIRI()) {
                    if (post.event) {
                        this.events = this.mapEvents(this.events, post.event);
                    }
                }
            });
    }

    setUpdatedAppointmentObserver(): Subscription {
        return this.appointmentService.onUpdatedAppointment()
            .subscribe(appointment => {
                if (!appointment) {
                    return;
                }

                if (this.circle.getIRI() == appointment.circle.getIRI() && appointment.finalChoice != null) {
                    this.events = this.mapEvents(this.events, appointment);
                }
            });
    }

    setDeletedEventObserver(): Subscription {
        return this.eventService.onDeletedEvent()
            .subscribe(event => {
                if (!event) {
                    return;
                }
                this.events = this.filterEvents(this.events, event);
            });
    }

    setDeletedAppointmentObserver(): Subscription {
        return this.appointmentService.onDeletedAppointment()
            .subscribe(appointment => {
                if (!appointment) {
                    return;
                }
                this.events = this.filterEvents(this.events, appointment);
            });
    }

    setRouterObserver(): Subscription {
        return this.router.events.subscribe(event => {
            if (event instanceof NavigationStart) {
                this.onNavigationStart.emit(event);
            }
        });
    }

    loadEvents(event: ScrollEvent, force: boolean = false) {
        if (!this.loadingEvents && ((event && event.isReachingBottom) || force) && this._eventsPage) {
            this.getEvents();
        }
    }

    getEvents() {
        this.loadingEvents = true;
        this.circleService.getCircleEvents(this.circle.token, { page: this._eventsPage }).subscribe(events => {
          (events.length > 0) ? this._eventsPage++ : this._eventsPage = 0;

          this.events = this.events.concat(events);

          this.circleService.getCircleAppointments(this.circle.token, { page: this._appointmentsPage }).subscribe(appointments => {
            (appointments.length > 0) ? this._appointmentsPage++ : this._appointmentsPage = 0;
            this.loadingEvents = false;
  
            for (var i = 0; i < appointments.length; i++) {
                if (appointments[i].finalChoice != null) {
                    let inArray = false;
                    for (var j = 0; j < this.events.length; j++) {
                        if ((this.events[j] instanceof Event && this.events[j].date < appointments[i].finalChoice)
                            || (this.events[j] instanceof Appointment && this.events[j].finalChoice < appointments[i].finalChoice)) {
                            this.events.splice(j, 0, appointments[i]);
                            inArray = true;
                            break;
                        }
                    }
                    if (!inArray) {
                        this.events.push(appointments[i]);
                    }
                }
            }
          });
        });
    }

    isTypeAppointment(item): boolean { return item instanceof Appointment; }

    private mapEvents(eventsTab: any, currentEvent: any) {
        let inArray = false;
        for (var j = 0; j < eventsTab.length; j++) {
            if (((currentEvent instanceof Appointment && eventsTab[j] instanceof Appointment) || (currentEvent instanceof Event && eventsTab[j] instanceof Event)) && eventsTab[j].getId() == currentEvent.getId()) {
                eventsTab[j] = currentEvent;
                inArray = true;
                break;
            }
            if ((currentEvent instanceof Appointment && eventsTab[j] instanceof Appointment && eventsTab[j].finalChoice < currentEvent.finalChoice)
                || (currentEvent instanceof Appointment && eventsTab[j] instanceof Event && eventsTab[j].date < currentEvent.finalChoice)
                || (currentEvent instanceof Event && eventsTab[j] instanceof Appointment && eventsTab[j].finalChoice < currentEvent.date)
                || (currentEvent instanceof Event && eventsTab[j] instanceof Event && eventsTab[j].date < currentEvent.date)) {
                eventsTab.splice(j, 0, currentEvent);
                inArray = true;
                break;
            }
        }
        if (!inArray) {
            eventsTab.push(currentEvent);
        }
        return eventsTab;
    }

    private filterEvents(eventsTab: any, deletedEvent: any) {
        for(var i = 0; i < eventsTab.length; i++) {
            if (((deletedEvent instanceof Appointment && eventsTab[i] instanceof Appointment) || (deletedEvent instanceof Event && eventsTab[i] instanceof Event)) && deletedEvent && eventsTab[i].getIRI() == deletedEvent.getIRI()) {
                eventsTab.splice(i, 1);
            }
        }
        return eventsTab;
    }

    ngOnDestroy() {
        this._observers.forEach((observer, index) => {
            observer.unsubscribe();
        });
    }

}
