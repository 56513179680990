<article class="circle-thumbnail" [ngClass]="{'deleted-circle' : circle.deletedAt, 'circle-left': circleLeft}">
    <a class="link-settings position-absolute" [routerLink]="['/cercles', circle.token, 'parametres']" *ngIf="meIsAdmin && !circle.deletedAt">
        <span class="material-icons material-icons-outlined">settings</span>
    </a>
    <a [routerLink]="(!circleLeft && !circle.deletedAt) ? ['/cercles', circle.token] : []" (click)="openInfoModal()">
        <div class="container-fluid py-3">
            <div class="row">
                <div class="col-12 col-md-1 order-md-2">
                    <div class="row">
                        <div class="col-12 d-md-none">
                            <app-user-avatar [user]="memberUnderSurveillance.user" [isUnderSurveillance]="memberUnderSurveillance.isUnderSurveillance"></app-user-avatar>
                            <ul class="d-inline-block list-unstyled list-inline mb-0 ml-n3" *ngIf="otherMembers.length > 0">
                                <ng-container *ngFor="let member of otherMembers; index as i;">
                                    <li class="ml-n3" [ngClass]="{ 'ml-n3': i > 0 }">
                                        <app-user-avatar [user]="member.user" [size]="'tiny'"></app-user-avatar>
                                    </li>
                                </ng-container>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-11">
                    <div class="d-flex">
                        <div class="text-md-center d-none d-md-block">
                            <app-user-avatar [user]="memberUnderSurveillance.user" [size]="'big'" [isUnderSurveillance]="memberUnderSurveillance.isUnderSurveillance"></app-user-avatar>
                            <ul class="list-unstyled list-inline mb-0 mt-md-n3" *ngIf="otherMembers.length > 0">
                                <ng-container *ngFor="let member of otherMembers; index as i;">
                                    <li class="ml-n3" [ngClass]="{ 'ml-n3': i > 0 }">
                                        <app-user-avatar [user]="member.user" [size]="'small'"></app-user-avatar>
                                    </li>
                                </ng-container>
                            </ul>
                        </div>
                        <div class="flex-fill mt-3 ml-md-5">
                            <h4 class="mb-2">{{ circle.name }}</h4>
                            <p>{{ circle.description }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </a>
</article>
