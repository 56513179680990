import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';

import { AuthenticationRoutingModule } from './authentication-routing.module';
import { RegisterComponent } from './register/register.component';
import { InvitationComponent } from './invitation/invitation.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { PasswordRequestComponent } from './password-request/password-request.component';

@NgModule({
  declarations: [RegisterComponent, InvitationComponent, ResetPasswordComponent, PasswordRequestComponent],
  imports: [
    CommonModule,
    AuthenticationRoutingModule,
    SharedModule
  ]
})
export class AuthenticationModule { }
