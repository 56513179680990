import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

import { Member } from '../models/member.model';
import { ApiService } from './api.service';
import { FeathersService } from './feathers.service';

@Injectable({
  providedIn: 'root'
})
export class InvitationVisioService {

  private _fsServiceName: string = "invitationsVisio";
  private _createdInvitation: Subject<any> = new Subject<any>();

  constructor(private apiService: ApiService,
    private feathersService: FeathersService) { 
    this.setCreatedInvitationEvent();
  }

  createInvitation(data: any): Observable<any> {
    return this.apiService.post(this._fsServiceName, data);
  }

  onCreatedInvitation(): Observable<any> {
    return this._createdInvitation;
  }

  setCreatedInvitationEvent() {
    this.feathersService.client.service(this._fsServiceName).on('created', invitation => {
      this._createdInvitation.next(invitation);
    });
  }
}
