import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Proposal } from '../models/proposal.model';
import { Vote } from '../models/vote.model';
import { ApiService } from './api.service';
import { FeathersService } from './feathers.service';

@Injectable({
  providedIn: 'root'
})
export class ProposalService {

  private _fsServiceName: string = "proposals";
  private _createdProposal: BehaviorSubject<Proposal> = new BehaviorSubject<Proposal>(null);

  constructor(private feathers: FeathersService,
    private apiService: ApiService) {
    this.setCreatedProposalEvent(); }

  onCreatedProposal(): Observable<Proposal> {
    return this._createdProposal;
  }

  createProposal(data: any): Observable<any> {
    return this.apiService.post(this._fsServiceName, data).pipe(
      map(event => new Proposal().deserialize(event))
    );
  }

  setCreatedProposalEvent() {
    this.feathers.client.service(this._fsServiceName).on('created', event => {
      this._createdProposal.next(new Proposal().deserialize(event));
      this._createdProposal.next(null);
    });
  }

  getProposalVotes(token: any, params: any = {}): Observable<any> {
    const defaultParams = {
      query: {
        operationPath: '/votes'
      }
    };

    Object.assign(defaultParams.query, params);

    return this.apiService.get(this._fsServiceName, token, defaultParams).pipe(
      map(votes => {
        votes.forEach((value, index) => {
          votes[index] = new Vote().deserialize(value);
        });

        return votes;
      })
    );
  }

}
