import { Directive, OnInit, Input, Output, EventEmitter, ElementRef  } from '@angular/core';

import { environment } from '../../../environments/environment';

declare var JitsiMeetExternalAPI;

@Directive({
    selector: '[appVisio]'
})
export class VisioDirective implements OnInit {

    @Input() public roomName: string = "";
    @Input() public subject: string = "";
    @Input() public participant: any = {};
    @Output() public onLoad: EventEmitter<any> = new EventEmitter();
    @Output() public onVideoConferenceLeft: EventEmitter<any> = new EventEmitter();
    @Output() public onVideoConferenceJoined: EventEmitter<any> = new EventEmitter();
    private _jitsiMeetConfig: any = {
        enableWelcomePage: false,
        enableInsecureRoomNameWarning: true,
        disableDeepLinking: true
    }
    private _jitsiMeetInterfaceConfig: any = {
        DEFAULT_BACKGROUND: '#333333',
        SHOW_JITSI_WATERMAK: false,
        SHOW_WATERMAK_FOR_GUESTS: false,
        HIDE_INVITE_MORE_HEADER: true,
        MOBILE_APP_PROMO: false,
        OPEN_IN_MOBILE_BROWSER: true,
        APP_NAME: 'Cercle Confiance Visio',
        CLOSE_PAGE_GUEST_HINT: true,
        TOOLBAR_BUTTONS: ['microphone', 'camera', 'desktop',
            'fullscreen', 'chat', 'sharedvideo', 'settings',
            'hangup', 'raisehand',
            'videoquality', 'raisehand'],
        SETTINGS_SECTIONS: ['devices', 'language', 'profile']
    }
    public jitsiMeet: any;

    constructor(private elementRef: ElementRef) {
    }

    ngOnInit() {
        this.prepareJitsiMeetOptions();
        this.initJitsiMeet();
    }

    prepareJitsiMeetOptions() {
        if (this.participant.isAdmin) {
            this._jitsiMeetInterfaceConfig.SETTINGS_SECTIONS.push('moderator');
        }
    }

    getJitsiJWT() {
        return "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjb250ZXh0Ijp7InVzZXIiOnsibmFtZSI6IkpvaG4gRG9lIiwiZW1haWwiOiJqZG9lQGV4YW1wbGUuY29tIiwiaWQiOiJ0b2sifX0sImF1ZCI6IlhoYU1MMllEd2RDRGRjeXZDRVJZWUV3NXIiLCJpc3MiOiJYaGFNTDJZRHdkQ0RkY3l2Q0VSWVlFdzVyIiwic3ViIjoiY2VyY2xlLWNvbmZpYW5jZS5mciIsInJvb20iOiIqIiwiZXhwIjoxNzE2MjM5MDIyfQ.gxBQPE3j-Nh-_LpmmcxncjsbqiCmbrPIyiIvbkBovF0";
    }

    initJitsiMeet() {
        const domain = environment.jitsi.domain;
        const options = {
            roomName: this.roomName,
            //jwt: this.getJitsiJWT(),
            width: "100%",
            height: "100%",
            parentNode: this.elementRef.nativeElement,
            configOverwrite: this._jitsiMeetConfig,
            interfaceConfigOverwrite: this._jitsiMeetInterfaceConfig,
            userInfo: {
                displayName: this.participant.name,
                email: this.participant.email
            },
            onload: () => {
                this.onLoad.emit();
            }
        };

        // Init Jitsi Meet
        this.jitsiMeet = new JitsiMeetExternalAPI(domain, options);

        this.setJitsiMeetCommands();
        this.setJitsiMeetEvents();
    }

    setJitsiMeetCommands() {
        this.jitsiMeet.executeCommand('subject', this.subject);
        if (this.participant.avatar) {
            this.jitsiMeet.executeCommand('avatarUrl', this.participant.avatar.getAbsolutePath());
        }
    }

    setJitsiMeetEvents() {
        this.jitsiMeet.addListener('videoConferenceJoined', event => {
            this.onVideoConferenceJoined.emit(true);
        });
        this.jitsiMeet.addListener('videoConferenceLeft', event => {
            this.onVideoConferenceLeft.emit();
        });
    }

}
