import { Component, OnInit, OnDestroy } from '@angular/core';

import { finalize } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { CircleService } from '../../../core/services/circle.service';
import { Circle } from '../../../core/models/circle.model';
import { Member } from '../../../core/models/member.model';
import { Router } from '@angular/router';
import { MemberService } from '../../../core/services/member.service';

@Component({
    selector: 'app-visio',
    templateUrl: './visio.component.html',
    styleUrls: ['./visio.component.scss']
})
export class VisioComponent implements OnInit, OnDestroy {

    private _observers: Subscription[] = [];
    public circle: Circle = new Circle();
    public participant: any;
    public loadingVisio: boolean = true;
    public memberMe: Member = new Member();

    constructor(private circleService: CircleService,
        private router: Router,
        private memberService: MemberService) { }

    ngOnInit() {
        this.setObservers();
    }

    setObservers() {
        this._observers.push(this.setCircleObserver());
    }

    setCircleObserver(): Subscription {
        return this.circleService.getCurrentCircle().subscribe(circle => {

            if (!circle) {
                return;
            }

            this.circle = circle;

            this.circleService.getCircleMembersMe(circle.token)
                .subscribe(member => {
                    this.participant = {
                        name: member.getName(),
                        email: member.email,
                        isAdmin: member.isAdmin,
                        avatar: member.user.avatar
                    };
                    this.memberMe = member;
                });
        });
    }

    loadedVisio() {
        this.loadingVisio = false;
    }

    leaveVisio() {
        this.updateParticipant(false);
        this.memberMe.isInvited = false;
        this.router.navigate(['cercles', this.circle.token]);
    }

    updateParticipant($event) {
        this.memberMe.isOnVisio = $event;
        return this.memberService.patchMember(this.memberMe.getId(), this.memberMe);
    }

    ngOnDestroy() {
        this._observers.forEach((observer, index) => {
            observer.unsubscribe();
        });
    }

}
