import { Component, OnInit, Input } from '@angular/core';

import { finalize } from 'rxjs/operators';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Circle } from '../../../core/models/circle.model';
import { CircleService } from '../../../core/services/circle.service';
import { LoadingService } from '../../../core/services/loading.service';
import { AlertService } from '../../../core/services/alert.service';

@Component({
  selector: 'app-modal-update-circle',
  templateUrl: './modal-update-circle.component.html',
  styleUrls: ['./modal-update-circle.component.scss']
})
export class ModalUpdateCircleComponent implements OnInit {

  public circleForm: FormGroup;
  @Input() public circle: Circle = new Circle();
  public editedCircle: any = {};

  constructor(public bsModalRef: BsModalRef,
    private circleService: CircleService,
    private loadingService: LoadingService,
    private alertService: AlertService,
    private toastrService: ToastrService) { }

  ngOnInit() {
    this.prepareCircleForm();
  }

  prepareCircleForm() {
    this.circleForm = new FormGroup({
        name: new FormControl(this.circle.name, Validators.required)
    });
  }

  onCircleFormSubmit() {
    this.circleForm['submitted'] = true;

    if (this.circleForm.valid) {
      this.loadingService.showLoading();
      this.editedCircle.name = this.circleForm.get('name').value;

      this.circleService.updateCircle(this.circle.token, this.editedCircle).pipe(
        finalize(() => this.loadingService.dismissLoading())
      ).subscribe(circle => {
        this.circleForm['submitted'] = false;
        this.circleForm.reset();
        this.bsModalRef.hide();
        this.alertService.closeAlert();
        this.toastrService.success("Le nom du cercle a bien été modifié.", 'Félicitations !');
      }, error => {
        const errorMessage: string[] = error.errors ? error.errors : ["Impossible de modifier le cercle pour l'instant, veuillez réessayer ultérieurement, merci"];
        this.alertService.showAlert(errorMessage, 'danger');
        console.log(error);
      });
    }
  }
}
