import { Serializable } from '../interfaces/serializable';

import { Avatar } from './avatar.model';

export class User implements Serializable {

  static readonly ROLE_USER = 'ROLE_USER';
  static readonly ROLE_ADMIN = 'ROLE_ADMIN';
  static readonly ROLE_SUPER_ADMIN = 'ROLE_SUPER_ADMIN';

  private _id: number = null;
  public email: string = "";
  public roles: string[] = [User.ROLE_USER];
  public firstName: string = "";
  public lastName: string = "";
  public plainPassword: string = "";
  public token: string = "";
  public avatar: Avatar = null;
  public deletedAt: string = null;
  public enabled: boolean = false;
  public subscriptions: string[] = [];
  public invoices: string[] = [];

  constructor() {}

  public getIRI() {
    return '/api/users/' + this._id;
  }

  public getId() {
    return this._id;
  }

  public getName() {
    return this.firstName + ' ' + this.lastName;
  }

  public isAdmin() {
    return this.roles.includes(User.ROLE_ADMIN) || this.roles.includes(User.ROLE_ADMIN);
  }

  public deserialize(input: any): this {
    if (!input) {
      return null;
    }

    Object.getOwnPropertyNames(this).forEach((property) => {
      switch (property) {
        case 'avatar':
          this[property] = new Avatar().deserialize(input[property]);
          break;
        default:
          if ('_id' == property && input.id) {
            this._id = input.id;
          } else if (property in input) {
            this[property] = input[property];
          }
          break;
      }
    });

    return this;
  }

  public serialize(): any {
    var output = {};

    Object.getOwnPropertyNames(this).forEach((property) => {
      switch (property) {
        case 'avatar':
          output[property] = (this[property] instanceof Avatar) ? this[property].getIRI() : null;
          break;
          case 'roles':
            if (this.isAdmin()) {
                output[property] = this[property];
            }
            break;
        default:
          if (property != '_id') {
            output[property] = this[property];
          }
          break;
      }
    });

    return output;
  }
}
