import { Injectable } from '@angular/core';

import { environment } from '../../../environments/environment';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { FeathersService } from './feathers.service';
import { MeService } from './me.service';
import { User } from '../models/user.model';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private _strategy: string = 'api';
  private _authentication = new BehaviorSubject<boolean>(false);

  constructor(private feathersService: FeathersService,
    private meService: MeService) {
  }

  public isAuthenticated(): Observable<boolean> {
    return this._authentication.asObservable();
  }

  public updateAuthentication(authenticated: boolean) {
    this._authentication.next(authenticated);
  }

  public authenticateAnonymous(): Promise<any> {
    const data = {
      strategy: this._strategy,
      code: 'client_credentials',
      clientId: environment.fsAPI.clientId,
      clientSecret: environment.fsAPI.clientSecret
    };

    return new Promise((resolve, reject) => {
      this.feathersService.client.authenticate(data).then(res => {
        resolve(res);
      }, err => {
        reject(err);
      });
    });
  }

  public authenticate(data: any = {}): Promise<any> {
    data = Object.assign({
      strategy: this._strategy ,
      clientId: environment.fsAPI.clientId,
      clientSecret: environment.fsAPI.clientSecret,
      code: !data.accessToken ? 'password' : 'refresh_token'
    }, data);

    return new Promise((resolve, reject) => {
      this.feathersService.client.authenticate(data).then(res => {
        this.meService.storeMe(new User().deserialize(res.user));
        this._authentication.next(true);
        resolve(res);
      }, err => {
        this._authentication.next(false);
        reject(err);
      });
    });
  }

  public logout(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.feathersService.client.logout().then(res => {
        this.meService.deleteMe();
        this._authentication.next(false);
        resolve();
      }, err => reject(err));
    });
  }
}
