import { Injectable } from '@angular/core';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FeathersService } from './feathers.service';
import { CircleService } from './circle.service';
import { ApiService } from './api.service';
import { Post } from '../models/post.model';
import { Comment } from '../models/comment.model';
import { Observable, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { Reaction } from '../models/reaction.model';

@Injectable({
  providedIn: 'root'
})
export class PostService {

  private _fsServiceName: string = "posts";
  private _createdPost: BehaviorSubject<Post> = new BehaviorSubject<Post>(null);
  private _updatedPost: BehaviorSubject<Post> = new BehaviorSubject<Post>(null);

  constructor(private feathers: FeathersService,
    private circleService: CircleService,
    private apiService: ApiService,
    private http: HttpClient) {
    this.setCreatedPostEvent();
    this.setUpdatedPostEvent();
  }

  onCreatedPost(): Observable<Post> {
    return this._createdPost.asObservable();
  }

  onUpdatedPost(): Observable<Post> {
    return this._updatedPost.asObservable();
  }

  createPost(data: any): Observable<any> {
    const accessToken = this.feathers.client.settings.accessToken;
    const httpOptions = {
      headers: new HttpHeaders({
        'authorization': accessToken
      })
    };
    return this.http.post(environment.fsAPI.url + '/' + this._fsServiceName, data, httpOptions).pipe(
      map(post => {
          return new Post().deserialize(post);
      })
    );
  }

  updatePost(data: any): Observable<any> {
    const accessToken = this.feathers.client.settings.accessToken;
    const httpOptions = {
      headers: new HttpHeaders({
        'authorization': accessToken
      })
    };

    return this.http.put(environment.fsAPI.url + '/' + this._fsServiceName + '/' + data.get('_id'), data, httpOptions).pipe(
      map(post => {
          return new Post().deserialize(post);
      })
    );
  }

  getPost(token: any): Observable<any> {
    const accessToken = this.feathers.client.settings.accessToken;
    const httpOptions = {
      headers: new HttpHeaders({
        'authorization': accessToken
      })
    };

    return this.http.get(environment.fsAPI.url + '/' + this._fsServiceName + '/' + token, httpOptions).pipe(
      map(post => {
          return new Post().deserialize(post);
      })
    );
  }

  deletePost(token: any): Observable<any> {
    return this.apiService.delete(this._fsServiceName, token).pipe(
      map(post => {
          return new Post().deserialize(post);
      })
    );
  }

  setCreatedPostEvent() {
    this.feathers.client.service(this._fsServiceName).on('created', post => {
      this._createdPost.next(new Post().deserialize(post));
    });
  }

  setUpdatedPostEvent() {
    this.feathers.client.service(this._fsServiceName).on('updated', post => {
      this._updatedPost.next(new Post().deserialize(post));
    });
  }

  getPostComments(token: any, params: any = {}): Observable<any> {
    const defaultParams = {
      query: {
        operationPath: '/comments'
      }
    };

    Object.assign(defaultParams.query, params);

    return this.apiService.get(this._fsServiceName, token, defaultParams).pipe(
      map(comments => {
        comments.forEach((value, index) => {
          comments[index] = new Comment().deserialize(value);
        });

        return comments;
      })
    );
  }

  getPostDeletedComments(token: any, params: any = {}): Observable<any> {
    const defaultParams = {
      query: {
        operationPath: '/deleted-comments'
      }
    };

    Object.assign(defaultParams.query, params);

    return this.apiService.get(this._fsServiceName, token, defaultParams);
  }

  getPostReactions(token: any, params: any = {}): Observable<any> {
    const defaultParams = {
      query: {
        operationPath: '/reactions'
      }
    };

    Object.assign(defaultParams.query, params);
    
    return this.apiService.get(this._fsServiceName, token, defaultParams).pipe(
      map(reactions => {
        reactions.forEach((value, index) => {
          reactions[index] = new Reaction().deserialize(value);
        });

        return reactions;
      })
    );
  }

}
