import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { Proposal } from '../models/proposal.model';
import { Vote } from '../models/vote.model';
import * as i0 from "@angular/core";
import * as i1 from "./feathers.service";
import * as i2 from "./api.service";
export class ProposalService {
    constructor(feathers, apiService) {
        this.feathers = feathers;
        this.apiService = apiService;
        this._fsServiceName = "proposals";
        this._createdProposal = new BehaviorSubject(null);
        this.setCreatedProposalEvent();
    }
    onCreatedProposal() {
        return this._createdProposal;
    }
    createProposal(data) {
        return this.apiService.post(this._fsServiceName, data).pipe(map(event => new Proposal().deserialize(event)));
    }
    setCreatedProposalEvent() {
        this.feathers.client.service(this._fsServiceName).on('created', event => {
            this._createdProposal.next(new Proposal().deserialize(event));
            this._createdProposal.next(null);
        });
    }
    getProposalVotes(token, params = {}) {
        const defaultParams = {
            query: {
                operationPath: '/votes'
            }
        };
        Object.assign(defaultParams.query, params);
        return this.apiService.get(this._fsServiceName, token, defaultParams).pipe(map(votes => {
            votes.forEach((value, index) => {
                votes[index] = new Vote().deserialize(value);
            });
            return votes;
        }));
    }
}
ProposalService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ProposalService_Factory() { return new ProposalService(i0.ɵɵinject(i1.FeathersService), i0.ɵɵinject(i2.ApiService)); }, token: ProposalService, providedIn: "root" });
