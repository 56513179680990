import { Injectable } from '@angular/core';

import { AuthService } from './auth.service';
import { MeService } from './me.service';
import { CookieStorage } from 'cookie-storage';

@Injectable({
  providedIn: 'root'
})
export class AppInitService {

  private _cookieStorage;
  private _storageKey: string = 'at';

  constructor(private auth: AuthService,
    private me: MeService) {
    this._cookieStorage = new CookieStorage();
  }

  getAuthentication(): Promise<any> {
    const accessToken = this._cookieStorage.getItem(this._storageKey);
    const url = window.location.href;

    if (accessToken && url.lastIndexOf('telecharger-donnees') == -1) {
      return new Promise((resolve, reject) => {
        this.auth.authenticate({
          accessToken: accessToken
        }).catch((e) => {
          this._cookieStorage.removeItem(this._storageKey);
          this.me.deleteMe();
        }).finally(() => resolve());
      });
    } else {
      this.me.deleteMe();
      return Promise.resolve();
    }
  }
}
