import { Injectable } from '@angular/core';

import { ApiService } from './api.service';
import { Circle } from '../models/circle.model';
import { Post } from '../models/post.model';
import { Member } from '../models/member.model';
import { Event } from '../models/event.model';
import { Observable, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { FeathersService } from './feathers.service';
import { Appointment } from '../models/appointment.model';

@Injectable({
  providedIn: 'root'
})
export class CircleService {

  private _fsServiceName: string = "circles";
  private _currentCircle: BehaviorSubject<Circle> = new BehaviorSubject<Circle>(null);
  private _updatedCircle: BehaviorSubject<Circle> = new BehaviorSubject<Circle>(null);

  constructor(private apiService: ApiService,
    private feathers: FeathersService) {
      this.setUpdatedCircleEvent();
  }

  getCurrentCircle(): Observable<Circle> {
    return this._currentCircle.asObservable();
  }

  updateCurrentCircle(circle: Circle | null) {
    this._currentCircle.next(circle);
  }

  createCircle(data: any, params: any = {}): Observable<any> {
    return this.apiService.post(this._fsServiceName, data).pipe(
      map(circle => new Circle().deserialize(circle))
    );
  }
  
  updateCircle(token: string, data: any): Observable<any> {
    return this.apiService.put(this._fsServiceName, token, data).pipe(
      map(circle => {
        return new Circle().deserialize(circle)
      })
    );
  }

    getCircles(params: any = {}): Observable<any> {
        const defaultParams = {
            query: params
        };

        return this.apiService.getAll(this._fsServiceName, defaultParams).pipe(
            map(circles => {
                circles.forEach((value, index) => {
                    circles[index] = new Circle().deserialize(value);
                });

                return circles;
            })
        );
    }

  getCircle(token: string): Observable<any> {
    return this.apiService.get(this._fsServiceName, token).pipe(
      map(circle => new Circle().deserialize(circle))
    );
  }

  getCirclePosts(token: string, params: any = {}): Observable<any> {
    const defaultParams = {
      query: {
        operationPath: '/posts'
      }
    };

    Object.assign(defaultParams.query, params);

    return this.apiService.get(this._fsServiceName, token, defaultParams).pipe(
      map(posts => {
        posts.forEach((value, index) => {
          posts[index] = new Post().deserialize(value);
        });

        return posts;
      })
    );
  }

  getCircleMembers(token: string, params: any = {}): Observable<any> {
    const defaultParams = {
      query: {
        operationPath: '/members'
      }
    };

    Object.assign(defaultParams.query, params);

    return this.apiService.get(this._fsServiceName, token, defaultParams).pipe(
      map(members => {
        members.forEach((value, index) => {
          members[index] = new Member().deserialize(value);
        });

        return members;
      })
    );
  }

  getCircleEvents(token: string, params: any = {}): Observable<any> {
    const defaultParams = {
      query: {
        operationPath: '/events'
      }
    };

    Object.assign(defaultParams.query, params);

    return this.apiService.get(this._fsServiceName, token, defaultParams).pipe(
      map(events => {
        events.forEach((value, index) => {
          events[index] = new Event().deserialize(value);
        });

        return events;
      })
    );
  }

  getCircleMembersMe(token: string): Observable<any> {
    const params = {
      query: {
        operationPath: '/members/me'
      }
    };

    return this.apiService.get(this._fsServiceName, token, params).pipe(
      map(member => new Member().deserialize(member))
    );
  }

  onUpdatedCircle(): Observable<Circle> {
    return this._updatedCircle;
  }

  deleteCircle(id: number|string, data: any): Observable<any> {
    const params = {
      query: {
        operationPath: '/delete'
      }
    };

    return this.apiService.put(this._fsServiceName, id, data, params).pipe(
      map(circle => new Circle().deserialize(circle))
    );
  }

  setUpdatedCircleEvent() {
    this.feathers.client.service(this._fsServiceName).on('updated', circle => {
      this._updatedCircle.next(new Circle().deserialize(circle));
      this._updatedCircle.next(null);
    });
  }

  getCircleAppointments(token: string, params: any = {}): Observable<any> {
    const defaultParams = {
      query: {
        operationPath: '/appointments'
      }
    };

    Object.assign(defaultParams.query, params);

    return this.apiService.get(this._fsServiceName, token, defaultParams).pipe(
      map(appointments => {
        appointments.forEach((value, index) => {
          appointments[index] = new Appointment().deserialize(value);
        });

        return appointments;
      })
    );
  }

}
