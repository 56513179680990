import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import HmacSHA256 from 'crypto-js/hmac-sha256';
import Base64 from 'crypto-js/enc-base64';
import { SubscriptionService } from '../../core/services/subscription.service';
import { SubscriptionModel } from '../../core/models/subscription.model';

@Component({
  selector: 'app-subscription-result',
  templateUrl: './subscription-result.component.html',
  styleUrls: ['./subscription-result.component.scss']
})
export class SubscriptionResultComponent implements OnInit {

  public isAccepted: boolean = false;
  
  constructor(private route: ActivatedRoute,
    private router: Router,
    private subscriptionService: SubscriptionService) { }

  ngOnInit() {
    this.updateSubscription();
  }

  updateSubscription() {
    let parameters = this.route.snapshot.queryParams;

    if (parameters['signature']) {
      if (this.verifySignature()) {
        if (SubscriptionModel.STATUS_OK.indexOf(parameters['vads_trans_status']) > -1) {
          this.isAccepted = true;
        }
        let subscription: any = {};
        subscription.alias = parameters['vads_identifier'];
        subscription.frequency = parameters['vads_sub_desc'].includes('MONTHLY') ? (parameters['vads_sub_desc'].includes('INTERVAL=3') ? 3 : 1) : 12;
        subscription.price = parameters['vads_sub_amount'] / 100;
        subscription.status = parameters['vads_trans_status'];
        let effectDate = parameters['vads_sub_effect_date'];
        effectDate = [effectDate.slice(0, 4), '-', effectDate.slice(4)].join('');
        effectDate = [effectDate.slice(0, 7), '-', effectDate.slice(7)].join('');
        subscription.effectDate = effectDate;
        this.subscriptionService.updateSubscription(parameters['vads_ext_info_subscription_id'], subscription);
      }
    }
  }

  verifySignature() {
    let parameters = this.route.snapshot.queryParams;
    let signatureContent = "";
    const keySogecommerce = environment.sogecommerce.key;
    let ordered = Object.entries(parameters).sort();
    for (const [key, value] of ordered) {
      if (key.substring(0,5) == 'vads_') {
        signatureContent += value + "+";
      }
    }
    signatureContent += keySogecommerce;
    let hash = HmacSHA256(signatureContent, keySogecommerce);
    return Base64.stringify(hash) == parameters['signature'];
  }

  refreshIframe() {
    this.router.navigateByUrl('profil/paiement');
  }

}
