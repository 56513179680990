import { of } from 'rxjs';
import { switchMap, catchError } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "../services/invitation.service";
import * as i2 from "@angular/router";
export class InvitationGuard {
    constructor(invitationService, router) {
        this.invitationService = invitationService;
        this.router = router;
    }
    canActivate(next, state) {
        if (!next.params.token) {
            return of(false);
        }
        return this.invitationService.getInvitation(next.params.token).pipe(switchMap(response => of(true)), catchError(error => {
            this.router.navigate(['']);
            return of(false);
        }));
    }
    canActivateChild(next, state) {
        return true;
    }
    canLoad(route, segments) {
        return true;
    }
}
InvitationGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function InvitationGuard_Factory() { return new InvitationGuard(i0.ɵɵinject(i1.InvitationService), i0.ɵɵinject(i2.Router)); }, token: InvitationGuard, providedIn: "root" });
