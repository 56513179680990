import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { combineLatest, Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

import { Member } from '../../../core/models/member.model';
import { Appointment } from '../../../core/models/appointment.model';
import { CircleService } from '../../../core/services/circle.service';
import { AppointmentService } from '../../../core/services/appointment.service';
import { LoadingService } from '../../../core/services/loading.service';
import { AlertService } from '../../../core/services/alert.service';
import { ModalConfirmationComponent } from '../modal-confirmation/modal-confirmation.component';

@Component({
  selector: 'app-appointment-event',
  templateUrl: './appointment-event.component.html',
  styleUrls: ['./appointment-event.component.scss']
})
export class AppointmentEventComponent implements OnInit {

  @Input() public appointment: Appointment = new Appointment();
  public visio = Appointment.VISIO;
  public memberMe: Member = new Member();
  public modalConfirmation: BsModalRef;
  private subscriptionsModal: Subscription[] = [];

  constructor(private circleService: CircleService,
    private appointmentService: AppointmentService,
    private bsModalService: BsModalService,
    private loadingService: LoadingService,
    private alertService: AlertService,
    private toastrService: ToastrService,
    private changeDetection: ChangeDetectorRef) { }

  ngOnInit() {
    this.getMe();
  }

  getMe() {
    this.circleService.getCircleMembersMe(this.appointment.circle.token).subscribe(member => {
      this.memberMe = member;
    });
  }

  removeAppointment() {
    this.modalConfirmation = this.bsModalService.show(ModalConfirmationComponent);
    this.modalConfirmation.content.objectToRemove = "supprimer ce rendez-vous";

    const _combine = combineLatest(
        this.bsModalService.onHide
    ).subscribe(() => this.changeDetection.markForCheck());
    
    this.subscriptionsModal.push(
      this.bsModalService.onHide.subscribe((reason) => {
          if (this.modalConfirmation.content && this.modalConfirmation.content.confirmation) {
              this.loadingService.showLoading();
              this.appointmentService.deleteAppointment(this.appointment.getId()).pipe(
                finalize(() => this.loadingService.dismissLoading())
            ).subscribe(appointment => {
                this.alertService.closeAlert();
                this.toastrService.success('Le rendez-vous a bien été supprimé', 'Confirmation');
            }, error => {
              console.log(error);
              this.alertService.showAlert(error.errors, 'danger');
            });
            this.appointmentService.patchAppointment(this.appointment).subscribe(appointment => {
            });
          }
          this.unsubscribe();
      })
    );
    this.subscriptionsModal.push(_combine);
  }

  private unsubscribe() {
    this.subscriptionsModal.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
    this.subscriptionsModal = [];
  }

}
