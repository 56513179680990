import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AppointmentService } from '../../../core/services/appointment.service';
import { CircleService } from '../../../core/services/circle.service';
import { VoteService } from '../../../core/services/vote.service';
import { AlertService } from '../../../core/services/alert.service';
import { ProposalService } from '../../../core/services/proposal.service';
import { LoadingService } from '../../../core/services/loading.service';
import { Appointment } from '../../../core/models/appointment.model';
import { Vote } from '../../../core/models/vote.model';
import { Member } from '../../../core/models/member.model';
import { Proposal } from '../../../core/models/proposal.model';
import { ModalAddProposalComponent } from '../modal-add-proposal/modal-add-proposal.component';

@Component({
  selector: 'app-appointment',
  templateUrl: './appointment.component.html',
  styleUrls: ['./appointment.component.scss']
})
export class AppointmentComponent implements OnInit, OnDestroy {

  public appointment: Appointment;
  public memberMe: Member = new Member();
  private _observers: Subscription[] = [];
  public isVisio = Appointment.VISIO;
  public yes = Vote.CHOICE_YES;
  public maybe = Vote.CHOICE_MAYBE;
  public no = Vote.CHOICE_NO;
  public voteForm: FormGroup;
  public voteCounterPropForm: FormGroup;
  public creatorProposals: Proposal[] = [];
  public counterProposals: Proposal[] = [];
  public newCounterProposals: Proposal[] = [];
  public votesProposal: Vote[] = [];
  public votesCounterProposal: Vote[] = [];
  public newVotes: Vote[] = [];
  public newVotesCounterProp: Vote[] = [];
  public meHasVoted: boolean = false;
  public meVotes: number[] = [];
  public yesTotal: number[] = [];
  public newYesTotal: number[] = [];
  public modalAddProposal: BsModalRef;
  
  constructor(private appointmentService: AppointmentService,
    private activatedRoute: ActivatedRoute,
    private circleService: CircleService,
    private proposalService: ProposalService,
    private voteService: VoteService,
    private alertService: AlertService,
    private modalService: BsModalService,
    private loadingService: LoadingService,
    private toastrService: ToastrService,
    private formBuilder: FormBuilder,
    private router: Router) { }

  ngOnInit() {
    this.getAppointment();
    this.setObservers();
    this.prepareVoteForm();
    this.prepareVoteCounterPropForm();
  }

  setObservers() {
    this._observers.push(this.setCreatedProposalObserver());
    this._observers.push(this.setCreatedVoteObserver());
  }

  setCreatedProposalObserver(): Subscription {
    return this.proposalService.onCreatedProposal().subscribe(proposal => {
        if (!proposal) {
          return;
        }

        if (this.appointment && proposal.appointment == this.appointment.getIRI() && proposal.isCounterProposal) {
          this.newCounterProposals.push(proposal);

          let nbCounterProp = this.counterProposals.length + this.newCounterProposals.length;
          for (let i = this.counterProposals.length; i < nbCounterProp; i++) {
            this.proposalsArray.push(this.formBuilder.group({
              vote: ['']
            }))
          }
        }
    });
  }

  setCreatedVoteObserver(): Subscription {
    return this.voteService.onCreatedVote().subscribe(vote => {
        if (!vote) {
            return;
        }

        if (this.newVotes.filter(v => v.member.getId() == vote.member.getId() && v.proposal.getId() == vote.proposal.getId()).length == 0) {
          for (var i = 0; i < this.creatorProposals.length; i++) {
            if (this.creatorProposals[i].getIRI() == vote.proposal.getIRI()) {
              this.newVotes.push(vote);
              if (vote.choice == this.yes) {
                this.yesTotal[vote.proposal.getId()]++;
              }
              if (vote.member.getIRI() == this.memberMe.getIRI()) {
                this.meHasVoted = true;
              }
              break;
            }
          }
        }
        if (this.newVotesCounterProp.filter(v => v.member.getId() == vote.member.getId() && v.proposal.getId() == vote.proposal.getId()).length == 0) {
          for (var i = 0; i < this.counterProposals.length; i++) {
            if (this.counterProposals[i].getIRI() == vote.proposal.getIRI()) {
              this.newVotesCounterProp.push(vote);
              if (vote.choice == this.yes) {
                this.yesTotal[vote.proposal.getId()]++;
              }
              if (vote.member.getIRI() == this.memberMe.getIRI()) {
                this.meVotes.push(vote.proposal.getId());
              }
              break;
            }
          }
          for (var i = 0; i < this.newCounterProposals.length; i++) {
            if (this.newCounterProposals[i].getIRI() == vote.proposal.getIRI()) {
              this.newVotesCounterProp.push(vote);
              if (vote.choice == this.yes) {
                this.newYesTotal[vote.proposal.getId()]++;
              }
              if (vote.member.getIRI() == this.memberMe.getIRI()) {
                this.meVotes.push(vote.proposal.getId());
              }
              break;
            }
          }
        }
    });
  }

  getMe(token: string) {
    this.circleService.getCircleMembersMe(token).subscribe(member => {
      this.memberMe = member;
      this.getProposalsAndVotes();
    });
  }

  getProposalsAndVotes() {
    for (var i = 0; i < this.appointment.proposals.length; i++) {
      let proposal = this.appointment.proposals[i];
      this.yesTotal[proposal.getId()] = 0;
      // Get proposals from the creator of the appointment
      if (this.appointment.member.getId() == proposal.member.getId() && !proposal.isCounterProposal) {
        this.creatorProposals.push(proposal);
        this.creatorProposals.sort((a, b) => (a.date > b.date) ? 1 : ((b.date > a.date) ? -1 : 0));

        this.proposalService.getProposalVotes(proposal.getId()).subscribe(votes => {
          this.votesProposal = this.votesProposal.concat(votes);
          for (var j = 0; j < votes.length ; j++) {
            if (votes[j].choice == this.yes) {
              this.yesTotal[proposal.getId()]++;
            }
            if (votes[j].member.getId() == this.memberMe.getId()) {
              // If memberMe has one vote to one proposal of the creator, it is not necessary to display the form
              this.meHasVoted = true;
            }
          }
        });
      } else {
        this.counterProposals.push(proposal);
        this.counterProposals.sort((a, b) => (a.date > b.date) ? 1 : ((b.date > a.date) ? -1 : 0));

        this.proposalService.getProposalVotes(proposal.getId()).subscribe(votes => {
          this.votesCounterProposal = this.votesCounterProposal.concat(votes);
          for (var j = 0; j < votes.length ; j++) {
            if (votes[j].choice == this.yes) {
              this.yesTotal[proposal.getId()]++;
            }
            if (votes[j].member.getId() == this.memberMe.getId()) {
              // If memberMe has voted for the proposal, it is not necessary to display the radio buttons
              this.meVotes.push(proposal.getId());
            }
          }
        });
      }
    }

    for (let i = this.proposalsArray.length; i < this.counterProposals.length; i++) {
      this.proposalsArray.push(this.formBuilder.group({
        vote: ['']
      }))
    }
  }

  prepareVoteForm() {
    this.voteForm = new FormGroup({
      votePropOne: new FormControl('', Validators.required),
      votePropTwo: new FormControl('', this.creatorProposals.length > 1 ? Validators.required : null),
      votePropThree: new FormControl('', this.creatorProposals.length > 2 ? Validators.required : null)
    })
  }

  prepareVoteCounterPropForm() {
    this.voteCounterPropForm = this.formBuilder.group({
      proposals: new FormArray([])
    });
  }
  
  getAppointment() {
    return this.appointmentService.getAppointment(this.activatedRoute.snapshot.params.id).subscribe(appointment => {
      this.appointment = appointment;
      this.getMe(appointment.circle.token);
    }, error => {
        console.log(error.errors)
    });
  }

  setNewVote(proposal: Proposal, choice: string) {
    let newVote: any = {};
    newVote.proposal = proposal.getIRI();
    newVote.choice = choice;
    return newVote;
  }

  onVoteFormSubmit() {
    this.voteForm['submitted'] = true;
    if (this.voteForm.valid) {
      let newVote = {};
      for (var i = 0; i < this.creatorProposals.length; i++) {
        let choice = (i == 0) ? this.votePropOne.value : ((i == 1) ? this.votePropTwo.value : this.votePropThree.value);
        newVote = this.setNewVote(this.creatorProposals[i], choice);
        this.voteService.createVote(newVote).subscribe(vote => {
          this.voteForm['submitted'] = false;
          this.voteForm.reset();
        }, error => {
          console.log(error.errors);
          this.alertService.showAlert(error.errors, 'danger');
        });
      }
    }
  }

  onVoteCounterPropFormSubmit() {
    this.voteCounterPropForm['submitted'] = true;
    if (this.voteCounterPropForm.valid) {
      for (let i = 0; i < this.proposalsArray.length; i++) {
        if (this.proposalsArray.value[i].vote != '') {
          let newVote: any = {};
          newVote.proposal = i < this.counterProposals.length ? this.counterProposals[i].getIRI() : this.newCounterProposals[i - this.counterProposals.length].getIRI();
          newVote.choice = this.proposalsArray.value[i].vote;
          this.voteService.createVote(newVote).subscribe(vote => {
            this.voteCounterPropForm['submitted'] = false;
            this.voteCounterPropForm.reset();
            this.proposalsArray.value[i].vote = '';
          }, error => {
            console.log(error.errors)
            this.alertService.showAlert(error.errors, 'danger');
          });
        }
      }
    }
  }

  onChoose(proposalId: number) {
    let updatedAppointment: any = {};
    this.loadingService.showLoading();
    updatedAppointment.circle = this.appointment.circle.getIRI();
    let finalProposal = this.creatorProposals.filter(p => p.getId() == proposalId).length > 0 ? this.creatorProposals.filter(p => p.getId() == proposalId) : this.counterProposals.filter(p => p.getId() == proposalId)
    updatedAppointment.finalChoice = finalProposal[0].date;
    this.appointmentService.updateAppointment(this.appointment.getId(), updatedAppointment).pipe(
      finalize(() => this.loadingService.dismissLoading())
    ).subscribe(appointment => {
      this.alertService.closeAlert();
      this.router.navigate(['cercles', this.appointment.circle.token]);
      this.toastrService.success('La date finale du rendez-vous a bien été choisie', 'Confirmation');
    }, error => {
      console.log(error)
      this.alertService.showAlert(error.errors, 'danger');
    });
  }

  openAddProposalModal() {
    this.modalAddProposal = this.modalService.show(ModalAddProposalComponent);
    this.modalAddProposal.content.appointment = this.appointment;
  }

  get votePropOne() { return this.voteForm.get('votePropOne'); }
  get votePropTwo() { return this.voteForm.get('votePropTwo'); }
  get votePropThree() { return this.voteForm.get('votePropThree'); }
  get dynamicControls() { return this.voteCounterPropForm.controls; }
  get proposalsArray() { return this.dynamicControls.proposals as FormArray; }

  ngOnDestroy() {
    this._observers.forEach((observer, index) => {
        observer.unsubscribe();
    });
  }
}
