import { Component, OnInit, OnDestroy, Input, Output, SimpleChanges, ChangeDetectorRef } from '@angular/core';

import { combineLatest, Subscription } from 'rxjs';
import { Post } from '../../../core/models/post.model';
import { Member } from '../../../core/models/member.model';
import { Comment } from '../../../core/models/comment.model';
import { PostService } from '../../../core/services/post.service';
import { CommentService } from '../../../core/services/comment.service';
import { LoadingService } from '../../../core/services/loading.service';
import { AlertService } from '../../../core/services/alert.service';
import { finalize } from 'rxjs/operators';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ReactionType, reactionTypeFilled, reactionTypeOutlined } from '../../../core/enums/reaction-type.enum';
import { ReactionService } from '../../../core/services/reaction.service';
import { Reaction } from '../../../core/models/reaction.model';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ModalReactionsComponent } from '../modal-reactions/modal-reactions.component';
import { KeyValue } from '@angular/common';
import { ModalEditPostComponent } from '../modal-edit-post/modal-edit-post.component';
import { DatePipe } from '@angular/common';
import { ModalConfirmationComponent } from '../modal-confirmation/modal-confirmation.component';

@Component({
    selector: 'app-post',
    templateUrl: './post.component.html',
    styleUrls: ['./post.component.scss']
})
export class PostComponent implements OnInit, OnDestroy {

    @Input() public post: Post = new Post();
    @Input() public memberMe: Member = new Member();
    @Input() public openedComments: boolean = false;
    @Input() public index: number;
    private _observers: Subscription[] = [];
    private subscriptionsModal: Subscription[] = [];
    private _commentsPage: number = 1;
    public newComments: Comment[] = [];
    public loadedComments: Comment[] = [];
    public nbDeletedComments: number = 0;
    public commentForm: FormGroup;
    public newComment: any = {};
    public newReaction: any = {};
    public reactions: Reaction[] = [];
    public newReactions: Reaction[] = [];
    public reactionOutlined = reactionTypeOutlined;
    public reactionFilled = reactionTypeFilled;
    public reactionMemberMe: string = "";
    public nbReactions: any = {};
    public modalReaction: BsModalRef;
    public modalEditPost: BsModalRef;
    public modalConfirmation: BsModalRef;
    public isReacted: boolean = false;

    constructor(private commentService: CommentService,
        private loadingService: LoadingService,
        private toastrService: ToastrService,
        private postService: PostService,
        private alertService: AlertService,
        private reactionService: ReactionService,
        private modalService: BsModalService,
        private datePipe: DatePipe,
        private changeDetection: ChangeDetectorRef) { }

    ngOnInit() {
        this.getNbDeletedComments();
        this.getComments();
        this.setObservers();
        this.prepareCommentForm();
        this.getReactions();
    }

    setObservers() {
        this._observers.push(this.setCreatedCommentObserver());
        this._observers.push(this.setUpdatedCommentObserver());
        this._observers.push(this.setCreatedReactionObserver());
        this._observers.push(this.setUpdatedReactionObserver());
        this._observers.push(this.setRemovedReactionObserver());
    }

    setReactionMemberMe() {
        for(var i = 0; i < this.reactions.length; i++) {
            let reaction = this.reactions[i];
            if (reaction.member.getIRI() == this.memberMe.getIRI()) {
                this.reactionMemberMe = reaction.type;
                break;
            }
        }
    }

    getClassReactionMemberMe(): string {
        return this.reactionFilled[this.reactionMemberMe] ? this.reactionFilled[this.reactionMemberMe] : "far fa-thumbs-up";
    }

    getComments() {
        this.postService.getPostComments(this.post.getId(), { page: this._commentsPage }).subscribe(comments => {
            // check comments exists
            comments = comments.filter((comment: Comment) => {
                return !this.newComments.map((newComment: Comment) => {
                    return newComment.getId();
                }).includes(comment.getId());
            });
            
            (comments.length > 0) ? this._commentsPage++ : this._commentsPage = 0;

            this.loadedComments = this.loadedComments.concat(comments);
        });
    }

    getReactions() {
        this.postService.getPostReactions(this.post.getId()).subscribe(reactions => {
            
            reactions = reactions.filter((reaction: Reaction) => {
                return !this.newReactions.map((newReaction: Reaction) => {
                    return newReaction.getId();
                }).includes(reaction.getId());
            });

            this.reactions = this.reactions.concat(reactions);
            this.getNbReactionsByType();
            this.setReactionMemberMe();
        });
    }

    setCreatedCommentObserver(): Subscription {
        return this.commentService.onCreatedComment()
            .subscribe(comment => {

                if (!comment) {
                    return;
                }

                if (this.post.getIRI() == comment.post.getIRI()) {
                    this.newComments.push(comment);
                    if (this.memberMe.getId() != comment.member.getId()) {
                        this.toastrService.success(`${comment.member.user.firstName} ${comment.member.user.lastName} vient de commenter un post`, 'Notification');
                    }
                }
            });
    }

    setUpdatedCommentObserver(): Subscription {
        return this.commentService.onUpdatedComment()
            .subscribe(comment => {
                if (!comment) {
                    return;
                }

                if (this.post.getIRI() == comment.post.getIRI()) {
                    this.loadedComments = this.mapComments(this.loadedComments, comment);
                    this.newComments = this.mapComments(this.newComments, comment);
                }
            })
    }

    setCreatedReactionObserver(): Subscription {
        return this.reactionService.onCreatedReaction()
            .subscribe(reaction => {
                if (!reaction) {
                    return;
                }

                if (reaction.post != null) {
                    if (this.post.getIRI() == reaction.post.getIRI()) {
                        this.newReactions.push(reaction);
                        this.getNbReactionsByType();
                        if (this.memberMe.getId() != reaction.member.getId()) {
                            this.toastrService.success(`${reaction.member.user.firstName} ${reaction.member.user.lastName} vient de réagir à un post`, 'Notification');
                        }
                    }
                }
        });
    }

    setUpdatedReactionObserver(): Subscription {
        return this.reactionService.onUpdatedReaction()
            .subscribe(reaction => {
                if (!reaction) {
                    return;
                }

                if (reaction.post != null) {
                    if (this.post.getIRI() == reaction.post.getIRI()) {
                        this.reactions = this.mapReactions(this.reactions, reaction);
                        this.newReactions = this.mapReactions(this.newReactions, reaction);
                        this.getNbReactionsByType();
                    }
                }
        });
    }

    setRemovedReactionObserver(): Subscription {
        return this.reactionService.onRemovedReaction()
            .subscribe(reaction => {
                if (!reaction) {
                    return;
                }

                this.newReactions = this.filterReactions(this.newReactions, reaction);
                this.reactions = this.filterReactions(this.reactions, reaction);
                this.getNbReactionsByType();
        });
    }

    getNbDeletedComments() {
        this.postService.getPostDeletedComments(this.post.getId()).subscribe(comments => {
            this.nbDeletedComments = comments.length;
        });
    }

    getNbComments(): number {
        let currentNbComments = 0;
        for (let i = 0; i < this.loadedComments.length; i++) {
            if (!this.loadedComments[i].deletedAt) {
                currentNbComments++;
            }
        }
        for (let i = 0; i < this.newComments.length; i++) {
            if (!this.newComments[i].deletedAt) {
                currentNbComments++;
            }
        }

        return (currentNbComments > this.post.comments.length - this.nbDeletedComments) ? currentNbComments : this.post.comments.length - this.nbDeletedComments;
    }

    getNbReactionsByType() {
        this.nbReactions = {[ReactionType.LIKE]: 0, [ReactionType.LOVE]: 0, [ReactionType.QUESTION]: 0};
        for(var i = 0; i < this.reactions.length; i++) {
            this.nbReactions[this.reactions[i].type] += 1;
        }
        for(var j = 0; j < this.newReactions.length; j++) {
            this.nbReactions[this.newReactions[j].type] += 1;
        }
        this.isReacted = this.reactions.length > 0 || this.newReactions.length > 0;
    }

    prepareCommentForm() {
        this.commentForm = new FormGroup({
            message: new FormControl('', Validators.required)
        });
    }

    setNewComment() {
        this.newComment.message = this.commentForm.get('message').value;
        this.newComment.post = this.post.getIRI();
    }

    onCommentFormSubmit() {
        this.commentForm['submitted'] = true;

        if (this.commentForm.valid) {
            this.setNewComment();
            this.loadingService.showLoading();
            this.commentService.createComment(this.newComment).pipe(
                finalize(() => this.loadingService.dismissLoading())
            ).subscribe(comment => {
                this.commentForm.reset();
                this.commentForm['submitted'] = false;
                this.alertService.closeAlert();
            }, error => {
                const errorMessage: string[] = error.errors ? error.errors : ["Impossible de poster un commentaire pour l'instant, veuillez réessayer ultérieurement, merci"];
                this.alertService.showAlert(errorMessage, 'danger');
                console.log(error);
            });
        }
    }

    onLike() {
        this.react(ReactionType.LIKE);
    }

    onLove() {
        this.react(ReactionType.LOVE);
    }

    onQuestion() {
        this.react(ReactionType.QUESTION);
    }

    react(reactionType: ReactionType) {
        let alreadyReacted = false;
        let currentReaction: any = {};
        for(var i = 0; i < this.reactions.length; i++) {
            if (this.reactions[i].member.getIRI() == this.memberMe.getIRI()) {
                currentReaction = this.reactions[i];
                alreadyReacted = true;
                break;
            }
        }
        for(var j = 0; j < this.newReactions.length; j++) {
            if (this.newReactions[j].member.getIRI() == this.memberMe.getIRI()) {
                currentReaction = this.newReactions[j];
                alreadyReacted = true;
                break;
            }
        }
        if (alreadyReacted) {
            if (currentReaction.type != reactionType) {
                currentReaction.type = reactionType;
                currentReaction.post = this.post.getIRI();
                this.reactionService.updateReaction(currentReaction.token, currentReaction)
                .subscribe(reaction => {
                    this.reactionMemberMe = reactionType;
                });
            } else {
                this.reactionService.deleteReaction(currentReaction, currentReaction.token)
                .subscribe(reaction => {
                    this.reactionMemberMe = "";
                });
                this.reactionService.patchReaction(currentReaction).subscribe(reaction => {
                    
                });
            }
        } else {
            this.newReaction.post = this.post.getIRI();
            this.newReaction.type = reactionType;
            this.reactionService.createReaction(this.newReaction)
            .subscribe(reaction => {
                this.reactionMemberMe = reactionType;
            });
        }
    }

    filterReactions(reactionsTab: Reaction[], deletedReaction: Reaction) {
        for(var i = 0; i < reactionsTab.length; i++) {
            if (deletedReaction && reactionsTab[i].getIRI() == deletedReaction.getIRI()) {
                reactionsTab.splice(i, 1);
            }
        }
        return reactionsTab;
    }

    mapReactions(reactionsTab: Reaction[], currentReaction: Reaction) {
        for(var i = 0; i < reactionsTab.length; i++) {
            if (reactionsTab[i].getIRI() == currentReaction.getIRI()) {
                reactionsTab[i] = currentReaction;
            }
        }
        return reactionsTab;
    }

    private mapComments(commentsTab: Comment[], currentComment: Comment) {
        for(var i = 0; i < commentsTab.length; i++) {
            if (commentsTab[i].getIRI() == currentComment.getIRI()) {
                commentsTab[i] = currentComment;
            }
        }
        return commentsTab;
    }

    orderOriginal = (a: KeyValue<number,string>, b: KeyValue<number,string>): number => {
        return 0
    }

    openReactionModal() {
        this.modalReaction = this.modalService.show(ModalReactionsComponent);
        this.modalReaction.content.nbReactions = this.nbReactions;
        this.modalReaction.content.reactions = this.reactions.concat(this.newReactions);
    }

    onEdit() {
        const initialState = {
            post: this.post
        };
        this.modalEditPost = this.modalService.show(ModalEditPostComponent, {initialState});
    }

    setDeletedPost(): FormData {
        let input = new FormData();
    
        input.append('_id', this.post.getId().toString());
        input.append('message', this.post.message);
        input.append('deletedAt', this.datePipe.transform(new Date(), 'yyyy-MM-dd HH:mm:ss'));
        input.append('circle', this.post.circle.getIRI());
    
        return input;
    }

    onDelete() {
        this.modalConfirmation = this.modalService.show(ModalConfirmationComponent);
        this.modalConfirmation.content.objectToRemove = "supprimer ce post";

        const _combine = combineLatest(
            this.modalService.onHide
          ).subscribe(() => this.changeDetection.markForCheck());
      
        this.subscriptionsModal.push(
            this.modalService.onHide.subscribe((reason) => {
                if (this.modalConfirmation.content && this.modalConfirmation.content.confirmation) {
                    const deletedPost = this.setDeletedPost();
                    this.loadingService.showLoading();

                    this.postService.updatePost(deletedPost).pipe(
                        finalize(() => this.loadingService.dismissLoading())
                    ).subscribe(post => {
                        if (this.post.getIRI() == post.getIRI()) {
                            this.alertService.closeAlert();
                            this.toastrService.success('Votre post a bien été supprimé', 'Confirmation');
                        }
                    }, error => {
                        const errorMessage: string[] = error.errors ? error.errors : ["Impossible de supprimer un message pour l'instant, veuillez réessayer ultérieurement, merci"];
                        this.alertService.showAlert(errorMessage, 'danger');
                        console.log(error);
                    });
                }
                this.unsubscribe();
            })
        );
        this.subscriptionsModal.push(_combine);
    }

    private unsubscribe() {
        this.subscriptionsModal.forEach((subscription: Subscription) => {
          subscription.unsubscribe();
        });
        this.subscriptionsModal = [];
    }

    ngOnDestroy() {
        this._observers.forEach((observer, index) => {
            observer.unsubscribe();
        });
    }

}
