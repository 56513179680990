import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
export class AlertService {
    constructor() {
        this._alert = new BehaviorSubject(null);
    }
    alert() {
        return this._alert.asObservable();
    }
    showAlert(messages, type = "success") {
        this._alert.next({
            messages: messages,
            type: type
        });
    }
    closeAlert() {
        this._alert.next(null);
    }
}
AlertService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AlertService_Factory() { return new AlertService(); }, token: AlertService, providedIn: "root" });
