<div class="h-lg-100 overflow-auto bg-grey-92">
    <ng-template [ngIf]="loadingPost && post.member">
        <ng-template [ngIf]="!post.deletedAt" [ngIfElse]="postDeleted">
            <div class="container-fluid py-10 pt-sm-4">
                <div class="row pt-7 pt-sm-0 mb-3">
                    <div class="col-12 post-details">
                        <a [routerLink]="['/cercles', circle.token]" class="" title="Revenir au cercle">
                            <i class="fas fa-arrow-left ml-1 mr-2 align-middle"></i>
                            <span>Revenir au cercle</span>
                        </a>
                    </div>
                </div>
                <div class="row h-lg-100">
                    <div class="col-12 col-lg-9 post-details">
                        <app-post [post]="post" [memberMe]="memberMe" [openedComments]="true" [index]="0"></app-post>
                    </div>
                </div>
            </div>
        </ng-template>
        <ng-template #postDeleted>
            <div class="container-fluid pt-6 pb-1">
                <div>Le post n'existe plus.</div>
                <a [routerLink]="['/cercles', circle.token]" class="btn-link-primary">Revenir au cercle</a>
            </div>
        </ng-template>
    </ng-template>
</div>
