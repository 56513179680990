import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { RegisterComponent } from './register/register.component';
import { InvitationComponent } from './invitation/invitation.component';
import { PasswordRequestComponent } from './password-request/password-request.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';

import { AnonymousGuard } from '../core/guards/anonymous.guard';
import { AuthGuard } from '../core/guards/auth.guard';
import { InvitationGuard } from '../core/guards/invitation.guard';
import { PasswordRequestGuard } from '../core/guards/password-request.guard';

const routes: Routes = [
    {
        path: "inscription",
        component: RegisterComponent,
        canActivate: [AnonymousGuard],
        runGuardsAndResolvers: 'always',
        data: {
            hideHeader: true
        }
    },
    {
        path: "invitation/:token",
        component: InvitationComponent,
        canActivate: [AuthGuard, InvitationGuard],
        runGuardsAndResolvers: 'always',
        data: {
          hideHeader: true
        }
    },
    {
        path: "mot-de-passe-oublie",
        component: PasswordRequestComponent,
        canActivate: [AnonymousGuard],
        runGuardsAndResolvers: 'always',
        data: {
          hideHeader: true
        }
    },
    {
        path: "nouveau-mot-de-passe/:token",
        component: ResetPasswordComponent,
        canActivate: [AnonymousGuard, PasswordRequestGuard],
        runGuardsAndResolvers: 'always',
        data: {
          hideHeader: true
        }
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class AuthenticationRoutingModule { }
