import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { combineLatest, Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';

import { ReactionType, reactionTypeFilled, reactionTypeOutlined } from '../../../core/enums/reaction-type.enum';
import { CommentService } from '../../../core/services/comment.service';
import { Member } from '../../../core/models/member.model';
import { Comment } from '../../../core/models/comment.model';
import { ReactionService } from '../../../core/services/reaction.service';
import { Reaction } from '../../../core/models/reaction.model';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { DatePipe, KeyValue } from '@angular/common';
import { ModalReactionsComponent } from '../modal-reactions/modal-reactions.component';
import { ModalEditCommentComponent } from '../modal-edit-comment/modal-edit-comment.component';
import { LoadingService } from '../../../core/services/loading.service';
import { AlertService } from '../../../core/services/alert.service';
import { ModalConfirmationComponent } from '../modal-confirmation/modal-confirmation.component';

@Component({
  selector: 'app-comment',
  templateUrl: './comment.component.html',
  styleUrls: ['./comment.component.scss']
})
export class CommentComponent implements OnInit {

  @Input() public comment: Comment = new Comment();
  @Input() public memberMe: Member = new Member();
  private _observers: Subscription[] = [];
  private subscriptionsModal: Subscription[] = [];
  public newReaction: any = {};
  public reactions: Reaction[] = [];
  public newReactions: Reaction[] = [];
  public reactionOutlined = reactionTypeOutlined;
  public reactionFilled = reactionTypeFilled;
  public reactionMemberMe: string = "";
  public nbReactions: any = {};
  public modalReaction: BsModalRef;
  public modalEditComment: BsModalRef;
  public modalConfirmation: BsModalRef;
  public commentToDelete: any = {};
  public isReacted: boolean = false;

  constructor(private reactionService: ReactionService,
    private commentService: CommentService,
    private toastrService: ToastrService,
    private modalService: BsModalService,
    private loadingService: LoadingService,
    private alertService: AlertService,
    private datePipe: DatePipe,
    private changeDetection: ChangeDetectorRef) { }

  ngOnInit() {
    this.setObservers();
    this.getReactions();
  }

  setObservers() {
    this._observers.push(this.setCreatedReactionObserver());
    this._observers.push(this.setUpdatedReactionObserver());
    this._observers.push(this.setRemovedReactionObserver());
  }

  setReactionMemberMe() {
    for(var i = 0; i < this.reactions.length; i++) {
      let reaction = this.reactions[i];
      if (reaction.member.getIRI() == this.memberMe.getIRI()) {
          this.reactionMemberMe = reaction.type;
          break;
      }
    }
  }

  getClassReactionMemberMe(): string {
    return this.reactionFilled[this.reactionMemberMe] ? this.reactionFilled[this.reactionMemberMe] : "far fa-thumbs-up";
  }

  getReactions() {
    this.commentService.getCommentReactions(this.comment.getId()).subscribe(reactions => {
        reactions = reactions.filter((reaction: Reaction) => {
            return !this.newReactions.map((newReaction: Reaction) => {
                return newReaction.getId();
            }).includes(reaction.getId());
        });

        this.reactions = this.reactions.concat(reactions);
        this.getNbReactionsByType();
        this.setReactionMemberMe();
    });
  }

  setCreatedReactionObserver(): Subscription {
    return this.reactionService.onCreatedReaction()
      .subscribe(reaction => {
          if (!reaction) {
              return;
          }

          if (reaction.comment != null) {
            if (this.comment.getIRI() == reaction.comment.getIRI()) {
                this.newReactions.push(reaction);
                this.getNbReactionsByType();
                if (this.memberMe.getId() != reaction.member.getId()) {
                    this.toastrService.success(`${reaction.member.user.firstName} ${reaction.member.user.lastName} vient de réagir à un commentaire`, 'Notification');
                }
            }
          }
      });
  }

  setUpdatedReactionObserver(): Subscription {
    return this.reactionService.onUpdatedReaction()
        .subscribe(reaction => {
            if (!reaction) {
                return;
            }

            if (reaction.comment != null) {
                if (this.comment.getIRI() == reaction.comment.getIRI()) {
                    this.reactions = this.mapReactions(this.reactions, reaction);
                    this.newReactions = this.mapReactions(this.newReactions, reaction);
                    this.getNbReactionsByType();
                }
            }
    });
  }

  setRemovedReactionObserver(): Subscription {
      return this.reactionService.onRemovedReaction()
          .subscribe(reaction => {
              if (!reaction) {
                  return;
              }

              this.newReactions = this.filterReactions(this.newReactions, reaction);
              this.reactions = this.filterReactions(this.reactions, reaction);
              this.getNbReactionsByType();
      });
  }

  getNbReactionsByType() {
    this.nbReactions = {[ReactionType.LIKE]: 0, [ReactionType.LOVE]: 0, [ReactionType.QUESTION]: 0};
    for(var i = 0; i < this.reactions.length; i++) {
        this.nbReactions[this.reactions[i].type] += 1;
    }
    for(var j = 0; j < this.newReactions.length; j++) {
        this.nbReactions[this.newReactions[j].type] += 1;
    }
    this.isReacted = this.reactions.length > 0 || this.newReactions.length > 0;
  }

  onLike() {
    this.react(ReactionType.LIKE);
  }

  onLove() {
    this.react(ReactionType.LOVE);
  }

  onQuestion() {
    this.react(ReactionType.QUESTION);
  }

  react(reactionType: ReactionType) {
    let alreadyReacted = false;
    let currentReaction: any = {};
    for(var i = 0; i < this.reactions.length; i++) {
        if (this.reactions[i].member.getIRI() == this.memberMe.getIRI()) {
            currentReaction = this.reactions[i];
            alreadyReacted = true;
            break;
        }
    }
    for(var j = 0; j < this.newReactions.length; j++) {
        if (this.newReactions[j].member.getIRI() == this.memberMe.getIRI()) {
            currentReaction = this.newReactions[j];
            alreadyReacted = true;
            break;
        }
    }
    if (alreadyReacted) {
        if (currentReaction.type != reactionType) {
            currentReaction.type = reactionType;
            currentReaction.comment = this.comment.getIRI();
            this.reactionService.updateReaction(currentReaction.token, currentReaction)
            .subscribe(reaction => {
                this.reactionMemberMe = reactionType;
            });
        } else {
          this.reactionService.deleteReaction(currentReaction, currentReaction.token)
          .subscribe(reaction => {
              this.reactionMemberMe = "";
          });
          this.reactionService.patchReaction(currentReaction).subscribe(reaction => {
              
          });
        }
    } else {
        this.newReaction.comment = this.comment.getIRI();
        this.newReaction.type = reactionType;
        this.reactionService.createReaction(this.newReaction)
        .subscribe(reaction => {
            this.reactionMemberMe = reactionType;
        });
    }
  }

  filterReactions(reactionsTab: Reaction[], deletedReaction: Reaction) {
    for(var i = 0; i < reactionsTab.length; i++) {
        if (deletedReaction && reactionsTab[i].getIRI() == deletedReaction.getIRI()) {
            reactionsTab.splice(i, 1);
        }
    }
    return reactionsTab;
  }

  mapReactions(reactionsTab: Reaction[], currentReaction: Reaction) {
    for(var i = 0; i < reactionsTab.length; i++) {
        if (reactionsTab[i].getIRI() == currentReaction.getIRI()) {
            reactionsTab[i] = currentReaction;
        }
    }
    return reactionsTab;
  }

  orderOriginal = (a: KeyValue<number,string>, b: KeyValue<number,string>): number => {
    return 0
  }

  openReactionModal() {
    this.modalReaction = this.modalService.show(ModalReactionsComponent);
    this.modalReaction.content.nbReactions = this.nbReactions;
    this.modalReaction.content.reactions = this.reactions.concat(this.newReactions);
  }

  onEdit() {
    const initialState = {
      comment: this.comment
    };
    this.modalEditComment = this.modalService.show(ModalEditCommentComponent, {initialState});
  }

  setDeletedComment() {
    this.commentToDelete.post = this.comment.post.getIRI();
    this.commentToDelete.deletedAt = this.datePipe.transform(new Date(), 'yyyy-MM-dd HH:mm:ss');
  }

  onDelete() {
    this.modalConfirmation = this.modalService.show(ModalConfirmationComponent);
    this.modalConfirmation.content.objectToRemove = "supprimer ce commentaire";

    const _combine = combineLatest(
      this.modalService.onHide
    ).subscribe(() => this.changeDetection.markForCheck());
    
    this.subscriptionsModal.push(
      this.modalService.onHide.subscribe((reason) => {

        if (this.modalConfirmation.content && this.modalConfirmation.content.confirmation) {
          this.setDeletedComment();

          this.commentService.updateComment(this.comment.getId(), this.commentToDelete).pipe(
            finalize(() => this.loadingService.dismissLoading())
            ).subscribe(comment => {
              if (!comment) {
                return;
              }

              if (this.comment.getIRI() == comment.getIRI()) {
                this.alertService.closeAlert();
                this.toastrService.success('Votre commentaire a bien été supprimé', 'Confirmation');
                this.modalConfirmation = null;
              }
            }, error => {
                this.alertService.showAlert(error.error.errors, 'danger');
            });
        }
        this.unsubscribe();
      })
    );
    this.subscriptionsModal.push(_combine);
  }

  private unsubscribe() {
    this.subscriptionsModal.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
    this.subscriptionsModal = [];
  }

  ngOnDestroy() {
    this._observers.forEach((observer, index) => {
        observer.unsubscribe();
    });
  }

}
