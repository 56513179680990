import { Observable, Subject } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "./api.service";
import * as i2 from "./feathers.service";
export class InvitationVisioService {
    constructor(apiService, feathersService) {
        this.apiService = apiService;
        this.feathersService = feathersService;
        this._fsServiceName = "invitationsVisio";
        this._createdInvitation = new Subject();
        this.setCreatedInvitationEvent();
    }
    createInvitation(data) {
        return this.apiService.post(this._fsServiceName, data);
    }
    onCreatedInvitation() {
        return this._createdInvitation;
    }
    setCreatedInvitationEvent() {
        this.feathersService.client.service(this._fsServiceName).on('created', invitation => {
            this._createdInvitation.next(invitation);
        });
    }
}
InvitationVisioService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function InvitationVisioService_Factory() { return new InvitationVisioService(i0.ɵɵinject(i1.ApiService), i0.ɵɵinject(i2.FeathersService)); }, token: InvitationVisioService, providedIn: "root" });
