<div class="appointment">
    <div class="d-flex">
        <div class="appointment__date text-white py-2 flex-grow-0">
            <div class="bg-primary font-weight-black">{{ appointment.finalChoice | date:"dd" }}</div>
            <span class="bg-primary font-weight-light">{{ appointment.finalChoice | date:"MM" }}</span><span class="bg-primary font-weight-black pr-1">{{ appointment.finalChoice | date:"yy" }}</span>
        </div>
        <div class="appointment__content flex-grow-1">
            <h4 class="mb-1">{{ appointment.subject }}</h4>
            <button type="button" class="close" (click)="removeAppointment()" title="Supprimer le rendez-vous" *ngIf="appointment.member.getId() == memberMe.getId()">
                <span class="material-icons">close</span>
            </button>
            <div class="text-dark-grey small">{{ appointment.finalChoice | date:"HH:mm" }}</div>
            <p class="mb-0 mt-2">{{ appointment.meetingPlace == visio ? 'En visio' : 'En présentiel'}}</p>
        </div>
    </div>
    
    <div class="mt-1 row">
        <div class="col-9 pr-0">
            <span class="small">{{ appointment.updatedAt | dateFrom }}</span>
        </div>
        <div class="col-3 text-right pl-0">
            <ng-template [ngIf]="!appointment.member.deletedAt" [ngIfElse]="memberDeleted">
                <a [routerLink]="['/profil', appointment.member.user.token]" title="{{ appointment.member.getName() }}">
                    <app-user-avatar [user]="appointment.member.user" [isUnderSurveillance]="appointment.member.isUnderSurveillance" [size]="'tiny'"></app-user-avatar>
                </a>
            </ng-template>
            <ng-template #memberDeleted>
                <app-user-avatar [user]="appointment.member.user" [isUnderSurveillance]="appointment.member.isUnderSurveillance" [memberDeleted]="appointment.member.deletedAt" [size]="'tiny'"></app-user-avatar>
            </ng-template>
        </div>
    </div>
</div>
