import { Component, OnInit, OnDestroy } from '@angular/core';

import { Subscription } from 'rxjs';
import { pairwise } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { Router, NavigationEnd, ActivatedRoute  } from '@angular/router';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import localeFrExtra from '@angular/common/locales/extra/fr';
import { LoadingService } from './core/services/loading.service';
import { NotificationService } from './core/services/notification.service';
import { AlertService } from './core/services/alert.service';
import { HeaderService } from './core/services/header.service';
import { Alert } from './core/interfaces/alert';
import { AuthService } from './core/services/auth.service';

registerLocaleData(localeFr, 'fr-FR', localeFrExtra);

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

    private _observers: Subscription[] = [];
    public loading: boolean = false;
    public hideHeader: boolean = true;
    public hideFooter: boolean = true;
    public swipedHeader: boolean = false;
    public isFullscreen: boolean = false;
    public alert: Alert|null;

    constructor(private loadingService: LoadingService,
        private authService: AuthService,
        private notificationService: NotificationService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private headerService: HeaderService,
        private toastrService: ToastrService,
        private alertService: AlertService) {
    }

    ngOnInit() {
        this.setObservers();
    }

    setObservers() {
        this._observers.push(this.loadingObserver());
        this._observers.push(this.alertObserver());
        this._observers.push(this.routerObserver());
        this._observers.push(this.notificationObserver());
        this._observers.push(this.headerSwipeObserver());
        //this._observers.push(this.authObserver());
    }

    loadingObserver(): Subscription {
        return this.loadingService.isLoading().subscribe(state => {
            this.loading = state;
        });
    }

    alertObserver(): Subscription {
        return this.alertService.alert().subscribe(alert => {
            this.alert = alert;
        });
    }

    routerObserver(): Subscription {
        return this.router.events.subscribe(e => {
            if (e instanceof NavigationEnd) {
                this.alert = null;
                var activatedRoute = this.activatedRoute.firstChild;
                while (activatedRoute) {
                    const data = activatedRoute.snapshot.data;
                    this.hideHeader = (data.hideHeader) ? data.hideHeader : false;
                    this.hideFooter = (data.hideFooter) ? data.hideFooter : false;
                    this.isFullscreen = (data.isFullscreen) ? data.isFullscreen : false;
                    activatedRoute = activatedRoute.firstChild;
                }
                this.headerService.closeMenu();
            }
        });
    }

    notificationObserver(): Subscription {
        return this.notificationService.onCreatedNotification().subscribe(message => {
            if (message) {
                this.toastrService.success(message.description, message.title);
            }
        });
    }

    authObserver(): Subscription {
        return this.authService.isAuthenticated().pipe(
            pairwise()
        ).subscribe(authenticated => {
            if (authenticated[0] && !authenticated[1]) {
                this.router.navigate(['/login']);
            }
        });
    }

    headerSwipeObserver(): Subscription {
        return this.headerService.swipedHeader().subscribe(response => {
            this.swipedHeader = response;
        });
    }

    ngOnDestroy() {
        this._observers.forEach(observer => {
            observer.unsubscribe();
        });
    }


    onAlertClosed() {
        this.alert = null;
    }

}
