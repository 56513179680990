import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { SubscriptionModel } from '../models/subscription.model';
import { Invoice } from '../models/invoice.model';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {

  private _fsServiceName: string = "subscriptions";

  constructor(private apiService: ApiService) { }

  createSubscription(data: any, params: any = {}): Observable<any> {
    return this.apiService.post(this._fsServiceName, data).pipe(
      map(subscription => new SubscriptionModel().deserialize(subscription))
    );
  }

  updateSubscription(id: number, data: any, params: any = {}): Observable<any> {
    return this.apiService.put(this._fsServiceName, id, data, params).pipe(
      map(subscription => new SubscriptionModel().deserialize(subscription))
    );
  }

  updateStorageSubscription(id: number, data: any, params: any = {}): Observable<any> {
    const defaultParams = {
      query: {
        operationPath: '/increase-storage'
      }
    };
    Object.assign(defaultParams.query, params);

    return this.apiService.put(this._fsServiceName, id, data, defaultParams).pipe(
      map(subscription => new SubscriptionModel().deserialize(subscription))
    );
  }

  deleteSubscription(id: number): Observable<any> {
    return this.apiService.delete(this._fsServiceName, id);
  }
}
