import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-subscription',
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.scss']
})
export class SubscriptionComponent implements OnInit {

  public iframeUrl: SafeResourceUrl;
  public isSubscribe: boolean = false;
  
  constructor(private sanitizer: DomSanitizer,
    private route: ActivatedRoute) { }

  ngOnInit() {
    if (this.route.snapshot.routeConfig.path == 'abonnement') {
      this.isSubscribe = true;
      this.iframeUrl = this.sanitizer.bypassSecurityTrustResourceUrl('/profil/paiement');
    } else {
      this.iframeUrl = this.sanitizer.bypassSecurityTrustResourceUrl('/profil/abonnement/mettre-a-jour/iframe');
    }
  }

}
