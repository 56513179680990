<div class="container py-8">
    <h1 class="text-center mb-10">Mes factures</h1>
    <div class="table-responsive" *ngIf="invoices">
        <table class="table">
            <thead>
              <tr>
                <th class="text-center text-sm-left align-middle" scope="col">Date</th>
                <th class="text-center text-sm-left align-middle" scope="col">Période</th>
                <th class="text-center text-sm-left align-middle" scope="col">Montant total</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
                <ng-container *ngFor="let invoice of invoices">
                    <tr>
                        <td class="align-middle">{{ invoice.createdAt | date:'dd/MM/yyyy' }}</td>
                        <td class="align-middle">Du {{ invoice.startingPeriod | date:'dd/MM/yyyy' }} au {{ invoice.endingPeriod | date:'dd/MM/yyyy' }}</td>
                        <td class="text-center text-sm-left align-middle">{{ invoice.amount }}€</td>
                        <td>
                            <button class="btn-link-primary" type="button" (click)="onDownload(invoice.getId(), invoice.fileName)">
                                <span class="material-icons-outlined align-middle mr-2">file_download</span>
                                <span class="d-inline-block align-middle">Télécharger</span>
                            </button>
                        </td>
                    </tr>
                </ng-container>
            </tbody>
        </table>
    </div>
</div>
