<div class="mt-9 mt-xl-0 h-xl-100 overflow-auto">
    <div class="container-fluid py-8">
        <div class="row justify-content-center" *ngIf="appointment && appointment.finalChoice == null">
            <div class="col-12 col-md-11">
                <div class="row">
                    <div class="col-12">
                        <h2 class="text-center">Concertation pour un rendez-vous</h2>
                    </div>
                </div>
                <div class="row mt-7 mb-2">
                    <div class="col-12">
                        {{ appointment.member.user.firstName }} {{ appointment.member.user.lastName }} propose de fixer un rendez-vous {{ (appointment.meetingPlace == isVisio) ? 'pour une visio' : 'en présentiel' }}
                        avec les membres du cercle {{ appointment.circle.name }}.
                    </div>
                </div>
                <div class="row mb-6">
                    <div class="col-12">
                        Le sujet du rendez-vous est le suivant : <b>{{ appointment.subject }}</b>.
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <form class="mb-0" [formGroup]="voteForm" (ngSubmit)="onVoteFormSubmit()">
                            <div class="row">
                                <ng-container *ngFor="let proposal of creatorProposals; index as i">
                                    <div class="col-12 mb-2" [ngClass]="{'col-xl-4': i==0, 'col-xl-3': i!=0}">
                                        <div class="row">
                                            <div class="col-12 proposal-date">
                                                <h5 class="text-center" [ngClass]="{'ml-xl-14': i == 0}">{{ proposal.date | date:'dd/MM/yyyy HH:mm' }}</h5>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-12">
                                                <table class="table table-borderless table-creator">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col"></th>
                                                            <th scope="col" class="align-middle">Oui</th>
                                                            <th scope="col" class="align-middle text-center">Peut-être</th>
                                                            <th scope="col" class="align-middle">Non</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody [ngClass]="{'without-vote': votesProposal.length == 0 && newVotes.length == 0}">
                                                        <ng-container *ngFor="let vote of votesProposal; index as j">
                                                            <tr *ngIf="vote.proposal.getId() == proposal.getId()">
                                                                <th scope="row" class="align-middle">
                                                                    <span [ngClass]="{'d-xl-none': i!=0}">{{ vote.member.getId() == memberMe.getId() ? 'Vous' : vote.member.user.firstName }}</span>
                                                                </th>
                                                                <td class="pl-4 align-middle" [ngClass]="{'pt-3': (i != 0 && j != 0)}"><div class="circle" [ngClass]="{'circle-green': vote.choice == yes}"></div></td>
                                                                <td class="pl-8 align-middle" [ngClass]="{'pt-3': (i != 0 && j != 0)}"><div class="circle" [ngClass]="{'circle-orange': vote.choice == maybe}"></div></td>
                                                                <td class="pl-4 align-middle" [ngClass]="{'pt-3': (i != 0 && j != 0)}"><div class="circle" [ngClass]="{'circle-red': vote.choice == no}"></div></td>
                                                            </tr>
                                                        </ng-container>
                                                        <ng-container *ngFor="let vote of newVotes; index as j">
                                                            <tr *ngIf="vote.proposal.getId() == proposal.getId()">
                                                                <th scope="row" >
                                                                    <span [ngClass]="{'d-xl-none': i!=0}">{{ vote.member.getId() == memberMe.getId() ? 'Vous' : vote.member.user.firstName }}</span>
                                                                </th>
                                                                <td class="pl-4 align-middle" [ngClass]="{'pt-3 pt-xl-4': (i != 0 && j != 0)}"><div class="circle" [ngClass]="{'circle-green': vote.choice == yes}"></div></td>
                                                                <td class="pl-8 align-middle" [ngClass]="{'pt-3 pt-xl-4': (i != 0 && j != 0)}"><div class="circle" [ngClass]="{'circle-orange': vote.choice == maybe}"></div></td>
                                                                <td class="pl-4 align-middle" [ngClass]="{'pt-3 pt-xl-4': (i != 0 && j != 0)}"><div class="circle" [ngClass]="{'circle-red': vote.choice == no}"></div></td>
                                                            </tr>
                                                        </ng-container>
                                                        <tr *ngIf="!meHasVoted">
                                                            <th scope="row">
                                                                <span [ngClass]="{'d-xl-none': i!=0}">Et vous ?</span>
                                                            </th>
                                                            <td class="pl-4">
                                                                <label [ngSwitch]="i">
                                                                    <input type="radio" name="votePropTwo" [value]="yes" formControlName="votePropTwo" required *ngSwitchCase="1"/>
                                                                    <input type="radio" name="votePropThree" [value]="yes" formControlName="votePropThree" required *ngSwitchCase="2"/>
                                                                    <input type="radio" name="votePropOne" [value]="yes" formControlName="votePropOne" required *ngSwitchDefault/>
                                                                    <span class="radio radio-yes"></span>
                                                                </label>
                                                            </td>
                                                            <td class="pl-8">
                                                                <label [ngSwitch]="i">
                                                                    <input type="radio" name="votePropTwo" [value]="maybe" formControlName="votePropTwo" required *ngSwitchCase="1">
                                                                    <input type="radio" name="votePropThree" [value]="maybe" formControlName="votePropThree" required *ngSwitchCase="2">
                                                                    <input type="radio" name="votePropOne" [value]="maybe" formControlName="votePropOne" required *ngSwitchDefault>
                                                                    <span class="radio radio-maybe"></span>
                                                                </label>
                                                            </td>
                                                            <td class="pl-4">
                                                                <label [ngSwitch]="i">
                                                                    <input type="radio" name="votePropTwo" [value]="no" formControlName="votePropTwo" required *ngSwitchCase="1">
                                                                    <input type="radio" name="votePropThree" [value]="no" formControlName="votePropThree" required *ngSwitchCase="2">
                                                                    <input type="radio" name="votePropOne" [value]="no" formControlName="votePropOne" required *ngSwitchDefault>
                                                                    <span class="radio radio-no"></span>
                                                                </label>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div class="row mt-2 mb-7">
                                            <div class="col-12 column-total d-flex justify-content-center justify-content-xl-end flex-wrap font-weight-bold" [ngClass]="{'col-xl-12 justify-content-xl-center': memberMe.getIRI() != appointment.member.getIRI()}">
                                                <span>TOTAL &nbsp;</span>OUI : {{ yesTotal[proposal.getId()] }}
                                            </div>
                                            <div class="col-12 column-choose d-flex justify-content-center justify-content-xl-start flex-wrap font-weight-bold">
                                                <button class="mt-3 mt-xl-0 ml-xl-5 btn-link-primary" type="button" (click)="onChoose(proposal.getId())" *ngIf="memberMe.getIRI() == appointment.member.getIRI()">
                                                    <i class="fas fa-check"></i>
                                                    Choisir
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                                <div class="col-12 col-xl-2 d-flex justify-content-center justify-content-xl-start align-items-xl-center" [ngClass]="{'pt-xl-12': votesProposal.length != 0 || newVotes.length != 0}" *ngIf="!meHasVoted">
                                    <div>
                                        <button type="submit" class="btn btn-primary d-xl-none mt-2">Valider</button>
                                        <button type="submit" class="btn btn-primary btn--small d-none d-xl-inline-block">Valider</button>
                                    </div>
                                </div>
                            </div>
                            <div class="mt-2 mt-xl-0 ml-2" *ngIf="voteForm['submitted'] && (votePropOne.invalid || (votePropTwo && votePropTwo.invalid) || (votePropThree && votePropThree.invalid))">
                                <small class="text-danger" *ngIf="votePropOne.errors?.required || votePropTwo.errors?.required || votePropThree.errors?.required">
                                    Chaque proposition doit être votée
                                </small>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="row mt-8">
                    <div class="col-12 col-xl-6">
                        <h3 class="mb-6" *ngIf="counterProposals.length > 0 || newCounterProposals.length > 0">Contre-propositions</h3>
                        <form class="mb-0" [formGroup]="voteCounterPropForm" (ngSubmit)="onVoteCounterPropFormSubmit()">
                            <div class="row">
                                <ng-container *ngFor="let proposal of counterProposals; index as i">
                                    <div class="col-12 mb-4">
                                        <h5 class="text-center">
                                            {{ proposal.date | date:'dd/MM/yyyy HH:mm' }}
                                            <small>(par {{ proposal.member.getId() == memberMe.getId() ? 'vous' : proposal.member.user.firstName }})</small>
                                        </h5>
                                        <table class="table table-borderless">
                                            <thead>
                                                <tr>
                                                    <th scope="col"></th>
                                                    <th scope="col">Oui</th>
                                                    <th scope="col">Peut-être</th>
                                                    <th scope="col">Non</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <ng-container *ngFor="let vote of votesCounterProposal; index as j">
                                                    <tr *ngIf="vote.proposal.getId() == proposal.getId()">
                                                        <th scope="row" [ngClass]="{'pr-8': vote.member.getId() == memberMe.getId()}">
                                                            {{ vote.member.getId() == memberMe.getId() ? 'Vous' : vote.member.user.firstName }}
                                                        </th>
                                                        <td class="pl-4"><div class="circle" [ngClass]="{'circle-green': vote.choice == yes}"></div></td>
                                                        <td class="pl-8"><div class="circle" [ngClass]="{'circle-orange': vote.choice == maybe}"></div></td>
                                                        <td class="pl-4"><div class="circle" [ngClass]="{'circle-red': vote.choice == no}"></div></td>
                                                    </tr>
                                                </ng-container>
                                                <ng-container *ngFor="let vote of newVotesCounterProp; index as j">
                                                    <tr *ngIf="vote.proposal.getId() == proposal.getId()">
                                                        <th scope="row" [ngClass]="{'pr-8': vote.member.getId() == memberMe.getId()}">
                                                            {{ vote.member.getId() == memberMe.getId() ? 'Vous' : vote.member.user.firstName }}
                                                        </th>
                                                        <td class="pl-4"><div class="circle" [ngClass]="{'circle-green': vote.choice == yes}"></div></td>
                                                        <td class="pl-8"><div class="circle" [ngClass]="{'circle-orange': vote.choice == maybe}"></div></td>
                                                        <td class="pl-4"><div class="circle" [ngClass]="{'circle-red': vote.choice == no}"></div></td>
                                                    </tr>
                                                </ng-container>
                                                <tr *ngIf="!meVotes.includes(proposal.getId())">
                                                    <th scope="row">Et vous ?</th>
                                                    <td class="pl-4">
                                                        <label>
                                                            <input type="radio" name="vote_{{proposal.getId()}}" [value]="yes" [formControl]="proposalsArray.controls[i].get('vote')">
                                                            <span class="radio radio-yes"></span>
                                                        </label>
                                                    </td>
                                                    <td class="pl-8">
                                                        <label>
                                                            <input type="radio" name="vote_{{proposal.getId()}}" [value]="maybe" [formControl]="proposalsArray.controls[i].get('vote')">
                                                            <span class="radio radio-maybe"></span>
                                                        </label>
                                                    </td>
                                                    <td class="pl-4">
                                                        <label>
                                                            <input type="radio" name="vote_{{proposal.getId()}}" [value]="no" [formControl]="proposalsArray.controls[i].get('vote')">
                                                            <span class="radio radio-no"></span>
                                                        </label>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <div class="row mt-2 mb-7">
                                            <div class="col-12 col-xl-6 d-flex justify-content-center justify-content-xl-end flex-wrap font-weight-bold" [ngClass]="{'col-xl-12 justify-content-xl-center': memberMe.getIRI() != appointment.member.getIRI()}">TOTAL OUI : {{ yesTotal[proposal.getId()] }}</div>
                                            <div class="col-12 col-xl-6 d-flex justify-content-center justify-content-xl-start flex-wrap font-weight-bold">
                                                <button class="mt-3 mt-xl-0 ml-xl-5 btn-link-primary" type="button" (click)="onChoose(proposal.getId())" *ngIf="memberMe.getIRI() == appointment.member.getIRI()">
                                                    <i class="fas fa-check"></i>
                                                    Choisir
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                                <ng-container *ngFor="let newProposal of newCounterProposals; index as i">
                                    <div class="col-12 mb-2">
                                        <h5 class="text-center">
                                            {{ newProposal.date | date:'dd/MM/yyyy HH:mm' }}
                                            <small>(par {{ newProposal.member.getId() == memberMe.getId() ? 'vous' : newProposal.member.user.firstName }})</small>
                                        </h5>
                                        <table class="table table-borderless">
                                            <thead>
                                                <tr>
                                                    <th scope="col"></th>
                                                    <th scope="col">Oui</th>
                                                    <th scope="col">Peut-être</th>
                                                    <th scope="col">Non</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <ng-container *ngFor="let vote of newVotesCounterProp; index as j">
                                                    <tr *ngIf="vote.proposal.getId() == newProposal.getId()">
                                                        <th scope="row" [ngClass]="{'pr-8': vote.member.getId() == memberMe.getId()}">
                                                            {{ vote.member.getId() == memberMe.getId() ? 'Vous' : vote.member.user.firstName }}
                                                        </th>
                                                        <td class="pl-4"><div class="circle" [ngClass]="{'circle-green': vote.choice == yes}"></div></td>
                                                        <td class="pl-8"><div class="circle" [ngClass]="{'circle-orange': vote.choice == maybe}"></div></td>
                                                        <td class="pl-4"><div class="circle" [ngClass]="{'circle-red': vote.choice == no}"></div></td>
                                                    </tr>
                                                </ng-container>
                                                <tr *ngIf="!meVotes.includes(newProposal.getId())">
                                                    <th scope="row">Et vous ?</th>
                                                    <td class="pl-4">
                                                        <label>
                                                            <input type="radio" name="vote_{{newProposal.getId()}}" [value]="yes" [formControl]="proposalsArray.controls[counterProposals.length + i].get('vote')">
                                                            <span class="radio radio-yes"></span>
                                                        </label>
                                                    </td>
                                                    <td class="pl-8">
                                                        <label>
                                                            <input type="radio" name="vote_{{newProposal.getId()}}" [value]="maybe" [formControl]="proposalsArray.controls[counterProposals.length + i].get('vote')">
                                                            <span class="radio radio-maybe"></span>
                                                        </label>
                                                    </td>
                                                    <td class="pl-4">
                                                        <label>
                                                            <input type="radio" name="vote_{{newProposal.getId()}}" [value]="no" [formControl]="proposalsArray.controls[counterProposals.length + i].get('vote')">
                                                            <span class="radio radio-no"></span>
                                                        </label>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </ng-container>
                                <div class="col-12 d-flex justify-content-center justify-content-xl-start align-items-xl-end pb-6" *ngIf="counterProposals.length + newCounterProposals.length > meVotes.length">
                                    <div>
                                        <button type="submit" class="btn btn-primary d-xl-none mt-2">Valider</button>
                                        <button type="submit" class="btn btn-primary btn--small d-none d-xl-inline-block">Valider</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <button class="btn-link-primary" type="button" (click)="openAddProposalModal()">
                            Faire une contre-proposition
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="row justify-content-center" *ngIf="appointment && appointment.finalChoice != null">
            <div class="col-12">La date finale du rendez-vous "{{appointment.subject}}" a été choisie</div>
        </div>
    </div>
</div>
