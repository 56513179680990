/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./modal-members.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../members/members.component.ngfactory";
import * as i3 from "../members/members.component";
import * as i4 from "ngx-bootstrap/modal";
import * as i5 from "@angular/router";
import * as i6 from "../../../core/services/loading.service";
import * as i7 from "../../../core/services/alert.service";
import * as i8 from "ngx-toastr";
import * as i9 from "../../../core/services/invitation.service";
import * as i10 from "../../../core/services/member.service";
import * as i11 from "../../../core/services/invitation-visio.service";
import * as i12 from "@angular/common";
import * as i13 from "../../../core/services/feathers.service";
import * as i14 from "../../../core/services/me.service";
import * as i15 from "./modal-members.component";
var styles_ModalMembersComponent = [i0.styles];
var RenderType_ModalMembersComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ModalMembersComponent, data: {} });
export { RenderType_ModalMembersComponent as RenderType_ModalMembersComponent };
function View_ModalMembersComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-members", [], null, [[null, "onNavigationStart"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onNavigationStart" === en)) {
        var pd_0 = (_co.bsModalRef.hide() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MembersComponent_0, i2.RenderType_MembersComponent)), i1.ɵdid(2, 245760, null, 0, i3.MembersComponent, [i4.BsModalService, i5.Router, i6.LoadingService, i7.AlertService, i8.ToastrService, i9.InvitationService, i10.MemberService, i11.InvitationVisioService, i12.DatePipe, i1.ChangeDetectorRef, i13.FeathersService, i14.MeService], { circle: [0, "circle"], members: [1, "members"], memberMe: [2, "memberMe"] }, { onNavigationStart: "onNavigationStart" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.circle; var currVal_1 = _co.circle.members; var currVal_2 = _co.memberMe; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_ModalMembersComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h3", [["class", "modal-title text-uppercase"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Membres"])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "button", [["aria-label", "Close"], ["class", "close pull-right"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.bsModalRef.hide() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "i", [["class", "fas fa-times"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModalMembersComponent_1)), i1.ɵdid(6, 16384, null, 0, i12.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.circle; _ck(_v, 6, 0, currVal_0); }, null); }
export function View_ModalMembersComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-modal-members", [], null, null, null, View_ModalMembersComponent_0, RenderType_ModalMembersComponent)), i1.ɵdid(1, 114688, null, 0, i15.ModalMembersComponent, [i4.BsModalRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ModalMembersComponentNgFactory = i1.ɵccf("app-modal-members", i15.ModalMembersComponent, View_ModalMembersComponent_Host_0, {}, {}, []);
export { ModalMembersComponentNgFactory as ModalMembersComponentNgFactory };
