import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import * as FileSaver from 'file-saver';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { combineLatest, Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { AlertService } from '../../../core/services/alert.service';
import { LoadingService } from '../../../core/services/loading.service';
import { Post } from '../../../core/models/post.model';
import { MediaService } from '../../../core/services/media.service';
import { ModalConfirmationComponent } from '../modal-confirmation/modal-confirmation.component';
import { PostService } from '../../../core/services/post.service';
import { DatePipe } from '@angular/common';
import { Member } from '../../../core/models/member.model';
import { CircleService } from '../../../core/services/circle.service';

declare var $;

@Component({
    selector: 'app-album',
    templateUrl: './album.component.html',
    styleUrls: ['./album.component.scss']
})
export class AlbumComponent implements OnInit {

    @Input() public post: Post = new Post();
    @Input() public showAuthor: boolean = true;
    @Input() public container: string = 'posts';
    public album: any[] = [];
    public modalConfirmation: BsModalRef;
    private subscriptionsModal: Subscription[] = [];
    public memberMe: Member = new Member();

    constructor(private mediaService: MediaService,
        private postService: PostService,
        private bsModalService: BsModalService,
        private changeDetection: ChangeDetectorRef,
        private loadingService: LoadingService,
        private toastrService: ToastrService,
        private alertService: AlertService,
        private datePipe: DatePipe,
        private circleService: CircleService) { }

    ngOnInit() {
        $.fancybox.defaults.buttons = ['zoom', 'close', 'customDownload'];
        $.fancybox.defaults.i18n.fr = {
            CLOSE: "Fermer",
            NEXT: "Suivant",
            PREV: "Précédent",
            ERROR: "Erreur. <br/> Réessayez plus tard.",
            PLAY_START: "Commencer le diaporama",
            PLAY_STOP: "Stopper le diaporama",
            FULL_SCREEN: "Plein écran",
            THUMBS: "Thumbnails",
            DOWNLOAD: "Télécharger",
            SHARE: "Partager",
            ZOOM: "Zoom"
        };
        $.fancybox.defaults.lang = 'fr';
        $.fancybox.defaults.afterShow = (instance, current) => {
            var downloadButton = document.getElementsByClassName("fancybox-button--download")[0];

            downloadButton.addEventListener('click', (event) => {
                this.downloadImage(current.opts.mediaId, current.opts.caption, current.opts.mimeType);
            });
        };

        $.fancybox.defaults.btnTpl.customDownload = '<button type="button" class="fancybox-button fancybox-button--download" title="{{DOWNLOAD}}">'
            + '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M18.62 17.09V19H5.38v-1.91zm-2.97-6.96L17 11.45l-5 4.87-5-4.87 1.36-1.32 2.68 2.64V5h1.92v7.77z"/></svg>'
            + '</button>';

        this.setAlbum();
        this.circleService.getCircleMembersMe(this.post.circle.token).subscribe(member => {
	this.memberMe = member;
        });
    }

    setAlbum() {
        $().fancybox({
            selector : '.album--' + this.container + ' > .album__medias .media.gallery-' + this.post.getId()
        });
    }

    downloadImage(id, filename, mimeType) {
        this.mediaService.downloadFile(id).subscribe(response => {
            var blob = new Blob([response], { type: mimeType });

            setTimeout(function() {
                FileSaver.saveAs(blob, filename);
            }, 250);
        });
    }

    setEditedPost(deleted: boolean): FormData {
        let input = new FormData();
    
        input.append('_id', this.post.getId().toString());
        input.append('message', this.post.message);
        input.append('circle', this.post.circle.getIRI());
        if (deleted) {
            input.append('deletedAt', this.datePipe.transform(new Date(), 'yyyy-MM-dd HH:mm:ss'));
        }

        return input;
    }

    removeMedia(index: number) {
        this.modalConfirmation = this.bsModalService.show(ModalConfirmationComponent);
        this.modalConfirmation.content.objectToRemove = "supprimer ce document";

        const _combine = combineLatest(
            this.bsModalService.onHide
          ).subscribe(() => this.changeDetection.markForCheck());
      
        this.subscriptionsModal.push(
            this.bsModalService.onHide.subscribe((reason) => {
                if (this.modalConfirmation.content && this.modalConfirmation.content.confirmation) {
                    this.loadingService.showLoading();

                    this.mediaService.deleteMedia(this.post.medias[index].getId()).pipe(
                        finalize(() => this.loadingService.dismissLoading())
                    ).subscribe(media => {
                        this.alertService.closeAlert();
                        this.toastrService.success('Le document a bien été supprimé', 'Confirmation');
                        this.post.medias.splice(index, 1);

                        if (this.post.medias.length == 0 && this.post.message == "" && this.post.event == null) {
                            const deletedPost = this.setEditedPost(true);
                            this.postService.deletePost(this.post.getId()).subscribe(post => {},
                                error => {
                                    console.log(error);
                                });
                        } else {
                            const editedPost = this.setEditedPost(false);
                            this.postService.updatePost(editedPost).subscribe(post => {},
                                error => {
                                    console.log(error);
                                });
                        }
                    }, error => {
                        console.log(error);
                        this.alertService.showAlert(error.error.errors, 'danger');
                    });
                }
                this.unsubscribe();
            })
        );
        this.subscriptionsModal.push(_combine);
    }

    private unsubscribe() {
        this.subscriptionsModal.forEach((subscription: Subscription) => {
          subscription.unsubscribe();
        });
        this.subscriptionsModal = [];
    }

}
