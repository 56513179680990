import { Component, OnInit, OnDestroy } from '@angular/core';

import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { ScrollEvent } from 'ngx-scroll-event';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ModalMembersComponent } from './modal-members/modal-members.component';
import { ModalMediasComponent } from './modal-medias/modal-medias.component';
import { ModalEventsComponent } from './modal-events/modal-events.component';
import { CircleService } from '../../core/services/circle.service';
import { MemberService } from '../../core/services/member.service';
import { LoadingService } from '../../core/services/loading.service';
import { AppointmentService } from '../../core/services/appointment.service';
import { FeathersService } from '../../core/services/feathers.service';
import { Circle } from '../../core/models/circle.model';
import { Member } from '../../core/models/member.model';
import { Appointment } from '../../core/models/appointment.model';
import { ModalOnlineUsersComponent } from './modal-online-users/modal-online-users.component';
import { ModalAppointmentsComponent } from './modal-appointments/modal-appointments.component';
import { MeService } from '../../core/services/me.service';
import { AlertService } from '../../core/services/alert.service';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'app-circle',
    templateUrl: './circle.component.html',
    styleUrls: ['./circle.component.scss']
})
export class CircleComponent implements OnInit, OnDestroy {

    private _observers: Subscription[] = [];
    public circle: Circle;
    public memberMe: Member = new Member();
    public scrollEvent: ScrollEvent;
    public isMainPage: boolean = false;
    public modalMembers: BsModalRef;
    public modalMedias: BsModalRef;
    public modalEvents: BsModalRef;
    public onlineUsers: string[] = [];
    public modalOnlineUsers: BsModalRef;
    public activeMembers: number = 0;
    public modalAppointments: BsModalRef;
    public waitingAppointments: Appointment[] = [];
    public newAppointments: Appointment[] = [];

    constructor(private circleService: CircleService,
        private memberService: MemberService,
        private appointmentService: AppointmentService,
        private loadingService: LoadingService,
        private router: Router,
        private modalService: BsModalService,
        private activatedRoute: ActivatedRoute,
        private feathersService: FeathersService,
        private meService: MeService,
        private toastrService: ToastrService) {
        this.loadingService.showLoading();
    }

    ngOnInit() {
        this.getCircle();
        if (this.activatedRoute.firstChild) {
            this.isMainPage = ('' == this.activatedRoute.firstChild.snapshot.routeConfig.path);
        }
    }

    setObservers() {
        this._observers.push(this.setUpdatedCircleObserver());
        this._observers.push(this.onlineUsersObserver());
        this._observers.push(this.offlineUsersObserver());
        this._observers.push(this.setCreatedMemberObserver());
        this._observers.push(this.setCreatedAppointmentObserver());
        this._observers.push(this.setRouterObserver());
    }

    setUpdatedCircleObserver(): Subscription {
        return this.circleService.onUpdatedCircle().subscribe(circle => {
            if (!circle) {
                return;
            }
            this.circle = circle;
        });
    }

    setCreatedMemberObserver(): Subscription {
        return this.memberService.onCreatedMember()
            .subscribe(member => {

                if (!member) {
                    return;
                }

                if (this.circle.getIRI() == member.circle) {
                    this.circle.members.push(member);
                }
            });
    }

    setRouterObserver(): Subscription {
        return this.router.events.subscribe(e => {
            if (e instanceof NavigationEnd) {
                this.isMainPage = ('' == this.activatedRoute.firstChild.snapshot.routeConfig.path);
            }
        });
    }

    onlineUsersObserver(): Subscription {
        return this.feathersService.onUpdatedAuthenticated().subscribe(usersToken => {
            if(!usersToken) {
                return;
            }

            for (const token in usersToken) {
                for (var i = 0; i < this.circle.members.length; i++) {
                    if (this.circle.members[i].user && this.circle.members[i].user.token == token && !this.onlineUsers.includes(token)) {
                        this.onlineUsers.push(token);
                        break;
                    }
                }
            }
        });
    }
    
    offlineUsersObserver(): Subscription {
        return this.feathersService.onUpdatedDisconnected().subscribe(usersToken => {
            if(JSON.stringify(usersToken) === 'null') {
                return;
            }

            for (var i = 0; i < this.onlineUsers.length; i++) {
                if (this.onlineUsers[i] != this.meService.getMe().token && !(this.onlineUsers[i] in usersToken)) {
                    this.onlineUsers.splice(i, 1);
                    i--;
                }
            }
        });
    }
    
    setCreatedAppointmentObserver(): Subscription {
        return this.appointmentService.onCreatedAppointment().subscribe(appointment => {
            if (!appointment) {
                return;
            }

            if (this.waitingAppointments.filter(a => a.getId() == appointment.getId()).length == 0 && this.newAppointments.filter(a => a.getId() == appointment.getId()).length == 0 && this.circle.getIRI() == appointment.circle.getIRI() && null == appointment.finalChoice) {
                this.newAppointments.push(appointment);
            }
        });
    }

    getCircle() {
        return this.circleService.getCircle(this.activatedRoute.snapshot.params.token).pipe(
            finalize(() => this.loadingService.dismissLoading())
        ).subscribe(circle => {
            this.circle = circle;
            this.circleService.updateCurrentCircle(circle);
            this.circleService.getCircleMembersMe(circle.token).subscribe(member => {
                if (null != member.deletedAt) {
                    this.router.navigateByUrl('/cercles');
                    this.toastrService.error('Vous ne faites plus partie de ce cercle');
                } else {
                    this.memberMe = member;
                    this.getAppointments();
                }
            });
            this.circle.members.forEach(member => {
                if (!member.isWaiting() && null == member.deletedAt && null == member.user.deletedAt) {
                    this.activeMembers++;
                }
            })
            this.setObservers();
        }, error => {
            console.log(error)
        });
    }

    getAppointments() {
        this.circleService.getCircleAppointments(this.circle.token).subscribe(appointments => {
            appointments.forEach((appointment: Appointment) => {
                
                if (this.waitingAppointments.filter(a => a.getId() == appointment.getId()).length == 0 && this.newAppointments.filter(a => a.getId() == appointment.getId()).length == 0 && null == appointment.finalChoice) {
                    this.waitingAppointments.push(appointment);
                }
            });
        });
    }

    loadData(event: ScrollEvent) {
        this.scrollEvent = event;
    }

    openMembersModal() {
        this.modalMembers = this.modalService.show(ModalMembersComponent);
        this.modalMembers.content.circle = this.circle;
        this.modalMembers.content.memberMe = this.memberMe;
    }

    openMediasModal() {
        this.modalMedias = this.modalService.show(ModalMediasComponent);
        this.modalMedias.content.circle = this.circle;
    }

    openEventsModal() {
        this.modalEvents = this.modalService.show(ModalEventsComponent);
        this.modalEvents.content.circle = this.circle;
    }

    openOnlineUsersModal() {
        let onlineMembers = [];
        for (var i = 0; i < this.onlineUsers.length; i++) {
            for (var j =0; j < this.circle.members.length; j++) {
                if (this.onlineUsers[i] == this.circle.members[j].user.token) {
                    onlineMembers.push(this.circle.members[j]);
                    break;
                }
            }
        }
        this.modalOnlineUsers = this.modalService.show(ModalOnlineUsersComponent);
        this.modalOnlineUsers.content.onlineMembers = onlineMembers;
    }
    
    openWaitingAppointmentsModal() {
        this.modalAppointments = this.modalService.show(ModalAppointmentsComponent);
        this.modalAppointments.content.appointments = this.waitingAppointments.concat(this.newAppointments);
    }

    ngOnDestroy() {
        this._observers.forEach((observer, index) => {
            observer.unsubscribe();
        });
    }
}
