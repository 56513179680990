import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class InvoiceService {

  private _fsServiceName: string = "invoices";

  constructor(private apiService: ApiService) { }
  
  downloadFile(id: number, params: any = {}): Observable<any> {
    const defaultParams = {
      query: {
        operationPath: '/download-file'
      }
    };

    Object.assign(defaultParams.query, params);
    return this.apiService.get(this._fsServiceName, id, defaultParams);
}
}
