<div class="edit-profile py-8" *ngIf="user">
    <div class="container">
        <div class="row justify-content-center align-items-start">
            <div class="col-12 col-sm-8">
                <h1 class="mb-6">Modifier mon profil</h1>
                <form [formGroup]="userForm" (ngSubmit)="onEditUserFormSubmit()">
                    <div class="d-flex flex-wrap justify-content-center">
                        <div class="edit-profile__avatar flex-grow-0 mr-6 text-center">
                            <button type="button" class="close" (click)="removeAvatar()" title="supprimer l'avatar" *ngIf="tmpAvatar">
                                <span class="material-icons">close</span>
                            </button>
                            <div class="position-relative">
                                <app-user-avatar [user]="user" [size]="'big'"></app-user-avatar>
                                <div class="align-center position-absolute" *ngIf="previewInProgress">
                                    <div class="spinner-border text-primary" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group mt-3">
                                <input type="file" id="avatar" name="avatar" ng2FileSelect [uploader]="uploader" />
                                <label for="avatar" class="btn btn-secondary btn--small">
                                    Modifier ma photo
                                </label>
                            </div>
                        </div>
                        <div class="flex-grow-1">
                            <h2 class="mb-3">Mes informations personnelles</h2>
                            <div class="form-group">
                                <label for="first-name">Prénom*</label>
                                <input id="first-name" type="text" required formControlName="firstName" name="firstName" class="form-control" placeholder="Jean" />
                                <ng-container *ngIf="userForm.get('firstName').invalid && (userForm.get('firstName').dirty || userForm.get('firstName').touched || userForm['submitted'])">
                                    <small class="text-danger" *ngIf="userForm.get('firstName').errors.required">
                                        Le prénom est obligatoire
                                    </small>
                                </ng-container>
                            </div>
                            <div class="form-group">
                                <label for="last-name">Nom*</label>
                                <input id="last-name" type="text" required formControlName="lastName" name="lastName" class="form-control" placeholder="Dupont" />
                                <ng-container *ngIf="userForm.get('lastName').invalid && (userForm.get('lastName').dirty || userForm.get('lastName').touched || userForm['submitted'])">
                                    <small class="text-danger" *ngIf="userForm.get('lastName').errors.required">
                                        Le nom est obligatoire
                                    </small>
                                </ng-container>
                            </div>
                             <h2 class="mt-4 mb-3">Mot de passe</h2>
                             <div class="form-group">
                                <label for="password">Mot de passe</label>
                                <input id="password" type="password" class="form-control" formControlName="password" name="password" placeholder="Garder l'ancien">
                                <ng-container *ngIf="userForm.get('password').invalid && (userForm.get('password').dirty || userForm.get('password').touched || userForm['submitted'])">
                                    <small class="text-danger" *ngIf="userForm.get('password').errors.minlength">
                                        Le mot de passe doit contenir au minimum 6 caractères
                                    </small>
                                </ng-container>
                             </div>
                             <div class="form-group">
                                <label for="confirmPassword">Confirmation de mot de passe</label>
                                <input id="confirmPassword" type="password" class="form-control" name="confirmPassword" formControlName="confirmPassword" placeholder="Garder l'ancien">
                                <ng-container *ngIf="userForm.get('confirmPassword').invalid && (userForm.get('confirmPassword').dirty || userForm.get('confirmPassword').touched || userForm['submitted'])">
                                    <small class="text-danger" *ngIf="userForm.get('confirmPassword').errors.required">
                                        Le mot de passe est obligatoire
                                    </small>
                                    <small class="text-danger" *ngIf="userForm.get('confirmPassword').errors.matching">
                                        Les deux mots de passe doivent être identiques
                                    </small>
                                </ng-container>
                             </div>
                        </div>
                    </div>
                    <div class="text-center mt-8">
                        <div class="form-group">
                            <button type="submit" class="btn btn-primary w-100">Valider</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
