import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';

import { finalize } from 'rxjs/operators';
import { combineLatest, Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { CircleService } from '../../../core/services/circle.service';
import { MemberService } from '../../../core/services/member.service';
import { LoadingService } from '../../../core/services/loading.service';
import { AlertService } from '../../../core/services/alert.service';
import { Circle } from '../../../core/models/circle.model';
import { Member } from '../../../core/models/member.model';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { DatePipe } from '@angular/common';
import { ModalConfirmationComponent } from '../modal-confirmation/modal-confirmation.component';
import { ModalUpdateCircleComponent } from '../modal-update-circle/modal-update-circle.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit, OnDestroy {

    private _observers: Subscription[] = [];
    private subscriptionsModal: Subscription[] = [];
    public circle: Circle = new Circle();
    public loadingSettings: boolean = true;
    public memberMe: Member = new Member();
    public hover = {};
    public modalConfirmation: BsModalRef;
    public modalUpdateCircle: BsModalRef;

    constructor(private circleService: CircleService,
        private memberService: MemberService,
        private alertService: AlertService,
        private toastrService: ToastrService,
        private loadingService: LoadingService,
        private modalService: BsModalService,
        private datePipe: DatePipe,
        private changeDetection: ChangeDetectorRef,
        private router: Router) { }

    ngOnInit() {
        this.setObservers();
    }

    setObservers() {
        this._observers.push(this.setCircleObserver());
        this._observers.push(this.setUpdatedMemberObserver());
    }

    setCircleObserver(): Subscription {
        return this.circleService.getCurrentCircle().subscribe(circle => {

            if (!circle) {
                return;
            }

            this.circle = circle;
            this.loadingSettings = false;

            this.circleService.getCircleMembersMe(circle.token).subscribe(member => {
                this.memberMe = member;
            });
        });
    }

    setUpdatedMemberObserver(): Subscription {
        return this.memberService.onUpdatedMember().subscribe(member => {

            if(!member) {
                return;
            }

            this.circle.members = this.mapMembers(this.circle.members, member);
        });
    }

    deleteMember(index: number) {
        var member = this.circle.members[index];

        this.memberService.deleteMember(member.getId()).pipe(
            finalize(() => this.loadingService.dismissLoading())
        ).subscribe(() => {
            this.circle.members.splice(index, 1);
            this.alertService.closeAlert();
            this.toastrService.success(`Le membre "${member.email}" a bien été supprimé`, 'Suppression réussie !');
        }, error => {
            const errorMessage: string[] = error.error.errors ? error.error.errors : ["Impossible de supprimer ce membre pour l'instant, veuillez réessayer ultérieurement, merci"];

            this.alertService.showAlert(errorMessage, 'danger');
        });
    }

    setUpdatedMember(index: number) {
        let member: any = {};
        member.circle = this.circle.members[index].circle;
        member.deletedAt = this.datePipe.transform(new Date(), 'yyyy-MM-dd HH:mm:ss');
        member.selfLeft = this.memberMe.getId() == this.circle.members[index].getId();
        return member;
    }

    onLeaveCircle(index: number) {
        this.modalConfirmation = this.modalService.show(ModalConfirmationComponent);
        this.modalConfirmation.content.objectToRemove = this.memberMe.getId() == this.circle.members[index].getId() ? "sortir du cercle" : "sortir ce membre du cercle";

        const _combine = combineLatest(
            this.modalService.onHide
          ).subscribe(() => this.changeDetection.markForCheck());
      
        this.subscriptionsModal.push(
        this.modalService.onHide.subscribe((reason) => {
    
            if (this.modalConfirmation.content && this.modalConfirmation.content.confirmation) {
                this.loadingService.showLoading();

                const memberToUpdate = this.setUpdatedMember(index);
                    this.memberService.leaveCircleMember(this.circle.members[index].getId(), memberToUpdate).pipe(
                        finalize(() => this.loadingService.dismissLoading())
                    ).subscribe(member => {
            
                        if (this.circle.members[index].getId() == member.getId()) {
                            this.alertService.closeAlert();
                            if (this.memberMe.getId() == this.circle.members[index].getId()) {
                                this.router.navigate(['/cercles']);
                                this.toastrService.success('Vous êtes sorti(e) du cercle', 'Confirmation');
                            } else {
                                this.toastrService.success('Le membre a bien été sorti du cercle', 'Confirmation');
                            }
                            this.modalConfirmation = null;
                        }
                    }, error => {
                        const errorMessage: string[] = error.error.errors ? error.error.errors : ["Impossible de supprimer ce membre pour l'instant, veuillez réessayer ultérieurement, merci"];

                        this.alertService.showAlert(errorMessage, 'danger');
                    });
                }
                this.unsubscribe();
            })
        );
        this.subscriptionsModal.push(_combine);
    }

    openUpdateCircleModal() {
        const initialState = {
            circle: this.circle
          };
        this.modalUpdateCircle = this.modalService.show(ModalUpdateCircleComponent, {initialState});
    }

    onDeleteCircle() {
        this.modalConfirmation = this.modalService.show(ModalConfirmationComponent);
        this.modalConfirmation.content.objectToRemove = "supprimer ce cercle";

        const _combine = combineLatest(
            this.modalService.onHide
        ).subscribe(() => this.changeDetection.markForCheck());
        
        this.subscriptionsModal.push(
            this.modalService.onHide.subscribe((reason) => {
      
                if (this.modalConfirmation.content && this.modalConfirmation.content.confirmation) {
                    this.loadingService.showLoading();
                    
                    let circleToUpdate: any = {};
                    circleToUpdate.deletedAt = this.datePipe.transform(new Date(), 'yyyy-MM-dd HH:mm:ss');
                    this.circleService.deleteCircle(this.circle.token, circleToUpdate).pipe(
                        finalize(() => this.loadingService.dismissLoading())
                    ).subscribe(circle => {
                        if (!circle) {
                            return;
                        }
            
                        if (this.circle.getIRI() == circle.getIRI()) {
                            this.alertService.closeAlert();
                            this.router.navigate(['/cercles']);
                            this.toastrService.success('Le cercle a bien été supprimé', 'Confirmation');
                            this.modalConfirmation = null;
                        }
                    }, error => {
                        const errorMessage: string[] = error.error.errors ? error.error.errors : ["Impossible de supprimer ce cercle pour l'instant, veuillez réessayer ultérieurement, merci"];

                        this.alertService.showAlert(errorMessage, 'danger');
                    });
                }
                this.unsubscribe();
            })
        );
        this.subscriptionsModal.push(_combine);
    }
    
    private unsubscribe() {
        this.subscriptionsModal.forEach((subscription: Subscription) => {
          subscription.unsubscribe();
        });
        this.subscriptionsModal = [];
    }

    private mapMembers(membersTab: Member[], currentMember: Member) {
        for(var i = 0; i < membersTab.length; i++) {
            if (membersTab[i].getIRI() == currentMember.getIRI()) {
                membersTab[i] = currentMember;
            }
        }
        return membersTab;
    }

    ngOnDestroy() {
        this._observers.forEach((observer, index) => {
            observer.unsubscribe();
        });
    }

}
