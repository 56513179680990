<div class="ml-2 ml-lg-10 mt-10">
    <ng-template #refused>
        <p>Paiement refusé. Souhaitez-vous refaire un essai ?</p>
        <button type="button" class="btn-primary ml-1 py-2 px-2" (click)="refreshIframe()">Nouvel essai</button>
    </ng-template>
    <ng-template [ngIf]="isAccepted" [ngIfElse]="refused">
        <p>Paiement accepté. Vous pouvez maintenant créer un cercle.</p>
        <a [routerLink]="['/cercles']" target="_parent" class="btn-link-primary font-weight-bold">Mes cercles</a>
    </ng-template>
</div>

