import { CookieStorage } from 'cookie-storage';
import * as i0 from "@angular/core";
import * as i1 from "./auth.service";
import * as i2 from "./me.service";
export class AppInitService {
    constructor(auth, me) {
        this.auth = auth;
        this.me = me;
        this._storageKey = 'at';
        this._cookieStorage = new CookieStorage();
    }
    getAuthentication() {
        const accessToken = this._cookieStorage.getItem(this._storageKey);
        const url = window.location.href;
        if (accessToken && url.lastIndexOf('telecharger-donnees') == -1) {
            return new Promise((resolve, reject) => {
                this.auth.authenticate({
                    accessToken: accessToken
                }).catch((e) => {
                    this._cookieStorage.removeItem(this._storageKey);
                    this.me.deleteMe();
                }).finally(() => resolve());
            });
        }
        else {
            this.me.deleteMe();
            return Promise.resolve();
        }
    }
}
AppInitService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AppInitService_Factory() { return new AppInitService(i0.ɵɵinject(i1.AuthService), i0.ɵɵinject(i2.MeService)); }, token: AppInitService, providedIn: "root" });
