import { Comment } from '../models/comment.model';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { Reaction } from '../models/reaction.model';
import * as i0 from "@angular/core";
import * as i1 from "./feathers.service";
import * as i2 from "./api.service";
export class CommentService {
    constructor(feathers, apiService) {
        this.feathers = feathers;
        this.apiService = apiService;
        this._fsServiceName = "comments";
        this._createdComment = new BehaviorSubject(null);
        this._updatedComment = new BehaviorSubject(null);
        this.setCreatedCommentEvent();
        this.setUpdatedCommentEvent();
    }
    onCreatedComment() {
        return this._createdComment;
    }
    createComment(data) {
        return this.apiService.post(this._fsServiceName, data).pipe(map(comment => new Comment().deserialize(comment)));
    }
    setCreatedCommentEvent() {
        this.feathers.client.service(this._fsServiceName).on('created', comment => {
            this._createdComment.next(new Comment().deserialize(comment));
            this._createdComment.next(null);
        });
    }
    onUpdatedComment() {
        return this._updatedComment;
    }
    updateComment(id, data) {
        return this.apiService.put(this._fsServiceName, id, data).pipe(map(comment => new Comment().deserialize(comment)));
    }
    setUpdatedCommentEvent() {
        this.feathers.client.service(this._fsServiceName).on('updated', comment => {
            this._updatedComment.next(new Comment().deserialize(comment));
            this._updatedComment.next(null);
        });
    }
    getCommentReactions(token, params = {}) {
        const defaultParams = {
            query: {
                operationPath: '/reactions'
            }
        };
        Object.assign(defaultParams.query, params);
        return this.apiService.get(this._fsServiceName, token, defaultParams).pipe(map(reactions => {
            reactions.forEach((value, index) => {
                reactions[index] = new Reaction().deserialize(value);
            });
            return reactions;
        }));
    }
}
CommentService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CommentService_Factory() { return new CommentService(i0.ɵɵinject(i1.FeathersService), i0.ɵɵinject(i2.ApiService)); }, token: CommentService, providedIn: "root" });
