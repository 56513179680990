import { from } from 'rxjs';
import { switchMap, map, finalize } from 'rxjs/operators';
import { PasswordRequest } from '../models/password-request.model';
import * as i0 from "@angular/core";
import * as i1 from "./auth.service";
import * as i2 from "./api.service";
export class PasswordRequestService {
    constructor(authService, apiService) {
        this.authService = authService;
        this.apiService = apiService;
        this._fsServiceName = 'passwordRequests';
        this._resetPasswordPath = "/authentification/nouveau-mot-de-passe";
    }
    getResetPasswordPath() {
        return window.location.origin + this._resetPasswordPath;
    }
    createPasswordRequest(data) {
        return from(this.authService.authenticateAnonymous()).pipe(switchMap(response => {
            return this.apiService.post(this._fsServiceName, data);
        }), finalize(() => this.authService.logout()));
    }
    resetPassword(token, data) {
        const params = {
            query: {
                operationPath: '/reset-password'
            }
        };
        return from(this.authService.authenticateAnonymous()).pipe(switchMap(response => {
            return this.apiService.put(this._fsServiceName, token, data, params);
        }), map(passwordRequest => new PasswordRequest().deserialize(passwordRequest)), finalize(() => this.authService.logout()));
    }
    getPasswordRequest(token) {
        return from(this.authService.authenticateAnonymous()).pipe(switchMap(response => {
            return this.apiService.get(this._fsServiceName, token);
        }), map(passwordRequest => new PasswordRequest().deserialize(passwordRequest)), finalize(() => this.authService.logout()));
    }
}
PasswordRequestService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PasswordRequestService_Factory() { return new PasswordRequestService(i0.ɵɵinject(i1.AuthService), i0.ɵɵinject(i2.ApiService)); }, token: PasswordRequestService, providedIn: "root" });
