export enum ReactionType {
    LIKE = "reaction_type_like",
    LOVE = "reaction_type_love",
    QUESTION = "reaction_type_question"
}

export const reactionTypeOutlined: any = {
    [ReactionType.LIKE]: "far fa-thumbs-up",
    [ReactionType.LOVE]: "far fa-heart",
    [ReactionType.QUESTION]: "fas fa-question"
};

export const reactionTypeFilled: any = {
    [ReactionType.LIKE]: "fas fa-thumbs-up",
    [ReactionType.LOVE]: "fas fa-heart",
    [ReactionType.QUESTION]: "fas fa-question"
};
