import { Serializable } from '../interfaces/serializable';
import { environment } from '../../../environments/environment';

export class Avatar implements Serializable {
  private _id: number = null;
  public filename: string = "";
  public pathName: string = "";
  public originalName: string = "";
  public data: string = "";

  constructor() {}

  public getIRI() {
    return '/api/avatars/' + this._id;
  }

  public getAbsolutePath() {
    return environment.publicServer.url + environment.publicServer.directories.users + '/' + this.pathName;
  }

  public deserialize(input: any): this {
    if (!input) {
      return null;
    }

    Object.getOwnPropertyNames(this).forEach((property) => {
      if ('_id' == property && input.id) {
        this._id = input.id;
      } else if (property in input) {
        this[property] = input[property];
      }
    });

    return this;
  }

  public serialize(): any {
    var output = {};

    Object.getOwnPropertyNames(this).forEach((property) => {
      if (property != '_id') {
        output[property] = this[property];
      }
    });

    return output;
  }
}
