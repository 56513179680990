import { Serializable } from "../interfaces/serializable";
import { SubscriptionModel } from "./subscription.model";
import { User } from "./user.model";

export class Invoice implements Serializable {
    private _id: number = null;
    public subscription: SubscriptionModel = null;
    public user: User = null;
    public createdAt: Date = null;
    public startingPeriod: Date = null;
    public endingPeriod: Date = null;
    public fileName: string = "";
    public amount: number = null;

    constructor() {}

    public getId() {
        return this._id;
    }

    public deserialize(input: any) {
        if (!input) {
          return null;
        }
    
        Object.getOwnPropertyNames(this).forEach((property) => {
          switch (property) {
            case 'subscription':
            this[property] = new SubscriptionModel().deserialize(input[property]);
            break;
            case 'user':
            this[property] = new User().deserialize(input[property]);
            break;
            default:
              if ('_id' == property && input.id) {
                this._id = input.id;
              } else if (property in input) {
                this[property] = input[property];
              }
              break;
          }
        });
        return this;
      }
    
    public serialize(): any {
        var output = {};

        Object.getOwnPropertyNames(this).forEach((property) => {
            switch (property) {
            case 'user':
              output[property] = this[property] != null ? this[property].getIRI() : null;
              break;
            default:
                if (property != '_id') {
                output[property] = this[property];
                }
                break;
            }
        });

        return output;
    }
}
