import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
export class HeaderService {
    constructor() {
        this._menuClosure = new BehaviorSubject(false);
        this._headerSwipe = new BehaviorSubject(false);
    }
    closeMenu() {
        this._menuClosure.next(false);
    }
    closedMenu() {
        return this._menuClosure.asObservable();
    }
    swipeHeader(swiped) {
        this._headerSwipe.next(swiped);
    }
    swipedHeader() {
        return this._headerSwipe.asObservable();
    }
}
HeaderService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function HeaderService_Factory() { return new HeaderService(); }, token: HeaderService, providedIn: "root" });
