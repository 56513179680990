import * as i0 from "@angular/core";
import * as i1 from "./api.service";
export class InvoiceService {
    constructor(apiService) {
        this.apiService = apiService;
        this._fsServiceName = "invoices";
    }
    downloadFile(id, params = {}) {
        const defaultParams = {
            query: {
                operationPath: '/download-file'
            }
        };
        Object.assign(defaultParams.query, params);
        return this.apiService.get(this._fsServiceName, id, defaultParams);
    }
}
InvoiceService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function InvoiceService_Factory() { return new InvoiceService(i0.ɵɵinject(i1.ApiService)); }, token: InvoiceService, providedIn: "root" });
