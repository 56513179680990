import { Injectable } from '@angular/core';

import { FeathersService } from './feathers.service';
import { ApiService } from './api.service';
import { Event } from '../models/event.model';
import { Observable, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class EventService {

  private _fsServiceName: string = "events";
  private _createdEvent: BehaviorSubject<Event> = new BehaviorSubject<Event>(null);
  private _deletedEvent: BehaviorSubject<Event> = new BehaviorSubject<Event>(null);

  constructor(private feathers: FeathersService,
    private apiService: ApiService) {
    this.setCreatedEventEvent();
    this.setDeletedEventEvent();
  }

  onCreatedEvent(): Observable<Event> {
    return this._createdEvent;
  }

  createEvent(data: any): Observable<any> {
    return this.apiService.post(this._fsServiceName, data).pipe(
      map(event => new Event().deserialize(event))
    );
  }

  setCreatedEventEvent() {
    this.feathers.client.service(this._fsServiceName).on('created', event => {
      this._createdEvent.next(new Event().deserialize(event));
      this._createdEvent.next(null);
    });
  }

  onDeletedEvent(): Observable<Event> {
    return this._deletedEvent;
  }

  deleteEvent(id: number): Observable<any> {
    return this.apiService.delete(this._fsServiceName, id).pipe(
      map(event => new Event().deserialize(event))
    );
  }

  patchEvent(data: any): Observable<any> {
    return this.apiService.patch(this._fsServiceName, data);
  }

  setDeletedEventEvent() {
    this.feathers.client.service(this._fsServiceName).on('patched', event => {
      this._deletedEvent.next(new Event().deserialize(event));
      this._deletedEvent.next(null);
    });
  }
}
