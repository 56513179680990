<div class="post">
    <div class="post__header">
        <div class="container-fluid pt-3 pb-1">
            <div class="row">
                <div class="col-10">
                    <ng-template #memberDeleted>
                        <app-user-avatar [user]="post.member.user" [isUnderSurveillance]="post.member.isUnderSurveillance" [memberDeleted]="post.member.deletedAt"></app-user-avatar>
                        <span class="name d-inline-block align-middle ml-2 font-weight-bold">
                            <span>{{ post.member.getName() }}</span>
                            <span class="small d-block">
                                {{ post.createdAt | dateFrom }}
                                <span class="modified-post" *ngIf="post.createdAt != post.updatedAt">Modifié</span>
                            </span>
                        </span>
                    </ng-template>
                    <ng-template [ngIf]="!post.member.deletedAt" [ngIfElse]="memberDeleted">
                        <a [routerLink]="['/profil', post.member.user.token]" *ngIf="!post.member.isWaiting()" title="{{ post.member.getName() }}">
                            <app-user-avatar [user]="post.member.user" [isUnderSurveillance]="post.member.isUnderSurveillance"></app-user-avatar>
                            <span class="name d-inline-block align-middle ml-2 font-weight-bold">
                                {{ post.member.getName() }}
                                <span class="small text-primary d-block font-weight-normal" *ngIf="post.member.isUnderSurveillance">Centre du cercle</span>
                                <span class="small d-block">
                                    {{ post.createdAt | dateFrom }}
                                    <span class="modified-post" *ngIf="post.createdAt != post.updatedAt">Modifié</span>
                                </span>
                            </span>
                        </a>
                    </ng-template>
                </div>
                <div class="col-2 text-right" *ngIf="(post.member.getId() == memberMe.getId()) || memberMe.isAdmin">
                    <div class="dropdown dropdown-actions">
                        <button class="pr-0" id="dropdownAction" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <span style="font-size: 1.25em;" class="text-grey-50"><i class="fas fa-ellipsis-h"></i></span>
                        </button>
                        <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownAction">
                            <button type="button" class="dropdown-item d-flex pl-4" (click)="onEdit()">
                                <span class="material-icons-outlined mr-3">edit</span>
                                <span>Modifier</span>
                            </button>
                            <button type="button" class="dropdown-item d-flex pl-4" (click)="onDelete()">
                                <span class="material-icons-outlined mr-3">delete</span>
                                <span>Supprimer</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="post__content">
        <div class="container-fluid pt-1">
            <div class="row mb-3" *ngIf="post.message">
                <div class="col-12">
                    <p [innerHTML]="post.message" class="mb-0 post__content__message">
                </div>
            </div>
            <div class="row mb-3" *ngIf="post.event">
                <div class="col-12">
                    <app-event [event]="post.event"></app-event>
                </div>
            </div>
            <div class="row mb-3" *ngIf="post.medias.length">
                <div class="col-12">
                    <app-album [post]="post" [showAuthor]="false"></app-album>
                </div>
            </div>
            <div class="row border-top border-light-grey py-2 justify-content-between">
                <div class="col-10">
                    <a [href]="'#post-comments_' + index" role="button" data-toggle="collapse">
                        <span class="material-icons material-icons-outlined mr-1 d-inline-block align-middle">comment</span>
                        <span class="align-middle">
                            {{ getNbComments() }}
                            <span class="d-none d-sm-inline-block">
                                {{ (getNbComments() > 1) ? 'commentaires' : 'commentaire' }}
                            </span>
                        </span>
                    </a>
                    <button class="align-middle text-dark-grey ml-2 pl-0" [ngClass]="{'nb-reactions':isReacted}" (click)="openReactionModal()"> 
                        <ng-template ngFor let-r [ngForOf]="nbReactions | keyvalue: orderOriginal">
                            <ng-template [ngIf]="r.value > 0">
                                <span class="mr-1" style="font-size: 1em;"><i [ngClass]="reactionOutlined[r.key]"></i></span>
                                <span class="mr-2">{{ r.value }}</span>
                            </ng-template>
                        </ng-template>
                    </button>
                </div>
                <div class="col-2 d-flex justify-content-end">
                    <div class="dropdown dropdown-reactions">
                        <button class="text-dark-grey pr-0" [ngClass]="{'active' : reactionMemberMe}" id="dropdownReactionButton" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <span style="font-size: 1.25em;"><i [ngClass]="getClassReactionMemberMe()"></i></span>
                        </button>
                        <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownReactionButton">
                            <button class="dropdown-item text-dark-grey ml-2 px-2" (click)="onLike()">
                                <span style="font-size: 1.25em;"><i class="far fa-thumbs-up"></i></span>
                            </button>
                            <button class="dropdown-item text-dark-grey px-2" (click)="onLove()">
                                <span style="font-size: 1.25em;"><i class="far fa-heart"></i></span>
                            </button>
                            <button class="dropdown-item text-dark-grey mr-2 px-2" (click)="onQuestion()">
                                <span style="font-size: 1.25em;"><i class="fas fa-question"></i></span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div [id]="'post-comments_' + index" class="post__comments border-top border-light-grey" [ngClass]="{'show': openedComments, 'collapse': !openedComments}">
        <div class="container-fluid py-3">
            <ng-container *ngFor="let comment of loadedComments">
                <div class="row" *ngIf="!comment.deletedAt">
                    <div class="col-12 px-0">
                        <app-comment [comment]="comment" [memberMe]="memberMe"></app-comment>
                    </div>
                </div>
            </ng-container>            
            <div class="row mt-3" *ngIf="post.comments.length - nbDeletedComments > loadedComments.length">
                <div class="col-12 text-center">
                    <button type="button" class="btn-link-primary" (click)="getComments()">
                        Charger plus de commentaires
                    </button>
                </div>
            </div>
            <ng-container *ngFor="let comment of newComments">
                <div class="row" *ngIf="!comment.deletedAt">
                    <div class="col-12 px-0">
                        <app-comment [comment]="comment" [memberMe]="memberMe"></app-comment>
                    </div>
                </div>
            </ng-container>
            <div class="row mt-3">
                <div class="col-12">
                    <form [formGroup]="commentForm" (ngSubmit)="onCommentFormSubmit()">
                        <div class="input-group mb-0">
                            <textarea class="form-control mr-3" autocomplete="off" name="message" formControlName="message" placeholder="Tapez votre texte ici..." aria-describedby="button-new-comment"></textarea>
                            <div class="input-group-append">
                                <button class="btn btn-primary rounded-circle btn-rounded btn-rounded--small" type="submit">
                                    <span class="material-icons">send</span>
                                </button>
                            </div>
                        </div>
                        <ng-container *ngIf="commentForm.get('message').invalid && commentForm['submitted']">
                            <small class="text-danger" *ngIf="commentForm.get('message').errors.required">
                                Le message est obligatoire
                            </small>
                        </ng-container>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
