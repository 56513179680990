import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'app-user-avatar',
    templateUrl: './user-avatar.component.html',
    styleUrls: ['./user-avatar.component.scss']
})
export class UserAvatarComponent implements OnInit {

    @Input() public user: any;
    @Input() public size: string = '';
    @Input() public isUnderSurveillance: boolean = false;
    @Input() public memberDeleted: boolean = false;
    @Input() public isOnline: boolean = false;

    constructor() { }

    ngOnInit() {
    }

}
