import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { Vote } from '../models/vote.model';
import * as i0 from "@angular/core";
import * as i1 from "./feathers.service";
import * as i2 from "./api.service";
export class VoteService {
    constructor(feathers, apiService) {
        this.feathers = feathers;
        this.apiService = apiService;
        this._fsServiceName = "votes";
        this._createdVote = new BehaviorSubject(null);
        this.setCreatedVoteEvent();
    }
    onCreatedVote() {
        return this._createdVote;
    }
    createVote(data) {
        return this.apiService.post(this._fsServiceName, data).pipe(map(vote => new Vote().deserialize(vote)));
    }
    setCreatedVoteEvent() {
        this.feathers.client.service(this._fsServiceName).on('created', vote => {
            this._createdVote.next(new Vote().deserialize(vote));
            //this._createdVote.next(null);
        });
    }
}
VoteService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function VoteService_Factory() { return new VoteService(i0.ɵɵinject(i1.FeathersService), i0.ɵɵinject(i2.ApiService)); }, token: VoteService, providedIn: "root" });
