<div class="event">
    <div class="d-flex">
        <div class="event__date text-primary py-2 flex-grow-0">
            <div class="font-weight-black">{{ event.date | date:"dd" }}</div>
            <span class="font-weight-light">{{ event.date | date:"MM" }}</span><span class="font-weight-black">{{ event.date | date:"yy" }}</span>
        </div>
        <div class="event__content flex-grow-1">
            <h4 class="mb-1">{{ event.title }}</h4>
            <div class="text-dark-grey small">{{ event.date | date:"HH:mm" }}</div>
            <p *ngIf="event.description" [innerHtml]="event.description" class="mb-0 mt-2"></p>
        </div>
    </div>
    <div class="mt-1 row" *ngIf="showAuthor">
        <div class="col-9 pr-0">
            <span class="small">{{ event.createdAt | dateFrom }}</span>
        </div>
        <div class="col-3 text-right pl-0">
            <ng-template [ngIf]="!event.member.deletedAt" [ngIfElse]="memberDeleted">
                <a [routerLink]="['/profil', event.member.user.token]" title="{{ event.member.getName() }}">
                    <app-user-avatar [user]="event.member.user" [isUnderSurveillance]="event.member.isUnderSurveillance" [size]="'tiny'"></app-user-avatar>
                </a>
            </ng-template>
            <ng-template #memberDeleted>
                <app-user-avatar [user]="event.member.user" [isUnderSurveillance]="event.member.isUnderSurveillance" [memberDeleted]="event.member.deletedAt" [size]="'tiny'"></app-user-avatar>
            </ng-template>
        </div>
    </div>
</div>
