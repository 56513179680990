import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { KeyValue } from '@angular/common';

import { Reaction } from '../../../core/models/reaction.model';
import { reactionTypeFilled } from '../../../core/enums/reaction-type.enum';

@Component({
  selector: 'app-modal-reactions',
  templateUrl: './modal-reactions.component.html',
  styleUrls: ['./modal-reactions.component.scss']
})
export class ModalReactionsComponent implements OnInit {

  public nbReactions: any = {};
  public reactions: Reaction[] = [];
  public reactionFilled = reactionTypeFilled;

  constructor(public bsModalRef: BsModalRef) { }

  ngOnInit() {
  }

  orderOriginal = (a: KeyValue<number,string>, b: KeyValue<number,string>): number => {
    return 0
  }

}
