<div class="container py-8">
    <div class="row justify-content-center">
        <div>
            <img src="../../assets/img/logo.svg" class="img-fluid about-logo" />
        </div>
    </div>
    <div class="row py-5">
        <div class="col-12">
            <h1 class="text-center">A propos</h1>
        </div>
    </div>
    <div class="row pb-8 justify-content-center">
        <div class="col-md-10 text-justify">
            Cercle-Confiance est un réseau social dédié à des groupes restreints, qu’ils soient familiaux, amicaux, professionnels ou associatifs.
            Il est sans publicité, payant, confidentiel.
            Il permet les échanges de messages, la visiophonie de groupe, l’envoi et le stockage de fichiers, le partage de données issues d’objets connectés*.
            Seuls les administrateurs de cercles paient, et le service est gratuit pour les invités.
            <p class="pt-2 text-muted">* Le partage de données issues d’objets connectés est en cours de développement.</p>
        </div>
        
    </div>
    <div class="row justify-content-center">
        <div>
            <video class="about-video" controls>
                <source src="../../assets/videos/TutoCCAJ.mp4" type="video/mp4">
                <p>
                    Votre navigateur ne prend pas en charge les vidéos HTML5.
                    <a href="../../assets/videos/TutoCCAJ.mp4">Télécharger la vidéo</a>
                </p>
            </video>
        </div>
    </div>
</div>