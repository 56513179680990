<div class="modal-header">
    <h3 class="modal-title text-uppercase">Modifier le nom du cercle</h3>
    <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
        <i class="fas fa-times"></i>
    </button>
</div>
<div class="modal-body">
    <form [formGroup]="circleForm" (ngSubmit)="onCircleFormSubmit()">
        <div class="form-group">
            <label for="name">Nom*</label>
            <input id="name" type="text" required name="name" formControlName="name" class="form-control" placeholder="Mon cercle" />
            <ng-container *ngIf="circleForm.get('name').invalid && (circleForm.get('name').dirty || circleForm.get('name').touched || circleForm['submitted'])">
                <small class="text-danger" *ngIf="circleForm.get('name').errors.required">
                    Le nom est obligatoire
                </small>
            </ng-container>
        </div>
        <div class="form-group mt-14">
            <button type="submit" class="btn btn-primary w-100">Envoyer</button>
        </div>
    </form>
</div>
