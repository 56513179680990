import { Component, OnInit } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../services/auth.service';
import { LoadingService } from '../services/loading.service';
import { AlertService } from '../services/alert.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  public loginForm: FormGroup;
  public redirectionUrl: string = '/';

  constructor(private authService: AuthService,
    private loadingService: LoadingService,
    private alertService: AlertService,
    private route: ActivatedRoute,
    private router: Router) { }

  ngOnInit() {
    this.redirectionUrl = this.route.snapshot.queryParams['redirectionUrl'] || '/';

    this.prepareLoginForm();
  }

  prepareLoginForm() {
    this.loginForm = new FormGroup({
      username: new FormControl('', Validators.required),
      password: new FormControl('', Validators.required)
    });
  }

  onLoginFormSubmit() {
    this.loginForm['submitted'] = true;

    if (this.loginForm.valid) {
      this.loadingService.showLoading();
      this.authService.authenticate(this.loginForm.value).then((data) => {
        this.router.navigateByUrl(this.redirectionUrl);
      }).catch(e => {
        if (e.oauthError.data.lastIndexOf("Compte supprimé") > -1) {
          this.alertService.showAlert(["Votre compte a été supprimé"], 'danger');
        } else {
          this.alertService.showAlert(["Combinaison Identifiant/Mot de passe invalide"], 'danger');
        }
      }).finally(() => this.loadingService.dismissLoading());
    }
  }
}
