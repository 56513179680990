import { Pipe, PipeTransform } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Observable, of } from 'rxjs';

declare var EXIF;

@Pipe({
    name: 'fixOrientation'
})
export class FixOrientationPipe implements PipeTransform {

    private _checkedDesktopBrowsers: string[] = ['Safari'];
    private _checkedMobileOs: string[] = ['iOS'];
    private _maxMajorIOSVersion: number = 11;

    constructor(private deviceService: DeviceDetectorService) {}

    transform(value: any, ...args: any[]): any {
        if ((this.deviceService.isMobile() && this._checkedMobileOs.includes(this.deviceService.os)) || (this.deviceService.isDesktop() && this._checkedDesktopBrowsers.includes(this.deviceService.browser))) {
                
            // Check iOS version
            if (this.deviceService.isMobile()) {
                let version = this.deviceService.userAgent.match(/OS (\d+)_(\d+)_?(\d+)?/);
                if (parseInt(version[1], 10) > this._maxMajorIOSVersion) {
                    return of(value);
                }
            }

            return new Observable(subscriber => {
                var image = new Image();
                image.src = value;

                image.onload = () => {
                    EXIF.getData(image, function() {
                        const orientation = EXIF.getTag(this, 'Orientation');

                        if (orientation) {
                            var width = image.width;
                            var height = image.height;
                            var canvas = document.createElement('canvas');
                            var ctx = canvas.getContext("2d");

                            if (4 < orientation && orientation < 9) {
                            canvas.width = height;
                            canvas.height = width;
                            } else {
                            canvas.width = width;
                            canvas.height = height;
                            }

                            switch (orientation) {
                                case 2: ctx.transform(-1, 0, 0, 1, width, 0); break;
                                case 3: ctx.transform(-1, 0, 0, -1, width, height); break;
                                case 4: ctx.transform(1, 0, 0, -1, 0, height); break;
                                case 5: ctx.transform(0, 1, 1, 0, 0, 0); break;
                                case 6: ctx.transform(0, 1, -1, 0, height, 0); break;
                                case 7: ctx.transform(0, -1, -1, 0, height, width); break;
                                case 8: ctx.transform(0, -1, 1, 0, 0, width); break;
                                default: break;
                            }

                            // draw image
                            ctx.drawImage(image, 0, 0);

                            subscriber.next(canvas.toDataURL());
                        } else {
                            subscriber.next(value);
                        }
                    });
                };
            });
        }

        return of(value);
    }

}
