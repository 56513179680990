import { Injectable } from '@angular/core';

import { ApiService } from './api.service';
import { Observable, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { Reaction } from '../models/reaction.model';
import { FeathersService } from './feathers.service';

@Injectable({
  providedIn: 'root'
})
export class ReactionService {

  private _fsServiceName: string = "reactions";
  private _createdReaction: BehaviorSubject<Reaction> = new BehaviorSubject<Reaction>(null);
  private _updatedReaction: BehaviorSubject<Reaction> = new BehaviorSubject<Reaction>(null);
  private _removedReaction: BehaviorSubject<Reaction> = new BehaviorSubject<Reaction>(null);

  constructor(private apiService: ApiService,
    private feathers: FeathersService) {
      this.setCreatedReactionEvent();
      this.setUpdatedReactionEvent();
      this.setRemovedReactionEvent();
  }
  
  onCreatedReaction(): Observable<Reaction> {
    return this._createdReaction;
  }

  onUpdatedReaction(): Observable<Reaction> {
    return this._updatedReaction;
  }

  onRemovedReaction(): Observable<Reaction> {
    return this._removedReaction;
  }

  createReaction(data: any, params: any = {}): Observable<any> {
    return this.apiService.post(this._fsServiceName, data).pipe(
      map(reaction => new Reaction().deserialize(reaction))
    );
  }

  updateReaction(id: string, data: any, params: any = {}): Observable<any> {
    return this.apiService.put(this._fsServiceName, id, data, params).pipe(
      map(reaction => new Reaction().deserialize(reaction))
    );
  }

  deleteReaction(data: any, id: string): Observable<any> {
    return this.apiService.delete(this._fsServiceName, id).pipe(
      map(reaction => new Reaction().deserialize(reaction))
    );
  }

  patchReaction(data: any): Observable<any> {
    return this.apiService.patch(this._fsServiceName, data);
  }

  setCreatedReactionEvent() {
    this.feathers.client.service(this._fsServiceName).on('created', reaction => {
      this._createdReaction.next(new Reaction().deserialize(reaction));
      this._createdReaction.next(null);
    });
  }

  setUpdatedReactionEvent() {
    this.feathers.client.service(this._fsServiceName).on('updated', reaction => {
      this._updatedReaction.next(new Reaction().deserialize(reaction));
      this._updatedReaction.next(null);
    });
  }

  setRemovedReactionEvent() {
    this.feathers.client.service(this._fsServiceName).on('patched', reaction => {
      this._removedReaction.next(new Reaction().deserialize(reaction));
      this._removedReaction.next(null);
    });
  }
}
