import { Serializable } from '../interfaces/serializable';

import { Member } from './member.model';
import { Post } from './post.model';
import { Comment } from './comment.model';

export class Reaction implements Serializable {
  private _id: number = null;
  public type: string = "";
  public comment: Comment = null;
  public post: Post = null;
  public token: string = "";
  public member: Member = null;

  constructor() {}

  public getIRI() {
    return '/api/reactions/' + this.token;
  }

  public getId() {
    return this._id;
  }

  public deserialize(input: any) {
    if (!input) {
      return null;
    }

    Object.getOwnPropertyNames(this).forEach((property) => {
      switch (property) {
        case 'member':
            this[property] = new Member().deserialize(input[property]);
            break;
        case 'post':
            this[property] = new Post().deserialize(input[property]);
            break;
        case 'comment':
            this[property] = new Comment().deserialize(input[property]);
            break;
        default:
          if ('_id' == property && input.id) {
            this._id = input.id;
          } else if (property in input) {
            this[property] = input[property];
          }
          break;
      }
    });
    return this;
  }

  public serialize(): any {
    var output = {};

    Object.getOwnPropertyNames(this).forEach((property) => {
      switch (property) {
        case 'member':
        case 'post':
        case 'comment':
          output[property] = this[property] != null ? this[property].getIRI() : null;
          break;
        default:
          if (property != '_id') {
            output[property] = this[property];
          }
          break;
      }
    });

    return output;
  }
}
