import { environment } from '../../../environments/environment';
import { Observable, BehaviorSubject } from 'rxjs';
import { User } from '../models/user.model';
import * as i0 from "@angular/core";
import * as i1 from "./feathers.service";
import * as i2 from "./me.service";
export class AuthService {
    constructor(feathersService, meService) {
        this.feathersService = feathersService;
        this.meService = meService;
        this._strategy = 'api';
        this._authentication = new BehaviorSubject(false);
    }
    isAuthenticated() {
        return this._authentication.asObservable();
    }
    updateAuthentication(authenticated) {
        this._authentication.next(authenticated);
    }
    authenticateAnonymous() {
        const data = {
            strategy: this._strategy,
            code: 'client_credentials',
            clientId: environment.fsAPI.clientId,
            clientSecret: environment.fsAPI.clientSecret
        };
        return new Promise((resolve, reject) => {
            this.feathersService.client.authenticate(data).then(res => {
                resolve(res);
            }, err => {
                reject(err);
            });
        });
    }
    authenticate(data = {}) {
        data = Object.assign({
            strategy: this._strategy,
            clientId: environment.fsAPI.clientId,
            clientSecret: environment.fsAPI.clientSecret,
            code: !data.accessToken ? 'password' : 'refresh_token'
        }, data);
        return new Promise((resolve, reject) => {
            this.feathersService.client.authenticate(data).then(res => {
                this.meService.storeMe(new User().deserialize(res.user));
                this._authentication.next(true);
                resolve(res);
            }, err => {
                this._authentication.next(false);
                reject(err);
            });
        });
    }
    logout() {
        return new Promise((resolve, reject) => {
            this.feathersService.client.logout().then(res => {
                this.meService.deleteMe();
                this._authentication.next(false);
                resolve();
            }, err => reject(err));
        });
    }
}
AuthService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthService_Factory() { return new AuthService(i0.ɵɵinject(i1.FeathersService), i0.ɵɵinject(i2.MeService)); }, token: AuthService, providedIn: "root" });
