<div class="modal-header">
    <h3 class="modal-title text-uppercase">Information</h3>
    <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
        <i class="fas fa-times"></i>
    </button>
</div>
<div class="modal-body">
    <div class="mb-2">
        Vous ne faites plus partie de ce cercle.
    </div>
    <div class="mb-8">
        Un mail vous a été envoyé afin de vous permettre de télécharger les données que vous y avez enregistrées.
    </div>
    <div class="small">Attention : il vous reste {{ nbDays }} {{ (nbDays > 1) ? 'jours' : 'jour' }} pour les télécharger</div>
    <div class="float-right mb-4">
        <button type="button" class="btn btn-primary btn--small py-2 px-3" (click)="bsModalRef.hide()">OK</button>
    </div>
</div>
