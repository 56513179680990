import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UserService } from '../../core/services/user.service';
import { LoadingService } from '../../core/services/loading.service';
import { ActivatedRoute } from '@angular/router';
import * as FileSaver from 'file-saver';
import { finalize } from 'rxjs/operators';
import { AlertService } from '../../core/services/alert.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ModalArchiveExpiredComponent } from '../../circles/circle/modal-archive-expired/modal-archive-expired.component';
import { MemberService } from '../../core/services/member.service';
import { combineLatest, Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-login-download-archive',
  templateUrl: './login-download-archive.component.html',
  styleUrls: ['./login-download-archive.component.scss']
})
export class LoginDownloadArchiveComponent implements OnInit {

  public codeForm: FormGroup;
  public token: string;
  public hash: string;
  public archiveId: string;
  public expires: string;
  public memberId: string;
  public modalArchiveExpired: BsModalRef;
  private subscriptionsModal: Subscription[] = [];

  static readonly ARCHIVE_EXPIRED = "L'archive a expiré";

  constructor(private loadingService: LoadingService,
    private userService: UserService,
    private route: ActivatedRoute,
    private alertService: AlertService,
    private modalService: BsModalService,
    private memberService: MemberService,
    private changeDetection: ChangeDetectorRef,
    private toastrService: ToastrService) { }

  ngOnInit() {
    this.prepareCodeForm();
    this.getParameters();
  }

  prepareCodeForm() {
      this.codeForm = new FormGroup({
        code: new FormControl('', Validators.required)
      });
  }

  getParameters() {
    this.token = this.route.snapshot.queryParamMap.get('token');
    this.hash = this.route.snapshot.queryParamMap.get('_hash');
    this.archiveId = this.route.snapshot.queryParamMap.get('archive');
    this.expires = this.route.snapshot.queryParamMap.get('expire');
    this.memberId = this.route.snapshot.queryParamMap.get('membre') ? this.route.snapshot.queryParamMap.get('membre') : null;
  }

  onCodeFormSubmit() {
    this.codeForm['submitted'] = true;

    if (this.codeForm.valid) {
      this.loadingService.showLoading();
      this.userService.downloadArchive(this.token, this.hash, this.archiveId, this.expires, this.memberId, this.codeForm.get('code').value).pipe(
        finalize(() => this.loadingService.dismissLoading())
    ).subscribe(
        response => {
          var blob = new Blob([response], { type: 'application/zip' });

          setTimeout(function() {
              FileSaver.saveAs(blob, 'donnees-cercle-confiance.zip');
          }, 250);
        }, (error) => {
          this.alertService.showAlert(error.errors, 'danger');

          if (error.errors.indexOf(LoginDownloadArchiveComponent.ARCHIVE_EXPIRED) > -1) {
            this.modalArchiveExpired = this.modalService.show(ModalArchiveExpiredComponent);
            
            const _combine = combineLatest(
              this.modalService.onHide
            ).subscribe(() => this.changeDetection.markForCheck());

            this.subscriptionsModal.push(
              this.modalService.onHide.subscribe((reason) => {
                if (this.modalArchiveExpired.content && this.modalArchiveExpired.content.confirmation) {
                  this.loadingService.showLoading();
                  if (this.memberId != null) {
                    this.memberService.regenerateArchive(this.memberId).pipe(
                      finalize(() => this.loadingService.dismissLoading())
                    ).subscribe(member => {
                      this.alertService.closeAlert();
                      this.toastrService.success('Votre demande a bien été prise en compte', 'Confirmation');
                    });
                  } else {
                    this.userService.regenerateArchive(this.token).pipe(
                      finalize(() => this.loadingService.dismissLoading())
                    ).subscribe(member => {
                      this.alertService.closeAlert();
                      this.toastrService.success('Votre demande a bien été prise en compte', 'Confirmation');
                    });
                  }
                }
                this.unsubscribe();
              })
            );
            this.subscriptionsModal.push(_combine);
          }
        });
    }
  }

  private unsubscribe() {
    this.subscriptionsModal.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
    this.subscriptionsModal = [];
  }

}
